import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Organisation, OrganisationArraySchema } from "@vision/common";
import React, { useEffect } from "react";
import { apiHooks } from "../../../api/index.js";

function OrganisationRow({ organisation }: { organisation: Organisation }) {
  return (
    <TableRow key={organisation.id}>
      <TableCell>{organisation.name}</TableCell>
      <TableCell>{organisation.role}</TableCell>
    </TableRow>
  );
}

export const OrganisationInner = ({
  organisations,
}: {
  organisations: Organisation[];
}) => {
  return (
    <Box>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organisations.map((organisation) => (
              <OrganisationRow
                key={organisation.id}
                organisation={organisation}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export function OrganisationManagement() {
  const [{ data, loading }] = apiHooks.organisation.search("");
  const [organisations, setOrganisations] = React.useState<Organisation[]>([]);

  useEffect(() => {
    if (data) {
      const orgs = OrganisationArraySchema.parse(data.results);
      setOrganisations(orgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return loading ? (
    <CircularProgress />
  ) : (
    <OrganisationInner organisations={organisations} />
  );
}
