import "@fontsource/ibm-plex-sans/300.css";
import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/700.css";
import { createTheme } from "@mui/material";
import { NotificationsProviderSlotProps } from "@toolpad/core/useNotifications";

export const theme = createTheme({
  palette: {
    common: {
      black: "#000",
      white: "#FFFFFF",
      background: "#D7D9DE",
      lightBlue: "#4E87E5",
      teal: "#00BF7C",
      deepTeal: "#008F5A",
    },
    primary: { main: "#0033a1" },
    secondary: { main: "#F5F5F7", light: "#F6F9FD" },
    error: {
      light: "#FEF6F4",
      dark: "#EC501C",
      main: "#D32f2f",
    },
    warning: {
      light: "#FFFCF3",
      main: "#FDC910",
      contrastText: "#FFFFFF",
      dark: "#FF9900",
    },
    text: {
      primary: "#424552",
      secondary: "#54596B",
      disabled: "#9A9CA1",
      deepNavy: "#0B122C",
    },
    banner: {
      background: "#DCE7FA",
    },
  },
  typography: {
    fontFamily: `IBM Plex Sans, sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          "&.Mui-disabled": {
            backgroundColor: "text.disabled",
            color: "#FFFFFF",
          },
        },
      },
    },
  },
});

export const notificationsSlotProps: NotificationsProviderSlotProps = {
  snackbar: {
    anchorOrigin: { horizontal: "right", vertical: "top" },
    sx: {
      border: 1,
      borderColor: "grey.300",
      borderRadius: 1,
      boxShadow: 2,
    },
  },
};
