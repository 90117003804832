import {
  AvailableOrganisationUnbranded,
  OrganisationIdUnbranded,
  OrganisationUnbranded,
} from "@vision/common";
import { AxiosInstance } from "axios";
import { useApiAxios } from "../axios/api.js";

export const config = {
  organisation: {
    getAvailableOrganisations: (id: OrganisationIdUnbranded) => ({
      url: `/api/organisation/${id}/available-organisations`,
      method: "get",
    }),
    search: (query: string, limit: number = 10, offset: number = 0) => {
      return {
        url: `/api/organisation/search`,
        method: "get",
        params: {
          query,
          limit,
          offset,
        },
      };
    },
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    organisation: {
      getAvailableOrganisations: (id: OrganisationIdUnbranded) =>
        apiAxios.request<AvailableOrganisationUnbranded[]>(
          config.organisation.getAvailableOrganisations(id),
        ),
      search: (query: string, limit: number = 10, offset: number = 0) =>
        apiAxios.request<{ results: OrganisationUnbranded[]; count: number }>(
          config.organisation.search(query, limit, offset),
        ),
    },
  };
};

export const apiHooks = () => {
  return {
    organisation: {
      search: (query: string, limit: number = 10, offset: number = 0) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useApiAxios<{
          results: OrganisationUnbranded[];
          count: number;
        }>(config.organisation.search(query, limit, offset));
      },
      getAvailableOrganisations: (id: OrganisationIdUnbranded) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useApiAxios<AvailableOrganisationUnbranded[]>(
          config.organisation.getAvailableOrganisations(id),
        );
      },
    },
  };
};
