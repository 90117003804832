import {
  type EnsureItemNotInArray,
  type EnsureItemsInArray,
  type IsEmptyArray,
  type Prettify,
  type RemoveItemsFromArray,
} from "@thalamos/common";
import { z, type ZodTypeAny } from "zod";
import {
  createPermissiveSchema,
  type FormBuilderField,
  type FormBuilderReadonlyField,
} from "../../types/index.js";

type FieldInfoBaseInput<
  FieldName extends string,
  FieldSchema extends z.ZodTypeAny,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = FormBuilderField<any, FieldName> & {
  schema: FieldSchema;
};

type FieldAlwaysRequiredInput<
  FieldName extends string,
  FieldSchema extends z.ZodTypeAny,
> = FieldInfoBaseInput<FieldName, FieldSchema>;

type FieldConditionallyRequiredInput<
  FieldName extends string,
  FieldSchema extends z.ZodTypeAny,
  FormSchema extends Record<string, unknown>,
> = FieldInfoBaseInput<FieldName, FieldSchema> & {
  isVisible: ({ formValues }: { formValues: Partial<FormSchema> }) => boolean;
};

type ReadonlyFieldInfoBaseInput<
  FieldName extends string,
  FormSchema extends Record<string, unknown>,
> = FormBuilderReadonlyField<FormSchema, FieldName>;

type ReadonlyFieldAlwaysRequiredInput<
  FieldName extends string,
  FormSchema extends Record<string, unknown>,
> = ReadonlyFieldInfoBaseInput<FieldName, FormSchema>;

type ReadonlyFieldConditionallyRequiredInput<
  FieldName extends string,
  FormSchema extends Record<string, unknown>,
> = ReadonlyFieldInfoBaseInput<FieldName, FormSchema> & {
  isVisible: ({ formValues }: { formValues: Partial<FormSchema> }) => boolean;
};

type FieldInfo<
  FieldName extends Readonly<string>,
  FieldSchema extends z.ZodTypeAny,
  FormSchema extends Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = FieldInfoBaseInput<FieldName, FieldSchema> & {
  isVisible:
    | true
    | FieldConditionallyRequiredInput<
        FieldName,
        FieldSchema,
        FormSchema
      >["isVisible"];
};

type ReadonlyFieldInfo<
  FieldName extends string,
  FormSchema extends Record<string, unknown>,
> = ReadonlyFieldInfoBaseInput<FieldName, FormSchema> & {
  isVisible:
    | true
    | ReadonlyFieldConditionallyRequiredInput<
        FieldName,
        FormSchema
      >["isVisible"];
};

/**
 * A form validation issue.
 */
type FormValidationIssue = { fieldName: string; message: string };

/**
 * A form validation function.
 */
type FormValidationFn<FormValues extends Record<string, unknown>> = ({
  formValues,
}: {
  formValues: FormValues;
}) => FormValidationIssue[];

type PageInfoInput<FieldNames extends readonly unknown[]> = {
  fields: readonly FieldNames[number][];
};

type PageInfoOutput = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: readonly FormBuilderField<any, any>[];
};

/**
 * Transforms an object so that all values which are invalid are set to undefined.
 * This is useful during the process of the form being filled out, as it allows us
 * to treat fields with intermediate values as undefined.
 */
export function treatInvalidValuesAsUndefined<Schema extends z.ZodTypeAny>(
  strictFieldSchemas: Record<string, ZodTypeAny>,
  values: z.infer<Schema>,
): Partial<z.infer<Schema>> {
  const valuesWithInvalidValuesSetToUndefined = Object.fromEntries(
    Object.keys(values).map((key) => {
      const parsedResult = (
        strictFieldSchemas[key] as ZodTypeAny | undefined
      )?.safeParse(values[key]);
      return [key, parsedResult?.success ? parsedResult.data : undefined];
    }),
  );

  // This line is useful for debugging. It should only output a valid value
  // as reflected in the TypeScript types, or undefined. It should never output
  // partially completed field information.
  // console.log(valuesWithInvalidValuesSetToUndefined);

  return valuesWithInvalidValuesSetToUndefined as Partial<z.infer<Schema>>;
}

export class MultiPageFormBuilder<
  FieldNames extends string[] = [],
  UnallocatedFieldNames extends string[] = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  FieldSchemas extends Record<string, z.ZodTypeAny> = {},
> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fields: FieldInfo<any, z.ZodTypeAny, any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonlyFields: ReadonlyFieldInfo<any, any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private pages: PageInfoInput<any>[];
  private validations: FormValidationFn<Record<string, unknown>>[];

  constructor() {
    this.fields = [];
    this.readonlyFields = [];
    this.pages = [];
    this.validations = [];
  }

  /**
   * Declare the existence of a field in the form.
   * The field is always required.
   *
   * @param field Information about the field
   * @returns
   */
  withFieldAlwaysVisible<
    FieldName extends string,
    FieldSchema extends z.ZodTypeAny,
  >(
    field: FieldAlwaysRequiredInput<
      EnsureItemNotInArray<FieldName, FieldNames>,
      FieldSchema
    >,
  ): MultiPageFormBuilder<
    [...FieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    [...UnallocatedFieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    Prettify<FieldSchemas & { [K in FieldName]: z.infer<FieldSchema> }>
  > {
    this.fields.push({ ...field, isVisible: true });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Declare the existence of a field in the form.
   * The field is conditionally required so may be null.
   *
   * @param field Information about the field
   * @returns
   */
  withFieldConditionallyVisible<
    FieldName extends string,
    FieldSchema extends z.ZodTypeAny,
  >(
    field: FieldConditionallyRequiredInput<
      EnsureItemNotInArray<FieldName, FieldNames>,
      FieldSchema,
      FieldSchemas
    >,
  ): MultiPageFormBuilder<
    [...FieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    [...UnallocatedFieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    Prettify<FieldSchemas & { [K in FieldName]: z.infer<FieldSchema> | null }>
  > {
    this.fields.push(field);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Declare the existence of a readonly field in the form.
   * The field is always visible.
   *
   * @param field Information about the field
   * @returns
   */
  withReadonlyFieldAlwaysVisible<FieldName extends string>(
    field: ReadonlyFieldAlwaysRequiredInput<
      EnsureItemNotInArray<FieldName, FieldNames>,
      FieldSchemas
    >,
  ): MultiPageFormBuilder<
    [...FieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    [...UnallocatedFieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    FieldSchemas
  > {
    this.readonlyFields.push({ ...field, isVisible: true });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Declare the existence of a field in the form.
   * The field is conditionally required so may be null.
   *
   * @param field Information about the field
   * @returns
   */
  withReadonlyFieldConditionallyVisible<FieldName extends string>(
    field: ReadonlyFieldConditionallyRequiredInput<
      EnsureItemNotInArray<FieldName, FieldNames>,
      FieldSchemas
    >,
  ): MultiPageFormBuilder<
    [...FieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    [...UnallocatedFieldNames, EnsureItemNotInArray<FieldName, FieldNames>],
    FieldSchemas
  > {
    this.readonlyFields.push(field);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Declare the existence of a page in the form.
   * This can only reference fields which have already been declared.
   *
   * @param fields The fields on the page
   * @returns
   */
  withPage<FieldNames extends UnallocatedFieldNames[number][]>(
    fields: EnsureItemsInArray<FieldNames, UnallocatedFieldNames>,
  ): MultiPageFormBuilder<
    FieldNames,
    RemoveItemsFromArray<FieldNames, UnallocatedFieldNames>,
    FieldSchemas
  > {
    this.pages.push({
      fields,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  /**
   * Add a form-level validation function.
   *
   * @param validationFn A validation function to run on the form as a whole.
   * @returns
   */
  withFormValidation(validationFn: FormValidationFn<FieldSchemas>): this {
    this.validations.push(
      validationFn as FormValidationFn<Record<string, unknown>>,
    );
    return this;
  }

  /**
   * Build the form. Returns "never" if there are any fields unallocated to pages.
   *
   * @returns A schema, initialValues, and pages to be provided to the MultiPageForm component.
   */
  build(): IsEmptyArray<UnallocatedFieldNames> extends true
    ? {
        strictFieldSchemas: Record<string, ZodTypeAny>;
        formSchema: ZodTypeAny;
        formType: FieldSchemas;
        pages: PageInfoOutput[];
      }
    : never {
    // Validate that all fields have been allocated to a page
    {
      const allFields = [...this.fields, ...this.readonlyFields].map(
        (field) => field.fieldName,
      );
      const fieldsOnPages = this.pages.flatMap((page) => page.fields);
      const unallocatedFields = allFields.filter(
        (field) => !fieldsOnPages.includes(field),
      );

      if (unallocatedFields.length > 0) {
        // The return signature at this point will be "never"
        throw new Error(
          `The following fields have not been allocated to a page: ${unallocatedFields.join(
            ", ",
          )}`,
        );
      }
    }

    // Individual strict schemas for each field.
    // These are used in isolation by Formik to determine whether
    // each provided field is valid.
    const strictFieldSchemas: Record<string, ZodTypeAny> = this.fields.reduce(
      (acc, field) => {
        return {
          ...acc,
          [field.fieldName]: field.schema,
        };
      },
      {},
    );

    // A permissive schema which allows any fields to be present.
    const permissiveSchema = createPermissiveSchema(strictFieldSchemas);

    // Clean up any values which should be null because of isRequired
    const transformedSchema: ZodTypeAny = this.fields.reduce<ZodTypeAny>(
      (acc, field) =>
        acc.transform((data) =>
          typeof field.isVisible === "function"
            ? field.isVisible({
                formValues: treatInvalidValuesAsUndefined(
                  strictFieldSchemas,
                  data,
                ),
              })
              ? data
              : {
                  ...data,
                  [field.fieldName]: null,
                }
            : data,
        ),

      permissiveSchema,
    );

    // Run any form-level validations
    const validatedSchema: ZodTypeAny = this.validations.reduce(
      (acc, validationFn) =>
        acc.superRefine((data, ctx) =>
          validationFn({ formValues: data }).map(({ fieldName, message }) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [fieldName],
              message,
              fatal: true,
            });
          }),
        ),
      transformedSchema,
    );

    // TODO: Super refine missing fields?

    const pages = this.pages.map((page) => {
      return {
        fields: page.fields.map((fieldName) => {
          const field = [...this.fields, ...this.readonlyFields].find(
            (f) => f.fieldName === fieldName,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as FieldInfo<any, z.ZodTypeAny, any> | ReadonlyFieldInfo<any, any>;
          return {
            ...field,
            visibilityFn:
              typeof field.isVisible === "function"
                ? field.isVisible
                : undefined,
          };
        }),
      };
    });

    return {
      strictFieldSchemas: strictFieldSchemas,
      formSchema: validatedSchema,
      formType: {} as FieldSchemas,
      pages: pages,
    } as unknown as IsEmptyArray<UnallocatedFieldNames> extends true
      ? {
          strictFieldSchemas: Record<string, ZodTypeAny>;
          formSchema: ZodTypeAny;
          formType: FieldSchemas;
          pages: PageInfoOutput[];
        }
      : never;
  }
}
