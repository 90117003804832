import { defined } from "@thalamos/common";
import { ok, safeTry } from "neverthrow";
import {
  FormSection,
  type RequestPolicePresenceMinimumFields,
} from "../../types/Workflow.js";
import {
  IncidentFormType,
  type IncidentWorkItemWithContext,
} from "../../types/workItem.js";
import type { ActionInput } from "../ActionInput.js";

type MarkPolicePresenceAction = {
  canFinishForm: boolean;
};

// Helper function to extract the police presence form and section
const getPolicePresenceFormAndSection = (
  incidentWorkItem: IncidentWorkItemWithContext | undefined,
  formType: IncidentFormType,
  sectionId: FormSection,
) => {
  return safeTry(function* () {
    const doesHaveAnActivePolicePresenceFormId =
      incidentWorkItem?.incidentWorkItem.activeOfficerRequestFormId;
    if (doesHaveAnActivePolicePresenceFormId === null) {
      return ok(undefined);
    }
    const requestPolicePresenceForm = yield* defined(
      (incidentWorkItem?.forms ?? []).find(
        (form) => form.formType === formType,
      ),
    );

    const requestPolicePresenceFormSection = yield* defined(
      requestPolicePresenceForm.sections.find(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        (section) => section.id === sectionId,
      ),
    );

    return ok(requestPolicePresenceFormSection);
  });
};

/*
 * This function checks if the police presence form can be finished.
 * The form can be finished if the user has selected either "yes" or "no"
 * for the "hasPolicePresenceRequested" field (nhs side)
 */
export function canFinishForm(
  incidentWorkItem: IncidentWorkItemWithContext | undefined,
): boolean {
  if (!incidentWorkItem) return false;

  const requestPolicePresenceFormSection = getPolicePresenceFormAndSection(
    incidentWorkItem,
    IncidentFormType.requestPolicePresence,
    FormSection.requestPolicePresenceMain,
  ).unwrapOr(undefined);

  if (!requestPolicePresenceFormSection) return false;

  const hasRequestedPolicePresence = (
    requestPolicePresenceFormSection.data as
      | RequestPolicePresenceMinimumFields
      | undefined
  )?.hasPolicePresenceRequested.value;

  return (
    hasRequestedPolicePresence === "no" || hasRequestedPolicePresence === "yes"
  );
}

export function calculatePolicePresenceActions(
  actionInput: ActionInput,
): MarkPolicePresenceAction {
  return { canFinishForm: canFinishForm(actionInput.incidentWorkItem) };
}
