import React, { useEffect } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PlaceOfSafetyFormBuilderField, TranslationKey } from "@vision/common";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";
import { usePlacesOfSafety } from "./PlaceOfSafetyContext.js";

const debug = debugFn("vision-frontend:PlaceOfSafetyPicker");

export type PlaceOfSafetyPickerProps = {
  fieldName: string;
  label?: TranslationKey;
};

export const PlaceOfSafetyPicker = <
  Form extends Record<string, unknown>,
  Field extends keyof Form,
>({
  fieldName,
  label,
}: PlaceOfSafetyPickerProps) => {
  const { t } = useTranslation();
  const placesOfSafety = usePlacesOfSafety();
  const [manuallyInput, setManuallyInput] = React.useState(false);
  const [manualName, setManualName] = React.useState<string | null>(null);
  const [manualAddress, setManualAddress] = React.useState<string | null>(null);
  const [manualPostalCode, setManualPostalCode] = React.useState<string | null>(
    null,
  );

  const [fieldInput, meta, helpers] =
    useField<PlaceOfSafetyFormBuilderField<Form, Field>>(fieldName);

  useEffect(() => {
    if (manuallyInput) {
      void helpers.setValue({
        name: manualName ?? "",
        address: manualAddress ?? "",
        postalCode: manualPostalCode ?? "",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
  }, [manuallyInput, manualName, manualAddress, manualPostalCode, helpers]);

  return (
    <FormControl fullWidth data-testid={`formField-${fieldName}`}>
      {label && <FormLabel label={label} />}
      {
        // Objects are compared by identity rather than by value, so we
        // need to stringify the object in order for value comparisons to be made
        // by child components such as MenuItem.
      }
      <Select
        name={fieldName}
        value={JSON.stringify(fieldInput.value)}
        onChange={(event) => {
          // Take the stringified value and parse it back into the object that Formik expects.
          event.target.value = JSON.parse(event.target.value);
          fieldInput.onChange(event);
        }}
        onBlur={fieldInput.onBlur}
        disabled={manuallyInput}
        data-testid="select-dropdown"
      >
        {placesOfSafety.placesOfSafety.map((option) => (
          <MenuItem key={option.id} value={JSON.stringify(option)}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <FormControlLabel
        label={t("placeOfSafetyPicker.manuallyEnter" satisfies TranslationKey)}
        control={
          <Checkbox
            checked={manuallyInput}
            onChange={() => {
              setManuallyInput(!manuallyInput);
            }}
            inputProps={{ "aria-label": "controlled" }}
            data-testid="manual-checkbox"
          />
        }
      />
      {manuallyInput && (
        <Stack spacing={2}>
          <TextField
            label={t("placeOfSafetyPicker.name" satisfies TranslationKey)}
            value={manualName}
            onChange={(e) => {
              setManualName(e.target.value);
            }}
            data-testid="name-textfield"
          />
          <TextField
            label={t("placeOfSafetyPicker.address" satisfies TranslationKey)}
            value={manualAddress}
            onChange={(e) => {
              setManualAddress(e.target.value);
            }}
            data-testid="address-textfield"
          />
          <TextField
            label={t("placeOfSafetyPicker.postalCode" satisfies TranslationKey)}
            value={manualPostalCode}
            onChange={(e) => {
              setManualPostalCode(e.target.value);
            }}
            data-testid="postalcode-textfield"
          />
        </Stack>
      )}
      {meta.error && meta.touched && (
        <FormError data-testid="place-of-safety-error" error={meta.error} />
      )}
      {debug.enabled && (
        <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
          {JSON.stringify(fieldInput.value)}
        </Typography>
      )}
    </FormControl>
  );
};
