import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import { freeTextSchema, yesNoSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "hasPolicePresenceRequested",
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "policePresenceRequestedReason",
    type: "dropdown",
    ...new FieldBuilder()
      .withNullableSchema(
        z.enum([
          "notEnoughStaff" as const,
          "transfer" as const,
          "other" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    options: [
      {
        label: "requestPolicePresence.dropdown.notEnoughStaff",
        value: "notEnoughStaff",
      },
      {
        label: "requestPolicePresence.dropdown.transfer",
        value: "transfer",
      },
      {
        label: "requestPolicePresence.dropdown.other",
        value: "other",
      },
    ],
    isVisible: ({ formValues }) =>
      formValues.hasPolicePresenceRequested?.value === "yes",
  })
  .withFieldConditionallyVisible({
    fieldName: "furtherReasoning",
    type: "textbox",
    ...new FieldBuilder().withNullableSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasPolicePresenceRequested?.value === "yes",
  })
  .withPage([
    "hasPolicePresenceRequested",
    "policePresenceRequestedReason",
    "furtherReasoning",
  ])
  .build();

export type RequestPolicePresenceMainFormData =
  typeof formSpecification.formType;
