import React, { useMemo, useState } from "react";

import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

const debug = debugFn("vision-frontend:RadioButtonGroup");

export type RadioButtonGroupProps = {
  fieldName: string;
  label?: TranslationKey;
  options: { label: TranslationKey; value: string }[];
  canBeUnknown?: boolean;
};

export type RadioButtonGroupOutput =
  | {
      value: null;
      isUnknown: true;
    }
  | {
      value: string;
      valueLabel: string;
      isUnknown: false;
    }
  | {
      value: string;
      valueLabel: string;
    }
  | "";

export function RadioButtonGroup({
  fieldName,
  label,
  options,
  canBeUnknown,
  ...props
}: RadioButtonGroupProps) {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<RadioButtonGroupOutput>(fieldName);

  // We use this to store the last value that the user selected.
  // This is so that we can restore it if they turn on the "unknown" flag.
  // We pre-populate this with the incoming "initialValue".
  const [selectedValue, setSelectedValue] = useState<string | null>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    field.value === "" ? null : field.value.value,
  );

  // Clean up the field value to be a structured object
  const fieldValue = useMemo(() => {
    const value =
      field.value === ""
        ? // Turn null-ish values into a structured object
          ({ value: null, valueLabel: null, isUnknown: false } as const)
        : "isUnknown" in field.value
          ? field.value.isUnknown
            ? // If "unknown", clear out the value and label
              ({ value: null, valueLabel: null, isUnknown: true } as const)
            : {
                value: field.value.value,
                valueLabel: field.value.valueLabel,
                isUnknown: field.value.isUnknown,
              }
          : { value: field.value.value, valueLabel: field.value.valueLabel };

    debug("Cleaned up value", { value });
    return value;
  }, [field.value]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runAsync = async () => {
      const selectedValue = event.target.value;

      const selectedValueLabel = t(
        options.find((option) => option.value === selectedValue)?.label ?? "",
      );

      const newValue = {
        value: selectedValue,
        valueLabel: selectedValueLabel,
        ...(canBeUnknown ? { isUnknown: false } : {}),
      };

      debug("handleValueChange, setting value", newValue);
      setSelectedValue(selectedValue);
      await helpers.setValue(newValue);
      await helpers.setTouched(true);
    };

    void runAsync();
  };

  const handleUnknownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runAsync = async () => {
      const selectedValueLabel = t(
        options.find((option) => option.value === selectedValue)?.label ?? "",
      );

      const newValue = event.target.checked
        ? ({
            value: null,
            valueLabel: null,
            isUnknown: true,
          } as const)
        : selectedValue
          ? ({
              value: selectedValue,
              valueLabel: selectedValueLabel,
              isUnknown: false,
            } as const)
          : "";

      debug("handleUnknownChange, Setting value", newValue);

      await helpers.setValue(newValue);
      await helpers.setTouched(true);
    };

    void runAsync();
  };

  return (
    <Box data-testid={`formField-${fieldName}`}>
      <FormControl component="fieldset">
        {label && <FormLabel data-testid="radio-label" label={label} />}
        <RadioGroup
          data-testid="radio-group"
          value={selectedValue}
          onChange={handleValueChange}
          {...props}
          name={fieldName}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={t(option.label)}
                data-testid={`radio-option-${option.value}`}
                disabled={"isUnknown" in fieldValue && fieldValue.isUnknown}
              />
            );
          })}
        </RadioGroup>
        {canBeUnknown && (
          <FormControlLabel
            control={
              <Checkbox
                checked={"isUnknown" in fieldValue && fieldValue.isUnknown}
                onChange={handleUnknownChange}
                data-testid={`formField-${fieldName}-unknown`}
              />
            }
            label={t("unknown")}
          />
        )}

        {meta.touched && meta.error && (
          <FormError data-testid="radio-error" error={meta.error} />
        )}
      </FormControl>
      {debug.enabled && (
        <>
          <Divider />
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"field.value : "}
            {JSON.stringify(field.value)}
          </Typography>
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"Treated like: "}
            {JSON.stringify(fieldValue)}
          </Typography>
          <Divider />
        </>
      )}
    </Box>
  );
}
