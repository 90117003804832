import React from "react";

import { Stack } from "@mui/material";
import {
  FormSection,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentWorkItemWithContext,
  RecordPlaceOfSafetyMinimumFields,
  WorkflowVersions,
} from "@vision/common";
import { useNavigate, useParams } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import Banner, { BannerList } from "../../components/Banner/Banner.js";
import {
  LeavePlaceOfSafetyForm,
  LeavePlaceOfSafetyFormSkeleton,
} from "../../forms/LeavePlaceOfSafety/LeavePlaceOfSafetyForm.js";
import { createRoute } from "../../routing/createRoute.js";

export type LeavePlaceOfSafetyPageProps = Record<string, unknown>;

export type LeavePlaceOfSafetyPageInnerProps = {
  incidentWorkItem: IncidentWorkItemWithContext | undefined;
  refetch: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
};

export function LeavePlaceOfSafetyPage(_props: LeavePlaceOfSafetyPageProps) {
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ data: incidentWorkItem }, refetch] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(incidentId.data);

  return (
    <LeavePlaceOfSafetyPageInner
      incidentWorkItem={incidentWorkItem ?? undefined}
      refetch={refetch}
    />
  );
}

export function LeavePlaceOfSafetyPageInner({
  incidentWorkItem,
  refetch,
}: LeavePlaceOfSafetyPageInnerProps) {
  const navigate = useNavigate();
  const params = useParams();

  const placeOfSafetyFormId = IncidentFormIdSchema.safeParse(params.formId);
  if (!placeOfSafetyFormId.success) {
    throw new Error("Invalid form ID");
  }

  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      .workflowVersion as WorkflowVersions | undefined;

    const activePlaceOfSafetyName: string | undefined = (
      incidentWorkItem?.forms
        .find(
          (form) =>
            form.formType === IncidentFormType.recordPlaceOfSafety &&
            form.id === params.formId,
        )
        ?.sections.find(
          (section) => section.id === FormSection.recordPlaceOfSafetyMain,
        )?.data as RecordPlaceOfSafetyMinimumFields | undefined
    )?.placeOfSafety.name;

    const initialValues =
      incidentWorkItem?.forms
        .find(
          (form) =>
            form.formType === IncidentFormType.recordPlaceOfSafety &&
            form.id === params.formId,
        )
        ?.sections.find(
          (section) => section.id === FormSection.recordPlaceOfSafetyLeave,
        )?.data ?? undefined;

    return (
      <Stack margin={"1rem"}>
        {activePlaceOfSafetyName !== undefined && (
          <Banner
            bannerType={BannerList.INFO}
            body={`The current Place of Safety is "${activePlaceOfSafetyName}"`}
          />
        )}
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <LeavePlaceOfSafetyFormSkeleton />
        ) : (
          <LeavePlaceOfSafetyForm
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={async (values: Record<string, unknown>) => {
              try {
                // Populate it with values
                const populateFormResponse =
                  await api.incidentWorkItem.updateData(
                    incidentWorkItemId,
                    placeOfSafetyFormId.data,
                    FormSection.recordPlaceOfSafetyLeave,
                    values,
                    true,
                  );
                if (populateFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(populateFormResponse.data.message),
                  );
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                await refetch();
                navigate(
                  createRoute.incidentRecordPlaceOfSafetyPage(
                    incidentWorkItemId,
                  ),
                );
                return;
              } catch (error) {
                setError(
                  "Failed to record leaving place of safety: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            workflowVersion={workflowVersion}
          />
        )}
      </Stack>
    );
  };

  return render(incidentWorkItem);
}
