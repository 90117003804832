import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  commandUnitSchema,
  dateTimeSchema,
  freeTextSchema,
  LocationSchema,
  OrganisationRoles,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "custodyNumber", // 136-detention-1.0
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "detentionDetails.custodyNumber",
  })
  .withFieldAlwaysVisible({
    fieldName: "dateTimeOfDetention", // 136-detention-2.0
    type: "date-time",
    timeLabel: "detentionDetails.timeOfDetention",
    dateLabel: "detentionDetails.dateOfDetention",
    ...new FieldBuilder().withSchema(dateTimeSchema).build(),
  })
  .withFieldAlwaysVisible({
    fieldName: "orgResponsibleForDetention", // 136-detention-3.0
    type: "organisation",
    ...new FieldBuilder()
      .withSchema(commandUnitSchema)
      .captureValueLabel()
      .build(),
    filterByRoles: [OrganisationRoles.PoliceCommandUnit],
    label: "detentionDetails.responsibleForDetention",
  })
  .withFieldAlwaysVisible({
    fieldName: "locationDetentionTookPlace", // 136-detention-3.0.1
    type: "location",
    ...new FieldBuilder().withSchema(LocationSchema).build(),
    label: "detentionDetails.locationDetentionTookPlace",
  })
  .withFieldAlwaysVisible({
    fieldName: "locationType", // 136-detention-3.0.1.1
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "railwayLines" as const,
          "hospitalWards" as const,
          "rooftopsCommercial" as const,
          "policeStations" as const,
          "offices" as const,
          "school" as const,
          "gardensCarParks" as const,
          "nonResidentialRestricted" as const,
          "other" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    label: "detentionDetails.locationType.title",
    options: [
      {
        value: "railwayLines",
        label: "detentionDetails.locationType.railwayLines",
      },
      {
        value: "hospitalWards",
        label: "detentionDetails.locationType.hospitalWards",
      },
      {
        value: "rooftopsCommercial",
        label: "detentionDetails.locationType.rooftopsCommercial",
      },
      {
        value: "policeStations",
        label: "detentionDetails.locationType.policeStations",
      },
      { value: "offices", label: "detentionDetails.locationType.offices" },
      { value: "school", label: "detentionDetails.locationType.school" },
      {
        value: "gardensCarParks",
        label: "detentionDetails.locationType.gardensCarParks",
      },
      {
        value: "nonResidentialRestricted",
        label: "detentionDetails.locationType.nonResidentialRestricted",
      },
      { value: "other", label: "detentionDetails.locationType.other" },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "locationTypeOther", // 136-detention-3.0.1.1.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.locationType?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withPage([
    "custodyNumber", // 136-detention-1.0
  ])
  .withPage([
    "dateTimeOfDetention", // 136-detention-2.0
  ])
  .withPage([
    "orgResponsibleForDetention", // 136-detention-3.0
  ])
  .withPage([
    "locationDetentionTookPlace", // 136-detention-3.0.1
  ])
  .withPage([
    "locationType", // 136-detention-3.0.1.1
    "locationTypeOther", // 136-detention-3.0.1.1.1.1
  ])
  .build();

export type DetentionDetailsFormData = typeof formSpecification.formType;
