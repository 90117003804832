import { Close } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { RecordPolicePresenceFormModal } from "../RecordPolicePresenceForm/RecordPolicePresenceForm.js";

export interface PolicePresenceRequestedModalProps {
  open: boolean;
  close: () => void;
  closeNotification: () => void;
  requesterName: string;
  posName?: string;
  reason: string;
  reasonDescription?: string;
  incidentId: string;
  notificationId: string;
}

export function PolicePresenceRequestedModal({
  open,
  close,
  closeNotification,
  requesterName,
  posName,
  reason,
  reasonDescription,
  notificationId,
  incidentId,
}: PolicePresenceRequestedModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPolicePresenceFormModalOpen, setIsPolicePresenceFormModalOpen] =
    useState(false);

  const handleNext = () => {
    setIsPolicePresenceFormModalOpen(true);
  };

  const getReason = (
    reason: string,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    t: TFunction<"translation", undefined>,
  ) => {
    const reasonOptions = [
      {
        value: "notEnoughStaff",
        label: t("policePresenceNotification.options.notEnoughStaff"),
      },
      {
        value: "transfer",
        label: t("policePresenceNotification.options.transfer"),
      },
      {
        value: "other",
        label: t("policePresenceNotification.options.other"),
      },
    ];
    const selectedOption = reasonOptions.find(
      (option) => option.value === reason,
    );
    return selectedOption?.label ?? "";
  };

  return (
    <>
      <PopupDialog
        open={open}
        onClose={close}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        disableDialogRootPadding={true}
      >
        <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
          {t("policePresenceNotification.requested")}
          <IconButton
            aria-label="close"
            onClick={close}
            edge="end"
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem" }}
            gutterBottom
          >
            {t("policePresenceNotification.requestedMessage", {
              requesterName,
              posName,
            })}
          </Typography>
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem", marginTop: "1rem" }}
            gutterBottom
          >
            {getReason(reason, t)}
          </Typography>
          {reasonDescription && (
            <Typography
              sx={{ color: "primary", fontSize: "0.875rem" }}
              gutterBottom
            >
              {reasonDescription}
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            px: 3,
          }}
        >
          <Button onClick={handleNext} color="primary" variant="outlined">
            {t("policePresenceNotification.next")}
          </Button>
        </DialogActions>
      </PopupDialog>
      {isPolicePresenceFormModalOpen && (
        <RecordPolicePresenceFormModal
          incidentId={incidentId}
          notificationId={notificationId}
          closeNotification={closeNotification}
          isModalOpen={isPolicePresenceFormModalOpen}
          closeModal={() => {
            setIsPolicePresenceFormModalOpen(false);
            close();
          }}
        />
      )}
    </>
  );
}
