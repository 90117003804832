import React from "react";

import {
  FormSection,
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  RecordPolicePresenceMinimumFields,
  WorkflowVersions,
} from "@vision/common";
import { useNavigate, useParams } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import {
  RecordPolicePresenceForm,
  RecordPolicePresenceFormSkeleton,
} from "../../forms/RecordPolicePresence/RecordPolicePresenceForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { Error as ErrorComponent } from "../Error/Error.js";
import { getRequestedPolicePresenceForm } from "./helpers.js";

export type RecordPolicePresencePageProps = Record<string, unknown>;
export type RecordPolicePresenceInnerProps = {
  incidentWorkItem: IncidentWorkItemWithContext | undefined;
  refetch: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
};

export function RecordPolicePresenceInner({
  incidentWorkItem,
  refetch,
}: RecordPolicePresenceInnerProps) {
  const navigate = useNavigate();

  const [error, setError] = React.useState<string | undefined>(undefined);

  if (error) {
    throw new Error(error);
  }

  const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;
  const workflowVersion = incidentWorkItem?.incidentWorkItem.workflowVersion as
    | WorkflowVersions
    | undefined;

  const nhsRequestedPolicePresence =
    getRequestedPolicePresenceForm(incidentWorkItem);

  return (
    <>
      {incidentWorkItemId === undefined || workflowVersion === undefined ? (
        <RecordPolicePresenceFormSkeleton />
      ) : (
        <RecordPolicePresenceForm
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={async (values: RecordPolicePresenceMinimumFields) => {
            try {
              if (nhsRequestedPolicePresence === undefined) {
                return;
              }
              const response = await api.incidentWorkItem.updateData(
                incidentWorkItemId,
                nhsRequestedPolicePresence.id,
                FormSection.recordPolicePresence,
                values,
                true,
              );
              if (response.status === 204) {
                if (values.hasRemainedAtPlaceOfSafety.value === "yes") {
                  navigate(
                    createRoute.incidentProgressPage(incidentWorkItemId),
                  );
                  return;
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  await refetch();
                  navigate(`/incident/${incidentWorkItemId}/section-complete`, {
                    state: {
                      type: FormSection.recordPolicePresence,
                    },
                  });
                  return;
                }
              } else {
                setError(JSON.stringify(response.data.message));
              }
            } catch (error) {
              setError(
                "Failed to update 136 form: " +
                  JSON.stringify((error as Error).message),
              );
            }
          }}
          onCancel={() => {
            navigate(createRoute.incidentProgressPage(incidentWorkItemId));
          }}
          workflowVersion={workflowVersion}
        />
      )}
    </>
  );
}

export function RecordPolicePresencePage(
  _props: RecordPolicePresencePageProps,
) {
  const params = useParams();
  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ data: incidentWorkItem, error: incidentWorkItemError }, refetch] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(incidentId.data);

  if (incidentWorkItemError) return <ErrorComponent />;

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
    refetch: ReturnType<typeof apiHooks.incidentWorkItem.get>[1],
  ) => {
    return (
      <RecordPolicePresenceInner
        incidentWorkItem={incidentWorkItem}
        refetch={refetch}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return render(incidentWorkItem, refetch);
}
