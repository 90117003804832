import { defined } from "@thalamos/common";
import {
  FormSection,
  IncidentForm,
  IncidentFormType,
  IncidentWorkItemWithContext,
  RequestPolicePresenceMinimumFields,
} from "@vision/common";
import { ok, safeTry } from "neverthrow";

/**
 * Retrieves the active "Request Police Presence" form from the incident work item.
 * - Ensures `activeOfficerRequestFormId` exists.
 * - Finds the police request form that matches the correct `formType` and `id`.
 * - Checks if the `requestPolicePresenceMain` section exists.
 * - Validates that `hasPolicePresenceRequested` is explicitly `"yes"`.
 * - Returns the form if all conditions are met; otherwise, returns `undefined`.
 * @param incidentWorkItem The incident work item to retrieve the form from.
 * @returns The active "Request Police Presence" form if it exists; otherwise, `undefined`.
 */
export const getRequestedPolicePresenceForm = (
  incidentWorkItem: IncidentWorkItemWithContext | undefined,
): IncidentForm | undefined => {
  const result = safeTry(function* () {
    const policeFormId = yield* defined(
      incidentWorkItem?.incidentWorkItem.activeOfficerRequestFormId,
    );

    const policeForm = yield* defined(
      (incidentWorkItem?.forms ?? [])
        .filter(
          (form) => form.formType === IncidentFormType.requestPolicePresence,
        )
        .find((form) => form.id === policeFormId),
    );

    const mainSection = yield* defined(
      policeForm.sections.find(
        (section) => section.id === FormSection.requestPolicePresenceMain,
      ),
    );

    const hasRequestedPolicePresence = yield* defined(
      (mainSection.data as RequestPolicePresenceMinimumFields | undefined)
        ?.hasPolicePresenceRequested.value,
    );

    if (hasRequestedPolicePresence !== "yes") {
      return ok(undefined);
    }

    return ok(policeForm);
  });

  return result.unwrapOr(undefined);
};
