import React, { useMemo } from "react";

import { CircularProgress } from "@mui/material";
import {
  CuratedPlaceOfSafety,
  CuratedPlaceOfSafetySchema,
} from "@thalamos/common";
import {
  FormSection,
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import api, { apiHooks } from "../../api/index.js";
import { PlacesOfSafetyContext } from "../../components/FormBuilder/PlaceOfSafetyPicker/PlaceOfSafetyContext.js";
import {
  RecordPlaceOfSafetyForm,
  RecordPlaceOfSafetyFormSkeleton,
} from "../../forms/RecordPlaceOfSafety/RecordPlaceOfSafetyForm.js";
import { Error as ErrorComponent } from "../../pages/Error/Error.js";
import { createRoute } from "../../routing/createRoute.js";

export type RecordPlaceOfSafetyPageProps = Record<string, unknown>;

export type RecordPlaceOfSafetyPageInnerProps = {
  incidentWorkItem: IncidentWorkItemWithContext | undefined;
  placesOfSafety: CuratedPlaceOfSafety[] | undefined;
  refetch: () => Promise<void>;
};

export function RecordPlaceOfSafetyPage(_props: RecordPlaceOfSafetyPageProps) {
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    {
      data: incidentWorkItem,
      loading: isIncidentLoading,
      error: incidentError,
    },
    refetchIncidentWorkItem,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  ] = apiHooks.incidentWorkItem.get(incidentId.data);

  const [
    {
      data: placesOfSafety,
      loading: isPlacesOfSafetyLoading,
      error: placeOfSafetyError,
    },
    refetchPlacesOfSafety,
  ] = apiHooks.placeOfSafety.getAll();

  if (incidentError || placeOfSafetyError) {
    <ErrorComponent />;
  }

  if (isIncidentLoading || isPlacesOfSafetyLoading) {
    return <CircularProgress />;
  }

  return (
    <RecordPlaceOfSafetyPageInner
      incidentWorkItem={incidentWorkItem ?? undefined}
      placesOfSafety={placesOfSafety?.placesOfSafety ?? undefined}
      refetch={async () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await refetchIncidentWorkItem();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await refetchPlacesOfSafety();
      }}
    />
  );
}

export function RecordPlaceOfSafetyPageInner({
  incidentWorkItem,
  placesOfSafety,
  refetch,
}: RecordPlaceOfSafetyPageInnerProps) {
  const navigate = useNavigate();

  const [error, setError] = React.useState<string | undefined>(undefined);

  const formId = useMemo(() => IncidentFormIdSchema.parse(v4()), []);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      .workflowVersion as WorkflowVersions | undefined;
    return (
      <>
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <RecordPlaceOfSafetyFormSkeleton />
        ) : (
          <RecordPlaceOfSafetyForm
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={async (values: Record<string, unknown>) => {
              try {
                // Create a form
                const createFormResponse =
                  await api.incidentWorkItem.createPlaceOfSafetyForm(
                    incidentWorkItemId,
                    formId,
                  );
                if (createFormResponse.status !== 204) {
                  throw Error(JSON.stringify(createFormResponse.data.message));
                }

                // Populate it with values
                const populateFormResponse =
                  await api.incidentWorkItem.updateData(
                    incidentWorkItemId,
                    formId,
                    FormSection.recordPlaceOfSafetyMain,
                    values,
                    true,
                  );
                if (populateFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(populateFormResponse.data.message),
                  );
                }

                // Mark it as the active Place of Safety
                const updateActivePlaceOfSafetyFormResponse =
                  await api.incidentWorkItem.updateActivePlaceOfSafety(
                    incidentWorkItemId,
                    formId,
                  );
                if (updateActivePlaceOfSafetyFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(
                      updateActivePlaceOfSafetyFormResponse.data.message,
                    ),
                  );
                }

                await refetch();
                navigate(
                  createRoute.incidentRecordPlaceOfSafetyArrivalPage(
                    incidentWorkItemId,
                    formId,
                  ),
                );
                return;
              } catch (error) {
                setError(
                  "Failed to record place of safety: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return (
    <PlacesOfSafetyContext.Provider
      value={{
        placesOfSafety: (placesOfSafety ?? [])
          .map((placeOfSafety) => {
            const result = CuratedPlaceOfSafetySchema.safeParse(placeOfSafety);
            return result.success ? result.data : undefined;
          })
          .filter((placeOfSafety) => placeOfSafety !== undefined),
      }}
    >
      {render(incidentWorkItem)}
    </PlacesOfSafetyContext.Provider>
  );
}
