export default {
  role: {
    officer: "BTP Officer",
    commandunit: "Sub-Divisional Command Unit",
  },
  incidentWorkItem: {
    commandUnit: "Sub-Division",
    geographicUnit: "Sector ID",
  },
  userProfile: {
    shoulderNumber: "Police Number",
  },
  common: {
    dispatchNumber: "Control Works Number",
    shoulderNumber: "Police Number",
  },
  createIncident: {
    incidentLocation: "Location of incident/concern",
    personForename: "Person's given names",
  },
  detentionDetails: {
    custodyNumber: "Custody Number (optional)",
    dateOfDetention: "Date of detention",
    timeOfDetention: "Time of detention",
  },
  informationForHealthcare: {
    wasHealthcareProvided:
      "Was physical health care provided prior to arrival at the Place of Safety (ED or 136 Suite)?",
    wasForceUsed: "Was restraint used?",
    didSeizeproperty: "Did you seize any weapons?",
    detailsPropertySeized: "Please provide detail of the weapons seized",
    describeRiskFactorsOther: "Please specify",
  },
  personDetails: {
    ethnicity: {
      options: {
        IC1: "IC1 - North European",
        IC2: "IC2 - South European",
        IC3: "IC3 - Black",
        IC4: "IC4 - Asian",
        IC5: "IC5 - Chinese, Japanese, or SE Asian",
        IC6: "IC6 - Arabic or North African",
        IC7: "IC7/0 - Unknown",
      },
    },
  },
  triageIncident: {
    reasonNotConsulted: {
      title: "Why were health/AMHP services not consulted?",
      options: {
        noAnswer: "Not available/no answer",
      },
    },
    howDidYouContactNhs: {
      title: "Who did you consult?",
      options: {
        healthcarePractitionerAmbulance: "Ambulance service",
        ldPractitionersInCustody: "Health care in custody",
        localHbpos: "Mental Health team at HBPOS",
        controlRoom: "Control Room - IMHT",
      },
    },
    givenHealthCareProfessionalAdvice:
      "What advice did the health/AMHP services give?",
    otherActionDetails: "Please provide a brief description of advice provided",
    whatWillYouDo: {
      136: "Section 136 (MHA)",
      56: "Section 1-6 (MCA)",
      voluntary: "Voluntary Support",
      other: "Other",
    },
  },
};
