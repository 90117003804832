import React, { useCallback } from "react";

import {
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox as MUICheckbox,
  Typography,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";

const debug = debugFn("vision-frontend:Checkbox");

export type CheckboxProps = {
  fieldName: string;
  label: TranslationKey;
};

type CheckboxOutput = { value: "yes"; valueLabel: string } | "";

export const Checkbox = ({ fieldName, label }: CheckboxProps) => {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField<CheckboxOutput>(fieldName);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const runAsync = async () => {
        const newValue = event.target.checked
          ? ({ value: "yes", valueLabel: t(label) } as const)
          : "";

        debug("Setting value", {
          newValue,
        });
        await helpers.setValue(newValue);
        await helpers.setTouched(true);
      };

      void runAsync();
    },
    [helpers, label, t],
  );

  return (
    <FormControl
      error={!!(meta.touched && meta.error)}
      component="fieldset"
      data-testid={`formField-${fieldName}`}
    >
      <FormControlLabel
        control={
          <MUICheckbox
            checked={field.value !== ""}
            onChange={handleChange}
            name={field.name}
          />
        }
        label={t(label)}
      />
      {meta.touched && meta.error && <FormError error={meta.error} />}
      {debug.enabled && (
        <>
          <Divider />
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"field.value : "}
            {JSON.stringify(field.value)}
          </Typography>
          <Divider />
        </>
      )}
    </FormControl>
  );
};
