import { CircularProgress, Divider, Stack, useTheme } from "@mui/material";
import {
  ExtendedVisionUser,
  GetIncidentWorkItemResponse,
} from "@vision/common";
import React from "react";
import { useNavigate } from "react-router-dom";
import { apiHooks } from "../../api/index.js";
import {
  IncidentFormType,
  StartNewIncidentWorkItem,
} from "../../components/IncidentWorkItems/StartNewIncidentWorkItem/StartNewIncidentWorkItem.js";
import { useUser } from "../../Context/UserContext.js";
import { createRoute } from "../../routing/createRoute.js";
import { AdminPage } from "../AdminPage/AdminPage.js";
import { Error } from "../Error/Error.js";
import { HomePageIncidents } from "./HomePageIncidents.js";

export const HomePageInner = ({
  user,
  incidentWorkItems,
  refetchWorkItems,
}: {
  user: ExtendedVisionUser | undefined;
  incidentWorkItems: GetIncidentWorkItemResponse | undefined;
  refetchWorkItems: ReturnType<
    typeof apiHooks.incidentWorkItem.getMyActiveIncidentWorkItems
  >[1];
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const enabledForms: IncidentFormType[] = [
    "section136",
    // "section56",
    // "voluntary",
  ];

  return (
    <Stack
      data-testid="home"
      sx={{
        [theme.breakpoints.up("md")]: {
          padding: "5rem",
        },
        [theme.breakpoints.down("md")]: {
          padding: "2rem",
        },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <StartNewIncidentWorkItem
        enabledForms={enabledForms}
        onNewForm={() => {
          navigate(createRoute.incidentCreate());
        }}
      />

      <Divider />

      <HomePageIncidents
        user={user}
        incidentWorkItems={incidentWorkItems}
        refetchWorkItems={refetchWorkItems}
      />
    </Stack>
  );
};

const HomePage = () => {
  const { user, loading: loadingUser, error: userError } = useUser();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    {
      data: incidentWorkItems,
      loading: loadingIncidentWorkItems,
      error: incidentWorkItemsError,
    },
    refetchWorkItems,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  ] = apiHooks.incidentWorkItem.getMyActiveIncidentWorkItems(user?.id);

  if (loadingUser || loadingIncidentWorkItems) {
    return <CircularProgress />;
  }

  if (userError || incidentWorkItemsError) {
    return <Error />;
  }

  const render = ({
    incidentWorkItems,
    user,
    refetchWorkItems,
  }: {
    incidentWorkItems: GetIncidentWorkItemResponse | undefined;
    user: ExtendedVisionUser | undefined;
    refetchWorkItems: ReturnType<
      typeof apiHooks.incidentWorkItem.getMyActiveIncidentWorkItems
    >[1];
  }) => {
    const isAdminUser = user?.isAdminSession;
    return isAdminUser ? (
      <AdminPage user={user} />
    ) : (
      <HomePageInner
        user={user}
        incidentWorkItems={incidentWorkItems}
        refetchWorkItems={refetchWorkItems}
      />
    );
  };

  return render({
    user,
    incidentWorkItems,
    refetchWorkItems,
  });
};

export default HomePage;
