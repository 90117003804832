/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { match, P } from "ts-pattern";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  commandUnitSchema,
  dispatchNumberSchema,
  forenameSchema,
  freeTextSchema,
  LocationSchema,
  OrganisationRoles,
  surnameSchema,
  yesNoSchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "dispatchNumber", // create-1.0
    type: "textbox",
    ...new FieldBuilder().withSchema(dispatchNumberSchema).build(),
    label: "common.dispatchNumber",
  })
  .withFieldAlwaysVisible({
    fieldName: "incidentLocation", // create-2.0
    type: "location",
    ...new FieldBuilder().withSchema(LocationSchema).build(),
    label: "createIncident.incidentLocation",
  })
  .withFieldAlwaysVisible({
    fieldName: "commandUnit", // create-2.2
    type: "organisation",
    filterByRoles: [OrganisationRoles.PoliceCommandUnit],
    ...new FieldBuilder()
      .withSchema(commandUnitSchema)
      .captureValueLabel()
      .build(),
    label: "createIncident.commandUnit",
  })
  .withFieldAlwaysVisible({
    fieldName: "geographicUnit", // create-2.3
    type: "organisation",
    filterByRoles: [OrganisationRoles.PoliceGeographicUnit],
    ...new FieldBuilder()
      .withSchema(commandUnitSchema)
      .captureValueLabel()
      .build(),
    label: "createIncident.geographicUnit",
  })
  .withFieldAlwaysVisible({
    fieldName: "userIsAttendingOfficer", // create-3.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "createIncident.userIsAttendingOfficer",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "createdOnBehalfOfAnotherOfficerWithShoulderNumber", // create-3.0.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "createIncident.createdOnBehalfOfAnotherOfficerWithShoulderNumber",
    isVisible: ({ formValues }) =>
      match(formValues.userIsAttendingOfficer)
        .with({ value: "no", valueLabel: P._ }, () => true)
        .with(undefined, { value: "yes", valueLabel: P._ }, () => false)
        .exhaustive(),
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "createdOnBehalfOfAnotherOfficerBanner", // create-3.0.2
    type: "banner",
    bannerType: "info",
    label: "createIncident.createdOnBehalfOfAnotherOfficerBanner",
    isVisible: ({ formValues }) =>
      match(formValues.userIsAttendingOfficer)
        .with({ value: "no", valueLabel: P._ }, () => true)
        .with(undefined, { value: "yes", valueLabel: P._ }, () => false)
        .exhaustive(),
  })
  .withFieldAlwaysVisible({
    fieldName: "personForename", // create-4.0
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(forenameSchema).build(),
    label: "createIncident.personForename",
  })
  .withFieldAlwaysVisible({
    fieldName: "personSurname", // create-4.1
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(surnameSchema).build(),
    label: "createIncident.personSurname",
  })
  .withPage([
    "dispatchNumber", // create-1.0
  ])
  .withPage([
    "incidentLocation", // create-2.0
  ])
  .withPage([
    "commandUnit", // create-2.2
    "geographicUnit", // create-2.3
  ])
  .withPage([
    "userIsAttendingOfficer", // create-3.0
    "createdOnBehalfOfAnotherOfficerWithShoulderNumber", // create-3.0.1
    "createdOnBehalfOfAnotherOfficerBanner", // create-3.0.2
  ])
  .withPage([
    "personForename", // create-4.0
    "personSurname", // create-4.1
  ])
  .build();

export type CreateIncidentWorkItemFormData = typeof formSpecification.formType;
