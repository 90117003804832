import React, { useMemo } from "react";

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  InputBaseComponentProps,
  TextField as MuiTextField,
  Stack,
  InputProps as StandardInputProps,
  Typography,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../Tooltip/Tooltip.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

export type TextFieldProps = {
  fieldName: string;
  label?: TranslationKey;
  rows?: string;
  multiline?: boolean;
  canBeUnknown?: boolean;
  InputProps?: Partial<StandardInputProps>;
  inputProps?: InputBaseComponentProps;
  tooltip?: {
    title: TranslationKey;
    content: TranslationKey;
  };
  placeholder?: string;
} & Pick<React.ComponentProps<typeof MuiTextField>, "type">;

type TextFieldOutput = {
  value: string | null;
  isUnknown?: boolean;
};

const debug = debugFn("vision-frontend:TextField");

export function TextField({
  fieldName,
  label,
  rows,
  canBeUnknown,
  multiline,
  tooltip,
  InputProps,
  inputProps,
  placeholder,
  type,
}: TextFieldProps) {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField<TextFieldOutput>(fieldName);

  // We use this to store the last value that the user typed in the text field.
  // This is so that we can restore it if they turn on the "unknown" flag.
  // We pre-populate this with the incoming "initialValue".
  const [lastTypedValue, setLastTypedValue] = React.useState<string | null>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    ["", null, undefined].includes(field.value as any)
      ? null
      : field.value.value,
  );

  // Clean up the field value to be a structured object
  const fieldValue = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
      ["", null, undefined].includes(field.value as any)
        ? // Turn null-ish values into a structured object
          { value: null, isUnknown: false }
        : field.value.isUnknown === true
          ? // If "unknown", clear out the value
            { value: null, isUnknown: true }
          : field.value,
    [field.value],
  );

  // The error could either come from Formik ("Invalid input") or from the schema
  // ({value: "string too short"}). We need to figure out which at runtime.
  const fieldError = useMemo(
    () => {
      const value =
        typeof meta.error === "string"
          ? meta.error
          : typeof meta.error === "object"
            ? (meta.error as { value: string } | undefined)?.value
            : undefined;

      return value === undefined ? undefined : t(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(meta.error)],
  );
  if (fieldError) {
    debug("fieldError", fieldError);
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runAsync = async () => {
      // Store away the thing they typed so we can restore it if they
      // turn on the unknown flag
      setLastTypedValue(event.target.value);

      const newValue = {
        value: event.target.value,
        ...(canBeUnknown ? { isUnknown: false } : {}),
      };

      debug("handleTextChange, setting value", newValue);
      await helpers.setValue(newValue);
      await helpers.setTouched(true);
    };

    void runAsync();
  };

  const handleUnknownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runAsync = async () => {
      const newValue = event.target.checked
        ? {
            value: null,
            isUnknown: true,
          }
        : {
            value: lastTypedValue,
            isUnknown: false,
          };

      debug("handleUnknownChange, Setting value", newValue);

      await helpers.setValue(newValue);
      await helpers.setTouched(true);
    };

    void runAsync();
  };

  return (
    <Stack data-testid={`formField-${fieldName}`}>
      <Box
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent="flex-start"
        marginBottom="0.5rem"
      >
        {label && <FormLabel label={label} />}
        {tooltip && (
          <Tooltip
            fieldName={fieldName}
            title={tooltip.title}
            content={tooltip.content}
          />
        )}
      </Box>
      <MuiTextField
        id={fieldName}
        placeholder={placeholder}
        name={fieldName}
        value={fieldValue.value ?? lastTypedValue ?? ""}
        error={meta.touched && Boolean(fieldError)}
        multiline={multiline}
        rows={rows}
        type={type}
        helperText={
          // We always render the helper text so that the "unknown" checkbox
          // doesn't jump vertically when the error message appears.
          // Empty string would not be rendered, so we use a space.
          meta.touched ? (fieldError ?? " ") : " "
        }
        onChange={handleTextChange}
        onBlur={field.onBlur}
        disabled={fieldValue.isUnknown}
        fullWidth
        InputProps={{
          autoComplete: "off",
          inputProps: {
            "aria-label": fieldName,
            "data-testid": `formField-${fieldName}-input`,
            ...inputProps,
            ...InputProps?.inputProps,
          },
          ...InputProps,
        }}
      />
      {canBeUnknown && (
        <FormControlLabel
          control={
            <Checkbox
              checked={fieldValue.isUnknown}
              onChange={handleUnknownChange}
              data-testid={`formField-${fieldName}-unknown`}
            />
          }
          label={t("unknown")}
        />
      )}
      {debug.enabled && (
        <>
          <Divider />
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"field.value : "}
            {JSON.stringify(field.value)}
          </Typography>
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"Treated like: "}
            {JSON.stringify(fieldValue)}
          </Typography>
          <Divider />
        </>
      )}
    </Stack>
  );
}
