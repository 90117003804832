import { CircularProgress } from "@mui/material";
import { IncidentFormIdSchema } from "@vision/common";
import React from "react";
import { useParams } from "react-router-dom";
import { apiHooks } from "../../api/index.js";
import { MultiPagePdf } from "../../components/index.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export function FormPdf() {
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ data: pdfData, error, loading }] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.getPdf(incidentId.data);

  if (loading) {
    return <CircularProgress />;
  }
  if (error || pdfData === null) return <ErrorComponent />;

  const render = ({ pdfData }: { pdfData: string | undefined }) => {
    return pdfData ? <MultiPagePdf data={pdfData} /> : <CircularProgress />;
  };
  return render({ pdfData });
}
