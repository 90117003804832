import { Close } from "@mui/icons-material/";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IncidentWorkItemIdUnbranded, UserUnbranded } from "@vision/common";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "../../api/index.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { Typeahead } from "../../components/Typeahead/Typeahead.js";
import { useUser } from "../../Context/UserContext.js";

export interface InitiateTransferModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (email: string) => void;
  incidentWorkItemId: IncidentWorkItemIdUnbranded | undefined;
}

export function InitiateTransferModal({
  open,
  onClose,
  onConfirm,
  incidentWorkItemId,
}: InitiateTransferModalProps) {
  const { user } = useUser();
  const [email, setEmail] = React.useState<string>("");
  const [options, setOptions] = React.useState<UserUnbranded[]>([]);

  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleConfirm = () => {
    // TODO - think about how we want to handle validation
    if (email) {
      onConfirm(email.trim());
    }
  };

  const handleInputChange = async (inputValue: string | null) => {
    setEmail(inputValue ?? "");

    if (inputValue && inputValue.length > 2) {
      const result = await api.user.search(inputValue, 10, 0);
      if (result.status === 200) {
        setOptions(
          result.data.results.filter(
            (searchResult) => searchResult.email !== user?.email,
          ),
        );
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  return (
    <PopupDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      disableDialogRootPadding={true}
    >
      <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
        {t("transfer.initiateTransfer.title")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          edge="end"
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem" }}
          gutterBottom
        >
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem" }}
            gutterBottom
          >
            {t("transfer.initiateTransfer.transferIncidentWorkItem", {
              incidentWorkItemId,
            })}
          </Typography>
          <Typography
            sx={{ color: "primary", fontSize: "0.875rem" }}
            gutterBottom
          >
            {t("transfer.initiateTransfer.transferIncidentWorkItemId", {
              incidentWorkItemId,
            })}
          </Typography>
          {t("transfer.initiateTransfer.confirmedTransfer")}
        </Typography>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem", mb: 2 }}
          gutterBottom
        >
          {t("transfer.initiateTransfer.untilTransferAccepted")}
        </Typography>

        <Formik
          initialValues={{ email }} // TODO should the submission buttons use this instead
          onSubmit={(_values) => {}}
        >
          {() => (
            <Form>
              <Typeahead
                inputValue={email}
                placeholder={t("transfer.initiateTransfer.enterEmailAddress")}
                name={"transfer"}
                label={undefined}
                fieldName="transfer"
                onInputChange={(event) => void handleInputChange(event)}
                options={options}
                optionsKey="email"
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.email
                }
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 3,
        }}
      >
        <Button onClick={onClose} color="error" variant="outlined">
          {t("common.close")}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={!email}
        >
          {t("transfer.initiateTransfer.confirm")}
        </Button>
      </DialogActions>
    </PopupDialog>
  );
}
