import { z } from "zod";
import type { TranslationKey } from "../../@types/react-i18next.js";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  freeTextSchema,
  givenHealthCareProfessionalAdviceSchema,
  IncidentType,
  nhsContactResults,
  nhsContactSchema,
  triageOutcomeSchema,
  voluntaryActionSchema,
  yesNoSchema,
  yesOnlySchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "isHealthcareProfessionalConsulted", // triage-1.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "triageIncident.isHealthcareProfessionalConsulted",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhs", // triage-1.0.3
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(nhsContactSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.howDidYouContactNhs.title",
    options: [
      {
        value: "healthcarePractitionerAmbulance", // triage-1.0.3.1
        label:
          "triageIncident.howDidYouContactNhs.options.healthcarePractitionerAmbulance",
      },
      {
        value: "custodyHealthcarePractitioner", // triage-1.0.3.3
        label:
          "triageIncident.howDidYouContactNhs.options.custodyHealthcarePractitioner",
      },
      {
        value: "panLondonMentalHealthAdviceLine", // triage-1.0.3.4
        label:
          "triageIncident.howDidYouContactNhs.options.panLondonMentalHealthAdviceLine",
      },
      {
        value: "localHbpos", // triage-1.0.3.6
        label: "triageIncident.howDidYouContactNhs.options.localHbpos",
      },
      {
        value: "mentalHealthTeamOther", // triage-1.0.3.8
        label:
          "triageIncident.howDidYouContactNhs.options.mentalHealthTeamOther",
      },
      {
        value: "triageCarHO", // triage-1.0.3.9
        label: "triageIncident.howDidYouContactNhs.options.triageCarHO",
      },
      {
        value: "triageCarBtp", // triage-1.0.3.10
        label: "triageIncident.howDidYouContactNhs.options.triageCarBtp",
      },
      {
        value: "nhs111", // triage-1.0.3.11
        label: "triageIncident.howDidYouContactNhs.options.nhs111",
      },
      {
        value: "nhsStaffNwr", // triage-1.0.3.12
        label: "triageIncident.howDidYouContactNhs.options.nhsStaffNwr",
      },
      {
        value: "nhsStaffAE", // triage-1.0.3.13
        label: "triageIncident.howDidYouContactNhs.options.nhsStaffAE",
      },
      {
        value: "controlRoom", // triage-1.0.3.14
        label: "triageIncident.howDidYouContactNhs.options.controlRoom",
      },
      {
        value: "amph", // triage-1.0.3.15
        label: "triageIncident.howDidYouContactNhs.options.amph",
      },
      {
        value: "other", // triage-1.0.3.16
        label: "common.other",
      },
    ] satisfies Array<{
      value: (typeof nhsContactResults)[number];
      label: TranslationKey;
    }>,
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhsOtherDetails", // triage-1.0.3.16.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.howDidYouContactNhs?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonNotConsulted", // triage-1.0.1
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(freeTextSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "no",
    label: "triageIncident.reasonNotConsulted.title",
    options: [
      {
        label:
          "triageIncident.reasonNotConsulted.options.immediateNeedForCareOrControl",
        value: "immediateNeedForCareOrControl",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.noAnswer",
        value: "noAnswer",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.didNotConsider",
        value: "didNotConsider",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.notSureWhoToContact",
        value: "notSureWhoToContact",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.notDeemedNecessary",
        value: "notDeemedNecessary",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonNotConsultedOther", // triage-1.0.1.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.reasonNotConsulted?.value === "other",
    label: "triageIncident.reasonNotConsultedOther",
  })
  .withFieldConditionallyVisible({
    fieldName: "givenHealthCareProfessionalAdvice", // triage-1.0.4
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(givenHealthCareProfessionalAdviceSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.givenHealthCareProfessionalAdvice",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: IncidentType.section136,
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: IncidentType.section56,
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: IncidentType.voluntary,
      },
      {
        label: "common.other",
        value: IncidentType.other,
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonsForDetention", // triage-1.0.4.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section136,
    label: "triageIncident.reasonsForDetention",
    multiline: true,
    rows: "5",
  })
  .withFieldConditionallyVisible({
    fieldName: "confirmMentalCapacityLack", // triage-1.0.4.1.2
    type: "checkbox",
    ...new FieldBuilder().withSchema(yesOnlySchema).captureValueLabel().build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section56,
    label: "triageIncident.confirmMentalCapacityLack",
  })
  .withFieldConditionallyVisible({
    fieldName: "voluntaryAction", // triage-1.0.4.1.3
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(voluntaryActionSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.voluntary,
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label:
          "triageIncident.voluntaryAction.options.voluntaryAttendanceHospital",
        value: "voluntaryAttendanceHospital",
      },
      {
        label:
          "triageIncident.voluntaryAction.options.divertOtherHealthservice",
        value: "divertOtherHealthservice",
      },
      {
        label: "triageIncident.voluntaryAction.options.contactSupportGroup",
        value: "contactSupportGroup",
      },
      {
        label: "triageIncident.voluntaryAction.options.contactFriendsFamily",
        value: "contactFriendsFamily",
      },
      {
        label: "triageIncident.voluntaryAction.options.takePersonHome",
        value: "takePersonHome",
      },
      {
        label: "triageIncident.voluntaryAction.options.divertCrisisCafe",
        value: "divertCrisisCafe",
      },
      {
        label: "triageIncident.voluntaryAction.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "voluntaryActionOtherDetails", // triage-1.0.4.1.3.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.voluntaryAction?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "otherActionDetails", // triage-1.0.4.1.4
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.other,
    label: "triageIncident.otherActionDetails",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "otherActionBanner", // triage-1.0.4.1.4.1.2
    type: "banner",
    bannerType: "warning",
    label: "triageIncident.otherActionBanner",
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.other,
  })
  .withFieldAlwaysVisible({
    fieldName: "whatWillYouDo", // triage-1.0.2
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(triageOutcomeSchema)
      .captureValueLabel()
      .build(),
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: "136",
      },
      {
        label: "triageIncident.whatWillYouDo.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "whatWillYouDoOther", // triage-1.0.2.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.whatWillYouDo?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "explainWhyActionDiffers", // triage-1.0.5.2
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "changeInPresentation",
          "differenceInOpinion",
          "other",
        ] as const),
      )
      .captureValueLabel()
      .build(),
    options: [
      {
        label:
          "triageIncident.explainWhyActionDiffers.options.changeInPresentation",
        value: "changeInPresentation",
      },
      {
        label:
          "triageIncident.explainWhyActionDiffers.options.differenceInOpinion",
        value: "differenceInOpinion",
      },
      {
        label: "triageIncident.explainWhyActionDiffers.options.other",
        value: "other",
      },
    ],
    isVisible: ({ formValues }) =>
      formValues.whatWillYouDo?.value !== undefined &&
      formValues.givenHealthCareProfessionalAdvice?.value !== undefined &&
      formValues.whatWillYouDo.value !==
        formValues.givenHealthCareProfessionalAdvice.value,
    label: "triageIncident.explainWhyActionDiffers.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "explainWhyActionDiffersOther", // triage-1.0.5.2.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.explainWhyActionDiffers?.value === "other",
    label: "triageIncident.explainWhyActionDiffersOther",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "unsupportedAction",
    type: "banner",
    bannerType: "error",
    label: "triageIncident.unsupportedAction",
    isVisible: ({ formValues }) =>
      formValues.whatWillYouDo?.value !== undefined &&
      formValues.whatWillYouDo.value !== "136",
  })
  .withPage([
    "isHealthcareProfessionalConsulted", // triage-1.0
  ])
  .withPage([
    "reasonNotConsulted", // triage-1.0.1
    "reasonNotConsultedOther", // triage-1.0.1.1.1
    "howDidYouContactNhs", // triage-1.0.3
    "howDidYouContactNhsOtherDetails", // triage-1.0.3.16.1
  ])
  .withPage([
    "givenHealthCareProfessionalAdvice", // triage-1.0.4
    "reasonsForDetention", // triage-1.0.4.1.1
    "confirmMentalCapacityLack", // triage-1.0.4.1.2
    "voluntaryAction", // triage-1.0.4.1.3
    "voluntaryActionOtherDetails", // triage-1.0.4.1.3.1.1
    "otherActionDetails", // triage-1.0.4.1.4
    "otherActionBanner", // triage-1.0.4.1.4.1.2
  ])
  .withPage([
    "whatWillYouDo", // triage-1.0.2
    "whatWillYouDoOther", // triage-1.0.2.1.1
    "explainWhyActionDiffers", // triage-1.0.5.2
    "explainWhyActionDiffersOther", // triage-1.0.5.2.1.1
  ])
  .withPage(["unsupportedAction"])
  .build();

export type TriageIncidentFormData = typeof formSpecification.formType;
