import { CssBaseline, ThemeProvider } from "@mui/material";
import { NotificationsProvider } from "@toolpad/core/useNotifications";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { config } from "./config/index.js";
import "./i18n.js";
import { PusherProvider } from "./lib/pusher/core/PusherProvider.js";
import { createRouter } from "./routing/createRouter.js";
import {
  theme as defaultTheme,
  notificationsSlotProps,
} from "./theme/defaultTheme.js";
import { initialiseTracing } from "./tracing.js";

// Create global singletons
const router = createRouter();

initialiseTracing();

const App = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <PusherProvider {...config.pusher}>
          <NotificationsProvider slotProps={notificationsSlotProps}>
            <RouterProvider router={router} />
          </NotificationsProvider>
        </PusherProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />,
);
