import { ExtendedVisionUser } from "@vision/common";
import React from "react";
import { useNotifications } from "../../Context/NotificationContext.js";
import { UserContextType } from "../../Context/UserContext.js";
import { TransferRequestedModal } from "../../modals/TransferRequested/TransferRequested.js";
import { PolicePresenceRequestedNotification } from "./PolicePresenceRequestedNotification/PolicePresenceRequestedNotification.js";
import { S136FormRejectedNotification } from "./S136FormRejectedNotification/S136FormRejectedNotification.js";
import { TransferRejectedNotification } from "./TransferRejectedNotification/TransferRejectedNotification.js";

export const Notifications = ({
  user,
  refetchUser,
}: {
  user: ExtendedVisionUser;
  refetchUser: UserContextType["refetchUser"];
}) => {
  const {
    transferRequested,
    transferRejected,
    s136FormRejected,
    policePresenceRequested,
  } = useNotifications();

  return (
    <>
      {transferRequested.isOpen && transferRequested.data && (
        <TransferRequestedModal
          user={user}
          notificationId={transferRequested.data.id}
          incidentWorkItemId={transferRequested.data.incidentWorkItemId}
          open={transferRequested.isOpen}
          close={transferRequested.close}
          refetchUser={refetchUser}
        />
      )}
      {transferRejected.isOpen && transferRejected.data && (
        <TransferRejectedNotification
          incidentWorkItemId={transferRejected.data.incidentWorkItemId}
          notificationId={transferRejected.data.id}
          close={transferRejected.close}
          refetchUser={refetchUser}
        />
      )}
      {s136FormRejected.isOpen && s136FormRejected.data && (
        <S136FormRejectedNotification
          notification={s136FormRejected.data}
          close={s136FormRejected.close}
        />
      )}
      {policePresenceRequested.isOpen && policePresenceRequested.data && (
        <PolicePresenceRequestedNotification
          notification={policePresenceRequested.data}
          close={policePresenceRequested.close}
        />
      )}
    </>
  );
};
