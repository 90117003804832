/* eslint-disable @typescript-eslint/no-unsafe-call */
import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  freeTextSchema,
  valueAndLabelSchemaDeprecated,
  yesNoSchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "incidentDescription", // 136-healthinfo-1.0
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "informationForHealthcare.incidentDescription",
    multiline: true,
    rows: "5",
  })
  .withFieldAlwaysVisible({
    fieldName: "wasHealthcareProvided", // 136-healthinfo-2.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "informationForHealthcare.wasHealthcareProvided",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withReadonlyFieldAlwaysVisible({
    fieldName: "healthcareBanner", // 136-healthinfo-2.0.2
    type: "banner",
    label: "informationForHealthcare.healthcareBanner",
    bannerType: "warning",
  })
  .withFieldConditionallyVisible({
    fieldName: "medicalAttentionProvided", // 136-healthinfo-2.0.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.wasHealthcareProvided?.value === "yes",
    label: "informationForHealthcare.medicalAttentionProvided",
  })
  .withFieldAlwaysVisible({
    fieldName: "wasForceUsed", // 136-healthinfo-3.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "informationForHealthcare.wasForceUsed",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "hasPersonBeenSearched", // 136-healthinfo-4.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "informationForHealthcare.hasPersonBeenSearched",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "didSeizeproperty", // 136-healthinfo-5.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    isVisible({ formValues }) {
      return formValues.hasPersonBeenSearched?.value === "yes";
    },
    label: "informationForHealthcare.didSeizeproperty",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "detailsPropertySeized", // 136-healthinfo-5.0.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.didSeizeproperty?.value === "yes",
    label: "informationForHealthcare.detailsPropertySeized",
  })
  .withFieldAlwaysVisible({
    fieldName: "hasAdditionalRiskFactors", // 136-healthinfo-7.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "informationForHealthcare.hasAdditionalRiskFactors",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "describeRiskFactors", // 136-healthinfo-7.0.1
    type: "multi-select",
    schema: z
      .array(valueAndLabelSchemaDeprecated(z.string()))
      .min(1, "At least one option must be selected."),
    isVisible: ({ formValues }) =>
      formValues.hasAdditionalRiskFactors?.value === "yes",
    label: "informationForHealthcare.describeRiskFactors.title",
    options: [
      {
        value: "substanceAbuse",
        label:
          "informationForHealthcare.describeRiskFactors.options.substanceAbuse",
      },
      {
        value: "bereavement",
        label:
          "informationForHealthcare.describeRiskFactors.options.bereavement",
      },
      {
        value: "bullying",
        label: "informationForHealthcare.describeRiskFactors.options.bullying",
      },
      {
        value: "carerIssues",
        label:
          "informationForHealthcare.describeRiskFactors.options.carerIssues",
      },
      {
        value: "clininicallyDiagnosedNeurodiverse",
        label:
          "informationForHealthcare.describeRiskFactors.options.clininicallyDiagnosedNeurodiverse",
      },
      {
        value: "clininicallyDiagnosedMentalHealthIllness",
        label:
          "informationForHealthcare.describeRiskFactors.options.clininicallyDiagnosedMentalHealthIllness",
      },
      {
        value: "clininicallyDiagnosedIllness",
        label:
          "informationForHealthcare.describeRiskFactors.options.clininicallyDiagnosedIllness",
      },
      {
        value: "disability",
        label:
          "informationForHealthcare.describeRiskFactors.options.disability",
      },
      {
        value: "stress",
        label: "informationForHealthcare.describeRiskFactors.options.stress",
      },
      {
        value: "financialIssues",
        label:
          "informationForHealthcare.describeRiskFactors.options.financialIssues",
      },
      {
        value: "genderIdentityIssues",
        label:
          "informationForHealthcare.describeRiskFactors.options.genderIdentityIssues",
      },
      {
        value: "traumaHistory",
        label:
          "informationForHealthcare.describeRiskFactors.options.traumaHistory",
      },
      {
        value: "housingIssues",
        label:
          "informationForHealthcare.describeRiskFactors.options.housingIssues",
      },
      {
        value: "militaryHistory",
        label:
          "informationForHealthcare.describeRiskFactors.options.militaryHistory",
      },
      {
        value: "natalConcerns",
        label:
          "informationForHealthcare.describeRiskFactors.options.natalConcerns",
      },
      {
        value: "previousConvictions",
        label:
          "informationForHealthcare.describeRiskFactors.options.previousConvictions",
      },
      {
        value: "previouslySectioned",
        label:
          "informationForHealthcare.describeRiskFactors.options.previouslySectioned",
      },
      {
        value: "relationshipIssues",
        label:
          "informationForHealthcare.describeRiskFactors.options.relationshipIssues",
      },
      {
        value: "sexualOrientationIssues",
        label:
          "informationForHealthcare.describeRiskFactors.options.sexualOrientationIssues",
      },
      {
        value: "suspectedSeriousCrime",
        label:
          "informationForHealthcare.describeRiskFactors.options.suspectedSeriousCrime",
      },
      {
        value: "other",
        label: "informationForHealthcare.describeRiskFactors.options.other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "describeRiskFactorsOther", // 136-healthinfo-7.0.1.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.describeRiskFactors?.find(({ value }) => value === "other") !==
      undefined,
    label: "informationForHealthcare.describeRiskFactorsOther",
  })
  .withPage([
    "incidentDescription", // 136-healthinfo-1.0
  ])
  .withPage([
    "wasHealthcareProvided", // 136-healthinfo-2.0
    "healthcareBanner", // 136-healthinfo-2.0.2
    "medicalAttentionProvided", // 136-healthinfo-2.0.1
  ])
  .withPage([
    "wasForceUsed", // 136-healthinfo-3.0
  ])
  .withPage([
    "hasPersonBeenSearched", // 136-healthinfo-4.0
    "didSeizeproperty", // 136-healthinfo-5.0
    "detailsPropertySeized", // 136-healthinfo-5.0.1
  ])
  .withPage([
    "hasAdditionalRiskFactors", // 136-healthinfo-7.0
    "describeRiskFactors", // 136-healthinfo-7.0.1
    "describeRiskFactorsOther", // 136-healthinfo-7.0.1.1.1
  ])
  .build();

export type InformationForHealthcareFormData =
  typeof formSpecification.formType;
