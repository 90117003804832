import React from "react";

import { CheckCircle, Done } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  FormSection,
  FormSectionCompletePos,
  FormSectionCompleteProps,
  FormSectionCompletedEndIncident,
  TranslationKey,
} from "@vision/common";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FormWrapper } from "../../layout/FormWrapper.js";
import { createRoute } from "../../routing/createRoute.js";
import { TypographyI18N } from "../TypographyI18N/TypographyI18N.js";

const PlaceOfSafetyBody = ({ pos, arrivalDate }: FormSectionCompletePos) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "2rem 0",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          paddingBottom: "1rem",
        }}
      >
        <CheckCircle color="success" />
        <TypographyI18N
          translationKey="formSuccess.confirmed"
          sx={{
            marginLeft: "1rem",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
      <Box>
        <TypographyI18N translationKey="formSuccess.currentPos" />
        <Typography
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {pos}
        </Typography>
        <Typography
          sx={{
            paddingTop: "1rem",
            color: "#2e7d32",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {arrivalDate}
        </Typography>
      </Box>
      <Box>
        <TypographyI18N translationKey="formSuccess.proceedToShare" />
      </Box>
    </Stack>
  );
};

const FormSectionCompleteBody = ({
  body,
  proceedTo,
}: {
  body: TranslationKey;
  proceedTo: TranslationKey;
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "2rem 0",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CheckCircle color="success" />
        <TypographyI18N
          translationKey="formSuccess.formComplete"
          sx={{
            marginLeft: "1rem",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
      <Box>
        <TypographyI18N translationKey={body} />
      </Box>
      <Box>
        <TypographyI18N translationKey="formSuccess.proceedWith" />
        <TypographyI18N
          translationKey={proceedTo}
          sx={{ fontWeight: theme.typography.fontWeightMedium }}
        />
      </Box>
    </Stack>
  );
};

const TriageIncidentBody = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "2rem 0",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CheckCircle color="success" />
        <TypographyI18N
          translationKey="formSuccess.formComplete"
          sx={{
            marginLeft: "1rem",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
      <Box>
        <TypographyI18N translationKey="formSuccess.triageComplete" />
      </Box>
      <Box>
        <TypographyI18N translationKey="formSuccess.proceedWith" />
        <TypographyI18N
          translationKey="formSuccess.section136Form"
          sx={{ fontWeight: theme.typography.fontWeightMedium }}
        />
      </Box>
    </Stack>
  );
};

const FinishFormBody = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "2rem 0",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CheckCircle color="success" />
        <TypographyI18N
          translationKey="formSuccess.formComplete"
          sx={{
            marginLeft: "1rem",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
      <Box>
        <TypographyI18N
          translationKey="formSuccess.hasBeenEnded"
          sx={{ fontWeight: theme.typography.fontWeightMedium }}
        />
      </Box>
      <Box>
        <TypographyI18N
          translationKey="formSuccess.formEndedArchived"
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
    </Stack>
  );
};

const PreEndIncidentBody = ({ endingReason }: { endingReason: string }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "2rem 0",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CheckCircle color="success" />
        <TypographyI18N
          translationKey="formSuccess.formEnded"
          sx={{
            marginLeft: "1rem",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            paddingTop: "1rem",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {endingReason}
        </Typography>
      </Box>

      <Box>
        <TypographyI18N
          translationKey="formSuccess.formEndedArchived"
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
    </Stack>
  );
};

export const PolicePresenceRecorded = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "2rem 0",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CheckCircle color="success" />
        <TypographyI18N
          translationKey="formSuccess.policePresenceComplete"
          sx={{
            marginLeft: "1rem",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      </Box>
    </Stack>
  );
};

const Body = ({ type, payload }: FormSectionCompleteProps) => {
  const { t } = useTranslation();
  switch (type) {
    case FormSection.createIncidentMain:
      return (
        <FormSectionCompleteBody
          // TODO: How do we add the officer name whilst remaining translatable?
          // body={`${t("formSuccess.mentalHealthForm")} ${(payload as FormSectionCompleteIncidentMain).officer}`}
          body={"formSuccess.mentalHealthForm"}
          proceedTo="formSuccess.incidentTriage"
        />
      );
    case FormSection.triageMain:
      return <TriageIncidentBody />;
    case FormSection.personDetails:
      return (
        <FormSectionCompleteBody
          body="formSuccess.personDetailsComplete"
          proceedTo="formSuccess.detentionDetails"
        />
      );
    case FormSection.detentionDetails:
      return (
        <FormSectionCompleteBody
          body="formSuccess.detentionDetailsComplete"
          proceedTo="formSuccess.informationForHealthcareStaff"
        />
      );
    case FormSection.informationForHealthcare:
      return (
        <FormSectionCompleteBody
          body="formSuccess.informationForHealthcareComplete"
          proceedTo="formSuccess.addPOS"
        />
      );
    case FormSection.recordPlaceOfSafetyMain:
      return (
        <PlaceOfSafetyBody
          pos={(payload as FormSectionCompletePos).pos}
          arrivalDate={`${t("formSuccess.arrivedAt")} ${(payload as FormSectionCompletePos).arrivalDate}`}
        />
      );
    case FormSection.preEndIncident:
      return (
        <PreEndIncidentBody
          endingReason={`${t("formSuccess.formEndedReason")} ${(payload as FormSectionCompletedEndIncident).endingReason.label}`}
        />
      );
    case FormSection.recordPolicePresence:
      return <PolicePresenceRecorded />;
    case FormSection.finishForm:
      return <FinishFormBody />;
    default:
      return <></>;
  }
};

const Header = ({ type }: { type: FormSection }) => {
  const theme = useTheme();

  const getTitle = (type: FormSection): TranslationKey => {
    switch (type) {
      case FormSection.createIncidentMain:
        return "formSuccess.mentalHealthFormTitle";
      case FormSection.triageMain:
        return "formSuccess.incidentTriage";
      case FormSection.personDetails:
        return "formSuccess.personDetails";
      case FormSection.detentionDetails:
        return "formSuccess.detentionDetails";
      case FormSection.informationForHealthcare:
        return "formSuccess.informationForHealthcare";
      case FormSection.recordPlaceOfSafetyMain:
        return "formSuccess.addPOS";
      case FormSection.recordPlaceOfSafetyArrival:
        return "formSuccess.arrivePOS";
      case FormSection.recordPlaceOfSafetyLeave:
        return "formSuccess.leavePOS";
      case FormSection.preEndIncident:
        return "formSuccess.endIncident";
      default:
        return "formSuccess.mentalHealthFormTitle";
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          paddingBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <TypographyI18N
          translationKey={getTitle(type)}
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        />
        <Done color="success" />
      </Box>
      <Divider
        sx={{
          borderColor: theme.palette.primary.main,
          borderWidth: "2px",
        }}
      />
    </Box>
  );
};

const Footer = ({ type }: { type: FormSection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const getProceedLocation = (type: FormSection, incidentId: string) => {
    switch (type) {
      case FormSection.createIncidentMain:
        return createRoute.incidentTriagePage(incidentId);
      case FormSection.triageMain:
        return createRoute.incidentProgressPage(incidentId);
      case FormSection.personDetails:
        return createRoute.incidentDetentionDetailsPage(incidentId);
      case FormSection.detentionDetails:
        return createRoute.incidentInformationForHealthcarePage(incidentId);
      case FormSection.informationForHealthcare:
        return createRoute.incidentProgressPage(incidentId);
      case FormSection.recordPolicePresence:
        return createRoute.incidentProgressPage(incidentId);
      case FormSection.recordPlaceOfSafetyMain:
      case FormSection.recordPlaceOfSafetyArrival:
      case FormSection.recordPlaceOfSafetyLeave:
      case FormSection.preEndIncident:
      case FormSection.requestPolicePresenceMain:
      case FormSection.finishForm:
        return "/"; // TODO add route when next page is created
    }
  };

  return (
    <Box
      sx={{
        position: "sticky",
        bottom: 0,
        left: 0,
        width: "100%",
        background: "white",
        zIndex: 1000,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          color="error"
          sx={{
            textDecoration: "underline",
          }}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigate(createRoute.incidentProgressPage(id!));
          }}
        >
          {t("common.close")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigate(getProceedLocation(type, id!));
          }}
          color="primary"
          data-testid="proceed-button"
        >
          {t("formSuccess.proceed")}
        </Button>
      </Box>
    </Box>
  );
};

export const FormSectionComplete = ({
  type,
  payload,
}: FormSectionCompleteProps) => {
  return (
    <FormWrapper>
      <Header type={type} />
      <Body type={type} payload={payload} />
      <Footer type={type} />
    </FormWrapper>
  );
};
