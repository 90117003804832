import React from "react";

import { HorizontalRule } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getCommandUnit,
  getPatientName,
  IncidentWorkItemStatus,
  IncidentWorkItemWithContext,
  TranslationKey,
} from "@vision/common";
import { useTranslation } from "react-i18next";
import { TypographyI18N } from "../../TypographyI18N/TypographyI18N.js";
import { renderUkDateTime } from "../../util.js";

export type IncidentWorkItemCardProps = {
  incidentWorkItem: IncidentWorkItemWithContext;
  onViewIncident: () => void;
  showAssignedOfficerOnMobile: boolean;
  primaryButtonLabel: TranslationKey;
  onSecondaryBtn?: () => void;
  showSecondaryBtn?: boolean;
  secondaryLabel: TranslationKey;
  isPendingTransfer?: boolean;
};

function calculateDerivedIncidentStatus(
  incidentWorkItem: IncidentWorkItemWithContext,
) {
  // TODO: more complex logic here around status of arrival/handover etc.
  // Maybe this logic happens on the server side?
  switch (incidentWorkItem.incidentWorkItem.status) {
    case IncidentWorkItemStatus.unknown:
      return "formStatusUnknown";
    case IncidentWorkItemStatus.arrivedAtPlaceOfSafety:
      return "arrivedAtPlaceOfSafety";
    case IncidentWorkItemStatus.awaitingArrival:
      return "awaitingArrival";
    case IncidentWorkItemStatus.formShared:
      return "formShared";
    case IncidentWorkItemStatus.nhsFormAccepted:
      return "formAccessed";
    case IncidentWorkItemStatus.policePresenceRequested:
      return "policePresenceRequested";
    case IncidentWorkItemStatus.policePresenceNotRequested:
      return "policePresenceNotRequested";
    default:
      return "formInProgress";
  }
}

export function HeadingAndSubheading({
  heading,
  subheading,
}: {
  heading: TranslationKey;
  subheading: string;
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <TypographyI18N translationKey={heading} />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>{subheading}</Typography>
      </Box>
    </Box>
  );
}

const PendingTransferBox = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "auto",
        maxWidth: "80%",
        margin: "0 auto",
        marginBottom: "1rem",
        backgroundColor: theme.palette.common.lightBlue,
        borderRadius: "8px",
        padding: "0.5rem 1rem",
      }}
    >
      <Typography
        variant="body1"
        color={theme.palette.common.white}
        px={5}
        sx={{ fontWeight: "bold" }}
      >
        {t("incidentWorkItem.pendingTransfer")}
      </Typography>
    </Box>
  );
};

type DesktopIncidentWorkItemCardProps = {
  typeOfIncident: string;
  createdAt: string;
  nameOfPerson: string;
  commandUnit: string;
  currentAssignee: string;
  onViewIncident: () => void;
  primaryButtonLabel: TranslationKey;
  onSecondaryBtn?: () => void;
  showSecondaryBtn?: boolean;
  secondaryLabel?: TranslationKey;
  isPendingTransfer?: boolean;
};

function DesktopIncidentWorkItemCard(props: DesktopIncidentWorkItemCardProps) {
  const { t } = useTranslation();

  return (
    <>
      {props.isPendingTransfer && <PendingTransferBox />}
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <HeadingAndSubheading
          heading="incidentWorkItem.typeOfIncident"
          subheading={props.typeOfIncident}
        />
        <HeadingAndSubheading
          heading="incidentWorkItem.createdAt"
          subheading={props.createdAt}
        />
        <HeadingAndSubheading
          heading="incidentWorkItem.nameOfPerson"
          subheading={props.nameOfPerson}
        />
        <HeadingAndSubheading
          heading="incidentWorkItem.commandUnit"
          subheading={props.commandUnit}
        />
        <HeadingAndSubheading
          heading="incidentWorkItem.currentAssignee"
          subheading={props.currentAssignee}
        />
        <Box sx={{ display: "flex", gap: "1rem" }}>
          {props.showSecondaryBtn && props.secondaryLabel && (
            <Button
              variant="outlined"
              onClick={props.onSecondaryBtn}
              sx={{ minWidth: "5rem" }}
              data-testid="cancel"
            >
              {t(props.secondaryLabel)}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={props.onViewIncident}
            sx={{ minWidth: "5rem" }}
            data-testid="viewIncident"
          >
            {t(props.primaryButtonLabel)}
          </Button>
        </Box>
      </Box>
    </>
  );
}

type MobileIncidenWorkItemCardProps = DesktopIncidentWorkItemCardProps & {
  showAssignedOfficer: boolean;
};

function MobileIncidentWorkItemCard(props: MobileIncidenWorkItemCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          border: "1px solid",
          borderRadius: "0.5rem",
          borderColor: theme.palette.grey[400],
          padding: "1rem",
          minHeight: "12rem",
          justifyContent: "space-between",
        }}
      >
        {props.isPendingTransfer && <PendingTransferBox />}
        <Box sx={{ gap: "1rem", flexDirection: "column", display: "flex" }}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {props.typeOfIncident}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <HeadingAndSubheading
              heading="incidentWorkItem.createdAt"
              subheading={props.createdAt}
            />
            <Box>
              <HeadingAndSubheading
                heading="incidentWorkItem.nameOfPerson"
                subheading={props.nameOfPerson}
              />

              {props.showAssignedOfficer ? (
                <>
                  <HorizontalRule />
                  <HeadingAndSubheading
                    heading="incidentWorkItem.currentAssignee"
                    subheading={props.currentAssignee}
                  />
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            gap: "1rem",
          }}
        >
          {props.showSecondaryBtn && props.secondaryLabel && (
            <Button
              variant="outlined"
              onClick={props.onSecondaryBtn}
              data-testid="cancel"
            >
              {t(props.secondaryLabel)}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={props.onViewIncident}
            data-testid="viewIncident"
          >
            {t(props.primaryButtonLabel)}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export function IncidentWorkItemCard(props: IncidentWorkItemCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { incidentWorkItem, participants } = props.incidentWorkItem;
  const owner = participants.find(
    (participant) => participant.role === "owner",
  );

  const ownerName = owner ? owner.name : t("incidentWorkItem.unknownOwner");

  const commandUnit = getCommandUnit({
    incidentWorkItem: props.incidentWorkItem,
  });
  const { fullName } = getPatientName({
    incidentWorkItem: props.incidentWorkItem,
  });

  // TODO: get this from the work ite once we've added that column
  const typeOfIncidentForm = "136";

  const created = renderUkDateTime(incidentWorkItem.createdAt, true);

  const derivedIncidentStatus = calculateDerivedIncidentStatus(
    props.incidentWorkItem,
  );

  const typeOfIncident = `${t(`incidentWorkItem.type.${typeOfIncidentForm}`)} - ${t(`incidentWorkItem.${derivedIncidentStatus}`)}`;

  return isDesktop ? (
    <DesktopIncidentWorkItemCard
      onViewIncident={props.onViewIncident}
      primaryButtonLabel={props.primaryButtonLabel}
      isPendingTransfer={props.isPendingTransfer}
      onSecondaryBtn={props.onSecondaryBtn}
      showSecondaryBtn={props.showSecondaryBtn}
      secondaryLabel={props.secondaryLabel}
      typeOfIncident={typeOfIncident}
      createdAt={created}
      nameOfPerson={fullName}
      commandUnit={commandUnit?.name ?? "Unknown"}
      currentAssignee={ownerName}
    />
  ) : (
    <MobileIncidentWorkItemCard
      showAssignedOfficer={props.showAssignedOfficerOnMobile}
      onViewIncident={props.onViewIncident}
      primaryButtonLabel={props.primaryButtonLabel}
      isPendingTransfer={props.isPendingTransfer}
      onSecondaryBtn={props.onSecondaryBtn}
      showSecondaryBtn={props.showSecondaryBtn}
      secondaryLabel={props.secondaryLabel}
      typeOfIncident={typeOfIncident}
      createdAt={created}
      nameOfPerson={fullName}
      commandUnit={commandUnit?.name ?? "Unknown"}
      currentAssignee={ownerName}
    />
  );
}
