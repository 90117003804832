import type { UUID } from "@thalamos/common";
import { z, ZodType } from "zod";
import { IncidentFormType, IncidentWorkItemStatus } from "./workItem.js";

export enum AuditTrailEntryResourceType {
  incident = "incident",
  user = "user",
  organisation = "organisation",
  userDirectoryInstance = "userDirectoryInstance",
}

export type AuditTrailResourceUnbranded = {
  resourceType: AuditTrailEntryResourceType;
  resourceId: UUID;
  resourceName?: string;
};

export const AuditTrailResourceSchema = z.object({
  resourceType: z.nativeEnum(AuditTrailEntryResourceType),
  resourceId: z.string().uuid(),
  resourceName: z.string().optional(),
}) satisfies ZodType<AuditTrailResourceUnbranded>;

export type AuditTrailResource = z.infer<typeof AuditTrailResourceSchema>;

export enum AuditTrailEntryType {
  // Aspire user actions
  aspireReviewIncident = "aspireReviewIncident",
  aspireAcceptIncident = "aspireAcceptIncident",
  aspireRejectIncident = "aspireRejectIncident",
  aspireRequestPolicePresence = "aspireRequestPolicePresence",
  aspireRequestPolicePresenceNotRequested = "aspireNotRequestPolicePresence",

  // Test
  test = "test",

  // Incident
  createIncident = "createIncident",
  getIncident = "getIncident",
  getMyIncidents = "getMyIncidents",
  updateIncidentData = "updateIncidentData",

  // Share
  formShared = "formShared",

  // User
  userLoginSuccess = "userLoginSuccess",
  userLogout = "userLogout",
  userUpdate = "userUpdate",

  // configuration
  setOrganisationConfiguration = "setOrganisationConfiguration",
  setOrganisationWorkflowVersion = "setOrganisationWorkflowVersion",

  // Transfer
  transferIncident = "transferIncident",
  resolveTransfer = "resolveTransfer",
  revokeTransfer = "revokeTransfer",

  // Place of Safety
  createPlaceOfSafety = "createPlaceOfSafety",

  // End Incident
  preEndIncident = "preEndIncident",
  finishForm = "finishForm",

  // User Directory Instance
  createUserDirectoryInstance = "createUserDirectoryInstance",
  updateUserDirectoryInstance = "updateUserDirectoryInstance",
  deleteUserDirectoryInstance = "deleteUserDirectoryInstance",
}

const BaseAuditTrailEntrySchema = z.object({
  id: z.string().uuid(),
  timestamp: z.coerce.date(),
  userId: z.string().uuid().optional(),
  userName: z.string().optional(),
  organisationId: z.string().uuid().optional(),
  organisationName: z.string().optional(),
  parentTraceId: z.string().optional(),
  sessionId: z.string().optional(),
  codeVersion: z.string(),
  resources: z.array(AuditTrailResourceSchema),
});

export type AuditTrailEntryUnbranded = {
  id: UUID;
  timestamp: Date;

  type: AuditTrailEntryType;
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };

  userId?: UUID;
  userName?: string;

  organisationId?: UUID;
  organisationName?: string;

  parentTraceId?: string;
  sessionId?: string;
  codeVersion: string;

  resources: AuditTrailResourceUnbranded[];
};

export type GetAuditTrailEntriesResponse = {
  entries: AuditTrailEntryUnbranded[];
  count: number;
};

export const AuditTrailEntrySchema = z
  .discriminatedUnion("type", [
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.test),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.aspireAcceptIncident),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid(),
          teamName: z.string(),
          teamEmail: z.string(),
          organisationId: z.string().uuid(),
          organisationName: z.string(),
          placeOfSafety: z.object({
            id: z.string().uuid().optional(),
            eMHACuratedPlaceOfSafetyId: z.string().uuid().optional(),

            name: z.string(),
            address: z.string(),
            postalCode: z.string(),
            odsCode: z.string().optional(),
          }),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.aspireRejectIncident),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid(),
          teamName: z.string(),
          teamEmail: z.string(),
          organisationId: z.string().uuid(),
          organisationName: z.string(),
          reason: z.string(),
          reasonDescription: z.string().optional(),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.updateIncidentData),
      data: z
        .object({
          status: z.nativeEnum(IncidentWorkItemStatus).optional(),
          formType: z.nativeEnum(IncidentFormType),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.aspireRequestPolicePresence),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid().optional(),
          teamName: z.string().optional(),
          teamEmail: z.string().optional(),
          organisationId: z.string().uuid().optional(),
          organisationName: z.string().optional(),
          policePresenceRequested: z.object({
            hasPolicePresenceRequested: z.object({
              valueLabel: z.string(),
              value: z.literal("yes"),
            }),
            policePresenceRequestedReason: z.object({
              valueLabel: z.string(),
              value: z.enum(["notEnoughStaff", "transfer", "other"]),
            }),
            furtherReasoning: z.object({
              value: z.string(),
            }),
          }),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(
        AuditTrailEntryType.aspireRequestPolicePresenceNotRequested,
      ),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid().optional(),
          teamName: z.string().optional(),
          teamEmail: z.string().optional(),
          organisationId: z.string().uuid().optional(),
          organisationName: z.string().optional(),
          policePresenceRequested: z.object({
            valueLabel: z.string(),
            value: z.literal("no"),
          }),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.aspireReviewIncident),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid().optional(),
          teamName: z.string().optional(),
          teamEmail: z.string().optional(),
          organisationId: z.string().uuid().optional(),
          organisationName: z.string().optional(),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.createIncident),
      data: z
        .object({
          isOnBehalfOfSomeoneElse: z.boolean(),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.getIncident),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.formShared),
      data: z
        .object({
          status: z.literal(IncidentWorkItemStatus.formShared),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.userLoginSuccess),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.userLogout),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.getMyIncidents),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.userUpdate),
      data: z
        .object({
          updatedFields: z.array(
            z.enum(["organisationId", "name", "isOnboarded", "shoulderNumber"]),
          ),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.transferIncident),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.resolveTransfer),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.revokeTransfer),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.preEndIncident),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.createPlaceOfSafety),
      data: z
        .object({
          formType: z.nativeEnum(IncidentFormType),
          status: z.literal(IncidentWorkItemStatus.awaitingArrival),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.finishForm),
      data: z
        .object({
          formType: z.nativeEnum(IncidentFormType),
          status: z.literal(IncidentWorkItemStatus.ended),
        })
        .strict(),
    }),
  ])
  .brand(Symbol("AuditTrailEntry")) satisfies ZodType<AuditTrailEntryUnbranded>;

export type AuditTrailEntry = z.infer<typeof AuditTrailEntrySchema>;

export const AuditTrailSearchParamsSchema = z.object({
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  limit: z.number().min(1).max(1000).optional(),
  offset: z.number().min(0).optional(),
  types: z.array(z.nativeEnum(AuditTrailEntryType)).optional(),
  resourceId: z.string().uuid().optional(),
  userId: z.string().uuid().optional(),
});

export type AuditTrailSearchParams = z.infer<
  typeof AuditTrailSearchParamsSchema
>;
