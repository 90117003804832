import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "endingReason",
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "startedFormInError" as const,
          "formExpired" as const,
          "nhsNotEngage" as const,
          "medicalExamination" as const,
          "other" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    options: [
      {
        label: "preEndIncident.endingReason.options.startedFormInError",
        value: "startedFormInError",
      },
      {
        label: "preEndIncident.endingReason.options.formExpired",
        value: "formExpired",
      },
      {
        label: "preEndIncident.endingReason.options.nhsNotEngage",
        value: "nhsNotEngage",
      },
      {
        label: "preEndIncident.endingReason.options.medicalExamination",
        value: "medicalExamination",
      },
      {
        label: "preEndIncident.endingReason.options.other",
        value: "other",
      },
    ],
    label: "preEndIncident.endingReason.title",
  })
  .withPage(["endingReason"])
  .build();

export type PreEndIncidentFormData = typeof formSpecification.formType;
