import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  IncidentWorkItemId,
  IncidentWorkItemWithContext,
  ParticipantStatus,
} from "@vision/common";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { apiHooks } from "../../api/index.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { Error as ErrorComponent } from "../../pages/Error/Error.js";

export interface TransferRejectedModalInnerProps {
  open: boolean;
  onClose: () => void;
  onTryAgainYes: () => void;
  onTryAgainNo: () => void;
  incidentWorkItem: IncidentWorkItemWithContext | undefined;
}
interface TransferRejectedModalProps {
  open: boolean;
  onClose: () => void;
  onTryAgainYes: () => void;
  onTryAgainNo: () => void;
  incidentWorkItemId: IncidentWorkItemId;
}

export function TransferRejectedModalInner({
  open,
  onClose,
  onTryAgainYes,
  onTryAgainNo,
  incidentWorkItem,
}: TransferRejectedModalInnerProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    const getOfficerThatRejectedTransfer = (
      incidentWorkItem: IncidentWorkItemWithContext,
    ) => {
      const officersHaveRejectedTransfer = incidentWorkItem.participants.filter(
        (participant) => {
          return participant.status === ParticipantStatus.rejectedTransfer;
        },
      );
      if (officersHaveRejectedTransfer.length === 0) {
        return "";
      }
      if (officersHaveRejectedTransfer.length === 1) {
        return officersHaveRejectedTransfer[0].name;
      }

      const latestParticipant = officersHaveRejectedTransfer.reduce(
        (latest, current) => {
          return dayjs(current.assignedAt) > dayjs(latest.assignedAt)
            ? current
            : latest;
        },
      );

      return latestParticipant.name;
    };

    return (
      <PopupDialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        disableDialogRootPadding={true}
      >
        {incidentWorkItem === undefined ? (
          <CircularProgress />
        ) : (
          <>
            <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
              {t("transfer.rejectedTransfer.title")}
              <IconButton
                aria-label="close"
                onClick={onClose}
                edge="end"
                sx={{
                  position: "absolute",
                  right: 15,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography
                sx={{ color: "primary", fontSize: "0.875rem" }}
                gutterBottom
              >
                <Typography
                  sx={{ color: "primary", fontSize: "0.875rem" }}
                  gutterBottom
                >
                  {t("transfer.rejectedTransfer.declinedMessage", {
                    officerName:
                      getOfficerThatRejectedTransfer(incidentWorkItem),
                  })}
                </Typography>
                <Typography
                  sx={{ color: "primary", fontSize: "0.875rem" }}
                  gutterBottom
                >
                  {t("transfer.rejectedTransfer.tryAgain")}
                </Typography>
              </Typography>
            </DialogContent>

            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: 3,
              }}
            >
              <Button onClick={onTryAgainNo} color="error" variant="outlined">
                {t("common.no")}
              </Button>
              <Button
                onClick={onTryAgainYes}
                color="primary"
                variant="contained"
              >
                {t("common.yes")}
              </Button>
            </DialogActions>
          </>
        )}
      </PopupDialog>
    );
  };
  return render(incidentWorkItem);
}

export function TransferRejectedModal({
  open,
  onClose,
  onTryAgainYes,
  onTryAgainNo,
  incidentWorkItemId,
}: TransferRejectedModalProps) {
  const [{ data, error }] = apiHooks.incidentWorkItem.get(incidentWorkItemId);

  if (error) return <ErrorComponent />;

  return (
    <TransferRejectedModalInner
      open={open}
      onClose={onClose}
      onTryAgainYes={onTryAgainYes}
      onTryAgainNo={onTryAgainNo}
      incidentWorkItem={data}
    />
  );
}
