import { Close } from "@mui/icons-material";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  FormSection,
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  RecordPolicePresenceMinimumFields,
  WorkflowVersions,
} from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import {
  RecordPolicePresenceForm,
  RecordPolicePresenceFormSkeleton,
} from "../../forms/RecordPolicePresence/RecordPolicePresenceForm.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { Error as ErrorComponent } from "../../pages/Error/Error.js";
import { getRequestedPolicePresenceForm } from "../../pages/RecordPolicePresencePage/helpers.js";
import { createRoute } from "../../routing/createRoute.js";

export interface RecordPolicePresenceFormModalProps {
  incidentId: string;
  notificationId: string;
  closeNotification: () => void;
  isModalOpen: boolean;
  closeModal: () => void;
}
export function RecordPolicePresenceFormModal({
  notificationId,
  incidentId,
  closeNotification,
  isModalOpen,
  closeModal,
}: RecordPolicePresenceFormModalProps) {
  const notifications = useToastNotifications();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | undefined>(undefined);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const parsedIncidentId = IncidentFormIdSchema.safeParse(incidentId);
  if (!parsedIncidentId.success) {
    throw new Error("Invalid form ID");
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    { data: incidentWorkItem, error: incidentWorkItemError },
    refetchWorkItem,
  ] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(parsedIncidentId.data);

  if (incidentWorkItemError) return <ErrorComponent />;

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
    refetchWorkItem: ReturnType<typeof apiHooks.incidentWorkItem.get>[1],
  ) => {
    if (error) {
      throw new Error(error);
    }
    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      .workflowVersion as WorkflowVersions | undefined;
    const nhsRequestedPolicePresence =
      getRequestedPolicePresenceForm(incidentWorkItem);
    return (
      <PopupDialog
        open={isModalOpen}
        onClose={closeModal}
        maxWidth="lg"
        fullWidth
        fullScreen={fullScreen}
        disableDialogRootPadding={true}
      >
        <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            edge="end"
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <>
            {incidentWorkItemId === undefined ||
            workflowVersion === undefined ? (
              <RecordPolicePresenceFormSkeleton />
            ) : (
              <RecordPolicePresenceForm
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={async (values: RecordPolicePresenceMinimumFields) => {
                  try {
                    if (nhsRequestedPolicePresence === undefined) {
                      return;
                    }
                    const response = await api.incidentWorkItem.updateData(
                      incidentWorkItemId,
                      nhsRequestedPolicePresence.id,
                      FormSection.recordPolicePresence,
                      values,
                      true,
                    );
                    if (response.status === 204) {
                      const res =
                        await api.notifications.markAsRead(notificationId);
                      if (res.status !== 204) {
                        notifications.show(
                          t("notifications.failedMarkAsRead"),
                          {
                            severity: "error",
                          },
                        );
                      } else {
                        closeNotification();
                      }

                      await refetchWorkItem();
                      navigate(
                        createRoute.incidentFinishFormPage(incidentWorkItemId),
                      );
                      return;
                    } else {
                      setError(JSON.stringify(response.data.message));
                    }
                  } catch (error) {
                    setError(
                      "Failed to update 136 form: " +
                        JSON.stringify((error as Error).message),
                    );
                  }
                }}
                onCancel={() => {
                  closeModal();
                }}
                workflowVersion={workflowVersion}
              />
            )}
          </>
        </DialogContent>
      </PopupDialog>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return render(incidentWorkItem, refetchWorkItem);
}
