import React from "react";

import {
  Done as ArrivedIcon,
  DirectionsCar as CreatedIcon,
  Logout as LeftIcon,
} from "@mui/icons-material";
import { Avatar, Skeleton, Stack, Typography } from "@mui/material";

export type PlacesOfSafetyProps = { placesOfSafety?: PlaceOfSafetyProps[] };

export const PlacesOfSafety = ({ placesOfSafety }: PlacesOfSafetyProps) => {
  return (
    <Stack gap={2}>
      <Typography variant="h5">Places of Safety</Typography>
      {placesOfSafety === undefined ? (
        <PlaceOfSafetySkeleton />
      ) : (
        placesOfSafety.map((placeOfSafety) => (
          <PlaceOfSafety key={placeOfSafety.formId} {...placeOfSafety} />
        ))
      )}
    </Stack>
  );
};

export type PlaceOfSafetyProps = {
  formId: string;
  status: "created" | "arrived" | "left";
  name: string;
};

export const PlaceOfSafety = ({ status, name }: PlaceOfSafetyProps) => {
  return (
    <Stack direction={"row"} spacing={1} alignItems="center">
      {status === "left" ? (
        <Avatar sx={{ bgcolor: "error.main" }}>
          <LeftIcon />
        </Avatar>
      ) : status === "arrived" ? (
        <Avatar sx={{ bgcolor: "success.main" }}>
          <ArrivedIcon />
        </Avatar>
      ) : status === "created" ? (
        <Avatar>
          <CreatedIcon />
        </Avatar>
      ) : undefined}
      <Stack flexGrow={1}>
        <Typography variant="subtitle2">{name}</Typography>
        <Typography>
          {status === "left"
            ? "Left"
            : status === "arrived"
              ? "Arrived"
              : status === "created"
                ? "Created"
                : undefined}
        </Typography>
      </Stack>
    </Stack>
  );
};

export type PlaceOfSafetySkeletonProps = Record<string, unknown>;

export const PlaceOfSafetySkeleton = (_props: PlaceOfSafetySkeletonProps) => {
  return (
    <Stack direction={"row"} spacing={1} alignItems="center">
      <Skeleton variant="circular" width={40} height={40} />
      <Stack flexGrow={1}>
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      </Stack>
    </Stack>
  );
};
