import axios, { AxiosRequestConfig } from "axios";

import { NotificationEvents } from "@vision/common";
import {
  makeUseAxios,
  Options,
  RefetchFunction,
  UseAxiosResult,
} from "axios-hooks";
import { debounce } from "lodash-es";
import { config } from "../../config/index.js";
import { useChannel } from "../../lib/pusher/core/useChannel.js";
import { useEvent } from "../../lib/pusher/core/useEvent.js";

export const apiAxios = axios.create({
  // Important for cookies etc.
  withCredentials: true,
  baseURL: config.backendUrl,
  validateStatus: (status) => status >= 200 && status < 500,
});

apiAxios.interceptors.response.use((response) => {
  if (response.status === 401) {
    window.location.href = `${window.location.origin}/login`;
  }

  return response;
});

export const useApiAxios = makeUseAxios({ axios: apiAxios, cache: false });

/*
  Creates a hook that automatically reloads useAxios data based on events received on a pusher channel
 */
export function usePusherChannelReload<TResponse, TRequestBody = undefined>(
  channelName: string,
  config: AxiosRequestConfig<TRequestBody> | string,
  options?: Options,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-arguments
): UseAxiosResult<TResponse, any, any> {
  const channel = useChannel(channelName);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = useApiAxios<TResponse>(config as any, options);
  const reloadFn = debounce(() => {
    return result[1]();
  }, 500);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEvent(channel, NotificationEvents.resourceReload, (_event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    reloadFn();
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debouncedReloadFn = reloadFn as RefetchFunction<any, TResponse>;
  return [result[0], debouncedReloadFn, result[2]];
}
