import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import type { WorkflowPdfTemplate } from "../../types/index.js";
import type { Location } from "../../types/location.js";
import type { CreateIncidentWorkItemFormData } from "./createIncident.js";
import type { DetentionDetailsFormData } from "./detentionDetails.js";
import type { InformationForHealthcareFormData } from "./informationForHealthcare.js";
import type { PersonDetailsFormData } from "./personDetails.js";
import type { TriageIncidentFormData } from "./triageIncident.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export const PdfTemplate_BTP_0_1: WorkflowPdfTemplate<
  CreateIncidentWorkItemFormData,
  TriageIncidentFormData,
  Partial<PersonDetailsFormData>,
  Partial<DetentionDetailsFormData>,
  Partial<InformationForHealthcareFormData>
> = {
  sections: [
    ///////////////////
    // Person details
    ///////////////////
    {
      type: "text",
      textSections: [{ text: "Person details", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.personForename ??
            data.create.personForename,
        ),
      beforeLabelTextSections: [{ text: "Person's forename(s)" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.personSurname ??
            data.create.personSurname,
        ),
      beforeLabelTextSections: [{ text: "Person's Surname" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValueAsLocation(
          data.section136.personDetails?.personAddress,
        ),
      beforeLabelTextSections: [{ text: "Person's home address" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValueAsDate(data.section136.personDetails?.dateOfBirth),
      beforeLabelTextSections: [{ text: "Person's date of birth" }],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueLabel(data.section136.personDetails?.gender),
      beforeLabelTextSections: [{ text: "Gender" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.section136.personDetails?.ethnicAppearance),
      beforeLabelTextSections: [{ text: "Ethnic Appearance" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.section136.personDetails?.selfDeclaredEthnicity),
      beforeLabelTextSections: [{ text: "Self declared ethnicity" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendForename,
        ),
      beforeLabelTextSections: [{ text: "Relative or friend forename" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendSurname,
        ),
      beforeLabelTextSections: [{ text: "Relative or friend surname" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendRelationship,
        ),
      beforeLabelTextSections: [
        { text: "Relationship to person being detained" },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValueAsLocation(
          data.section136.personDetails?.relativeOrFriendAddress,
        ),
      beforeLabelTextSections: [
        {
          text: "Home address of the relative or friend",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendTelephoneNumber,
        ),
      beforeLabelTextSections: [
        {
          text: "Telephone number of relative or friend",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValue(data.section136.personDetails?.relativeOrFriendInformed),
      beforeLabelTextSections: [
        {
          text: "Has the relative or friend been informed of the detention?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValue(data.section136.personDetails?.reasonNotInformed),
      beforeLabelTextSections: [
        {
          text: "Why has the relative or friend not been informed?",
        },
      ],
    },
    /////////////////////
    // Detention Details
    /////////////////////
    {
      type: "text",
      textSections: [{ text: "Detention Details", options: { bold: true } }],
      divider: true,
    },
    // {
    //   type: "textbox",
    //   valueFn: (data) =>
    //     getValue(data.section136.detentionDetails.custodyNumber),
    //   beforeLabelTextSections: [
    //     {
    //       text: "Custody Number",
    //     },
    //   ],
    // },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueAsDate(data.section136.detentionDetails?.dateTimeOfDetention),
      beforeLabelTextSections: [
        {
          text: "Date of Detention",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueAsTime(data.section136.detentionDetails?.dateTimeOfDetention),
      beforeLabelTextSections: [
        {
          text: "Time of Detention",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueAsLocation(
          data.section136.detentionDetails?.locationDetentionTookPlace,
        ),
      beforeLabelTextSections: [
        {
          text: "Location where detention took place",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.section136.detentionDetails?.locationType),
      beforeLabelTextSections: [
        {
          text: "What type of location was this",
        },
      ],
    },
    //////////////////////////////
    // Triage
    //////////////////////////////
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.triage.isHealthcareProfessionalConsulted),
      beforeLabelTextSections: [
        {
          text: "Did you consult with anyone from Health services to assist with your decision making & actions taken?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueLabel(data.triage.howDidYouContactNhs),
      beforeLabelTextSections: [
        {
          text: "Who did you consult?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.triage.givenHealthCareProfessionalAdvice),
      beforeLabelTextSections: [
        {
          text: "What advice did the health services give?",
        },
      ],
    },
    //////////////////////////////
    // Information for Healthcare
    //////////////////////////////
    {
      type: "text",
      textSections: [{ text: "Health information", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValue(data.section136.informationForHealthcare?.incidentDescription),
      beforeLabelTextSections: [
        {
          text: "Account of the behaviour or crisis that led to Section 136 (MHA) powers being used",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(
          data.section136.informationForHealthcare?.wasHealthcareProvided,
        ),
      beforeLabelTextSections: [
        {
          text: "Was physical health care provided prior to arrival at the Place of Safety (ED or HBPOS)?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValue(
          data.section136.informationForHealthcare?.medicalAttentionProvided,
        ),
      beforeLabelTextSections: [
        {
          text: "Please describe the medical attention provided",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.section136.informationForHealthcare?.wasForceUsed),
      beforeLabelTextSections: [
        {
          text: "Was force used during detention?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(
          data.section136.informationForHealthcare?.hasPersonBeenSearched,
        ),
      beforeLabelTextSections: [
        {
          text: "Has the person been searched?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(
          data.section136.informationForHealthcare?.didSeizeproperty,
        ),
      beforeLabelTextSections: [
        {
          text: "Did you seize any property?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValue(
          data.section136.informationForHealthcare?.detailsPropertySeized,
        ),
      beforeLabelTextSections: [
        {
          text: "Detail of the property seized",
        },
      ],
    },
    // TODO: Need to extract data from the following fields : describeRiskFactors, describeRiskFactorsOther
    // {
    //   type: "textbox",
    //   valueFn: (data) =>
    //     getValue(data.section136.informationForHealthcare?.describeRiskFactors),
    //   beforeLabelTextSections: [
    //     {
    //       text: "Additional risk factors",
    //     },
    //   ],
    // },
    //////////////////////////////////////////
    // Final Place of safety + Police presence
    //////////////////////////////////////////
    // TODO
    //////////////////////////////////////////////
    // Previous places of safety + Police presence
    //////////////////////////////////////////////
    // TODO
    ///////////////
    // Officer log
    ///////////////
    // TODO
  ],
};

const notApplicable = "N/A";
const unknown = "Unknown";

export const getValue = (
  data: { value: string } | null | undefined,
): string => {
  return data?.value ?? notApplicable;
};

export const getUnknownableValue = (
  data: { value: string | null; isUnknown: boolean } | null | undefined,
): string => {
  return data?.isUnknown ? unknown : (data?.value ?? notApplicable);
};

export const getValueLabel = (
  data: { value: string; valueLabel: string } | null | undefined,
): string => {
  return data?.valueLabel ?? notApplicable;
};

export const getUnknownableValueLabel = (
  data:
    | {
        value: string | null;
        valueLabel: string | null;
        isUnknown: boolean;
      }
    | null
    | undefined,
): string => {
  return data?.isUnknown ? unknown : (data?.valueLabel ?? notApplicable);
};

export const getValueAsDate = (
  data: { value: string } | null | undefined,
): string => {
  if (!data) {
    return notApplicable;
  }

  const valueAsDate = dayjs.utc(data.value);
  const valueAsString = valueAsDate.isValid()
    ? valueAsDate.tz("Europe/London").format("DD/MM/YYYY")
    : notApplicable;

  return valueAsString;
};

export const getUnknownableValueAsDate = (
  data: { value: string | null; isUnknown: boolean } | null | undefined,
): string => {
  if (!data) {
    return notApplicable;
  }

  if (data.isUnknown) {
    return unknown;
  }

  const valueAsDate = dayjs.utc(data.value);
  const valueAsString = valueAsDate.isValid()
    ? valueAsDate.tz("Europe/London").format("DD/MM/YYYY")
    : notApplicable;

  return valueAsString;
};

export const getValueAsTime = (
  data: { value: string } | null | undefined,
): string => {
  if (!data) {
    return notApplicable;
  }

  const valueAsDate = dayjs.utc(data.value);
  const valueAsString = valueAsDate.isValid()
    ? valueAsDate.tz("Europe/London").format("HH:mm")
    : notApplicable;

  return valueAsString;
};

export const getValueAsDateTime = (
  data: { value: string } | null | undefined,
): string => {
  if (!data) {
    return notApplicable;
  }

  const valueAsDate = dayjs.utc(data.value);
  const valueAsString = valueAsDate.isValid()
    ? valueAsDate.tz("Europe/London").format("DD/MM/YYYY HH:mm")
    : notApplicable;

  return valueAsString;
};

export const getValueAsLocation = (
  data: { value: Location } | null | undefined,
): string => {
  if (!data) {
    return notApplicable;
  }

  return data.value.userProvided.formattedValue;
};

export const getUnknownableValueAsLocation = (
  data: { value: Location | null; isUnknown: boolean } | null | undefined,
): string => {
  if (!data) {
    return notApplicable;
  }

  if (data.isUnknown) {
    return unknown;
  }

  return data.value?.userProvided.formattedValue ?? notApplicable;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueFn = (data: any, sectionType: string, key: string) => {
  const section = data.section136?.[sectionType];
  const sectionFilledIn = section && Object.keys(section).length > 0;
  const value =
    section?.[key]?.valueLabel ?? (section?.[key]?.value || section?.[key]);

  if (value) {
    return value;
  }

  return sectionFilledIn ? "N/A" : "";
};
