import React, { useCallback, useMemo } from "react";

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

const debug = debugFn("vision-frontend:MultiSelect");

type MultiSelectOption = {
  label: TranslationKey;
  value: string;
};

export type MultiSelectProps = {
  fieldName: string;
  label: TranslationKey;
  options: MultiSelectOption[];
};

type SelectedField = { value: string; valueLabel: string };
type MultiSelectOutput = Array<SelectedField> | "";

export const MultiSelect = ({
  fieldName,
  label,
  options,
}: MultiSelectProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<MultiSelectOutput>(fieldName);

  // Clean up the field value to be a structured object
  const fieldValue: SelectedField[] = useMemo(() => {
    const original = field.value;
    const cleaned: Array<SelectedField> = original === "" ? [] : original;

    debug("Cleaned up value", { original, cleaned });
    return cleaned;
  }, [field.value]);

  const toggleValue = useCallback(
    ({ value, valueLabel }: SelectedField) => {
      const runAsync = async () => {
        // Is this value already selected?
        const alreadySelected = fieldValue
          .map(({ value }) => value)
          .includes(value);

        const newFieldArray = alreadySelected
          ? // Remove it from the array
            fieldValue.filter((entry) => entry.value !== value)
          : // Add it to the array, but with the translated label
            [...fieldValue, { value, valueLabel: valueLabel }];

        const newFieldValue = newFieldArray.length === 0 ? "" : newFieldArray;

        debug("toggleValue, setting value", newFieldValue);

        await helpers.setValue(newFieldValue);
        await helpers.setTouched(true);
      };

      void runAsync();
    },
    [fieldValue, helpers],
  );

  return (
    <Box data-testid={`formField-${fieldName}`}>
      <FormLabel data-testid="checkbox-group-label" label={label} />

      <Stack>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={JSON.stringify({
              value: option.value,
              valueLabel: t(option.label),
            })}
            control={
              <Checkbox
                checked={fieldValue
                  .map((entry) => entry.value)
                  .includes(option.value)}
                onChange={() => {
                  toggleValue({
                    value: option.value,
                    valueLabel: t(option.label),
                  });
                }}
              />
            }
            label={t(option.label)}
          />
        ))}
      </Stack>

      {meta.touched && meta.error && <FormError error={meta.error} />}

      {debug.enabled && (
        <>
          <Divider />
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"field.value : "}
            {JSON.stringify(field.value)}
          </Typography>
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"Treated like: "}
            {JSON.stringify(fieldValue)}
          </Typography>
          <Divider />
        </>
      )}
    </Box>
  );
};
