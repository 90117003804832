import { CircularProgress, Divider } from "@mui/material";
import {
  ExtendedVisionUser,
  GetIncidentWorkItemResponse,
  IncidentWorkItemWithContext,
  ParticipantRole,
  ParticipantStatus,
  UserIdUnbranded,
} from "@vision/common";
import React from "react";
import { apiHooks } from "../../api/index.js";
import { IncidentList } from "./IncidentList.js";

/**
 * Determines whether a given user is actively involved in an incident.
 *
 * If a user has requested an ownership transfer, or if they are a participant
 * but other participants have been transferred while they are not the owner,
 * they are not considered part of the incident.
 *
 * @param {IncidentWorkItemWithContext} incident - The incident containing participants.
 * @param {UserIdUnbranded | undefined} userId - The ID of the user to check.
 * @returns {boolean} - Returns `true` if the user is actively involved in the incident, otherwise `false`.
 */
const isUserActiveInIncident = (
  { participants }: IncidentWorkItemWithContext,
  userId: UserIdUnbranded | undefined,
): boolean => {
  // If there's no user ID, they cannot own or be part of the incident
  if (!userId) return false;

  // Find the participant entry for the current user
  const currentUser = participants.find((p) => p.participantId === userId);
  if (!currentUser) return false; // If the user is not a participant, return false

  // Find the participant who is the owner of the incident
  const owner = participants.find((p) => p.role === ParticipantRole.owner);

  // Check if the current user is the owner but has requested a transfer
  const isOwnerRequestingTransfer =
    owner?.participantId === userId &&
    owner.status === ParticipantStatus.requestedTransfer;
  if (isOwnerRequestingTransfer) return false; // If so, they no longer "own" the incident

  // If the current user is a "pending owner", they should have potential access (accept or reject transfer)
  if (currentUser.role === ParticipantRole.pendingOwner) return true;

  // Check if any participants have been transferred away from the incident
  const hasTransferredParticipants = participants.some(
    (p) => p.status === ParticipantStatus.transferred,
  );

  // If there are transferred participants AND the current user is not the owner, return false
  const isNonOwnerWithTransferredParticipants =
    hasTransferredParticipants && owner?.participantId !== userId;

  // If the user is an owner or no transfers affect their status, return true
  return !isNonOwnerWithTransferredParticipants;
};

export const HomePageInner = ({
  incidentWorkItems,
  user,
  refetchWorkItems,
}: {
  incidentWorkItems: GetIncidentWorkItemResponse | undefined;
  user: ExtendedVisionUser | undefined;
  refetchWorkItems: ReturnType<
    typeof apiHooks.incidentWorkItem.getMyActiveIncidentWorkItems
  >[1];
}) => {
  const incidents = Array.isArray(incidentWorkItems?.workItems)
    ? (incidentWorkItems.workItems as IncidentWorkItemWithContext[])
    : [];

  if ([user, incidents].some((x) => x === undefined)) {
    return <CircularProgress />;
  }

  const userId = user?.id;
  const myIncidents = incidents.filter((incident) =>
    isUserActiveInIncident(incident, userId),
  );

  const transferredIncidents = incidents.filter(
    (incident) => !isUserActiveInIncident(incident, userId),
  );

  return (
    <>
      <IncidentList
        incidentWorkItems={myIncidents}
        mode="myIncidents"
        isLoading={incidentWorkItems === undefined}
        userId={userId}
        refetchWorkItems={refetchWorkItems}
      />
      <Divider />
      <IncidentList
        incidentWorkItems={transferredIncidents}
        mode="transferredIncidents"
        isLoading={incidentWorkItems === undefined}
        userId={userId}
        refetchWorkItems={refetchWorkItems}
      />
    </>
  );
};

export const HomePageIncidents = ({
  user,
  incidentWorkItems,
  refetchWorkItems,
}: {
  user: ExtendedVisionUser | undefined;
  incidentWorkItems: GetIncidentWorkItemResponse | undefined;
  refetchWorkItems: ReturnType<
    typeof apiHooks.incidentWorkItem.getMyActiveIncidentWorkItems
  >[1];
}) => {
  const render = ({
    incidentWorkItems,
    user,
    refetchWorkItems,
  }: {
    incidentWorkItems: GetIncidentWorkItemResponse | undefined;
    user: ExtendedVisionUser | undefined;
    refetchWorkItems: ReturnType<
      typeof apiHooks.incidentWorkItem.getMyActiveIncidentWorkItems
    >[1];
  }) => {
    return (
      <HomePageInner
        incidentWorkItems={incidentWorkItems}
        user={user}
        refetchWorkItems={refetchWorkItems}
      />
    );
  };

  return render({ user, incidentWorkItems, refetchWorkItems });
};
