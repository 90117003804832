import { type ZodType, type ZodTypeAny } from "zod";
import type { TranslationKey } from "../@types/react-i18next.js";
import type { VisionPdfTemplate } from "./VisionPdfTemplate.js";
import type { FormBuilderField } from "./fields.js";
import type {
  EthnicityType,
  GenderType,
  SelfDeclaredEthnicityType,
} from "./formFieldSchemas.js";
import type { Location, LocationUnbranded } from "./location.js";
import type {
  IncidentFormType,
  IncidentWorkItemWithContextUnbranded,
} from "./workItem.js";
import type { WorkflowVersions } from "./workflowVersion.js";

export type Value<T> = {
  value: T;
};

export type ValueAndLabel<T> = {
  value: T;
  valueLabel: string;
};

export type UnknownableValue<T> = {
  value: T | null;
  isUnknown: boolean;
};

export type UnknownableValueAndLabel<T> = {
  value: T | null;
  isUnknown: boolean;
  valueLabel: string;
};

export type DetentionDetailsMinimumFields = {
  custodyNumber: Value<string> | null;
  dateTimeOfDetention: Value<string>;
  orgResponsibleForDetention: ValueAndLabel<string>;
  locationDetentionTookPlace: Value<LocationUnbranded>;
};

export type CreateIncidentMinimumFields = {
  dispatchNumber: Value<string>;
  incidentLocation: Value<LocationUnbranded>;
  commandUnit: ValueAndLabel<string>;
  personForename: UnknownableValue<string>;
  personSurname: UnknownableValue<string>;
};

export type TriageIncidentMinimumFields = {
  //  triage-1.0
  isHealthcareProfessionalConsulted: ValueAndLabel<"yes" | "no">;

  // triage-1.0.1
  reasonNotConsulted: ValueAndLabel<string> | null;

  // triage-1.0.3
  howDidYouContactNhs: Value<string> | null;

  // triage-1.0.3.16.1
  howDidYouContactNhsOtherDetails: Value<string> | null;

  // triage-1.0.4
  givenHealthCareProfessionalAdvice: ValueAndLabel<
    "136" | "5-6" | "voluntary" | "other"
  > | null;

  // triage-1.0.2
  whatWillYouDo: ValueAndLabel<"136" | "5-6" | "voluntary" | "other">;

  // triage-1.0.2.1.1
  whatWillYouDoOther: Value<string> | null;

  // triage-1.0.4.1.1
  reasonsForDetention: Value<string> | null;

  // triage-1.0.4.1.2
  confirmMentalCapacityLack: ValueAndLabel<"yes"> | null;
};

export type FinishFormMinimumFields = {
  dateTimeLeft: Value<string>;
};

export type PersonDetailsMinimumFields = {
  personForename: UnknownableValue<string>;
  personSurname: UnknownableValue<string>;
  personAddress: UnknownableValue<Location>;
  dateOfBirth: UnknownableValue<string>;
  satisfiedPersonIsOver18: ValueAndLabel<"yes" | "no"> | null;
  gender: ValueAndLabel<GenderType>;
  ethnicAppearance: ValueAndLabel<EthnicityType>;
  selfDeclaredEthnicity: ValueAndLabel<SelfDeclaredEthnicityType>;
  selfDeclaredEthnicityOther: Value<string> | null;
  hasRelativeOrFriend: ValueAndLabel<"yes" | "no">;
};

export type InformationForHealthcareMinimumFields = {
  incidentDescription: Value<string>;
  wasHealthcareProvided: ValueAndLabel<"yes" | "no">;
  hasPersonBeenSearched: ValueAndLabel<"yes" | "no">;
  hasAdditionalRiskFactors: ValueAndLabel<"yes" | "no">;
  describeRiskFactorsOther: Value<string> | null;
};

export type RecordPlaceOfSafetyMinimumFields = {
  placeOfSafety: {
    // These two values come from eMHA curated places of safety
    // and are not required for manually entered places of safety
    id?: string;
    odsCode?: string;

    // These fields are always present regardless
    // of whether the place of safety is curated or manually entered
    name: string;
    address: string;
    postalCode: string;
  };
};

export type RecordPlaceOfSafetyArrivalMinimumFields = {
  arrivalDateTime: Value<string> | null;
  didNotArrive: ValueAndLabel<"yes"> | null;
};

export type RecordPlaceOfSafetyLeaveMinimumFields = {
  leaveReason: ValueAndLabel<
    | "advised_visit_Hbpos_because_bedNowAvailable"
    | "advised_visit_accidentAndEmergency_because_noHbposAvailable"
    | "advised_visit_accidentAndEmergency_because_medicalGrounds"
    | "decided_visit_accidentAndEmergency_because_UnreasonableDelay"
    | "physicalHealthCheckComplete"
    | "other"
  >;
  leaveReasonOther: Value<string> | null;
};

export type RecordPreEndIncidentMinimumFields = {
  endingReason: ValueAndLabel<
    | "startedFormInError"
    | "formExpired"
    | "nhsNotEngage"
    | "medicalExamination"
    | "other"
  >;
};

export type RequestPolicePresenceMinimumFields = {
  hasPolicePresenceRequested: ValueAndLabel<"yes" | "no">;
};

export type RecordPolicePresenceMinimumFields = {
  hasRemainedAtPlaceOfSafety: ValueAndLabel<"yes" | "no">;
};

export enum FormSection {
  personDetails = "personDetails",
  detentionDetails = "detentionDetails",
  informationForHealthcare = "informationForHealthcare",
  triageMain = "triageMain",
  createIncidentMain = "createIncidentMain",
  recordPlaceOfSafetyMain = "recordPlaceOfSafetyMain",
  recordPlaceOfSafetyArrival = "recordPlaceOfSafetyArrival",
  recordPlaceOfSafetyLeave = "recordPlaceOfSafetyLeave",
  preEndIncident = "preEndIncident",
  requestPolicePresenceMain = "requestPolicePresenceMain",
  recordPolicePresence = "recordPolicePresence",
  finishForm = "finishForm",
}

export type MinimumFieldsPerSection = {
  [FormSection.personDetails]: PersonDetailsMinimumFields;
  [FormSection.detentionDetails]: DetentionDetailsMinimumFields;
  [FormSection.informationForHealthcare]: InformationForHealthcareMinimumFields;
  [FormSection.triageMain]: TriageIncidentMinimumFields;
  [FormSection.createIncidentMain]: CreateIncidentMinimumFields;
  [FormSection.recordPlaceOfSafetyMain]: RecordPlaceOfSafetyMinimumFields;
  [FormSection.recordPlaceOfSafetyArrival]: RecordPlaceOfSafetyArrivalMinimumFields;
  [FormSection.recordPlaceOfSafetyLeave]: RecordPlaceOfSafetyLeaveMinimumFields;
  [FormSection.preEndIncident]: RecordPreEndIncidentMinimumFields;
  [FormSection.finishForm]: FinishFormMinimumFields;
  [FormSection.requestPolicePresenceMain]: RequestPolicePresenceMinimumFields;
  [FormSection.recordPolicePresence]: RecordPolicePresenceMinimumFields;
};

export type IncidentFormSections = {
  [IncidentFormType.createIncident]: FormSection.createIncidentMain;
  [IncidentFormType.triage]: FormSection.triageMain;
  [IncidentFormType.recordPlaceOfSafety]:
    | FormSection.recordPlaceOfSafetyMain
    | FormSection.recordPlaceOfSafetyArrival
    | FormSection.recordPlaceOfSafetyLeave;
  [IncidentFormType.section136]:
    | FormSection.personDetails
    | FormSection.detentionDetails
    | FormSection.informationForHealthcare;
  [IncidentFormType.preEndIncident]: FormSection.preEndIncident;
  [IncidentFormType.requestPolicePresence]:
    | FormSection.requestPolicePresenceMain
    | FormSection.recordPolicePresence;
  [IncidentFormType.finishForm]: FormSection.finishForm;
};

export type FormSectionDefinition<
  Section extends FormSection,
  DataType extends MinimumFieldsPerSection[Section],
> = {
  id: Section;
  name: TranslationKey;
  strictFieldSchemas: Record<string, ZodTypeAny>;
  formSchema: ZodType<DataType>;
  autosave: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pages: { fields: readonly FormBuilderField<DataType, any>[] }[];
};

export type FormDefinition<
  SectionDefinitions extends FormSectionDefinition<
    FormSection,
    // Couldn't figure out how to avoid the any here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >[],
> = {
  sections: SectionDefinitions;
};

export type WorkflowPdfInputData<
  TCreateIncident extends CreateIncidentMinimumFields,
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends Partial<PersonDetailsMinimumFields>,
  TDetentionDetails extends Partial<DetentionDetailsMinimumFields>,
  TInformationForHealthcare extends
    Partial<InformationForHealthcareMinimumFields>,
> = {
  create: TCreateIncident;

  triage: TTriageIncident;

  section136: {
    personDetails?: TPersonDetails;
    detentionDetails?: TDetentionDetails;
    informationForHealthcare?: TInformationForHealthcare;
  };

  incidentWorkItem: IncidentWorkItemWithContextUnbranded;
  // TODO: other forms/sections and metadata
};

export type WorkflowPdfTemplate<
  TCreateIncident extends CreateIncidentMinimumFields,
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends Partial<PersonDetailsMinimumFields>,
  TDetentionDetails extends Partial<DetentionDetailsMinimumFields>,
  TInformationForHealthcare extends
    Partial<InformationForHealthcareMinimumFields>,
> = VisionPdfTemplate<
  WorkflowPdfInputData<
    TCreateIncident,
    TTriageIncident,
    TPersonDetails,
    TDetentionDetails,
    TInformationForHealthcare
  >
>;

export type Workflow<
  TCreateIncident extends CreateIncidentMinimumFields,
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends PersonDetailsMinimumFields,
  TDetentionDetails extends DetentionDetailsMinimumFields,
  TInformationForHealthcare extends InformationForHealthcareMinimumFields,
  TRecordPlaceOfSafety extends RecordPlaceOfSafetyMinimumFields,
  TRecordPlaceOfSafetyArrival extends RecordPlaceOfSafetyArrivalMinimumFields,
  TRecordPlaceOfSafetyLeave extends RecordPlaceOfSafetyLeaveMinimumFields,
  TRecordPreEndIncident extends RecordPreEndIncidentMinimumFields,
  TRequestPolicePresence extends RequestPolicePresenceMinimumFields,
  TRecordPolicePresence extends RecordPolicePresenceMinimumFields,
  TFinishForm extends FinishFormMinimumFields,
> = {
  workflowVersion: WorkflowVersions;
  [IncidentFormType.createIncident]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.createIncidentMain, TCreateIncident>]
    >;
  };
  [IncidentFormType.triage]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.triageMain, TTriageIncident>]
    >;
  };
  [IncidentFormType.recordPlaceOfSafety]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyMain,
          TRecordPlaceOfSafety
        >,
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyArrival,
          TRecordPlaceOfSafetyArrival
        >,
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyLeave,
          TRecordPlaceOfSafetyLeave
        >,
      ]
    >;
  };
  [IncidentFormType.preEndIncident]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.preEndIncident, TRecordPreEndIncident>]
    >;
  };

  [IncidentFormType.finishForm]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.finishForm, TFinishForm>]
    >;
  };

  [IncidentFormType.requestPolicePresence]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<
          FormSection.requestPolicePresenceMain,
          TRequestPolicePresence
        >,
        FormSectionDefinition<
          FormSection.recordPolicePresence,
          TRecordPolicePresence
        >,
      ]
    >;
  };

  [IncidentFormType.section136]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<FormSection.personDetails, TPersonDetails>,
        FormSectionDefinition<FormSection.detentionDetails, TDetentionDetails>,
        FormSectionDefinition<
          FormSection.informationForHealthcare,
          TInformationForHealthcare
        >,
      ]
    >;

    pdfTemplate: WorkflowPdfTemplate<
      TCreateIncident,
      TTriageIncident,
      TPersonDetails,
      TDetentionDetails,
      TInformationForHealthcare
    >;
  };
};
