import {
  config,
  GetPlacesOfSafetyResponseBranded,
  placeOfSafetyRequests,
} from "@vision/common";
import { useApiAxios } from "../axios/api.js";

export const api = placeOfSafetyRequests;

export const apiHooks = () => {
  return {
    placeOfSafety: {
      getAll: function useGetAll() {
        return useApiAxios<GetPlacesOfSafetyResponseBranded>(
          config.placeOfSafety.getAll(),
        );
      },
    },
  };
};
