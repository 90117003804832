import { Close, HorizontalRule } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ExtendedVisionUser,
  getPatientName,
  IncidentWorkItemId,
  IncidentWorkItemWithContext,
  ParticipantStatus,
} from "@vision/common";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { HeadingAndSubheading } from "../../components/IncidentWorkItems/IncidentWorkItemCard/IncidentWorkItemCard.js";
import { renderUkDateTime } from "../../components/util.js";
import { UserContextType } from "../../Context/UserContext.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { Error as ErrorComponent } from "../../pages/Error/Error.js";
import { createRoute } from "../../routing/createRoute.js";

interface TransferRequestedModalInnerProps {
  user: ExtendedVisionUser;
  incidentWorkItem: IncidentWorkItemWithContext | undefined;
  notificationId: string;
  open: boolean;
  close: () => void;
  refetchUser: UserContextType["refetchUser"];
}
interface TransferRequestedModalProps {
  user: ExtendedVisionUser;
  incidentWorkItemId: IncidentWorkItemId;
  notificationId: string;
  open: boolean;
  close: () => void;
  refetchUser: UserContextType["refetchUser"];
}

export function TransferRequestedModalInner({
  user,
  incidentWorkItem,
  notificationId,
  open,
  close,
  refetchUser,
}: TransferRequestedModalInnerProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const notifications = useToastNotifications();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    const containerStyles = {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      minHeight: "12rem",
      justifyContent: "space-between",
      ...(isMobileView && {
        border: "1px solid",
        borderRadius: "0.5rem",
        borderColor: theme.palette.grey[400],
        padding: "1rem",
      }),
    };

    const handleAcceptTransfer = async () => {
      const response = await api.incidentWorkItem.resolveTransfer({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        incidentWorkItemId: incidentWorkItem!.incidentWorkItem.id,
        isAccepted: true,
        resolvingNotification: true,
      });

      if (response.status === 204) {
        const markAsReadResponse =
          await api.notifications.markAsRead(notificationId);

        if (markAsReadResponse.status !== 204) {
          notifications.show(t("notifications.failedMarkAsRead"), {
            severity: "error",
          });
        } else {
          notifications.show(t("incidentWorkItem.notificationAcceptedShow"), {
            severity: "success",
          });
          close();
          navigate(
            createRoute.incidentProgressPage(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              incidentWorkItem!.incidentWorkItem.id,
            ),
          );
        }
      } else {
        notifications.show(t("incidentWorkItem.notificationAcceptedError"), {
          severity: "error",
        });
        close();
      }
    };

    const handleRejectTransfer = async () => {
      const response = await api.incidentWorkItem.resolveTransfer({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        incidentWorkItemId: incidentWorkItem!.incidentWorkItem.id,
        isAccepted: false,
        resolvingNotification: true,
      });

      if (response.status === 204) {
        const markAsReadResponse =
          await api.notifications.markAsRead(notificationId);

        if (markAsReadResponse.status !== 204) {
          notifications.show(t("notifications.failedMarkAsRead"), {
            severity: "error",
          });
        } else {
          notifications.show(t("incidentWorkItem.notificationRejectedShow"), {
            severity: "success",
          });
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          await refetchUser();
        }
      } else {
        notifications.show(t("incidentWorkItem.notificationRejectedError"), {
          severity: "error",
        });
      }

      close();
    };

    const getRequestingOfficerName = (
      incidentWorkItem: IncidentWorkItemWithContext,
    ) => {
      const requestingOfficerName = incidentWorkItem.participants.find(
        (participant) => {
          return participant.status === ParticipantStatus.requestedTransfer;
        },
      );

      return requestingOfficerName?.name ?? "";
    };

    const getTransferRequestedTime = (
      incidentWorkItem: IncidentWorkItemWithContext,
    ) => {
      const transferRequest = incidentWorkItem.participants.find(
        (participant) => {
          return participant.status === ParticipantStatus.pendingTransfer;
        },
      );

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const createdAtDate = transferRequest!.assignedAt;

      return renderUkDateTime(dayjs(createdAtDate).toDate(), true);
    };

    const getFormattedPatientName = (
      incidentWorkItem: IncidentWorkItemWithContext,
    ) => {
      const { surname, forename } = getPatientName({ incidentWorkItem });
      if (!forename && !surname) {
        return t("unknown");
      }
      return [surname, forename].filter(Boolean).join(", ");
    };

    return (
      <PopupDialog
        open={open}
        onClose={close}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        disableDialogRootPadding={false}
        hasPadding={false}
      >
        {incidentWorkItem === undefined ? (
          <CircularProgress />
        ) : (
          <>
            <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
              {t("transfer.requestedTransfer.title")}
              <IconButton
                aria-label="close"
                onClick={close}
                edge="end"
                sx={{
                  position: "absolute",
                  right: 15,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography
                sx={{ color: "primary", fontSize: "0.875rem" }}
                gutterBottom
              >
                {t("transfer.requestedTransfer.officerRequest", {
                  requestingOfficerName:
                    getRequestingOfficerName(incidentWorkItem),
                })}
              </Typography>

              <Box sx={containerStyles}>
                <Box
                  sx={{
                    display: "grid",
                    gap: 1,
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <HeadingAndSubheading
                    heading="transfer.requestedTransfer.createdAt"
                    subheading={getTransferRequestedTime(incidentWorkItem)}
                  />
                  <HeadingAndSubheading
                    heading="transfer.requestedTransfer.nameOfPerson"
                    subheading={getFormattedPatientName(incidentWorkItem)}
                  />
                  <HorizontalRule />
                  <HorizontalRule />
                  <HeadingAndSubheading
                    heading="transfer.requestedTransfer.incidentId"
                    subheading={incidentWorkItem.incidentWorkItem.id}
                  />
                  <HeadingAndSubheading
                    heading="transfer.requestedTransfer.officerAssigned"
                    subheading={user.name}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    gap: "1rem",
                    padding: "2rem 0",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      void handleRejectTransfer();
                    }}
                    sx={{ minWidth: "5rem" }}
                  >
                    {t("transfer.requestedTransfer.reject")}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => {
                      void handleAcceptTransfer();
                    }}
                    sx={{ minWidth: "5rem" }}
                  >
                    {t("transfer.requestedTransfer.accept")}
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </>
        )}
      </PopupDialog>
    );
  };

  return render(incidentWorkItem);
}

export function TransferRequestedModal({
  user,
  incidentWorkItemId,
  notificationId,
  open,
  close,
  refetchUser,
}: TransferRequestedModalProps) {
  const [{ data: incidentWorkItem, error }] =
    apiHooks.incidentWorkItem.get(incidentWorkItemId);

  if (error) return <ErrorComponent />;

  return (
    <TransferRequestedModalInner
      user={user}
      incidentWorkItem={incidentWorkItem ?? undefined}
      notificationId={notificationId}
      open={open}
      close={close}
      refetchUser={refetchUser}
    />
  );
}
