import React, { useCallback, useMemo } from "react";

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField as MuiTextField,
  Stack,
  Typography,
} from "@mui/material";
import { Location, LocationSchema, TranslationKey } from "@vision/common";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { config } from "../../../config/index.js";
import { FormLabel } from "../FormLabel/FormLabel.js";
import { useGoogleAutocomplete } from "./useGoogleAutocomplete.js";

const debug = debugFn("vision-frontend:LocationPicker");

export type LocationPickerProps = {
  fieldName: string;
  label?: TranslationKey;
  canBeUnknown?: boolean;
};

type LocationPickerOutput = {
  value: Location | null;
  isUnknown: boolean;
};

export function LocationPicker({
  fieldName,
  label,
  canBeUnknown,
}: LocationPickerProps) {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField<LocationPickerOutput>(fieldName);

  // We use this to store the last value that the user typed in the text field.
  // This is so that we can restore it if they turn on the "unknown" flag.
  // We pre-populate this with the incoming "initialValue".
  const [lastTypedValue, setLastTypedValue] = React.useState<string | null>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    ["", null, undefined].includes(field.value as any)
      ? null
      : (field.value.value?.userProvided.formattedValue ?? null),
  );

  // We use this to store the last location that the user picked.
  // This is so that we can restore it if they turn on the "unknown" flag.
  // We pre-populate this with the incoming "initialValue".
  const [lastPickedLocation, setLastPickedLocation] =
    React.useState<Location | null>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
      ["", null, undefined].includes(field.value as any)
        ? null
        : (field.value.value ?? null),
    );

  // Clean up the field value to be a structured object
  const fieldValue = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
      ["", null, undefined].includes(field.value as any)
        ? // Turn null-ish values into a structured object
          { value: null, isUnknown: false }
        : field.value.isUnknown
          ? // If "unknown", clear out the value
            { value: null, isUnknown: true }
          : field.value,
    [field.value],
  );

  // The error could either come from Formik ("Invalid input") or from the schema
  // ({value: "string too short"}). We need to figure out which at runtime.
  const fieldError = useMemo(
    () => {
      const value =
        typeof meta.error === "string"
          ? meta.error
          : typeof meta.error === "object"
            ? (meta.error as { value: string } | undefined)?.value
            : undefined;

      return value === undefined ? undefined : t(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(meta.error)],
  );
  if (fieldError) {
    debug("fieldError", fieldError);
  }

  const handleGooglePlaceSelected = useCallback(
    (location: Location) => {
      const runAsync = async () => {
        console.log("location selected", location);
        setLastPickedLocation(location);
        setLastTypedValue(location.userProvided.formattedValue);

        await helpers.setValue({ value: location, isUnknown: false });
        await helpers.setTouched(true);
      };

      void runAsync();
    },
    [helpers],
  );

  const apiKey = config.googleMaps.apiKey;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { ref } = useGoogleAutocomplete({
    apiKey,
    onPlaceSelected: handleGooglePlaceSelected,
  });

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runAsync = async () => {
      // Store away the thing they typed so we can restore it if they
      // turn on the unknown flag
      setLastTypedValue(event.target.value);

      const newValue = LocationSchema.safeParse({
        primaryLocationType: "userProvided",
        userProvided: { formattedValue: event.target.value },
      });

      if (newValue.success) {
        debug("handleTextChange, Setting value", newValue.data);
        setLastPickedLocation(newValue.data);
        await helpers.setValue({ value: newValue.data, isUnknown: false });
        await helpers.setTouched(true);
      } else {
        debug("handleTextChange, Error", newValue.error);
        await helpers.setValue({ value: null, isUnknown: false });
        await helpers.setTouched(true);
      }
    };

    void runAsync();
  };

  const handleUnknownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runAsync = async () => {
      const newValue = event.target.checked
        ? {
            value: null,
            isUnknown: true,
          }
        : {
            value: lastPickedLocation,
            isUnknown: false,
          };

      debug("handleUnknownChange, Setting value", newValue);

      await helpers.setValue(newValue);
      await helpers.setTouched(true);
    };

    void runAsync();
  };

  return (
    <Stack data-testid={`formField-${fieldName}`}>
      <Box
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent="flex-start"
        marginBottom="0.5rem"
      >
        {label && <FormLabel label={label} />}
      </Box>
      <MuiTextField
        id={fieldName}
        name={fieldName}
        value={lastTypedValue ?? ""}
        error={meta.touched && Boolean(fieldError)}
        helperText={
          // We always render the helper text so that the "unknown" checkbox
          // doesn't jump vertically when the error message appears.
          // Empty string would not be rendered, so we use a space.
          meta.touched ? (fieldError ?? " ") : " "
        }
        inputRef={ref}
        inputProps={{
          "data-testid": `formField-${fieldName}-input`,
        }}
        onChange={handleTextChange}
        onBlur={field.onBlur}
        disabled={fieldValue.isUnknown}
        fullWidth
      />
      {canBeUnknown && (
        <FormControlLabel
          control={
            <Checkbox
              checked={fieldValue.isUnknown}
              onChange={handleUnknownChange}
              data-testid={`formField-${fieldName}-unknown`}
            />
          }
          label={t("unknown")}
        />
      )}
      {debug.enabled &&
        Object.entries({
          "field.value": field.value,
          "Treated like": fieldValue,
          lastTypedValue,
          lastPickedLocation,
        }).map(([key, value]) => (
          <Box key={key}>
            <Divider />
            <Typography sx={{ color: debug.color }}>{key}</Typography>
            <Typography
              sx={{
                color: debug.color,
                fontFamily: "monospace",
                whiteSpace: "pre-wrap",
              }}
            >
              {JSON.stringify(value, undefined, 2)}
            </Typography>
          </Box>
        ))}
    </Stack>
  );
}
