import React, { useCallback } from "react";

import { CircularProgress } from "@mui/material";
import { CuratedPlaceOfSafetySchema } from "@thalamos/common";
import {
  FormSection,
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import api, { apiHooks } from "../../api/index.js";
import { PlacesOfSafetyContext } from "../../components/FormBuilder/PlaceOfSafetyPicker/PlaceOfSafetyContext.js";
import { ArrivePlaceOfSafetyForm } from "../../forms/ArrivePlaceOfSafety/ArrivePlaceOfSafetyForm.js";
import { LeavePlaceOfSafetyForm } from "../../forms/LeavePlaceOfSafety/LeavePlaceOfSafetyForm.js";
import { RecordPlaceOfSafetyForm } from "../../forms/RecordPlaceOfSafety/RecordPlaceOfSafetyForm.js";
import { Error as ErrorComponent } from "../../pages/Error/Error.js";
import { createRoute } from "../../routing/createRoute.js";

export type RecordRetrospectivePlaceOfSafetyPageProps = Record<string, unknown>;

export function RecordRetrospectivePlaceOfSafetyPage(
  _props: RecordRetrospectivePlaceOfSafetyPageProps,
) {
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    {
      data: incidentWorkItem,
      loading: isIncidentLoading,
      error: incidentError,
    },
    refetchIncidentWorkItem,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  ] = apiHooks.incidentWorkItem.get(incidentId.data);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    {
      data: placesOfSafety,
      loading: isPlacesOfSafetyLoading,
      error: placeOfSafetyError,
    },
    refetchPlacesOfSafety,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  ] = apiHooks.placeOfSafety.getAll();

  if (incidentError || placeOfSafetyError) {
    <ErrorComponent />;
  }

  if (isIncidentLoading || isPlacesOfSafetyLoading) {
    return <CircularProgress />;
  }

  const placesList = placesOfSafety?.placesOfSafety ?? [];

  return (
    <PlacesOfSafetyContext.Provider
      value={{
        placesOfSafety: placesList
          .map((placeOfSafety) => {
            const result = CuratedPlaceOfSafetySchema.safeParse(placeOfSafety);
            return result.success ? result.data : undefined;
          })
          .filter((placeOfSafety) => placeOfSafety !== undefined),
      }}
    >
      {incidentWorkItem && (
        <RecordRetrospectivePlaceOfSafetyPageInner
          incidentWorkItem={incidentWorkItem}
          refetch={async () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await refetchIncidentWorkItem();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await refetchPlacesOfSafety();
          }}
        />
      )}
    </PlacesOfSafetyContext.Provider>
  );
}

export type RecordRetrospectivePlaceOfSafetyPageInnerProps = {
  incidentWorkItem: IncidentWorkItemWithContext;
  refetch: () => Promise<void>;
};

/**
 * This form captures the values for multiple form sections before
 * submitting them all at once. This is an attempt to make the journey
 * "atomic" and to minimise the impact of the user navigating away from
 * the page before completing all sections.
 */
function RecordRetrospectivePlaceOfSafetyPageInner({
  incidentWorkItem,
  refetch,
}: RecordRetrospectivePlaceOfSafetyPageInnerProps) {
  const navigate = useNavigate();

  const incidentWorkItemId = incidentWorkItem.incidentWorkItem.id;
  const workflowVersion = incidentWorkItem.incidentWorkItem
    .workflowVersion as WorkflowVersions;

  // This is where we store the data for the three form sections
  const [posMainSection, setPosMainSection] = React.useState<Record<
    string,
    unknown
  > | null>(null);
  const [posArriveSection, setPosArriveSection] = React.useState<Record<
    string,
    unknown
  > | null>(null);

  // This is the current form section the user is completing
  const [currentFormSection, setCurrentFormSection] = React.useState<
    "main" | "arrive" | "leave"
  >("main");

  // These are the handlers for completing each form section
  const handleFormComplete = useCallback(() => {
    void refetch();
    navigate(createRoute.incidentReviewPlacesOfSafetyPage(incidentWorkItemId));
  }, [incidentWorkItemId, navigate, refetch]);

  const handleMainSectionComplete = useCallback(
    (values: Record<string, unknown>) => {
      setPosMainSection(values);
      setCurrentFormSection("arrive");
    },
    [],
  );

  const handleArriveSectionComplete = useCallback(
    (values: Record<string, unknown>) => {
      setPosArriveSection(values);
      setCurrentFormSection("leave");
    },
    [],
  );

  const handleLeaveSectionComplete = useCallback(
    async (posLeaveSection: Record<string, unknown>) => {
      if (posMainSection === null) {
        throw Error("Main section is missing and mandatory");
      }

      // Create a Place of Safety Form
      const formId = IncidentFormIdSchema.parse(v4());

      {
        const response = await api.incidentWorkItem.createPlaceOfSafetyForm(
          incidentWorkItemId,
          formId,
        );

        if (response.status !== 204) {
          throw Error(JSON.stringify(response.data.message));
        }
      }

      // Populate the Main section
      {
        const response = await api.incidentWorkItem.updateData(
          incidentWorkItemId,
          formId,
          FormSection.recordPlaceOfSafetyMain,
          posMainSection,
          true,
        );

        if (response.status !== 204) {
          throw Error(JSON.stringify(response.data.message));
        }
      }

      // Populate the Arrival section
      if (posArriveSection !== null) {
        const response = await api.incidentWorkItem.updateData(
          incidentWorkItemId,
          formId,
          FormSection.recordPlaceOfSafetyArrival,
          posArriveSection,
          true,
        );

        if (response.status !== 204) {
          throw Error(JSON.stringify(response.data.message));
        }
      }

      // Populate the Leave section
      {
        const response = await api.incidentWorkItem.updateData(
          incidentWorkItemId,
          formId,
          FormSection.recordPlaceOfSafetyLeave,
          posLeaveSection,
          true,
        );

        if (response.status !== 204) {
          throw Error(JSON.stringify(response.data.message));
        }
      }

      handleFormComplete();
    },
    [handleFormComplete, incidentWorkItemId, posArriveSection, posMainSection],
  );

  return (
    <>
      {currentFormSection === "main" && (
        <RecordPlaceOfSafetyForm
          onSubmit={handleMainSectionComplete}
          onCancel={handleFormComplete}
          workflowVersion={workflowVersion}
        />
      )}
      {currentFormSection === "arrive" && (
        <ArrivePlaceOfSafetyForm
          onSubmit={handleArriveSectionComplete}
          onCancel={handleFormComplete}
          workflowVersion={workflowVersion}
        />
      )}
      {currentFormSection === "leave" && (
        <LeavePlaceOfSafetyForm
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleLeaveSectionComplete}
          onCancel={handleFormComplete}
          workflowVersion={workflowVersion}
        />
      )}
    </>
  );
}
