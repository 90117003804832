import { apiAxios } from "./axios/api.js";
import { api as helloApi } from "./hello/api.js";
import {
  api as incidentWorkItemApi,
  apiHooks as incidentWorkItemApiHooks,
} from "./incidentWorkItem/api.js";
import {
  api as notificationApi,
  apiHooks as notificationApiHooks,
} from "./notification/api.js";
import {
  api as organisationApi,
  apiHooks as organisationApiHooks,
} from "./organisation/api.js";
import {
  api as placeOfSafetyApi,
  apiHooks as placeOfSafetyHooks,
} from "./placeOfSafety/api.js";
import { api as userApi, apiHooks as userApiHooks } from "./user/api.js";

export const apiHooks = {
  ...incidentWorkItemApiHooks(),
  ...notificationApiHooks(),
  ...organisationApiHooks(),
  ...userApiHooks(),
  ...placeOfSafetyHooks(),
};

export default {
  ...helloApi(apiAxios),
  ...incidentWorkItemApi(apiAxios),
  ...organisationApi(apiAxios),
  ...userApi(apiAxios),
  ...placeOfSafetyApi(apiAxios),
  ...notificationApi(apiAxios),
};
