import { Button, Input, Stack, Typography, useTheme } from "@mui/material";
import * as React from "react";
import api, { apiHooks } from "../../api/index.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";

export type ShareModalProps = {
  incidentWorkItemId: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  refetchWorkItem?: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
};

export function ShareModal(props: ShareModalProps) {
  const theme = useTheme();
  const notifications = useToastNotifications();
  const [email, setEmail] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const handleShare = React.useCallback(async () => {
    if (!email || !props.incidentWorkItemId) return;
    try {
      setSubmitting(true);
      await api.incidentWorkItem
        .share(props.incidentWorkItemId, email)
        .then(async (res) => {
          if (res.status === 200) {
            notifications.show("Incident shared successfully", {
              severity: "success",
            });
            if (props.refetchWorkItem) {
              await props.refetchWorkItem();
            }
            props.onClose();
          } else {
            const reason =
              "reason" in res.data && typeof res.data.reason === "string"
                ? (res.data.reason as string)
                : "Unexpected error sharing incident";

            notifications.show(reason, { severity: "error" });
          }
        });
    } catch (_) {
      notifications.show(
        "Unexpected error sharing incident - please try again later",
        { severity: "error" },
      );
    } finally {
      setSubmitting(false);
    }
  }, [email, props, notifications]);

  return (
    <PopupDialog
      open={props.isOpen}
      onClose={props.onClose}
      title="Share incident"
    >
      <Stack gap={"2rem"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void handleShare();
            if (props.onConfirm) {
              props.onConfirm();
            }
          }}
        >
          <Stack gap={"1rem"}>
            <Typography
              sx={{
                fontSize: theme.spacing(2),
                fontWeight: 500,
              }}
            >
              Share to NHS
            </Typography>
            <Input
              placeholder="Enter email address"
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={submitting || !email}
              data-testid="share-button"
            >
              Share
            </Button>
          </Stack>
        </form>
      </Stack>
    </PopupDialog>
  );
}
