import type { WorkflowPdfTemplate } from "../../types/index.js";
import {
  getUnknownableValue,
  getUnknownableValueAsLocation,
  getValue,
  getValueLabel,
} from "../thalamosMax0.1Workflow/pdfTemplate.js";
import { getValueAsLocation } from "../workflow_BTP_1_0/pdfTemplate.js";
import type { CreateIncidentWorkItemFormData } from "./createIncident.js";
import type { DetentionDetailsFormData } from "./detentionDetails.js";
import type { InformationForHealthcareFormData } from "./informationForHealthcare.js";
import type { PersonDetailsFormData } from "./personDetails.js";
import type { TriageIncidentThalamosMin01FormData } from "./triageIncident.js";

export const min01PdfTemplate: WorkflowPdfTemplate<
  CreateIncidentWorkItemFormData,
  TriageIncidentThalamosMin01FormData,
  Partial<PersonDetailsFormData>,
  Partial<DetentionDetailsFormData>,
  Partial<InformationForHealthcareFormData>
> = {
  sections: [
    {
      type: "text",
      textSections: [{ text: "Detention details", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueAsLocation(
          data.section136.detentionDetails?.locationDetentionTookPlace,
        ),
      beforeLabelTextSections: [{ text: "Incident Location - Address" }],
    },
    {
      type: "text",
      textSections: [{ text: "Person details", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.personForename ??
            data.create.personForename,
        ),
      beforeLabelTextSections: [{ text: "Person's First name(s)" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.personSurname ??
            data.create.personSurname,
        ),
      beforeLabelTextSections: [{ text: "Person's Surname" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValueAsLocation(
          data.section136.personDetails?.personAddress,
        ),
      beforeLabelTextSections: [{ text: "Person's home address" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(data.section136.personDetails?.dateOfBirth),
      beforeLabelTextSections: [{ text: "Person's date of birth" }],
    },
    {
      type: "textbox",
      valueFn: (data) => getValueLabel(data.section136.personDetails?.gender),
      beforeLabelTextSections: [{ text: "Gender" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.section136.personDetails?.selfDeclaredEthnicity),
      beforeLabelTextSections: [{ text: "Self declared ethnicity" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.section136.personDetails?.ethnicAppearance),
      beforeLabelTextSections: [{ text: "Ethnic Appearance" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendForename,
        ),
      beforeLabelTextSections: [{ text: "Relative or firend forename" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendSurname,
        ),
      beforeLabelTextSections: [{ text: "Relative or firend surname" }],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendRelationship,
        ),
      beforeLabelTextSections: [
        { text: "Relationship to person being detained" },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValueAsLocation(
          data.section136.personDetails?.relativeOrFriendAddress,
        ),
      beforeLabelTextSections: [
        {
          text: "Home address of the relative or friend of the person being detained",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getUnknownableValue(
          data.section136.personDetails?.relativeOrFriendTelephoneNumber,
        ),
      beforeLabelTextSections: [
        {
          text: "Telephone number of relative or friend",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(data.section136.personDetails?.relativeOrFriendInformed),
      beforeLabelTextSections: [
        {
          text: "Has the relative or friend been informed of the detention?",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValue(data.section136.personDetails?.reasonNotInformed),
      beforeLabelTextSections: [
        {
          text: "Please explain why the relative or friend has not been informed",
        },
      ],
    },
    {
      type: "text",
      textSections: [{ text: "Health information", options: { bold: true } }],
      divider: true,
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValue(
          data.section136.informationForHealthcare?.medicalAttentionProvided,
        ),
      beforeLabelTextSections: [
        {
          text: "Please describe the medical attention provided",
        },
      ],
    },
    {
      type: "textbox",
      valueFn: (data) =>
        getValueLabel(
          data.section136.informationForHealthcare?.hasPersonBeenSearched,
        ),
      beforeLabelTextSections: [
        {
          text: "Has the person been searched?",
        },
      ],
    },
  ],
};
