import { match, P } from "ts-pattern";
import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  dateTimeSchema,
  freeTextSchema,
  yesNoSchema,
  yesOnlySchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "arrivalDateTime",
    type: "date-time",
    ...new FieldBuilder().withNullableSchema(dateTimeSchema).build(),
    dateLabel: "arrivePlaceOfSafety.arrivalDate",
    timeLabel: "arrivePlaceOfSafety.arrivalTime",
  })
  .withFieldAlwaysVisible({
    fieldName: "didNotArrive",
    type: "checkbox",
    ...new FieldBuilder()
      .withNullableSchema(yesOnlySchema)
      .captureValueLabel()
      .build(),
    label: "arrivePlaceOfSafety.didNotArrive",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "advisePhysicallyEnter",
    type: "banner",
    bannerType: "info",
    isVisible: ({ formValues }) =>
      match(formValues.didNotArrive)
        .with({ valueLabel: P._, value: "yes" }, () => false)
        .with(null, undefined, () => true)
        .exhaustive(),
    label: "arrivePlaceOfSafety.advisePhysicallyEnter",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "adviseCannotShare",
    type: "banner",
    bannerType: "info",
    isVisible: ({ formValues }) =>
      match(formValues.didNotArrive)
        .with({ valueLabel: P._, value: "yes" }, () => true)
        .with(null, undefined, () => false)
        .exhaustive(),
    label: "arrivePlaceOfSafety.adviseCannotShare",
  })
  .withFieldConditionallyVisible({
    fieldName: "wasEntryDelayed",
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    options: [
      { label: "common.yes", value: "yes" },
      { label: "common.no", value: "no" },
    ],
    isVisible: ({ formValues }) =>
      match(formValues.didNotArrive)
        .with({ valueLabel: P._, value: "yes" }, () => false)
        .with(null, undefined, () => true)
        .exhaustive(),
    label: "arrivePlaceOfSafety.wasEntryDelayed",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyWasEntryDelayedOther",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.wasEntryDelayed?.value === "yes",
    label: "arrivePlaceOfSafety.whyWasEntryDelayed.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "howLongWasEntryDelayed",
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "0-15" as const,
          "15-30" as const,
          "30-60" as const,
          "60+" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    options: [
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.0-15",
        value: "0-15",
      },
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.15-30",
        value: "15-30",
      },
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.30-60",
        value: "30-60",
      },
      {
        label: "arrivePlaceOfSafety.howLongWasEntryDelayed.options.60+",
        value: "60+",
      },
    ],
    isVisible: ({ formValues }) => formValues.wasEntryDelayed?.value === "yes",
    label: "arrivePlaceOfSafety.howLongWasEntryDelayed.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "methodOfTransport",
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "alreadyAtPos" as const,
          "ambulance" as const,
          "policeVehicle" as const,
          "otherHealthVehicle" as const,
          "escorted" as const,
          "other" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    options: [
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.alreadyAtPos",
        value: "alreadyAtPos",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.ambulance",
        value: "ambulance",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.policeVehicle",
        value: "policeVehicle",
      },
      {
        label:
          "arrivePlaceOfSafety.methodOfTransport.options.otherHealthVehicle",
        value: "otherHealthVehicle",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.escorted",
        value: "escorted",
      },
      {
        label: "arrivePlaceOfSafety.methodOfTransport.options.other",
        value: "other",
      },
    ],
    isVisible: ({ formValues }) =>
      match(formValues.didNotArrive)
        .with({ valueLabel: P._, value: "yes" }, () => false)
        .with(null, undefined, () => true)
        .exhaustive(),
    label: "arrivePlaceOfSafety.methodOfTransport.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "methodOfTransportOther",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.methodOfTransport?.value === "other",
    label: "arrivePlaceOfSafety.methodOfTransportOther.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyMethodOfTransportPoliceVehicle",
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "ambulanceAvailableInTimeframe" as const,
          "ambulanceCrewRefused" as const,
          "riskAssessment" as const,
          "ambulanceNotRequested" as const,
          "ambulanceRetasked" as const,
          "other" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    options: [
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceAvailableInTimeframe",
        value: "ambulanceAvailableInTimeframe",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceCrewRefused",
        value: "ambulanceCrewRefused",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.riskAssessment",
        value: "riskAssessment",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceNotRequested",
        value: "ambulanceNotRequested",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.ambulanceRetasked",
        value: "ambulanceRetasked",
      },
      {
        label:
          "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.options.other",
        value: "other",
      },
    ],
    isVisible: ({ formValues }) =>
      formValues.methodOfTransport?.value === "policeVehicle",
    label: "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicle.title",
  })
  .withFieldConditionallyVisible({
    fieldName: "whyMethodOfTransportPoliceVehicleOther",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.methodOfTransport?.value === "policeVehicle" &&
      formValues.whyMethodOfTransportPoliceVehicle?.value === "other",
    label: "arrivePlaceOfSafety.whyMethodOfTransportPoliceVehicleOther.title",
  })
  .withFormValidation(({ formValues }) =>
    formValues.arrivalDateTime === null && formValues.didNotArrive === null
      ? [
          {
            fieldName: "arrivalDateTime",
            message: "Required",
          },
          {
            fieldName: "didNotArrive",
            message: "Required",
          },
        ]
      : [],
  )
  .withPage([
    "arrivalDateTime",
    "didNotArrive",
    "advisePhysicallyEnter",
    "adviseCannotShare",
  ])
  .withPage([
    "wasEntryDelayed",
    "whyWasEntryDelayedOther",
    "howLongWasEntryDelayed",
  ])
  .withPage([
    "methodOfTransport",
    "methodOfTransportOther",
    "whyMethodOfTransportPoliceVehicle",
    "whyMethodOfTransportPoliceVehicleOther",
  ])
  .build();

export type ArrivePlaceOfSafetyFormData = typeof formSpecification.formType;
