import React from "react";

import {
  FormSection,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentType,
  IncidentWorkItemStatus,
  IncidentWorkItemWithContext,
  TriageIncidentMinimumFields,
  WorkflowVersions,
} from "@vision/common";
import { useNavigate, useParams } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import {
  TriageIncidentForm,
  TriageIncidentFormSkeleton,
} from "../../forms/TriageIncident/TriageIncidentForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type TriageIncidentPageProps = Record<string, unknown>;

export type TriageIncidentPageInnerProps = {
  incidentWorkItem?: IncidentWorkItemWithContext;
};

export function TriageIncidentPage(_props: TriageIncidentPageProps) {
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  const [{ data: incidentWorkItem, error: incidentWorkItemError }] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(incidentId.data);

  if (incidentWorkItemError) return <ErrorComponent />;

  return (
    <TriageIncidentPageInner incidentWorkItem={incidentWorkItem ?? undefined} />
  );
}

export function TriageIncidentPageInner({
  incidentWorkItem,
}: TriageIncidentPageInnerProps) {
  const navigate = useNavigate();

  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;
    const triageForm = incidentWorkItem?.forms.find(
      (form) => form.formType === IncidentFormType.triage,
    );
    const triageFormId = triageForm?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      .workflowVersion as WorkflowVersions | undefined;
    return (
      <>
        {incidentWorkItemId === undefined ||
        triageFormId === undefined ||
        workflowVersion === undefined ? (
          <TriageIncidentFormSkeleton />
        ) : (
          <TriageIncidentForm
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            initialValues={triageForm?.sections.at(0)?.data}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={async (values: TriageIncidentMinimumFields) => {
              try {
                const response = await api.incidentWorkItem.updateData(
                  incidentWorkItemId,
                  triageFormId,
                  FormSection.triageMain,
                  values,
                  true,
                  IncidentWorkItemStatus.inProgress,
                );
                if (response.status === 204) {
                  if (values.whatWillYouDo.value === IncidentType.section136) {
                    const res =
                      await api.incidentWorkItem.create136Form(
                        incidentWorkItemId,
                      );

                    if (res.status !== 204) {
                      setError(JSON.stringify(res.data.message));
                      return;
                    }

                    navigate(
                      createRoute.incidentSectionComplete(incidentWorkItemId),
                      {
                        state: {
                          type: FormSection.triageMain,
                        },
                      },
                    );
                  }

                  // TODO: This is a placeholder solution to stop people choosing forms they can't complete.
                  // We should move this business logic out of the frontend and into reusable logic.
                  if (
                    [
                      IncidentType.section56,
                      IncidentType.voluntary,
                      IncidentType.other,
                    ].includes(
                      values.whatWillYouDo.value as Exclude<
                        IncidentType,
                        IncidentType.section136
                      >,
                    )
                  ) {
                    navigate(
                      createRoute.incidentPreEndIncidentPage(
                        incidentWorkItemId,
                      ),
                    );
                  }
                  return;
                } else {
                  setError(JSON.stringify(response.data.message));
                }
              } catch (error) {
                setError(
                  "Failed to create incident work item: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return render(incidentWorkItem);
}
