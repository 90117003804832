import {
  Phone as HelpIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  Add as NewIncidentIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { Avatar, Box, List, Stack, Typography, useTheme } from "@mui/material";
import {
  AppDrawerDivider,
  AppDrawerListItem,
  AppDrawer as ThalamosAppDrawer,
} from "@thalamos/frontend";
import { ExtendedVisionUser } from "@vision/common";
import React from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../config/index.js";
import { createRoute } from "../../routing/createRoute.js";
import { Logo } from "../Logo/Logo.js";
import { TypographyI18N } from "../TypographyI18N/TypographyI18N.js";

export type AppDrawerProps = unknown;

const UserProfileAvatar = ({ user }: { user: ExtendedVisionUser }) => {
  const theme = useTheme();

  return (
    <Avatar
      sx={{
        transform: "scale(0.75)",
        bgcolor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
      }}
    >
      {user.name.split(" ").map((n, i) => i < 2 && n[0].toUpperCase())}
    </Avatar>
  );
};

const UserProfileText = ({ user }: { user: ExtendedVisionUser }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography sx={{ fontSize: theme.spacing(1.75) }}>
        {user.name}
      </Typography>
      {user.shoulderNumber && (
        <Typography sx={{ fontSize: theme.spacing(1.75) }}>
          {user.shoulderNumber}
        </Typography>
      )}
    </Box>
  );
};

const DrawerItems = ({ user }: { user: ExtendedVisionUser }) => {
  const navigate = useNavigate();
  const isAdminUser = user.isAdminSession;

  return (
    <Stack sx={{ display: "flex", flexGrow: 1, height: "100%" }}>
      <List>
        {isAdminUser ? (
          <AppDrawerListItem
            icon={<SettingsIcon />}
            text={<TypographyI18N translationKey="navigation.admin" />}
            onClick={() => {
              navigate(createRoute.home());
            }}
          />
        ) : (
          <>
            <AppDrawerListItem
              icon={<HomeIcon />}
              text={<TypographyI18N translationKey="navigation.home" />}
              onClick={() => {
                navigate(createRoute.home());
              }}
            />
            <AppDrawerListItem
              icon={<NewIncidentIcon />}
              text={<TypographyI18N translationKey="navigation.newIncident" />}
              onClick={() => {
                navigate(createRoute.incidentCreate());
              }}
            />
            <AppDrawerListItem
              icon={<HelpIcon />}
              text={<TypographyI18N translationKey="navigation.help" />}
              onClick={() => {
                window.open(
                  "https://thalamos1.zohodesk.eu/portal/en/kb",
                  "_blank",
                );
              }}
            />
          </>
        )}
      </List>
      <AppDrawerDivider />
      <Stack sx={{ marginTop: "auto" }}>
        <List>
          <AppDrawerListItem
            icon={<UserProfileAvatar user={user} />}
            text={<UserProfileText user={user} />}
            onClick={() => {
              navigate(createRoute.userProfile(user.id));
            }}
          />
        </List>
        <AppDrawerDivider />
        <List>
          <AppDrawerListItem
            icon={<LogoutIcon />}
            text={<TypographyI18N translationKey="navigation.logout" />}
            onClick={() => {
              window.location.href = `${config.backendUrl}/api/oauth/logout`; // TODO: Get from config.backendUrl,
            }}
          />
        </List>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            padding: "0.5rem",
          }}
        >
          <Typography>©️ 2024 Thalamos</Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export const AppDrawer = ({
  user,
  children,
}: {
  user: ExtendedVisionUser;
  children: React.ReactNode;
}): React.ReactNode => {
  return (
    <Stack>
      <ThalamosAppDrawer
        logo={<Logo />}
        drawerItems={<DrawerItems user={user} />}
      >
        {children}
      </ThalamosAppDrawer>
    </Stack>
  );
};
