import React, { useMemo } from "react";

import {
  FormSection,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import api, { apiHooks } from "../../api/index.js";
import {
  FinishForm,
  FinishFormSkeleton,
} from "../../forms/FinishForm/FinishForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type FinishFormPageProps = Record<string, unknown>;

export function FinishFormPage(_props: FinishFormPageProps) {
  const navigate = useNavigate();
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    { data: incidentWorkItem, error: incidentWorkItemError },
    refetchWorkItem,
  ] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(incidentId.data);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const formId = useMemo(() => IncidentFormIdSchema.parse(v4()), []);

  if (incidentWorkItemError) return <ErrorComponent />;

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
    refetchWorkItem: ReturnType<typeof apiHooks.incidentWorkItem.get>[1],
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }
    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      .workflowVersion as WorkflowVersions | undefined;

    return (
      <>
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <FinishFormSkeleton />
        ) : (
          <FinishForm
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={async (values: Record<string, unknown>) => {
              try {
                const createFormResponse =
                  await api.incidentWorkItem.createEndIncidentOrFinishForm(
                    incidentWorkItemId,
                    formId,
                    IncidentFormType.finishForm,
                  );
                if (createFormResponse.status !== 204) {
                  throw Error(JSON.stringify(createFormResponse.data.message));
                }
                const response = await api.incidentWorkItem.updateData(
                  incidentWorkItemId,
                  formId,
                  FormSection.finishForm,
                  values,
                  true,
                );
                if (response.status === 204) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  await refetchWorkItem();
                  navigate(`/incident/${incidentWorkItemId}/section-complete`, {
                    state: {
                      type: FormSection.finishForm,
                      payload: values,
                    },
                  });
                  return;
                } else {
                  setError(JSON.stringify(response.data.message));
                }
              } catch (error) {
                setError(
                  "Failed to update 136 form: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return render(incidentWorkItem, refetchWorkItem);
}
