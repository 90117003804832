import React from "react";

import { AvailableOrganisationArray } from "@vision/common";

export type Organisations = {
  organisations: AvailableOrganisationArray;
  // TODO: Consider adding methods on here to do a dynamic query which returns some Orgs.
  // e.g. getOrganisations: (query: string) => Organisation[];
};

/**
 * This provides a mechanism for components to make queries about Places Of Safety.
 * This might involve reading pre-fetched values, or a query to the server.
 */
export const OrganisationContext = React.createContext<Organisations>({
  /**
   * A list of organisations that can be picked from.
   */
  organisations: [],
});

export const useOrganisations = () => React.useContext(OrganisationContext);
