import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import { freeTextSchema, yesNoSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "hasRemainedAtPlaceOfSafety",
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "recordPolicePresence.remainedAtPlaceOfSafety",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "reasoning",
    type: "textbox",
    ...new FieldBuilder().withNullableSchema(freeTextSchema).build(),
    label: "recordPolicePresence.reasoning",
    rows: "5",
    multiline: true,
  })
  .withPage(["hasRemainedAtPlaceOfSafety"])
  .withPage(["reasoning"])
  .build();

export type RecordPolicePresenceFormData = typeof formSpecification.formType;
