import { CircularProgress } from "@mui/material";
import { parsedAny } from "@thalamos/common";
import {
  AvailableOrganisationArray,
  AvailableOrganisationArraySchema,
  ExtendedVisionUser,
  OrganisationIdUnbranded,
} from "@vision/common";
import React, { useEffect } from "react";
import { apiHooks } from "../../api/index.js";
import {
  UserContextType,
  UserProvider,
  useUser,
} from "../../Context/UserContext.js";
import i18n from "../../i18n.js";
import { Error } from "../../pages/Error/Error.js";
import { OrganisationContext } from "../FormBuilder/OrganisationDropdown/OrganisationContext.js";
import { MainLayout } from "../MainLayout/MainLayout.js";
import { Onboarding } from "../Onboarding/Onboarding.js";

export const RootComponent = () => {
  return (
    <UserProvider>
      <RootComponentContent />
    </UserProvider>
  );
};

const RootComponentContent = () => {
  const {
    user,
    loading: userLoading,
    error: userError,
    refetchUser,
  } = useUser();

  useEffect(() => {
    if (user?.sessionOrganisationConfiguration?.language) {
      void i18n.changeLanguage(user.sessionOrganisationConfiguration.language);
    }
  }, [user?.sessionOrganisationConfiguration?.language]);

  if (userLoading || user === undefined) {
    return <CircularProgress />;
  }

  if (userError) {
    return <Error />;
  }

  return <RootComponentInner user={user} refetchUser={refetchUser} />;
};

export const RootComponentInner = ({
  user,
  refetchUser,
}: {
  user: ExtendedVisionUser;
  refetchUser: UserContextType["refetchUser"];
}) => {
  const organisationId = user.sessionContext?.organisationId;

  return (
    <OrganisationFetcher
      organisationId={organisationId}
      user={user}
      refetchUser={refetchUser}
    />
  );
};

const OrganisationFetcher = ({
  organisationId,
  user,
  refetchUser,
}: {
  organisationId: OrganisationIdUnbranded | undefined;
  user: ExtendedVisionUser;
  refetchUser: UserContextType["refetchUser"];
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ data: organisationsResponse, loading, error }] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.organisation.getAvailableOrganisations(organisationId ?? "");

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Error />;
  }

  // Parse organisations data
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const availableOrganisations = parsedAny(
    AvailableOrganisationArraySchema,
    organisationsResponse,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  ).unwrapOr([]) as AvailableOrganisationArray;

  return (
    <RootComponentInnerContent
      user={user}
      refetchUser={refetchUser}
      availableOrganisations={availableOrganisations}
    />
  );
};

export const RootComponentInnerContent = ({
  user,
  refetchUser,
  availableOrganisations,
}: {
  user: ExtendedVisionUser;
  refetchUser: UserContextType["refetchUser"];
  availableOrganisations: AvailableOrganisationArray;
}) => {
  return user.isOnboarded ? (
    <MainLayout user={user} refetchUser={refetchUser} />
  ) : (
    <OrganisationContext.Provider
      value={{ organisations: availableOrganisations }}
    >
      <Onboarding user={user} refetchUser={refetchUser} />
    </OrganisationContext.Provider>
  );
};
