import { Box } from "@mui/material";

import { IncidentWorkItemId } from "@vision/common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../../api/index.js";
import { UserContextType } from "../../../Context/UserContext.js";
import { useToastNotifications } from "../../../hooks/useToastNotifications.js";
import { InitiateTransferModal } from "../../../modals/InitiateTransfer/InitiateTransfer.js";
import { TransferRejectedModal } from "../../../modals/TransferRejected/TransferRejected.js";
import ActionBanner, {
  ActionBannerList,
} from "../../ActionBanner/ActionBanner.js";

interface TransferRejectedNotificationProps {
  incidentWorkItemId: IncidentWorkItemId;
  notificationId: string;
  close: () => void;
  refetchUser: UserContextType["refetchUser"];
}

export const TransferRejectedNotification = ({
  incidentWorkItemId,
  notificationId,
  close,
  refetchUser,
}: TransferRejectedNotificationProps) => {
  const { t } = useTranslation();
  const notifications = useToastNotifications();
  const [isTransferRejectedModalOpen, setIsTransferRejectedModalOpen] =
    useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const handleCloseRejectedModal = () => {
    setIsTransferRejectedModalOpen(false);
  };

  const handleOnTryAgainNo = async () => {
    setIsTransferRejectedModalOpen(false);
    close();
    const res = await api.notifications.markAsRead(notificationId);
    if (res.status !== 204) {
      notifications.show(t("notifications.failedMarkAsRead"), {
        severity: "error",
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await refetchUser();
  };

  const handleOnTryAgainYes = () => {
    setIsTransferRejectedModalOpen(false);
    setShowTransferModal(true);
  };

  const handleConfirm = async (email: string) => {
    if (email === undefined) {
      return;
    }

    const createTransfer = {
      receivingOfficerEmail: email,
      incidentWorkItemId: incidentWorkItemId,
    };

    await api.incidentWorkItem
      .createTransfer(createTransfer)
      .then(async (res) => {
        if (res.status === 204) {
          const response = await api.notifications.markAsRead(notificationId);

          if (response.status !== 204) {
            notifications.show(res.data.reason, {
              severity: "error",
            });
          }

          notifications.show(
            t("transfer.initiateTransfer.confirmToast", { email }),
            {
              severity: "success",
            },
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          await refetchUser();
        } else {
          notifications.show(res.data.reason, {
            severity: "error",
          });
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          padding: ".5rem",
        }}
      >
        <ActionBanner
          title={t("transfer.transferDeclined")}
          bannerType={ActionBannerList.WARNING}
          onClick={() => {
            setIsTransferRejectedModalOpen(true);
          }}
        />
      </Box>
      {isTransferRejectedModalOpen && (
        <TransferRejectedModal
          open={isTransferRejectedModalOpen}
          onClose={handleCloseRejectedModal}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onTryAgainNo={handleOnTryAgainNo}
          onTryAgainYes={handleOnTryAgainYes}
          incidentWorkItemId={incidentWorkItemId}
        />
      )}
      {showTransferModal && (
        <InitiateTransferModal
          open={showTransferModal}
          onClose={() => {
            setShowTransferModal(false);
          }}
          incidentWorkItemId={incidentWorkItemId}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onConfirm={async (email) => {
            if (email === undefined) return;
            await handleConfirm(email);
            setShowTransferModal(false);
            close();
          }}
        />
      )}
    </>
  );
};
