import React from "react";

import { Box, CircularProgress, Container, useTheme } from "@mui/material";
import { parsedAny } from "@thalamos/common";
import {
  AvailableOrganisationArray,
  AvailableOrganisationArraySchema,
  ExtendedVisionUser,
  FieldBuilder,
  FormBuilderField,
  OrganisationRoles,
  UserOrganisationRole,
  emailSchema,
  nameSchema,
  orgIdSchema,
} from "@vision/common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import api, { apiHooks } from "../../api/index.js";
import { OrganisationContext } from "../../components/FormBuilder/OrganisationDropdown/OrganisationContext.js";
import { SinglePageForm } from "../../components/FormBuilder/SinglePageForm/SinglePageForm.js";
import { TypographyI18N } from "../../components/index.js";
import { UserContextType, useUser } from "../../Context/UserContext.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { Error as ErrorComponent } from "../../pages/Error/Error.js";

const UserProfileFormDataSchema = z.object({
  name: new FieldBuilder().withSchema(nameSchema).build().schema,
  email: new FieldBuilder().withSchema(emailSchema).build().schema,
  orgId: new FieldBuilder().withSchema(orgIdSchema).captureValueLabel().build()
    .schema,
  shoulderNumber: new FieldBuilder().withSchema(z.string()).build().schema,
  role: new FieldBuilder()
    .withSchema(z.nativeEnum(UserOrganisationRole))
    .build().schema,
});

type UserProfileFormData = z.infer<typeof UserProfileFormDataSchema>;

const UserProfileForm = ({
  onSubmit,
  onCancel,
  user,
}: {
  onSubmit: (values: UserProfileFormData) => void;
  onCancel: () => void;
  user: ExtendedVisionUser;
}) => {
  const { t } = useTranslation();

  const displayRole = (role: UserOrganisationRole) => {
    switch (role) {
      case UserOrganisationRole.Officer:
        return t("role.officer");
      case UserOrganisationRole.Supervisor:
        return t("role.supervisor");
      case UserOrganisationRole.ClientAdmin:
        return t("role.clientAdmin");
      case UserOrganisationRole.AnalyticsViewer:
        return t("role.analyticsViewer");
      case UserOrganisationRole.GuestUser:
        return t("role.guestUser");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const content: { fields: FormBuilderField<UserProfileFormData, any>[] } = {
    fields: [
      {
        type: "typography",
        label: "userProfile.email",
        textFn: ({ formValues }) => [formValues.email.value],
        fieldName: "email",
      },
      {
        type: "textbox",
        fieldName: "name",
        label: "userProfile.name",
      },
      {
        type: "textbox",
        fieldName: "shoulderNumber",
        label: "userProfile.shoulderNumber",
      },
      {
        type: "organisation",
        fieldName: "orgId",
        label: "userProfile.organisationMembership",
      },
      {
        type: "typography",
        label: "userProfile.role",
        textFn: ({ formValues }) => [displayRole(formValues.role.value)],
        fieldName: "role",
      },
    ],
  };

  return (
    <SinglePageForm<typeof UserProfileFormDataSchema>
      schema={UserProfileFormDataSchema}
      onSubmit={onSubmit}
      content={content}
      initialValues={{
        name: { value: user.name },
        email: { value: user.email },
        shoulderNumber: { value: user.shoulderNumber ?? "" },
        orgId: {
          value: user.sessionContext?.organisationId ?? "",
          valueLabel: user.sessionContext?.organisationName ?? "",
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        role: { value: user.sessionContext?.role! },
      }}
      canCancel={true}
      onCancel={onCancel}
      confirmButtonLabel="userProfile.confirm"
    />
  );
};

export type UserProfileProps = {
  user: ExtendedVisionUser;
  onCancel: () => void;
  refetchUser: UserContextType["refetchUser"];
};

export const UserProfile = ({
  user,
  onCancel,
  refetchUser,
}: UserProfileProps) => {
  const theme = useTheme();
  const notifications = useToastNotifications();
  const { t } = useTranslation();

  return (
    <Box>
      <TypographyI18N
        translationKey={"userProfile.title"}
        sx={{
          paddingBottom: theme.spacing(2),
          fontSize: theme.spacing(2.5),
          fontWeight: 700,
        }}
      />
      <TypographyI18N
        translationKey={"userProfile.banner"}
        sx={{
          paddingBottom: theme.spacing(4),
          fontSize: theme.spacing(2),
        }}
      />
      <UserProfileForm
        user={user}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={async (values) => {
          try {
            const response = await api.user.update(user.id, {
              name: values.name.value,
              organisationId: values.orgId.value,
              shoulderNumber: values.shoulderNumber.value,
            });
            if (response.status === 204) {
              notifications.show(t("userProfile.success"), {
                severity: "success",
              });
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              await refetchUser();
            } else {
              throw new Error(JSON.stringify(response.data.message));
            }
          } catch {
            notifications.show(t("userProfile.error"), {
              severity: "error",
            });
          }
        }}
        onCancel={onCancel}
      />
    </Box>
  );
};

export const UserProfilePage = () => {
  const {
    user,
    loading: userLoading,
    error: userError,
    refetchUser,
  } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();

  if (userLoading || user === undefined) {
    return <CircularProgress />;
  }

  if (userError) {
    return <ErrorComponent />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ data: organisationsResponse }] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.organisation.getAvailableOrganisations(
      user.sessionContext?.organisationId ?? "",
    );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const availableOrganisations = parsedAny(
    AvailableOrganisationArraySchema,
    organisationsResponse,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  ).unwrapOr([]) as AvailableOrganisationArray;

  // Requested as part of this ticket (ASP-2664) just filter by PoliceCommandUnit for now
  const commandUnitsOnly = availableOrganisations.filter(
    (org) =>
      org.role === OrganisationRoles.PoliceCommandUnit ||
      org.role === OrganisationRoles.PoliceForce,
  );

  return (
    <OrganisationContext.Provider value={{ organisations: commandUnitsOnly }}>
      <Container sx={{ marginTop: theme.spacing(2) }}>
        <UserProfile
          user={user}
          onCancel={() => {
            navigate(-1);
          }}
          refetchUser={refetchUser}
        />
      </Container>
    </OrganisationContext.Provider>
  );
};
