export default {
  unknown: "Unknown",
  fieldSchema: {
    dateTime: {
      invalid: "Invalid date and time",
    },
    date: {
      invalid: "Invalid date",
    },
    time: {
      invalid: "Invalid time",
    },
    dispatchNumber: {
      tooShort: "$t(common.dispatchNumber) must be at least 4 characters",
    },
  },
  role: {
    officer: "Officer",
    commandunit: "Command Unit",
    supervisor: "Supervisor",
    clientAdmin: "Client Admin",
    analyticsViewer: "Analytics Viewer",
    guestUser: "Guest User",
  },
  navigation: {
    home: "Home",
    newIncident: "New Incident",
    inProgressIncidents: "In-progress Incidents",
    endedIncidents: "Ended Incidents",
    help: "Help",
    notifications: "Notifications",
    logout: "Logout",
    admin: "Admin",
  },
  login: {
    loginToAccess: "Click login to access Vision portal",
    login: "Login",
  },
  common: {
    dispatchNumber: "Dispatch Number",
    shoulderNumber: "Shoulder Number",
    yes: "Yes",
    no: "No",
    other: "Other",
    close: "Close",
  },
  multiPageForm: {
    next: "Next",
    cancel: "Cancel",
    storybook: {
      playerName: "What's your name? [This is an optional field]",
      wouldYouLikeToPlayAGame:
        "Would you like to play a game? (yes/no) [This is a branching question]",
      wouldYouLikeToPlayAGameEncourage:
        "Games are fun and are good for your mental health. [This 'readonly' field always appears]",
      whatKindOfGame:
        "What kind of game do you want to play? (number/word) [This reveals different fields on a page]",
      numberGameLowNumber: "Pick a number, any number.",
      numberGameHighNumber:
        "Pick a number, higher than the previous number. [This shows on-page validation between fields]",
      wordGamePhrase: "What is a word you struggle to spell?",
      whyDontYouWantToPlayAGame: "Why don't you want to play a game?",
      wouldYouLikeToPlayAGameShame:
        "Shame! Feel free to press the back arrow if you change your mind. [This 'readonly' field conditionally appears]",
      didYouEnjoyTheGame:
        "Did you enjoy the game? [This page only appears if you played the game]",
      willYouConsiderPlayingAnotherTime:
        "Will you consider playing again another time? (yes/no) [We converge here irrespective of the branch taken at the start]",
    },
  },
  incidentBanner: {
    viewShareForm: "Share Form",
    viewTimeline: "View Timeline",
    personName: "Name of Person",
    incidentStatus: "Incident Status",
  },
  incidentWorkItem: {
    startNew: "Start new incident form",
    section136: "Section 136",
    section56: "Section 5-6",
    voluntary: "Voluntary",
    newForm: "New Form",
    nameOfPerson: "Name of person",
    typeOfIncident: "Type of incident",
    createdAt: "Created at",
    commandUnit: "Command Unit",
    geographicUnit: "Geographic Unit ID",
    currentAssignee: "Officer Assigned",
    view: "View",
    acceptTransfer: "Accept Transfer",
    cancel: "Cancel",
    reject: "Reject",
    formInProgress: "Form in progress",
    arrivedAtPlaceOfSafety: "Arrived at POS",
    awaitingArrival: "Awaiting Arrival",
    formShared: "Form shared",
    formAccessed: "Form accessed",
    policePresenceRequested: "Police support requested",
    policePresenceNotRequested: "Police free to leave",
    formStatusUnknown: "Form status unknown",
    pendingTransfer: "Pending Transfer",
    unknownOwner: "Unknown",
    type: {
      "136": "Section 136",
    },
    notificationAcceptedShow: "Section 136 incident accepted",
    notificationAcceptedError: "Error accepting Section 136 incident",
    notificationCancelledShow: "Transfer cancelled successfully",
    notificationCancelledError: "Error cancelling transfer",
    notificationRejectedShow: "Transfer was rejected successfully",
    notificationRejectedError: "Error rejecting transfer",
    myIncidents: "My Incidents",
    transferredIncidents: "Transferred Incidents",
    yourIncidentsHere: "Your incidents will show here",
    transferredIncidentsHere: "Transferred Incidents will show here",
  },

  createIncident: {
    newIncidentForm: "New Incident Form",
    dispatchNumberCreatedDate: "Date of $t(common.dispatchNumber) creation",
    dispatchNumberCreatedTime: "Time of $t(common.dispatchNumber) creation",
    incidentLocation: "Location where incident took place",
    userIsAttendingOfficer: "Were you the attending/responding officer?",
    createdOnBehalfOfAnotherOfficerWithShoulderNumber:
      "Please confirm the $t(common.shoulderNumber) of the responsible officer",
    createdOnBehalfOfAnotherOfficerBanner:
      "Please note, the responsible officer will need to confirm the details of this form before it can be shared & handed over to the Place of Safety. You can transfer the form to them, by clicking cancel and then 'transfer incident'.",
    personForename: "Person's forename(s)",
    personSurname: "Person's surname",
    commandUnit:
      "In which $t(incidentWorkItem.commandUnit) did the incident happen?",
    geographicUnit: "$t(incidentWorkItem.geographicUnit)",
  },
  incidentFormSections: {
    incident: "Incident created",
    triage: "Triage",
    incidentAndTriage: "Incident created + Triage",
    detention: "Detention details",
    person: "Person details",
    information: "Information for Healthcare",
    endIncident: "End Incident",
  },
  formProgressPage: {
    share: "Share",
    handoverAndShare: "Share & Handover",
    arrival: "Arrival",
    activePlaceOfSafety: {
      title: "Active Place of safety: ",
      notKnown: "Not known",
    },
    confirmArrival:
      "Confirm arrival when you enter the doors of the place of safety",
    confirmHandover:
      "Confirming handover will share 136 details with place of safety",
    endIncident: "End Incident",
    transfer: "Transfer",
    add: "Add +",
    view: "View",
    change: "Change",
    statuses: {
      s136InProgress: "S136 - Form in progress",
      awaitingArrival: "S136 - Awaiting Arrival",
      arrivedAtPlaceOfSafety: "S136 - Arrived at POS",
      formShared: "S136 - Form Shared",
      formAccessed: "S136 - Form Accessed",
      policePresenceRequested: "S136 - Police support requested",
      policePresenceNotRequested: "S136 - Police free to leave",
    },
  },
  onboarding: {
    welcomeTitle: "Welcome to Thalamos!",
    welcomeMessage:
      "As this is your first time logging on, you will need to provide some details about yourself before completing any forms. Please fill out the questions below.",
    name: "Name:",
    email: "Email:",
    organisationMembership: "Organisation Membership:",
    confirm: "Confirm",
    error: "Failed to save details",
  },
  triageIncident: {
    title: "Incident Triage",
    isHealthcareProfessionalConsulted:
      "Did you consult with anyone from Health/AMHP services to assist with your decision making & actions taken?",

    howDidYouContactNhs: {
      title: "How did you contact the healthcare professional?",
      options: {
        healthcarePractitionerAmbulance:
          "Healthcare practitioner/Ambulance on scene",
        ldPractitionersInCustody: "L&D practitioners in custody",
        custodyHealthcarePractitioner:
          "Custody Healthcare practitioner (Dr/RN/Para)",
        panLondonMentalHealthAdviceLine:
          "Pan London Mental Health Advice Line: 0300 200 0169",
        localMentalHealthAdviceLine:
          "Direct to local mental health advice line",
        localHbpos: "Direct to local HBPOS (Health-Based Place of Safety)",
        localStreetTriageTeam: "Direct to local street triage team",
        mentalHealthTeamOther: "Mental Health Team other",
        triageCarHO: "Triage car - HO",
        triageCarBtp: "Triage car - BTP",
        nhs111: "NHS 111",
        nhsStaffNwr: "NHS Staff NWR",
        nhsStaffAE: "NHS Staff A&E",
        controlRoom: "Control Room",
        amph: "AMHP",
        ///
        other: "Other",
      },
    },
    isAdviceGiven:
      "Please confirm: Healthcare professional is of the opinion that the person is likely to lack the mental capacity to make this decision as a result of a mental disorder",
    givenHealthCareProfessionalAdvice:
      "What advice did the healthcare professional give?",
    reasonsForDetention: "Reasons for detention",
    clinicalCaseRecordNumber:
      "Please provide the clinical case record number for this call",
    provideDetails: "Please provide details",
    confirmMentalCapacityLack:
      "Please confirm: Healthcare professional is of the opinion that the person is likely to lack the mental capacity to make this decision as a result of a mental disorder",
    reasonNotConsulted: {
      title: "Why was a healthcare professional not consulted?",
      options: {
        needForCare: "Immediate need for care (Not practicable)",
        immediateControl: "Immediate need for control (Not practicable)",
        immediateNeedForCareOrControl:
          "Immediate need for care and/or control (Not practicable)",
        noAnswer: "No answer/reply",
        didNotConsider: "Did not consider it",
        notSureWhoToContact: "Not sure who to call",
        notDeemedNecessary: "Not deemed necessary",
        other: "Other",
      },
    },
    reasonNotConsultedOther: "Please provide details",
    whatActionWillYouTake: "What action will you take?",
    voluntaryAction: {
      options: {
        voluntaryAttendanceHospital: "Voluntary attendance at hospital",
        divertOtherHealthservice: "Divert to other health service",
        contactSupportGroup: "Contact support group",
        contactFriendsFamily: "Contact friends or family",
        takePersonHome: "Take person home",
        divertCrisisCafe: "Divert to crisis café",
        other: "None of the above (please provide details)",
      },
    },
    otherAction: {
      title: "What other action will you take?",
      options: {
        arrestCriminalOffence: "Arrest for criminal offence",
        watchList: "Put on watch list",
        referSupportOptions: "Refer to other support options",
        other: "Other (please specify)",
      },
    },
    whatWillYouDo: {
      136: "Detain under 136",
      56: "Detain under 5-6",
      voluntary: "Voluntary Support",
      other: "Other",
    },
    whatWillYouDoOther: "Please provide details",
    otherActionDetails: "Add more details",
    otherActionBanner:
      "This platform should only be used for incidents where a person's mental health is a risk to themselves or others. If none of these options apply, please reach out to your supervisor to confirm most appropriate next steps.",
    explainWhyActionDiffers: {
      title:
        "Your action differs from the advice given by health/AMHP services. Please explain why",
      options: {
        changeInPresentation: "Change in presentation",
        differenceInOpinion: "Difference in opinion and/or assessment of risk",
        other: "Other",
      },
    },
    explainWhyActionDiffersOther: "Please provide details",
    unsupportedAction:
      "Section 1-6 (Mental Capacity Act) and Voluntary mental health forms are not yet available on this platform. Please continue to follow existing procedures for such incidents or contact your supervisor for more information.",
  },
  recordPlaceOfSafety: {
    title: "Record Place of Safety",
    choosePlaceOfSafety: {
      title: "Choose a Place of Safety",
      confirmCorrect: "Please confirm these details are correct",
    },
    choosePlaceOfSafetyType: {
      title: "What type of Place of Safety is this?",
      options: {
        hbPos136: "HBPOS (136 Suite)",
        emergencyDepartment: "Emergency Department (A&E)",
        returnedToMentalHealthUnit: "Returned to Mental Health Unit",
        careHome: "Care Home",
        custody: "Custody (exceptional circumstances)",
        privateHome: "Private home",
        other: "Other (please specify)",
      },
    },
    choosePlaceOfSafetyTypeOther: {
      title: "Please specify",
    },
    whyNotHbPosReason: {
      title:
        "Why are you not taking this person to a Health Based Place of Safety?",
      options: {
        noHbPosAvailable:
          "Advised that no Health Based Place of Safety (HBPOS) is available",
        unreasonableDelay: "Unreasonable delay in finding a HBPOS",
        medicalAttentionNeeded: "Medical attention needed",
        secureCustodyRequired: "Secure custody environment required",
        other: "Other (please specify)",
      },
    },
    whyNotHbPosReasonOther: {
      title: "Please specify",
    },
  },
  arrivePlaceOfSafety: {
    title: "Arrive at Place of Safety",
    arrivalDate: "What date did you arrive?",
    arrivalTime: "What time did you arrive?",
    didNotArrive: "No arrival at Place of Safety",
    advisePhysicallyEnter:
      "Arrival is when you enter the doors at the Place of Safety.",
    adviseCannotShare:
      "You won't be able to share until you physically entered the place of safety.",
    wasEntryDelayed: "Was your entry to the Place of Safety delayed?",
    whyWasEntryDelayed: {
      title: "What caused any delays?",
      options: {
        posOrEdClosed: "POS/ED is closed",
        nhsAdvisedWaitOutside: "NHS decision made for officers to wait outside",
        other: "Other",
      },
    },
    whyWasEntryDelayedOther: {
      title: "Please provide details",
    },
    howLongWasEntryDelayed: {
      title: "How long were you delayed?",
      options: {
        "0-15": "0 - 15 mins",
        "15-30": "15 - 30 mins",
        "30-60": "30 mins - 1 hour",
        "60+": "Over 1 hour",
      },
    },
    methodOfTransport: {
      title: "How did you travel to the Place of Safety?",
      options: {
        alreadyAtPos: "Already at the Place of Safety",
        ambulance: "Ambulance",
        policeVehicle: "Police vehicle",
        otherHealthVehicle: "Other Health Vehicle",
        escorted: "Escorted (walked)",
        other: "Other (please specify)",
      },
    },
    methodOfTransportOther: {
      title: "Please specify",
    },
    whyMethodOfTransportPoliceVehicle: {
      title: "Why did you use a police vehicle?",
      options: {
        ambulanceAvailableInTimeframe:
          "Ambulance not agreed within agreed timeframe",
        ambulanceCrewRefused: "Ambulance crew refused to convey",
        riskAssessment: "Police or police/ambulance risk assessment",
        ambulanceNotRequested: "Ambulance not requested",
        ambulanceRetasked: "Ambulance retasked to a higher priority call",
        other: "Other (please specify)",
      },
    },
    whyMethodOfTransportPoliceVehicleOther: {
      title: "Please specify",
    },
  },
  leavePlaceOfSafety: {
    title: "Change Place of Safety",
    leaveReason: {
      title: "Why are you changing place of safety?",
      options: {
        advised_visit_Hbpos_because_bedNowAvailable:
          "Bed now available at Health Based Place of Safety (HBPOS)",
        advised_visit_accidentAndEmergency_because_noHbposAvailable:
          "Advised that no HBPOS is available and directed to take to Emergency Department (A&E)",
        advised_visit_accidentAndEmergency_because_medicalGrounds:
          "Advised to take to Emergency Department (A&E) on medical grounds",
        decided_visit_accidentAndEmergency_because_UnreasonableDelay:
          "Unreasonable delay in accessing a HBPOS so a police decision was made to take to Emergency Department (A&E)",
        physicalHealthCheckComplete: "Physical health check complete",
        other: "Other (please specify)",
      },
    },
    leaveReasonOther: {
      title: "Please specify",
    },
  },
  reviewPlacesOfSafety: {
    didYouVisitMore:
      "Did you visit any additional places of safety for this incident?",
  },
  singlePageForm: {
    cancel: "Close",
    confirm: "Confirm",
  },
  userProfile: {
    title: "Profile",
    banner:
      "Please enter your professional information and click save. Your profile details will be populated onto the forms you are completing.",
    confirm: "Save",
    name: "Name",
    email: "Email",
    organisationMembership: "Organisation Membership",
    role: "Role",
    shoulderNumber: "Shoulder Number",
    success: "Profile successfully saved",
    error: "Failed to save profile",
  },
  formSuccess: {
    proceed: "Proceed",
    formComplete: "Form section complete",
    proceedWith: "Proceed with:",
    section136Form: "Section 136 form",
    mentalHealthFormTitle: "Mental Health Incident form",
    mentalHealthForm: "Mental health incident form created by officer ",
    incidentTriage: "Incident Triage",
    triageComplete: "Incident Triage completed.",
    personDetails: "Person details",
    personDetailsComplete: "Person Detail section has been completed",
    detentionDetails: "Detention details",
    detentionDetailsComplete: "Detention Detail section has been completed",
    informationForHealthcare: "Information for healthcare",
    informationForHealthcareStaff: "Information for healthcare staff",
    informationForHealthcareComplete:
      "Information for healthcare section has been completed",
    sharePOS: "Share place of Safety",
    addPOS: "Add place of safety",
    arrivePOS: "Arrive at place of safety",
    leavePOS: "Leave place of safety",
    confirmed: "Confirmed",
    proceedToShare:
      "Proceed to share details with Place of Safety and initiate handover",
    currentPos: "Current place of safety",
    arrivedAt: "Arrived at",
    endIncident: "End Incident",
    formEnded: "Section 136 has been ended.",
    formEndedReason: "Reason recorded:",
    formEndedArchived:
      "This form will be available in your archive for 3 months.",
    policePresence: "Record Police Presence",
    policePresenceComplete: "Police Presence has been recorded",
    hasBeenEnded: "Section 136 has been ended",
  },
  personDetails: {
    title: "Person Details",
    personAddress: "Person's home address",
    confirmPersonAddress:
      "Please confirm you have entered the address correctly",
    localAuthorityAddress: "Local authority of person's home address",
    relativeOrFriendAddress: "Address of relative or friend",
    relativeOrFriendTelephone: "Telephone number of relative or friend",
    relativeOrFriendInformed:
      "Has the relative or friend been informed of the detention?",
    reasonNotInformed:
      "Please explain why the relative or friend has not been informed",
    gender: {
      title: "Gender Identity",
      options: {
        male: "Male",
        female: "Female",
        nonBinary: "Non-binary",
        preferNotToSay: "Prefer not to say",
      },
    },
    ethnicity: {
      title: "Ethnic Appearance",
      options: {
        IC1: "IC1 - White, Northern European",
        IC2: "IC2 - Mediterranean/Southern European/Hispanic",
        IC3: "IC3 - African/Afro-Caribbean",
        IC4: "IC4 - Indian, Pakistani, Nepalese, Maldivian, Sri Lankan, Bangladeshi, or any other (South) Asian",
        IC5: "IC5 - Chinese, Japanese, or South-East Asian",
        IC6: "IC6 - Middle Eastern/Arab",
        IC7: "IC7 - Origin UNKNOWN",
      },
    },
    selfDeclaredEthnicity: {
      title: "Self Declared Ethnicity",
      options: {
        whiteEnglish:
          "White – English / Welsh / Scottish / Northern Irish / British",
        whiteIrish: "White – Irish",
        whiteGypsy: "White – Gypsy or Irish Traveller",
        whiteRoma: "White – Roma",
        whiteOther: "Any other White background, please describe",
        mixedBlackCaribbean:
          "Mixed / Multiple ethnic groups – White and Black Caribbean",
        mixedBlackAfrican:
          "Mixed / Multiple ethnic groups – White and Black African",
        mixedAsian: "Mixed / Multiple ethnic groups – White and Asian",
        mixedOther:
          "Any other Mixed / Multiple ethnic background, please describe",
        asianEnglish:
          "Asian / Asian British - English / Welsh / Scottish / Northern Irish / British",
        asianIndian: "Asian / Asian British - Indian",
        asianPakistani: "Asian / Asian British - Pakistani",
        asianBangladeshi: "Asian / Asian British - Bangladeshi",
        asianChinese: "Asian / Asian British – Chinese",
        asianOther: "Any other Asian background, please describe",
        blackEnglish:
          "Black / African / Caribbean / Black British – English / Welsh / Scottish / Northern Irish / British",
        blackAfrican: "Black / African / Caribbean / Black British - African",
        blackCaribbean:
          "Black / African / Caribbean / Black British – Caribbean",
        blackOther:
          "Black / African / Caribbean / Black British – Any other Black / African / Caribbean background, please describe",
        arab: "Other ethnic group - Arab",
        other: "Any other ethnic group, please specify",
        preferNotToSay: "Prefer not to say",
      },
    },
    selfDeclaredEthnicityOther: "Please specify",
    dob: "Person's date of birth",
    satisfiedPersonIsOver18:
      "Are you satisfied the person is over the age of 18?",
    nationalComputerCheck:
      "Have you carried out a Police National Computer check?",
    pncRecordForPerson:
      "Is there a Police National Computer record for this person?",
    pncidNumber: "PNCID no.",
    pncWarningMarkers: {
      title: "What are the PNC Warning markers?",
      options: {
        alleges: "AG - Alleges",
        ailment: "AT - Ailment",
        conceals: "CL - Conceals",
        contagious: "CO - Contagious",
        drugs: "DR - Drugs",
        escaper: "ES - Escaper",
        firearm: "FI - Firearm",
        fImpers: "IF - F/Impers",
        mImpers: "IM - M/Impers",
        impersonates: "IS - Impersonates",
        mentalHealth: "MN - Mental Health",
        selfHarm: "SH - Self Harm",
        suicidal: "SU - Suicidal",
        violent: "VI - Violent",
        weapons: "WE - Weapons",
        explosives: "XP - Explosives",
      },
    },
    relativeOrFriend: "Do you have the details of a relative or friend?",
    relativeOrFriendForename: "Relative or friend forename(s)",
    relativeOrFriendSurname: "Relative or friend surname",
    relativeOrFriendRelationship: "Relationship to person being detained",
    relativeOrFriendSameAddress:
      "Is the home address of the relative or friend the same as the person being detained?",
    unknownRelativeOrFriend:
      "Details of a relative or friend will be recorded as 'unknown'",
    unknownRelativeOrFriendAddress:
      "Home address of a relative or friend will be recorded as 'unknown'",
  },
  detentionDetails: {
    title: "Detention Details",
    custodyNumber: "Custody Number",
    dateOfDetention: "Date of Section 136 detention",
    timeOfDetention: "Time of Section 136 detention",
    responsibleForDetention:
      "$t(incidentWorkItem.commandUnit) responsible for detention",
    locationDetentionTookPlace: "Location where detention took place",
    wasLifesavingInterventionDetails:
      "Please confirm in line with Ovenstone Criteria, including direct or indirect evidence of suicidal intent",
    intervention: "Who made the Intervention?",
    locationType: {
      title: "What type of location was this",
      onTrain: "On train",
      concourse: "Concourse / booking office",
      platform: "Platform",
      stationToilet: "Station toilet",
      stationForecourt: "Station forecourt",
      carPark: "Car park",
      onTracksAtStation: "On tracks - at station",
      onTracksAwayFromStation: "On tracks - away from station",
      lineside: "Lineside",
      bridge: "Bridge",
      levelCrossing: "Level crossing / foot crossing",
      disusedRailwayOrStation: "Disused railway or station",
      tunnel: "Tunnel",
      depot: "Depot",
      otherRailwayInfrastructure: "Other railway infrastructure",
      offBtpJurisdiction: "Off BTP jurisdiction",
      btpOffice: "BTP office",
      ticketBarriers: "Ticket barriers",
      hotelWithinRailwayEnvironment: "Hotel (within railway environment)",
      publicHouse: "Public House",
      railwayLines: "Railway lines",
      hospitalWards: "Hospital wards",
      rooftopsCommercial: "Rooftops (of commercial or business buildings)",
      policeStations: "Police stations",
      offices: "Offices",
      school: "School",
      gardensCarParks:
        "Gardens and car parks associated with communal residential property",
      nonResidentialRestricted:
        "Non-residential parts of residential buildings with restricted entry",
      other: "Other",
    },
    wasLifesavingIntervention: "Was this a lifesaving intervention (LS)?",
  },
  informationForHealthcare: {
    title: "Information for Healthcare",
    incidentDescription:
      "Please give an account of the behaviour or crisis that led to Section 136 (MHA) powers being used",
    wasHealthcareProvided:
      "Was physical health care provided prior to arrival at the Place of Safety (ED or HBPOS)?",
    healthcareBanner:
      "Treatment may not be administered under the Mental Health Act. Any treatment for mental or physical ill health must have another authority, for example, the capacitous consent of the patient, or a best interests decision under the Mental Capacity Act for someone lacking treatment capacity.",
    medicalAttentionProvided: "Please describe the medical attention provided",
    wasForceUsed: "Was force used during detention?",
    describeForceUsed: "Please describe the force used, and for how long",
    hasPersonBeenSearched: "Has the person been searched?",
    didSeizeproperty: "Did you seize any property",
    detailsPropertySeized: "Pleas provide details of the property seized",
    underInfluenceOfSubstance:
      "Is the person under the influence of alcohol or drugs?",
    hasAdditionalRiskFactors:
      "Are there any additional risk factors that should be shared with healthcare staff?",
    describeRiskFactors: {
      title: "Please describe risk factors",
      options: {
        substanceAbuse: "Alcohol / drug / substance misuse / abuse",
        bereavement: "Bereavement (inc. Suicide)",
        bullying: "Bullying (inc. Cyber)",
        carerIssues: "Carer responsibilities / issues",
        clininicallyDiagnosedNeurodiverse:
          "Clinically diagnosed neurodiverse condition (inc. Aspergers)",
        clininicallyDiagnosedMentalHealthIllness:
          "Clinically diagnosed mental health illness (inc. Previous self-harm)",
        clininicallyDiagnosedIllness:
          "Clinically diagnosed short-term/long-term illness",
        disability: "Disability",
        stress:
          "Employment/educational/exam stress (inc. Learning difficulties)",
        financialIssues: "Financial issues (inc. Gambling debts)",
        genderIdentityIssues: "Gender identity issues",
        traumaHistory: "History of Trauma (inc. Adverse Childhood Experiences)",
        housingIssues: "Housing issues",
        militaryHistory: "Military history",
        natalConcerns: "Post / perinatal concerns",
        previousConvictions: "Previous convictions (inc. Imprisonment)",
        previouslySectioned: "Previously sectioned",
        relationshipIssues:
          "Relationship issues (family/partner/friend/social isolation)",
        sexualOrientationIssues: "Sexual orientation issues",
        suspectedSeriousCrime:
          "Suspected and/or survivor of serious crime (inc. Child sex crime, sexual, domestic abuse etc)",
        other: "Other (please specify)",
      },
    },
    describeRiskFactorsOther: "Please describe the additional risk factors",
  },
  placeOfSafetyPicker: {
    manuallyEnter: "Manually enter",
    name: "Name",
    address: "Address",
    postalCode: "Postal Code",
  },

  transfer: {
    initiateTransfer: {
      title: "Transfer to another officer?",
      transferIncidentWorkItem: "You are about to transfer",
      transferIncidentWorkItemId: "Incident id {{ incidentWorkItemId }}",
      confirmedTransfer:
        "Once you have confirmed the transfer, the receiving officer must accept the form for the transfer to complete.",
      untilTransferAccepted:
        "Until the transfer is accepted, the form will remain your responsibility.",
      enterEmailAddress: "Enter email address of receiving officer",
      confirm: "Confirm Transfer",
      confirmToast: "Incident transferred to {{ email }}",
    },
    rejectedTransfer: {
      title: "Transfer to another officer",
      declinedMessage:
        "Officer {{officerName}} has declined the 136 form you transferred to them.",
      tryAgain: "Would you like to try transferring again?",
    },
    transferDeclined: "Transfer has been declined",
    requestedTransfer: {
      title: "Accept form transfer?",
      officerRequest:
        "Officer {{requestingOfficerName}} has transferred the following form to you",
      createdAt: "Created at",
      nameOfPerson: "Name of person",
      incidentId: "Incident ID",
      officerAssigned: "Officer Assigned",
      accept: "Accept & View",
      reject: "Reject",
    },
  },
  finishForm: {
    informStaff: "Please inform staff at the place of safety before leaving.",
    finishFormTitle: "Finish Form",
    leavePlaceOfSafety: "Leave Place of Safety?",
    dateLeft: "Date police left",
    timeLeft: "Time police left",
  },
  preEndIncident: {
    endIncidentTitle: "End Incident",
    endingReason: {
      title: "Why are you ending the form?",
      options: {
        startedFormInError: "Started form in error",
        formExpired: "Section 136 Expired",
        nhsNotEngage: "NHS organisation did not engage in digital form",
        medicalExamination: "Medical examination confirmed no mental disorder",
        other: "Other",
      },
    },
    whyFormExpired: {
      title: "Why did the Section 136 expire?",
      options: {
        noBedAvailable: "No bed available in time",
        delayedAssessment: "Delay in assessment being completed",
        processProblems: "Process problems (please specify)",
      },
      otherProcessProblems: "Please specify the process problems",
    },
    whyNhsNotEngage: {
      title: "Why did they not engage with digital form?",
      options: {
        internetConnection: "Internet connectivity issues",
        emailNotReceived: "Email not received",
        handoverCompletedVerbally:
          "No forms used locally, handover completed verbally",
        localForm: "Insisted on using local form/process ",
        other: "Other (please specify)",
      },
      additionalInformationNotEngage: "Please provide additional details",
    },
    wasAssessedMentalHealthPlaceOfSafety:
      "Was the person assessed at the Place of Safety?",
    incidentStartTime: "What time did the incident start?",
    incidentStartDate: "What date did the incident start?",
    incidentTimeArrivePos: "What time did you arrive at your first POS?",
    incidentDateArrivePos: "What date did you arrive at your first POS?",
    incidentDateHandover: "What date did you handover?",
    incidentTimeHandover: "What time did you handover?",
    incidentLeaveDatePos: "What date did you leave?",
    incidentLeaveTimePos: "What time did you leave",
    selectedAssessmentOutcome: {
      title: "What was the outcome of the assessment?",
      options: {
        discharged: "discharged",
        admitted: "admitted",
        other: "other",
      },
    },
    furtherCareType: {
      title: "Please confirm what type of further care they received",
      options: {
        furtherDetention: "Further detention under the mental health act",
        voluntary: "Voluntary",
        unknown: "Don't know",
      },
    },
  },
  requestPolicePresence: {
    title: "Request Police Presence",
    dropdown: {
      notEnoughStaff: "Not enough staff",
      transfer:
        "Person to be transferred to another place of safety for assessment",
      other: "Other",
    },
  },
  recordPolicePresence: {
    title: "Record Police Presence",
    remainedAtPlaceOfSafety:
      "Have you remained, or will you remain, at the place of safety?",
    reasoning: "Please add the reasoning or conditions for your response",
  },
  notification: {
    failedRetrieve: "Failed to retrieve notifications",
    s136Accepted: "Section 136 incident accepted",
  },
  s136FormRejectedNotification: {
    s136Rejected: "Section 136 incident rejected",
    formRejected: "Form rejected",
    formRjectedBy: "Form rejected by",
    reason: "Reason:",
    reshareForm:
      "Please reshare form with another place of safety to continue the incident",
    reshare: "reshare",
  },
  policePresenceNotification: {
    requested: "Police presence requested",
    requestedMessage:
      "{{requesterName}} has requested ongoing police presence at {{posName}} with the following reason:",
    next: "Next",
    options: {
      notEnoughStaff: "Not enough staff",
      transfer:
        "Person to be transferred to another place of safety for assessment",
      other: "Other",
    },
  },
};
