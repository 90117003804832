import React, { useCallback, useEffect, useMemo } from "react";

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField as MUITimeField } from "@mui/x-date-pickers/TimeField";
import { TranslationKey } from "@vision/common";
import dayjs, { Dayjs } from "dayjs";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

const debug = debugFn("vision-frontend:DateTimePicker");

export type DateTimePickerOutput =
  | {
      value: null;
      isUnknown: true;
    }
  | {
      value: string;
      isUnknown: false;
    }
  | {
      value: string;
    }
  | "";

export type DateTimePickerProps = {
  label?: TranslationKey;
  fieldName: string;
  dateLabel: TranslationKey;
  timeLabel: TranslationKey;
  canBeUnknown?: boolean;
};

const combineDateAndTime = (
  date: string | null,
  time: string | null,
): string | null => {
  if (!date || !time) {
    return null;
  }

  const parsedDate = dayjs(date, "YYYY-MM-DD", true);
  const parsedTime = dayjs(time, "HH:mm", true);

  if (parsedDate.isValid() && parsedTime.isValid()) {
    return parsedDate
      .hour(parsedTime.hour())
      .minute(parsedTime.minute())
      .toISOString();
  }

  return null;
};

export const DateTimePicker = ({
  label,
  fieldName,
  dateLabel,
  timeLabel,
  canBeUnknown,
}: DateTimePickerProps) => {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField<DateTimePickerOutput>(fieldName);

  // This is where we store the individual date value as YYYY-MM-DD.
  const [date, setDate] = React.useState<string | null>(
    field.value === ""
      ? null
      : dayjs(field.value.value).isValid()
        ? dayjs(field.value.value).format("YYYY-MM-DD")
        : null,
  );

  // This is where we store the individual time value as HH:mm in "local time".
  const [time, setTime] = React.useState<string | null>(
    field.value === ""
      ? null
      : dayjs(field.value.value).isValid()
        ? dayjs(field.value.value).format("HH:mm")
        : null,
  );

  // Clean up the field value to be a structured object
  const fieldValue = useMemo(() => {
    const value =
      field.value === ""
        ? // Turn empty string into a structured object
          ({ value: null, isUnknown: false } as const)
        : "isUnknown" in field.value
          ? field.value.isUnknown
            ? // If "unknown", clear out the value
              ({ value: null, isUnknown: field.value.isUnknown } as const)
            : { value: field.value.value, isUnknown: field.value.isUnknown }
          : { value: field.value.value };

    debug("Cleaned up value", { value });
    return value;
  }, [field.value]);

  useEffect(
    () => {
      const runAsync = async () => {
        const combinedDateTime = combineDateAndTime(date, time);

        const newValue =
          // If the value is well structured and "unknown", then trust it and
          // use it going forward.
          "isUnknown" in fieldValue && fieldValue.isUnknown && !fieldValue.value
            ? fieldValue
            : // If the value is not explicitly unknown, but the data is not valid,
              // then store it as "" which is Formik's way of representing null.
              combinedDateTime === null
              ? ""
              : canBeUnknown
                ? ({
                    value: combinedDateTime,
                    isUnknown: false as const,
                  } as const)
                : ({
                    value: combinedDateTime,
                  } as const);

        if (JSON.stringify(newValue) !== JSON.stringify(field.value)) {
          debug("Setting value", { combinedDateTime, newValue, fieldValue });
          await helpers.setValue(newValue);
          await helpers.setTouched(true);
        }
      };
      void runAsync();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      canBeUnknown,
      date,
      fieldName,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      "isUnknown" in fieldValue && fieldValue.isUnknown,
      fieldValue.value,
      helpers,
      time,
    ],
  );

  const handleUnknownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runAsync = async () => {
      const combinedDateAndTime = combineDateAndTime(date, time);

      const newValue = event.target.checked
        ? ({
            value: null,
            isUnknown: true,
          } as const)
        : combinedDateAndTime
          ? ({
              value: combinedDateAndTime,
              isUnknown: false,
            } as const)
          : "";

      debug("handleUnknownChange, Setting value", newValue);

      await helpers.setValue(newValue);
      await helpers.setTouched(true);
    };

    void runAsync();
  };

  return (
    <>
      {label && <FormLabel label={label} />}
      <Stack direction="column">
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={4}
        >
          <Box sx={{ flex: 1 }}>
            <DatePickerInput
              date={date}
              setDate={setDate}
              label={dateLabel}
              fieldName={fieldName}
              disabled={"isUnknown" in fieldValue && fieldValue.isUnknown}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TimeField
              time={time}
              setTime={setTime}
              label={timeLabel}
              fieldName={fieldName}
              disabled={"isUnknown" in fieldValue && fieldValue.isUnknown}
            />
          </Box>
        </Stack>
        {canBeUnknown && (
          <FormControlLabel
            control={
              <Checkbox
                checked={"isUnknown" in fieldValue && fieldValue.isUnknown}
                onChange={handleUnknownChange}
                data-testid={`formField-${fieldName}-unknown`}
              />
            }
            label={t("unknown")}
          />
        )}
        {debug.enabled && (
          <>
            <Divider />
            <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
              {"field.value : "}
              {JSON.stringify(field.value)}
            </Typography>
            <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
              {"Treated like: "}
              {JSON.stringify(fieldValue)}
            </Typography>
            <Divider />
          </>
        )}
      </Stack>
      {meta.error && meta.touched && (
        <FormError data-testid="time-date-error" error={meta.error} />
      )}
    </>
  );
};

export const TimeField = ({
  time,
  setTime,
  label,
  fieldName,
  disabled,
}: {
  time: string | null;
  setTime: React.Dispatch<React.SetStateAction<string | null>>;
  label: TranslationKey;
  fieldName: string;
  disabled?: boolean;
}) => {
  const handleTimeChange = useCallback(
    (value: Dayjs | null) => {
      const newTime = value?.isValid() ? value.format("HH:mm") : null;
      debug("Setting time to", newTime);
      setTime(newTime);
    },
    [setTime],
  );

  return (
    <>
      <FormLabel label={label} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUITimeField<Dayjs>
          disabled={disabled}
          value={
            time && dayjs(time, "HH:mm", true).isValid()
              ? dayjs(time, "HH:mm", true)
              : null
          }
          onChange={handleTimeChange}
          ampm={false}
          slotProps={{
            textField: {
              fullWidth: true,
              inputProps: {
                "data-testid": `formField-${fieldName}-time`,
              },
            },
          }}
        />
        {debug.enabled && (
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {JSON.stringify(time)}
          </Typography>
        )}
      </LocalizationProvider>
    </>
  );
};

const DatePickerInput = ({
  date,
  setDate,
  label,
  fieldName,
  disabled,
}: {
  date: string | null;
  setDate: React.Dispatch<React.SetStateAction<string | null>>;
  label: TranslationKey;
  fieldName: string;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDateChange = (value: Dayjs | null) => {
    if (value === null) {
      setDate(null);
    } else if (value.isValid()) {
      setDate(value.format("YYYY-MM-DD"));
    }
  };

  const DatePicker = isMobile ? MobileDatePicker : DesktopDatePicker;

  return (
    <>
      <FormLabel label={label} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker<Dayjs>
          disabled={disabled}
          value={date ? dayjs(date, "YYYY-MM-DD") : null}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              fullWidth: true,
              inputProps: {
                "data-testid": `formField-${fieldName}-date`,
              },
            },
          }}
        />
        {debug.enabled && (
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {JSON.stringify(date)}
          </Typography>
        )}
      </LocalizationProvider>
    </>
  );
};
