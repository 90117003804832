import { z, ZodType } from "zod";
import {
  OrganisationIdSchema,
  type OrganisationIdUnbranded,
} from "./organisation.js";

export enum UserDirectoryKeys {
  UserPrincipalName = "upn",
  SamAccountName = "samAccountName",
}

const UserDirectoryKeysEnum = z.nativeEnum(UserDirectoryKeys);

export type UserDirectoryInstanceIdUnbranded = string;
export const UserDirectoryInstanceIdSchema = z
  .string()
  .uuid()
  .brand(
    Symbol("UserDirectoryInstanceId"),
  ) satisfies ZodType<UserDirectoryInstanceIdUnbranded>;
export type UserDirectoryInstanceId = z.infer<
  typeof UserDirectoryInstanceIdSchema
>;

export type UserDirectoryInstanceUnbranded = {
  id: UserDirectoryInstanceIdUnbranded;
  organisationId: OrganisationIdUnbranded;
  tenantId: string;
  clientId: string;
  clientSecret: string;
  accessToken: string;
  accessTokenExpires: Date;
  userDirectoryKey: UserDirectoryKeys;
};

export const UserDirectoryInstanceSchema = z
  .object({
    id: UserDirectoryInstanceIdSchema,
    organisationId: OrganisationIdSchema,
    tenantId: z.coerce.string().uuid(),
    clientId: z.coerce.string().uuid(),
    clientSecret: z.string(),
    accessToken: z.string(),
    accessTokenExpires: z.coerce.date(),
    userDirectoryKey: UserDirectoryKeysEnum,
  })
  .brand(
    Symbol("UserDirectoryInstance"),
  ) satisfies ZodType<UserDirectoryInstanceUnbranded>;

export type UserDirectoryInstance = z.infer<typeof UserDirectoryInstanceSchema>;

export type CreateUserDirectoryInstanceUnbranded = Omit<
  UserDirectoryInstanceUnbranded,
  "id" | "accessToken" | "accessTokenExpires"
>;

export type UpdateUserDirectoryInstanceUnbranded =
  Partial<CreateUserDirectoryInstanceUnbranded>;

export const CreateUserDirectoryInstanceSchema = z
  .object({
    organisationId: OrganisationIdSchema,
    tenantId: z.coerce.string().uuid(),
    clientId: z.coerce.string().uuid(),
    clientSecret: z.string(),
    userDirectoryKey: UserDirectoryKeysEnum,
  })
  .brand(
    Symbol("CreateUserDirectoryInstance"),
  ) satisfies ZodType<CreateUserDirectoryInstanceUnbranded>;

export type CreateUserDirectoryInstance = z.infer<
  typeof CreateUserDirectoryInstanceSchema
>;
