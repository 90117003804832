import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  commandUnitSchema,
  dispatchNumberSchema,
  forenameSchema,
  FormSection,
  LocationSchema,
  OrganisationRoles,
  surnameSchema,
  type FormDefinition,
  type FormSectionDefinition,
} from "../../types/index.js";

const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "dispatchNumber", // create-1.0
    type: "textbox",
    ...new FieldBuilder().withSchema(dispatchNumberSchema).build(),
    label: "common.dispatchNumber",
  })
  .withFieldAlwaysVisible({
    fieldName: "incidentLocation", // create-2.0
    type: "location",
    ...new FieldBuilder().withSchema(LocationSchema).build(),
    label: "createIncident.incidentLocation",
  })
  .withFieldAlwaysVisible({
    fieldName: "commandUnit", // create-2.2
    type: "organisation",
    filterByRoles: [OrganisationRoles.PoliceCommandUnit],
    ...new FieldBuilder()
      .withSchema(commandUnitSchema)
      .captureValueLabel()
      .build(),
    label: "createIncident.commandUnit",
  })
  .withFieldAlwaysVisible({
    fieldName: "personForename", // create-4.0
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(forenameSchema).build(),
    label: "createIncident.personForename",
  })
  .withFieldAlwaysVisible({
    fieldName: "personSurname", // create-4.1
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(surnameSchema).build(),
    label: "createIncident.personSurname",
  })
  .withPage([
    "dispatchNumber", // create-1.0
  ])
  .withPage([
    "incidentLocation", // create-2.0
  ])
  .withPage([
    "commandUnit", // create-2.2
  ])
  .withPage([
    "personForename", // create-4.0
    "personSurname", // create-4.1
  ])
  .build();

export type CreateIncidentWorkItemFormData = typeof formSpecification.formType;

const createIncidentThalamosMinV01: FormDefinition<
  [
    FormSectionDefinition<
      FormSection.createIncidentMain,
      CreateIncidentWorkItemFormData
    >,
  ]
> = {
  sections: [
    {
      id: FormSection.createIncidentMain,
      autosave: false,
      strictFieldSchemas: formSpecification.strictFieldSchemas,
      formSchema: formSpecification.formSchema,
      name: "incidentWorkItem.startNew",
      pages: formSpecification.pages,
    },
  ],
};

export default createIncidentThalamosMinV01;
