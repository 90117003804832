import React from "react";

import { CircularProgress, Stack, Typography } from "@mui/material";
import { apiHooks } from "../../api/index.js";

export type PlacesOfSafetyPageProps = Record<string, never>;

export const PlacesOfSafetyPage = (
  _props: PlacesOfSafetyPageProps,
): React.ReactNode => {
  const [{ data: placesOfSafety, loading, error }] =
    apiHooks.placeOfSafety.getAll();

  if (error) {
    return <Typography>Something went wrong</Typography>;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const placesList = placesOfSafety?.placesOfSafety ?? [];

  return (
    <Stack sx={{ display: "flex", textAlign: "center" }}>
      <Typography variant="h3">These are the Places of Safety</Typography>
      {placesList.length > 0 ? (
        <ul>
          {placesList.map((placeOfSafety) => (
            <li key={placeOfSafety.id}>
              <Typography variant="h3">{placeOfSafety.name}</Typography>
            </li>
          ))}
        </ul>
      ) : (
        <Typography>No places of safety found.</Typography>
      )}
    </Stack>
  );
};
