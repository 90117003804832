/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";

import { parsedAny } from "@thalamos/common";
import {
  AvailableOrganisationArraySchema,
  CreateIncidentMinimumFields,
  FormSection,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import { useNavigate, useParams } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import { OrganisationContext } from "../../components/FormBuilder/OrganisationDropdown/OrganisationContext.js";
import { useUser } from "../../Context/UserContext.js";
import {
  DetentionDetailsForm,
  DetentionDetailsSkeleton,
} from "../../forms/DetentionDetails/DetentionDetailsForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type DetentionDetailsPageProps = Record<string, unknown>;

export type DetentionDetailsPageInnerProps = {
  incidentWorkItem?: IncidentWorkItemWithContext;
  refetchWorkItem: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
};

export function DetentionDetailsPage(_props: DetentionDetailsPageProps) {
  const params = useParams();

  const { user, loading, error } = useUser();

  if (loading) {
    return <DetentionDetailsSkeleton />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  const [{ data: organisationsResponse }] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.organisation.getAvailableOrganisations(
      user?.sessionContext?.organisationId ?? "",
    );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const organisations = parsedAny(
    AvailableOrganisationArraySchema,
    organisationsResponse,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  ).unwrapOr([]);

  const [{ data: incidentWorkItem }, refetchWorkItem] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(incidentId.data);

  return (
    <OrganisationContext.Provider
      value={{
        organisations,
      }}
    >
      <DetentionDetailsPageInner
        incidentWorkItem={incidentWorkItem ?? undefined}
        refetchWorkItem={refetchWorkItem}
      />
    </OrganisationContext.Provider>
  );
}

export function DetentionDetailsPageInner({
  incidentWorkItem,
  refetchWorkItem,
}: DetentionDetailsPageInnerProps) {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }
    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;

    const createIncidentForm = incidentWorkItem?.forms.find(
      (form) => form.formType === IncidentFormType.createIncident,
    );
    const createIncidentFormSectionData = (createIncidentForm?.sections.find(
      (section) => section.id === FormSection.createIncidentMain,
    )?.data ?? undefined) as CreateIncidentMinimumFields | undefined;

    const section136Form = incidentWorkItem?.forms.find(
      (form) => form.formType === IncidentFormType.section136,
    );

    const section136FormId = section136Form?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      .workflowVersion as WorkflowVersions | undefined;

    // Show the loading skeleton if we're missing key details
    if (
      incidentWorkItemId === undefined ||
      section136FormId === undefined ||
      workflowVersion === undefined ||
      createIncidentFormSectionData === undefined
    ) {
      return <DetentionDetailsSkeleton />;
    }

    // Calculate the initial values for the form
    const initialValues = (() => {
      // Get the existing values from the form if it exists
      const existingValues =
        section136Form?.sections.find(
          (section) => section.id === FormSection.detentionDetails,
        )?.data ?? {};

      // This is where we will acculmulate the values for the form
      const finalValues: Record<string, unknown> = { ...existingValues };

      // If there is no existing value for the following fields, then populate them
      // from the create incident form.
      // * Command Unit Responsible
      // * Location
      finalValues.orgResponsibleForDetention ??=
        createIncidentFormSectionData.commandUnit;
      finalValues.locationDetentionTookPlace ??=
        createIncidentFormSectionData.incidentLocation;

      return finalValues;
    })();

    return (
      <DetentionDetailsForm
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={async (values: Record<string, unknown>) => {
          try {
            const response = await api.incidentWorkItem.updateData(
              incidentWorkItemId,
              section136FormId,
              FormSection.detentionDetails,
              values,
              true,
            );
            if (response.status === 204) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              await refetchWorkItem();
              navigate(`/incident/${incidentWorkItemId}/section-complete`, {
                state: {
                  type: FormSection.detentionDetails,
                },
              });
              return;
            } else {
              setError(JSON.stringify(response.data.message));
            }
          } catch (error) {
            setError(
              "Failed to update 136 form: " +
                JSON.stringify((error as Error).message),
            );
          }
        }}
        onCancel={() => {
          navigate(createRoute.incidentProgressPage(incidentWorkItemId));
        }}
        initialValues={initialValues}
        workflowVersion={workflowVersion}
      />
    );
  };

  return render(incidentWorkItem);
}
