import React from "react";

import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export type FooterProps = {
  onCancel: () => void;
  onNext?: () => void;
};

export const Footer = (props: FooterProps) => (
  <FooterInner type={"loaded"} {...props} />
);

///

export const FooterSkeleton = () => <FooterInner type={"loading"} />;

///

type FooterInnerProps =
  | { type: "loading" }
  | ({ type: "loaded" } & FooterProps);

const FooterInner = (props: FooterInnerProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        variant={props.type === "loading" ? "contained" : "outlined"}
        color={props.type === "loading" ? "primary" : "error"}
        disabled={props.type === "loading"}
        onClick={props.type === "loading" ? undefined : props.onCancel}
        data-testid="formButton-cancel"
      >
        {t("multiPageForm.cancel")}
      </Button>
      <Button
        variant="contained"
        disabled={props.type === "loading" || props.onNext === undefined}
        onClick={props.type === "loading" ? undefined : props.onNext}
        data-testid="formButton-next"
      >
        {t("multiPageForm.next")}
      </Button>
    </Box>
  );
};
