import { match } from "ts-pattern";
import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import { freeTextSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "leaveReason",
    label: "leavePlaceOfSafety.leaveReason.title",
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "advised_visit_Hbpos_because_bedNowAvailable" as const,
          "advised_visit_accidentAndEmergency_because_noHbposAvailable" as const,
          "advised_visit_accidentAndEmergency_because_medicalGrounds" as const,
          "decided_visit_accidentAndEmergency_because_UnreasonableDelay" as const,
          "physicalHealthCheckComplete" as const,
          "other" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    options: [
      {
        label:
          "leavePlaceOfSafety.leaveReason.options.advised_visit_Hbpos_because_bedNowAvailable",
        value: "advised_visit_Hbpos_because_bedNowAvailable",
      },
      {
        label:
          "leavePlaceOfSafety.leaveReason.options.advised_visit_accidentAndEmergency_because_noHbposAvailable",
        value: "advised_visit_accidentAndEmergency_because_noHbposAvailable",
      },
      {
        label:
          "leavePlaceOfSafety.leaveReason.options.advised_visit_accidentAndEmergency_because_medicalGrounds",
        value: "advised_visit_accidentAndEmergency_because_medicalGrounds",
      },
      {
        label:
          "leavePlaceOfSafety.leaveReason.options.decided_visit_accidentAndEmergency_because_UnreasonableDelay",
        value: "decided_visit_accidentAndEmergency_because_UnreasonableDelay",
      },
      {
        label:
          "leavePlaceOfSafety.leaveReason.options.physicalHealthCheckComplete",
        value: "physicalHealthCheckComplete",
      },
      {
        label: "leavePlaceOfSafety.leaveReason.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "leaveReasonOther",
    label: "leavePlaceOfSafety.leaveReasonOther.title",
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      match(formValues.leaveReason?.value)
        .with("other", () => true)
        .with(
          undefined,
          "advised_visit_Hbpos_because_bedNowAvailable",
          "advised_visit_accidentAndEmergency_because_noHbposAvailable",
          "advised_visit_accidentAndEmergency_because_medicalGrounds",
          "decided_visit_accidentAndEmergency_because_UnreasonableDelay",
          "physicalHealthCheckComplete",
          () => false,
        )
        .exhaustive(),
  })
  .withPage(["leaveReason", "leaveReasonOther"])
  .build();

export type LeavePlaceOfSafetyFormData = typeof formSpecification.formType;
