import React, { useMemo } from "react";

import {
  createInitialValues,
  workflows,
  WorkflowVersions,
} from "@vision/common";
import { z } from "zod";
import {
  MultiPageForm,
  MultiPageFormSkeleton,
} from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";

type FinishFormSchema<T extends WorkflowVersions> = z.infer<
  (typeof workflows)[T]["finishForm"]["formDefinition"]["sections"][0]["formSchema"]
>;

///

export function FinishFormSkeleton() {
  return <MultiPageFormSkeleton headerText={"finishForm.leavePlaceOfSafety"} />;
}

///

export type FinishFormProps<T extends WorkflowVersions> = {
  onSubmit: (value: FinishFormSchema<T>) => void;
  onCancel: () => void;
  workflowVersion: T;
  initialValues?: FinishFormSchema<T>;
};

///

export function FinishForm<T extends WorkflowVersions>(
  props: FinishFormProps<T>,
) {
  const workflow = workflows[props.workflowVersion];
  const formSchema = workflow.finishForm.formDefinition.sections[0].formSchema;
  const strictFieldSchemas =
    workflow.finishForm.formDefinition.sections[0].strictFieldSchemas;

  const initialValuesOrDefaults = useMemo(
    () =>
      props.initialValues ??
      (createInitialValues(strictFieldSchemas) as z.infer<typeof formSchema>),
    [props.initialValues, strictFieldSchemas],
  );

  return (
    <MultiPageForm<typeof formSchema>
      headerText={"finishForm.leavePlaceOfSafety"}
      schema={formSchema}
      strictFieldSchemas={strictFieldSchemas}
      initialValues={initialValuesOrDefaults}
      pages={workflow.finishForm.formDefinition.sections[0].pages}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    />
  );
}
