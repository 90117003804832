import React from "react";

import { CuratedPlaceOfSafety } from "@thalamos/common";

export type PlacesOfSafety = {
  placesOfSafety: CuratedPlaceOfSafety[];
};

/**
 * This provides a mechanism for components to make queries about Places Of Safety.
 * This might involve reading pre-fetched values, or a query to the server.
 */
export const PlacesOfSafetyContext = React.createContext<PlacesOfSafety>({
  /**
   * A list of places of safety that can be picked from.
   */
  placesOfSafety: [],

  // TODO: Consider adding methods on here to do a dynamic query which returns some POS.
  // e.g. getPlacesOfSafety: (query: string) => PlaceOfSafety[];
});

export const usePlacesOfSafety = () => React.useContext(PlacesOfSafetyContext);
