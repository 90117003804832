import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import { dateTimeSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withReadonlyFieldAlwaysVisible({
    fieldName: "informStaff",
    type: "banner",
    label: "finishForm.informStaff",
    bannerType: "warning",
  })
  .withFieldAlwaysVisible({
    fieldName: "dateTimeLeft",
    type: "date-time",
    ...new FieldBuilder().withSchema(dateTimeSchema).build(),
    dateLabel: "finishForm.dateLeft",
    timeLabel: "finishForm.timeLeft",
  })
  .withPage(["informStaff", "dateTimeLeft"])
  .build();

export type FinishFormData = typeof formSpecification.formType;
