import { notificationChannelFns, ThalamosNotification } from "@vision/common";
import { AxiosInstance } from "axios";
import { usePusherChannelReload } from "../axios/api.js";

export const config = {
  notifications: {
    unread: () => ({
      url: `/api/notifications/unread`,
      method: "get",
    }),
    markAsRead: (notificationId: string) => ({
      url: `/api/notifications/viewed`,
      method: "post",
      data: { notificationId },
    }),
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    notifications: {
      unread: () =>
        apiAxios.request<ThalamosNotification[]>(config.notifications.unread()),
      markAsRead: (notificationId: string) =>
        apiAxios.request(config.notifications.markAsRead(notificationId)),
    },
  };
};

export const apiHooks = () => {
  return {
    notifications: {
      unread: (userId: string) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return usePusherChannelReload<ThalamosNotification[] | null>(
          notificationChannelFns.user.notifications(userId),
          config.notifications.unread(),
        );
      },
    },
  };
};
