import React from "react";

import { DialogContent, useTheme } from "@mui/material";
import {
  ExtendedVisionUser,
  FieldBuilder,
  FormBuilderField,
  emailSchema,
  nameSchema,
  orgIdSchema,
} from "@vision/common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import api from "../../api/index.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { UserContextType } from "../../Context/UserContext.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { createRoute } from "../../routing/createRoute.js";
import { SinglePageForm } from "../FormBuilder/SinglePageForm/SinglePageForm.js";
import { TypographyI18N } from "../index.js";

const OnboardingFormDataSchema = z.object({
  name: new FieldBuilder().withSchema(nameSchema).build().schema,
  orgId: new FieldBuilder().withSchema(orgIdSchema).captureValueLabel().build()
    .schema,
  email: new FieldBuilder().withSchema(emailSchema).build().schema,
});

export type OnboardingFormData = z.infer<typeof OnboardingFormDataSchema>;
//          ^?

const collectUserDetails: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: FormBuilderField<OnboardingFormData, any>[];
} = {
  fields: [
    {
      type: "textbox",
      fieldName: "name",
      label: "onboarding.name",
    },
    {
      type: "typography",
      fieldName: "email",
      label: "onboarding.email",
      textFn: ({ formValues }) => [formValues.email.value],
    },
    {
      type: "organisation",
      fieldName: "orgId",
      label: "onboarding.organisationMembership",
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const content: { fields: FormBuilderField<OnboardingFormData, any>[] } =
  collectUserDetails;

const OnboardingForm = ({
  onSubmit,
  user,
}: {
  onSubmit: (values: OnboardingFormData) => void;
  user: ExtendedVisionUser;
}) => {
  return (
    <SinglePageForm<typeof OnboardingFormDataSchema>
      schema={OnboardingFormDataSchema}
      initialValues={{
        name: { value: user.name },
        email: { value: user.email },
        orgId: {
          value: user.sessionContext?.organisationId ?? "",
          valueLabel: user.sessionContext?.organisationName ?? "",
        },
      }}
      content={content}
      onSubmit={onSubmit}
    />
  );
};

export const Onboarding = ({
  user,
  refetchUser,
}: {
  user: ExtendedVisionUser;
  refetchUser: UserContextType["refetchUser"];
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const notifications = useToastNotifications();
  const { t } = useTranslation();
  return (
    <PopupDialog open={true} sx={{ height: "100%" }}>
      <DialogContent>
        <TypographyI18N
          translationKey={"onboarding.welcomeTitle"}
          sx={{
            paddingBottom: theme.spacing(2),
            fontSize: theme.spacing(2),
            fontWeight: 500,
          }}
        />
        <TypographyI18N
          translationKey={"onboarding.welcomeMessage"}
          sx={{
            paddingBottom: theme.spacing(4),
            fontSize: theme.spacing(2),
          }}
        />
        <OnboardingForm
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={async (values) => {
            try {
              const response = await api.user.update(user.id, {
                name: values.name.value,
                organisationId: values.orgId.value,
                isOnboarded: true,
              });
              if (response.status === 204) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                await refetchUser();
                navigate(createRoute.home());
                return;
              } else {
                throw new Error(JSON.stringify(response.data.message));
              }
            } catch {
              notifications.show(t("onboarding.error"), {
                severity: "error",
              });
            }
          }}
          user={user}
        />
      </DialogContent>
    </PopupDialog>
  );
};
