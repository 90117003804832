import React, { useMemo, useState } from "react";

import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AvailableOrganisationUnbranded,
  // OrganisationFormBuilderField,
  OrganisationRoles,
  TranslationKey,
} from "@vision/common";
import { debug as debugFn } from "debug";
import { useField } from "formik";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";
import { useOrganisations } from "./OrganisationContext.js";

const debug = debugFn("vision-frontend:OrganisationDropdown");

export type DropdownOutput =
  | {
      value: string;
      valueLabel: string;
    }
  | "";

export type OrganisationDropdownProps = {
  fieldName: string;
  label?: TranslationKey;
  filterByRoles?: OrganisationRoles[];
};

export function OrganisationDropdown({
  fieldName,
  label,
  filterByRoles,
}: OrganisationDropdownProps) {
  const theme = useTheme();
  const organisationContext = useOrganisations();

  const [field, meta, helpers] = useField<DropdownOutput>(fieldName);

  // We use this to store the last value that the user selected.
  // This is so that we can restore it if they turn on the "unknown" flag.
  // We pre-populate this with the incoming "initialValue".
  const [selectedValue, setSelectedValue] = useState<string | null>(
    field.value === "" ? null : field.value.value,
  );

  // Clean up the field value to be a structured object
  const fieldValue = useMemo(() => {
    const value =
      field.value === ""
        ? // Turn null-ish values into a structured object
          ({ value: null, valueLabel: null } as const)
        : { value: field.value.value, valueLabel: field.value.valueLabel };

    debug("Cleaned up value", { value });
    return value;
  }, [field.value]);

  const filteredOptions = useMemo(
    () =>
      filterByRoles
        ? organisationContext.organisations.filter((option) =>
            filterByRoles.includes(option.role),
          )
        : organisationContext.organisations,
    [filterByRoles, organisationContext.organisations],
  );

  const handleValueChange = (event: SelectChangeEvent) => {
    const runAsync = async () => {
      const selectedValue = event.target.value;

      const selectedValueLabel =
        filteredOptions.find((option) => option.id === selectedValue)?.name ??
        "";
      const newValue = {
        value: selectedValue,
        valueLabel: selectedValueLabel,
      };

      debug("handleValueChange, setting value", newValue);
      setSelectedValue(selectedValue);
      await helpers.setValue(newValue);
      await helpers.setTouched(true);
    };

    void runAsync();
  };

  return (
    <Box sx={{ paddingBottom: theme.spacing(3) }}>
      <FormControl fullWidth data-testid={`formField-${fieldName}`}>
        {label && <FormLabel label={label} />}
        <Select
          name={fieldName}
          value={selectedValue ?? ""}
          onChange={handleValueChange}
          onBlur={field.onBlur}
          data-testid="select-dropdown"
        >
          {filteredOptions.map((option: AvailableOrganisationUnbranded) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {meta.touched && meta.error && <FormError error={meta.error} />}
      </FormControl>
      {debug.enabled && (
        <>
          <Divider />
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"field.value : "}
            {JSON.stringify(field.value)}
          </Typography>
          <Typography sx={{ color: debug.color, fontFamily: "monospace" }}>
            {"Treated like: "}
            {JSON.stringify(fieldValue)}
          </Typography>
          <Divider />
        </>
      )}
    </Box>
  );
}
