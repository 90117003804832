import { Box } from "@mui/material";
import { S136FormRejectedBody, ThalamosNotification } from "@vision/common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../../api/index.js";
import { useToastNotifications } from "../../../hooks/useToastNotifications.js";
import { FormRejectedModal } from "../../../modals/FormRejected/FormRejected.js";
import { ShareModal } from "../../../pages/FormProgress/ShareModal.js";
import ActionBanner, {
  ActionBannerList,
} from "../../ActionBanner/ActionBanner.js";

interface S136FormRejectedNotificationProps {
  notification: ThalamosNotification;
  close: () => void;
}

export const S136FormRejectedNotification = ({
  notification,
  close,
}: S136FormRejectedNotificationProps) => {
  const { t } = useTranslation();
  const notifications = useToastNotifications();
  const [isFormRejectedModalOpen, setIsFormRejectedModalOpen] = useState(true);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const notificationText = notification.text as S136FormRejectedBody;

  const handleCloseFormRejectedModal = () => {
    setIsFormRejectedModalOpen(false);
  };

  const handleClose = async () => {
    setIsFormRejectedModalOpen(false);
    close();
    const res = await api.notifications.markAsRead(notification.id);
    if (res.status !== 204) {
      notifications.show(t("notifications.failedMarkAsRead"), {
        severity: "error",
      });
    }
  };

  const handleShare = () => {
    setIsFormRejectedModalOpen(false);
    setShowShareModal(true);
  };

  return (
    <>
      <Box
        sx={{
          padding: ".5rem",
        }}
      >
        <ActionBanner
          title={t("s136FormRejectedNotification.s136Rejected")}
          bannerType={ActionBannerList.WARNING}
          onClick={() => {
            setIsFormRejectedModalOpen(true);
          }}
        />
      </Box>
      {isFormRejectedModalOpen && (
        <FormRejectedModal
          open={isFormRejectedModalOpen}
          close={handleCloseFormRejectedModal}
          onClose={() => void handleClose()}
          onShare={handleShare}
          posName={notificationText.posName}
          reason={notificationText.reason}
          reasonDescription={notificationText.reasonDescription}
        />
      )}
      {showShareModal && (
        <ShareModal
          isOpen={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
          onConfirm={() => {
            void (async () => {
              const res = await api.notifications.markAsRead(notification.id);
              if (res.status !== 204) {
                notifications.show(t("notifications.failedMarkAsRead"), {
                  severity: "error",
                });
              }
              setShowShareModal(false);
              close();
            })();
          }}
          incidentWorkItemId={notificationText.incidentWorkItemId}
        />
      )}
    </>
  );
};
