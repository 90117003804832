import React, { useMemo, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { parsedAny } from "@thalamos/common";
import {
  AvailableOrganisationArray,
  AvailableOrganisationArraySchema,
  DEFAULT_WORKFLOW_VERSION,
  ExtendedVisionUser,
  FormSection,
  IncidentWorkItemIdSchema,
  workflows,
} from "@vision/common";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import api, { apiHooks } from "../../api/index.js";
import { OrganisationContext } from "../../components/FormBuilder/OrganisationDropdown/OrganisationContext.js";
import { useUser } from "../../Context/UserContext.js";
import { CreateIncidentWorkItemForm } from "../../forms/CreateIncidentWorkItem/CreateIncidentWorkItemForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { Error as ComponentError } from "../Error/Error.js";

export type CreateIncidentWorkItemProps = {
  errorHandling: "throw" | "inline";
  user: ExtendedVisionUser;
};

export type CreateIncidentWorkItemPageProps = {
  errorHandling: "throw" | "inline";
};

export function CreateIncidentWorkItem({
  errorHandling,
  user,
}: CreateIncidentWorkItemProps) {
  const navigate = useNavigate();

  const id = useMemo(() => IncidentWorkItemIdSchema.parse(v4()), []);
  const [error, setError] = useState<string | undefined>(undefined);

  if (error !== undefined && errorHandling === "throw") {
    throw new Error(error);
  }

  const workflowVersion =
    user.sessionOrganisationConfiguration?.workflowVersion ??
    DEFAULT_WORKFLOW_VERSION;

  const formSchema =
    workflows[workflowVersion].createIncident.formDefinition.sections[0]
      .formSchema;

  return (
    <>
      <CreateIncidentWorkItemForm
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={async (values: Record<string, unknown>) => {
          try {
            const parsedValue = formSchema.parse(values);

            const response = await api.incidentWorkItem.create(
              id,
              {
                workflowVersion,
                createIncidentFormSection: parsedValue,
              },
              // make sure sending the correct fields for specific workflow
            );

            if (response.status === 204) {
              navigate(`/incident/${id}/section-complete`, {
                state: {
                  type: FormSection.createIncidentMain,
                  payload: {
                    officer: user.name,
                  },
                },
              });
              return;
            } else {
              throw new Error(JSON.stringify(response.data.message));
            }
          } catch (error) {
            setError(
              "Failed to create incident work item: " +
                JSON.stringify((error as Error).message),
            );
          }
        }}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        defaultCommandUnitOrganisationId={user.sessionContext?.organisationId!}
        onCancel={() => {
          navigate(createRoute.home());
        }}
        workflowVersion={workflowVersion}
      />
      {error !== undefined && errorHandling === "inline" && <Box>{error}</Box>}
    </>
  );
}

export function CreateIncidentWorkItemPage({
  errorHandling,
}: CreateIncidentWorkItemPageProps) {
  const { user, loading, error } = useUser();

  if (error || user === undefined) {
    return <ComponentError />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <CreateIncidentWorkItemPageInner
        errorHandling={errorHandling}
        user={user}
      />
    </>
  );
}

export function CreateIncidentWorkItemPageInner({
  user,
  errorHandling,
}: CreateIncidentWorkItemProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ data: organisationsResponse, loading, error }] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.organisation.getAvailableOrganisations(
      user.sessionContext?.organisationId ?? "",
    );

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <ComponentError />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const availableOrganisations = parsedAny(
    AvailableOrganisationArraySchema,
    organisationsResponse,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  ).unwrapOr([]) as AvailableOrganisationArray;

  return (
    <OrganisationContext.Provider
      value={{
        organisations: availableOrganisations,
      }}
    >
      <CreateIncidentWorkItem errorHandling={errorHandling} user={user} />
    </OrganisationContext.Provider>
  );
}
