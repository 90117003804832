import { Search } from "@mui/icons-material";
import { Autocomplete, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TranslationKey } from "@vision/common";
import React, { useState } from "react";
import { Element } from "react-scroll";
import {
  TextField,
  TextFieldProps,
} from "../FormBuilder/TextField/TextField.js";

type IconType = "info" | "error" | "warning";

export interface TypeaheadProps<T> extends TextFieldProps {
  label?: TranslationKey;
  options: T[];
  getOptionLabel?: (option: string | T) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInputChange?: (e: any) => void;
  onBlur?: () => void;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  errorMessage?: string;
  subtext?: string;
  subtextIcon?: IconType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputValue?: any;
  testId?: string;
  maxLength?: number;
  allowFreeText?: boolean;
  name: string;
  optionsKey?: string;
  enableFuse?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputProps?: any;
  groupBy?: (option: T) => string;
  getOptionSubLabel?: (option: T) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any,
  ) => React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultProps: Partial<TypeaheadProps<any>> = {
  loading: false,
  onInputChange: () => {},
  label: undefined,
  placeholder: undefined,
  subtext: undefined,
  subtextIcon: undefined,
  inputValue: "",
  onBlur: undefined,
  disabled: false,
  InputProps: undefined,
  maxLength: undefined,
  allowFreeText: true,
  optionsKey: "label",
  groupBy: undefined,
  renderOption: undefined,
};

export const Typeahead = <T,>(props: TypeaheadProps<T>) => {
  const {
    label,
    options,
    getOptionLabel,
    onInputChange,
    disabled,
    loading,
    inputValue,
    maxLength,
    allowFreeText,
    name,
    optionsKey,
    onBlur,
    groupBy,
    getOptionSubLabel,
    renderOption,
    ...rest
  } = { ...defaultProps, ...props };

  const theme = useTheme();

  const testId = props.testId ? `field:${props.testId}` : undefined;
  const [textValue, setTextValue] = useState(inputValue);

  return (
    <Element name={name} data-testid={testId} style={{ position: "relative" }}>
      <Autocomplete
        filterOptions={
          options.some(
            (option) =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unsafe-call
              (option as any)[optionsKey!].toLowerCase() ===
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              inputValue.toLowerCase(),
          )
            ? (options) => options
            : undefined
        }
        fullWidth
        onBlur={onBlur}
        freeSolo={allowFreeText}
        value={textValue}
        inputValue={inputValue}
        disabled={disabled}
        loading={loading}
        options={options}
        includeInputInList
        getOptionLabel={getOptionLabel}
        ListboxProps={
          {
            "data-testid": testId,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any
        }
        groupBy={groupBy}
        onChange={(_e, v) => {
          setTextValue(v);
        }}
        onInputChange={(_e, v) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onInputChange!(v);
        }}
        renderOption={renderOption}
        renderGroup={(params) => (
          <Box key={params.key}>
            <Box
              sx={{
                m: 2,
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                paddingBottom: theme.spacing(1),
              }}
            >
              {params.group}
            </Box>
            {params.children}
          </Box>
        )}
        disableClearable
        renderInput={(params) => {
          return (
            <TextField
              {...rest}
              {...params}
              label={label}
              InputProps={{
                ...params.InputProps,
                autoComplete: "off",
                type: "search",
                inputProps: {
                  maxLength,
                  "data-testid": "text-field",
                  ...params.inputProps,
                },
                startAdornment: <Search fontSize="medium" color="primary" />,
              }}
            />
          );
        }}
      />
    </Element>
  );
};
