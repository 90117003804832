import { useWindowWidth } from "@wojtekmaj/react-hooks";
import React, { useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DEFAULT_MAX_PDF_WIDTH = 750;

/*
  Renders a PDF with all the pages stacked one below the other
 */
export function MultiPagePdf({
  data,
  maxWidth,
}: {
  data: string;
  maxWidth?: number;
}) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const width = useWindowWidth();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const documentOptions = useMemo(() => {
    return { useSystemFonts: false, standardFontDataUrl: "/standard_fonts/" };
  }, []);
  const dataUri = `data:application/pdf;base64,${data}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Document
        file={dataUri}
        options={documentOptions}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <>
          {Array.from(Array(numPages).keys()).map((i) => (
            <div style={{ marginBottom: 10 }} key={i}>
              <Page
                renderAnnotationLayer={false}
                pageNumber={i + 1}
                width={Math.min(
                  (width || 0) * 0.9,
                  maxWidth ?? DEFAULT_MAX_PDF_WIDTH,
                )}
              />
            </div>
          ))}
        </>
      </Document>
    </div>
  );
}
