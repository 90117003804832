import { AxiosInstance } from "axios";

export const config = {
  hello: {
    greeting: () => ({
      url: `/api/hello`,
      method: "get",
    }),
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    hello: {
      greeting: () => apiAxios.request<string>(config.hello.greeting()),
    },
  };
};
