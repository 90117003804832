import React from "react";

import { Box, Typography } from "@mui/material";
import { FormBuilderField } from "@vision/common";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Banner, { BannerList } from "../Banner/Banner.js";
import { Checkbox } from "./Checkbox/Checkbox.js";
import { DatePicker } from "./DatePicker/DatePicker.js";
import { DateTimePicker } from "./DateTimePicker/DateTimePicker.js";
import { Dropdown } from "./Dropdown/Dropdown.js";
import { LocationPicker } from "./LocationPicker/LocationPicker.js";
import { MultiSelect } from "./MultiSelect/MultiSelect.js";
import { OrganisationDropdown } from "./OrganisationDropdown/OrganisationDropdown.js";
import { PlaceOfSafetyPicker } from "./PlaceOfSafetyPicker/PlaceOfSafetyPicker.js";
import { RadioButtonGroup } from "./RadioButton/RadioButton.js";
import { TextField } from "./TextField/TextField.js";

export type FieldProps<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
> = {
  field: FormBuilderField<Form, Field>;
} & FormikProps<Form>;

export function Field<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
>({ field, values }: FieldProps<Form, Field>) {
  const { t } = useTranslation();

  // Every time the value changes, consider changing the values of dependent fields
  switch (field.type) {
    case "date":
      return (
        <DatePicker
          fieldName={field.fieldName}
          label={field.label}
          canBeUnknown={field.canBeUnknown}
        />
      );

    case "date-time":
      return (
        <DateTimePicker
          fieldName={field.fieldName}
          dateLabel={field.dateLabel}
          timeLabel={field.timeLabel}
          canBeUnknown={field.canBeUnknown}
        />
      );
    case "textbox":
      return (
        <TextField
          fieldName={field.fieldName}
          label={field.label}
          tooltip={field.tooltip}
          canBeUnknown={field.canBeUnknown}
          multiline={field.multiline}
          rows={field.rows}
          type={field.inputType}
        />
      );
    case "location":
      return (
        <LocationPicker
          fieldName={field.fieldName}
          label={field.label}
          canBeUnknown={field.canBeUnknown}
        />
      );
    case "checkbox":
      return <Checkbox fieldName={field.fieldName} label={field.label} />;
    case "typography":
      return (
        <Box>
          {field.label !== undefined && (
            <Typography sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
              {t(field.label)}
            </Typography>
          )}
          {field.textFn !== undefined &&
            field
              .textFn({ formValues: values, t })
              .map((value, index) => (
                <Typography key={index}>{value}</Typography>
              ))}
        </Box>
      );
    case "organisation":
      return (
        <OrganisationDropdown
          fieldName={field.fieldName}
          label={field.label}
          filterByRoles={field.filterByRoles}
        />
      );
    case "placeOfSafety":
      return (
        <PlaceOfSafetyPicker fieldName={field.fieldName} label={field.label} />
      );
    case "dropdown":
      return (
        <Box>
          <Dropdown
            fieldName={field.fieldName}
            label={field.label}
            options={field.options}
            canBeUnknown={field.canBeUnknown}
          />
        </Box>
      );
    case "radio":
      return (
        <RadioButtonGroup
          fieldName={field.fieldName}
          label={field.label}
          options={field.options}
          canBeUnknown={field.canBeUnknown}
        />
      );
    case "banner":
      return (
        <Box>
          <Banner
            body={t(field.label)}
            bannerType={field.bannerType as BannerList}
          />
        </Box>
      );

    case "multi-select":
      return (
        <MultiSelect
          fieldName={field.fieldName}
          label={field.label}
          options={field.options}
        />
      );
  }
  return null;
}
