import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Banner, { BannerList } from "../../components/Banner/Banner.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";

export interface FormRejectedModalProps {
  open: boolean;
  posName?: string;
  reason: string;
  reasonDescription?: string;
  close: () => void;
  onClose: () => void;
  onShare: () => void;
}
export function FormRejectedModal({
  open,
  posName,
  reason,
  reasonDescription,
  close,
  onClose,
  onShare,
}: FormRejectedModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PopupDialog
      open={open}
      onClose={close}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      disableDialogRootPadding={true}
    >
      <DialogTitle sx={{ fontSize: "1.250rem", fontWeight: "bold" }}>
        {t("transfer.rejectedTransfer.title")}
        <IconButton
          aria-label="close"
          onClick={close}
          edge="end"
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem" }}
          gutterBottom
        >
          {t("s136FormRejectedNotification.formRjectedBy")}
        </Typography>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem", fontWeight: "bold" }}
          gutterBottom
        >
          {posName}
        </Typography>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem" }}
          gutterBottom
        >
          {t("s136FormRejectedNotification.reason")}
        </Typography>
        <Typography
          sx={{ color: "primary", fontSize: "0.875rem" }}
          gutterBottom
        >
          {reasonDescription ?? reason}
        </Typography>
        <Box sx={{ marginTop: "2rem" }}>
          <Banner
            body={t("s136FormRejectedNotification.reshareForm")}
            bannerType={BannerList.WARNING}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 3,
        }}
      >
        <Button
          onClick={() => {
            onClose();
          }}
          color="error"
          variant="outlined"
        >
          {t("common.close")}
        </Button>
        <Button onClick={onShare} color="primary" variant="contained">
          {t("s136FormRejectedNotification.reshare")}
        </Button>
      </DialogActions>
    </PopupDialog>
  );
}
