import React, { createContext, useContext, useState } from "react";

import { NotificationBodyType, ThalamosNotification } from "@vision/common";
import { debug as debugFn } from "debug";
import { useTranslation } from "react-i18next";
import api, { apiHooks } from "../api/index.js";
import { useToastNotifications } from "../hooks/useToastNotifications.js";

type NotificationContextType = {
  notifications: ThalamosNotification[];
  transferRequested: {
    isOpen: boolean;
    data: ThalamosNotification | null;
    open: (data: ThalamosNotification) => void;
    close: () => void;
  };
  transferRejected: {
    isOpen: boolean;
    data: ThalamosNotification | null;
    open: (data: ThalamosNotification) => void;
    close: () => void;
  };
  s136FormRejected: {
    isOpen: boolean;
    data: ThalamosNotification | null;
    open: (data: ThalamosNotification) => void;
    close: () => void;
  };
  policePresenceRequested: {
    isOpen: boolean;
    data: ThalamosNotification | null;
    open: (data: ThalamosNotification) => void;
    close: () => void;
  };
};

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined,
);

export const NotificationProvider = ({
  children,
  userId,
}: {
  children: React.ReactNode;
  userId: string;
}) => {
  const [{ data }] = apiHooks.notifications.unread(userId);

  const notifications = data && data.length > 0 ? data : [];

  return (
    <NotificationProviderInner notifications={notifications}>
      {children}
    </NotificationProviderInner>
  );
};

export const NotificationProviderInner = ({
  children,
  notifications,
}: {
  children: React.ReactNode;
  notifications: ThalamosNotification[];
}) => {
  const { t } = useTranslation();
  const toastNotifications = useToastNotifications();

  const [transferRequested, setTransferRequested] = useState<{
    isOpen: boolean;
    data: ThalamosNotification | null;
  }>({
    isOpen: false,
    data: null,
  });

  const [transferRejected, setTransferRejected] = useState<{
    isOpen: boolean;
    data: ThalamosNotification | null;
  }>({
    isOpen: false,
    data: null,
  });

  const [s136FormRejected, setS136FormRejected] = useState<{
    isOpen: boolean;
    data: ThalamosNotification | null;
  }>({
    isOpen: false,
    data: null,
  });

  const [policePresenceRequested, setPolicePresenceRequested] = useState<{
    isOpen: boolean;
    data: ThalamosNotification | null;
  }>({
    isOpen: false,
    data: null,
  });

  const debug = debugFn("vision-frontend:Notifications");

  React.useEffect(() => {
    if (notifications.length > 0) {
      const notification = notifications[0];
      handleNotification(notification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const handleNotification = (notification: ThalamosNotification) => {
    switch (notification.text.type) {
      case NotificationBodyType.transferRequested:
        setTransferRequested({ isOpen: true, data: notification });
        break;
      case NotificationBodyType.transferRejected:
        setTransferRejected({ isOpen: true, data: notification });
        break;
      case NotificationBodyType.s136FormAccepted:
        api.notifications
          .markAsRead(notification.id)
          .then(() => {
            toastNotifications.show(t("notification.s136Accepted"), {
              severity: "success",
            });
          })
          .catch(() => {
            toastNotifications.show(t("notifications.failedMarkAsRead"), {
              severity: "error",
            });
          });
        break;
      case NotificationBodyType.s136FormRejected:
        setS136FormRejected({ isOpen: true, data: notification });
        break;
      case NotificationBodyType.policePresenceRequested:
        setPolicePresenceRequested({ isOpen: true, data: notification });
        break;
      default:
        debug("Unhandled notification type", notification.text.type);
        api.notifications.markAsRead(notification.id).catch(() => {
          toastNotifications.show(t("notifications.failedMarkAsRead"), {
            severity: "error",
          });
        });
        break;
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications: notifications,
        transferRequested: {
          ...transferRequested,
          open: (data) => {
            setTransferRequested({ isOpen: true, data });
          },
          close: () => {
            setTransferRequested({ isOpen: false, data: null });
          },
        },
        transferRejected: {
          ...transferRejected,
          open: (data) => {
            setTransferRejected({ isOpen: true, data });
          },
          close: () => {
            setTransferRejected({ isOpen: false, data: null });
          },
        },
        s136FormRejected: {
          ...s136FormRejected,
          open: (data) => {
            setS136FormRejected({ isOpen: true, data });
          },
          close: () => {
            setS136FormRejected({ isOpen: false, data: null });
          },
        },
        policePresenceRequested: {
          ...policePresenceRequested,
          open: (data) => {
            setPolicePresenceRequested({ isOpen: true, data });
          },
          close: () => {
            setPolicePresenceRequested({ isOpen: false, data: null });
          },
        },
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider",
    );
  }
  return context;
};
