import { type EMHAParticipantUnbranded } from "@thalamos/common";
import { z } from "zod";
import type { CreateIncidentMinimumFields } from "./Workflow.js";
import {
  IncidentWorkItemWithContextSchema,
  type IncidentFormUnbranded,
  type IncidentWorkItemEnhancedUnbranded,
  type IncidentWorkItemParticipantEnhancedUnbranded,
} from "./workItem.js";

export type IncidentWorkItemResponse = {
  incidentWorkItem: IncidentWorkItemEnhancedUnbranded;
  participants: IncidentWorkItemParticipantEnhancedUnbranded[];
  forms: IncidentFormUnbranded[];
  emhaParticipants: EMHAParticipantUnbranded[];
};

export type GetIncidentWorkItemResponse = {
  workItems: IncidentWorkItemResponse[];
};

export type CreateIncidentWorkItemRequest<
  T extends CreateIncidentMinimumFields,
> = {
  workflowVersion: string;
  createIncidentFormSection: T;
};

export const GetIncidentWorkItemResponseSchema = z
  .object({
    workItems: z.array(IncidentWorkItemWithContextSchema),
  })
  .brand(Symbol("GetIncidentWorkItemResponse"));

export type GetIncidentWorkItemResponseBranded =
  z.infer<
    typeof GetIncidentWorkItemResponseSchema
  > extends GetIncidentWorkItemResponse
    ? z.infer<typeof GetIncidentWorkItemResponseSchema>
    : never;
