import React from "react";

import {
  FormSection,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentWorkItemStatus,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import { debug as debugFn } from "debug";
import { useNavigate, useParams } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import {
  ArrivePlaceOfSafetyForm,
  ArrivePlaceOfSafetyFormSkeleton,
} from "../../forms/ArrivePlaceOfSafety/ArrivePlaceOfSafetyForm.js";
import { createRoute } from "../../routing/createRoute.js";

export type ArrivePlaceOfSafetyPageProps = Record<string, unknown>;

export type ArrivePlaceOfSafetyPageInnerProps = {
  incidentWorkItem: IncidentWorkItemWithContext | undefined;
  refetchWorkitem: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
};

export function ArrivePlaceOfSafetyPage(_values: ArrivePlaceOfSafetyPageProps) {
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ data: incidentWorkItem }, refetchWorkitem] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(incidentId.data);

  return (
    <ArrivePlaceOfSafetyPageInner
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      incidentWorkItem={incidentWorkItem ?? undefined}
      refetchWorkitem={refetchWorkitem}
    />
  );
}

export function ArrivePlaceOfSafetyPageInner({
  incidentWorkItem,
  refetchWorkitem,
}: ArrivePlaceOfSafetyPageInnerProps) {
  const navigate = useNavigate();
  const params = useParams();
  const debug = debugFn("vision-frontend:ArrivePlaceOfSafetyPage");

  const placeOfSafetyFormIdResult = IncidentFormIdSchema.safeParse(
    params.formId,
  );
  if (!placeOfSafetyFormIdResult.success) {
    throw new Error("Invalid form ID");
  }
  const placeOfSafetyFormId = placeOfSafetyFormIdResult.data;

  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      .workflowVersion as WorkflowVersions | undefined;
    const initialValues =
      incidentWorkItem?.forms
        .find((form) => form.formType === IncidentFormType.recordPlaceOfSafety)
        ?.sections.find(
          (section) => section.incidentFormId === placeOfSafetyFormId,
        )?.data ?? undefined;
    debug("initialValues", initialValues);

    return (
      <>
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <ArrivePlaceOfSafetyFormSkeleton />
        ) : (
          <ArrivePlaceOfSafetyForm
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={async (values: Record<string, unknown>) => {
              try {
                // Populate it with values
                const populateFormResponse =
                  await api.incidentWorkItem.updateData(
                    incidentWorkItemId,
                    placeOfSafetyFormId,
                    FormSection.recordPlaceOfSafetyArrival,
                    values,
                    true,
                    IncidentWorkItemStatus.arrivedAtPlaceOfSafety,
                  );
                if (populateFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(populateFormResponse.data.message),
                  );
                }

                // Update the active officer request to null so it resets the police presence active form
                const updateActivePlaceOfSafetyFormResponse =
                  await api.incidentWorkItem.updateActiveOfficerRequest(
                    incidentWorkItemId,
                    null,
                  );
                if (updateActivePlaceOfSafetyFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(
                      updateActivePlaceOfSafetyFormResponse.data.message,
                    ),
                  );
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                await refetchWorkitem();
                navigate(
                  createRoute.incidentReviewPlacesOfSafetyPage(
                    incidentWorkItemId,
                  ),
                );
                return;
              } catch (error) {
                setError(
                  "Failed to record arrival at place of safety: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return render(incidentWorkItem);
}
