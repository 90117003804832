import type { TranslationKey } from "../../@types/react-i18next.js";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  clinicalCaseRecordNumberSchema,
  FormSection,
  freeTextSchema,
  givenHealthCareProfessionalAdviceSchema,
  IncidentType,
  nhsContactSchema,
  otherActionSchema,
  triageOutcomeSchema,
  voluntaryActionSchema,
  yesNoSchema,
  yesOnlySchema,
  type FormDefinition,
  type FormSectionDefinition,
  type nhsContactResults,
} from "../../types/index.js";

const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "isHealthcareProfessionalConsulted", // triage-1.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "triageIncident.isHealthcareProfessionalConsulted",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhs", // triage-1.0.3
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(nhsContactSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.howDidYouContactNhs.title",
    options: [
      {
        value: "healthcarePractitionerAmbulance", // triage-1.0.3.1
        label:
          "triageIncident.howDidYouContactNhs.options.healthcarePractitionerAmbulance",
      },
      {
        value: "ldPractitionersInCustody", // triage-1.0.3.2
        label:
          "triageIncident.howDidYouContactNhs.options.ldPractitionersInCustody",
      },
      {
        value: "custodyHealthcarePractitioner", // triage-1.0.3.3
        label:
          "triageIncident.howDidYouContactNhs.options.custodyHealthcarePractitioner",
      },
      {
        value: "panLondonMentalHealthAdviceLine", // triage-1.0.3.4
        label:
          "triageIncident.howDidYouContactNhs.options.panLondonMentalHealthAdviceLine",
      },
      {
        value: "localMentalHealthAdviceLine", // triage-1.0.3.5
        label:
          "triageIncident.howDidYouContactNhs.options.localMentalHealthAdviceLine",
      },
      {
        value: "localHbpos", // triage-1.0.3.6
        label: "triageIncident.howDidYouContactNhs.options.localHbpos",
      },
      {
        value: "localStreetTriageTeam", // triage-1.0.3.7
        label:
          "triageIncident.howDidYouContactNhs.options.localStreetTriageTeam",
      },
      {
        value: "nhs111", // triage-1.0.3.11
        label: "triageIncident.howDidYouContactNhs.options.nhs111",
      },
      {
        value: "controlRoom", // triage-1.0.3.14
        label: "triageIncident.howDidYouContactNhs.options.controlRoom",
      },
      {
        value: "amph", // triage-1.0.3.15
        label: "triageIncident.howDidYouContactNhs.options.amph",
      },
      {
        value: "other", // triage-1.0.3.16
        label: "common.other",
      },
    ] satisfies Array<{
      value: (typeof nhsContactResults)[number];
      label: TranslationKey;
    }>,
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhsOtherDetails", // triage-1.0.3.16.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.howDidYouContactNhs?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "clinicalCaseRecordNumber", // triage-1.0.3.1.2
    type: "textbox",
    ...new FieldBuilder().withSchema(clinicalCaseRecordNumberSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.howDidYouContactNhs?.value ===
      "panLondonMentalHealthAdviceLine",
    label: "triageIncident.clinicalCaseRecordNumber",
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonNotConsulted", // triage-1.0.1
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(freeTextSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "no",
    label: "triageIncident.reasonNotConsulted.title",
    options: [
      {
        label: "triageIncident.reasonNotConsulted.options.needForCare",
        value: "needForCare",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.immediateControl",
        value: "immediateControl",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.noAnswer",
        value: "noAnswer",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "givenHealthCareProfessionalAdvice", // triage-1.0.4
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(givenHealthCareProfessionalAdviceSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.givenHealthCareProfessionalAdvice",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: IncidentType.section136,
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: IncidentType.section56,
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: IncidentType.voluntary,
      },
      {
        label: "common.other",
        value: IncidentType.other,
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonsForDetention", // triage-1.0.4.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section136,
    label: "triageIncident.reasonsForDetention",
    multiline: true,
    rows: "5",
  })
  .withFieldConditionallyVisible({
    fieldName: "confirmMentalCapacityLack", // triage-1.0.4.1.2
    type: "checkbox",
    ...new FieldBuilder().withSchema(yesOnlySchema).captureValueLabel().build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section56,
    label: "triageIncident.confirmMentalCapacityLack",
  })
  .withFieldConditionallyVisible({
    fieldName: "voluntaryAction", // triage-1.0.4.1.3
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(voluntaryActionSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.voluntary,
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label:
          "triageIncident.voluntaryAction.options.voluntaryAttendanceHospital",
        value: "voluntaryAttendanceHospital",
      },
      {
        label:
          "triageIncident.voluntaryAction.options.divertOtherHealthservice",
        value: "divertOtherHealthservice",
      },
      {
        label: "triageIncident.voluntaryAction.options.contactSupportGroup",
        value: "contactSupportGroup",
      },
      {
        label: "triageIncident.voluntaryAction.options.contactFriendsFamily",
        value: "contactFriendsFamily",
      },
      {
        label: "triageIncident.voluntaryAction.options.takePersonHome",
        value: "takePersonHome",
      },
      {
        label: "triageIncident.voluntaryAction.options.divertCrisisCafe",
        value: "divertCrisisCafe",
      },
      {
        label: "triageIncident.voluntaryAction.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "voluntaryActionOtherDetails", // triage-1.0.4.1.3.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.voluntaryAction?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "otherAction", // triage-1.0.4.1.4.1
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(otherActionSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.other,
    label: "triageIncident.otherAction.title",
    options: [
      {
        label: "triageIncident.otherAction.options.arrestCriminalOffence",
        value: "arrestCriminalOffence",
      },
      {
        label: "triageIncident.otherAction.options.watchList",
        value: "watchList",
      },
      {
        label: "triageIncident.otherAction.options.referSupportOptions",
        value: "referSupportOptions",
      },
      {
        label: "triageIncident.otherAction.options.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "otherActionDetails", // triage-1.0.4.1.4.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.otherAction?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "otherActionBanner", // triage-1.0.4.1.4.1.2
    type: "banner",
    bannerType: "warning",
    label: "triageIncident.otherActionBanner",
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.other,
  })
  .withFieldAlwaysVisible({
    fieldName: "whatWillYouDo",
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(triageOutcomeSchema)
      .captureValueLabel()
      .build(),
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: "136",
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: "5-6",
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: "voluntary",
      },
      {
        label: "triageIncident.whatWillYouDo.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "whatWillYouDoOther", // triage-1.0.2.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.whatWillYouDo?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withPage([
    "isHealthcareProfessionalConsulted", // triage-1.0
  ])
  .withPage([
    "reasonNotConsulted", // triage-1.0.1
    "howDidYouContactNhs", // triage-1.0.3
    "howDidYouContactNhsOtherDetails", // triage-1.0.3.16.1
    "clinicalCaseRecordNumber", // triage-1.0.3.1.2
  ])
  .withPage([
    "givenHealthCareProfessionalAdvice", // triage-1.0.4
    "reasonsForDetention", // triage-1.0.4.1.1
    "confirmMentalCapacityLack", // triage-1.0.4.1.2
    "voluntaryAction", // triage-1.0.4.1.3
    "voluntaryActionOtherDetails", // triage-1.0.4.1.3.1.1
    "otherAction", // triage-1.0.4.1.4.1
    "otherActionDetails", // triage-1.0.4.1.4.1.1
    "otherActionBanner", // triage-1.0.4.1.4.1.2
  ])
  .withPage([
    "whatWillYouDo", // triage-1.0.2
    "whatWillYouDoOther", // triage-1.0.2.1.1
  ])
  .build();

export type TriageIncidentThalamosMax01FormData =
  typeof formSpecification.formType;

const triageIncidentThalamosMax01: FormDefinition<
  [
    FormSectionDefinition<
      FormSection.triageMain,
      TriageIncidentThalamosMax01FormData
    >,
  ]
> = {
  sections: [
    {
      id: FormSection.triageMain,
      autosave: true,
      strictFieldSchemas: formSpecification.strictFieldSchemas,
      formSchema: formSpecification.formSchema,
      name: "triageIncident.title",
      pages: formSpecification.pages,
    },
  ],
};

export default triageIncidentThalamosMax01;
