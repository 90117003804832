import {
  type GetPlacesOfSafetyV1Response,
  type GetPlacesOfSafetyV1ResponseBranded,
  GetPlacesOfSafetyV1ResponseSchema,
} from "@thalamos/common";
import { type AxiosInstance } from "axios";

export type GetPlacesOfSafetyResponse = GetPlacesOfSafetyV1Response;
export const GetPlacesOfSafetyResponseSchema =
  GetPlacesOfSafetyV1ResponseSchema;
export type GetPlacesOfSafetyResponseBranded =
  GetPlacesOfSafetyV1ResponseBranded;

///

export const config = {
  placeOfSafety: {
    getAll: () => ({
      url: `/api/place-of-safety/`,
      method: "get",
    }),
  },
};

export const placeOfSafetyRequests = (apiAxios: AxiosInstance) => {
  return {
    placeOfSafety: {
      getAll: () =>
        apiAxios.request<GetPlacesOfSafetyResponseBranded>(
          config.placeOfSafety.getAll(),
        ),
    },
  };
};
