import { UserSchema } from "../../types/index.js";
import { btpRootOrg, parentOrg, rootOrg } from "./organisations.js";

export const joeBloggs = UserSchema.parse({
  id: "cf5bf028-8cd7-412b-aac6-8bae367b186f",
  name: "Joe Bloggs",
  email: "joe@bloggs.com",
  shoulderNumber: "123456",
});

export const janeDoe = UserSchema.parse({
  id: "19354453-879c-46a7-bcf6-ecb81660d19a",
  name: "Jane Doe",
  email: "jane@doe.com",
  shoulderNumber: "789012",
});

export const bobBtp = UserSchema.parse({
  id: "495d5692-a96d-4ce8-995c-25b340177271",
  name: "Bob BTP",
  email: "bob_officer@btp.police.uk",
  shoulderNumber: "999999",
});

export const charlieBtp = UserSchema.parse({
  id: "8e395c5b-29f7-433d-b73f-2c38213d7941",
  name: "Charlie BTP",
  email: "btpofficer@btp.police.uk",
  shoulderNumber: "888888",
});

export const users = [joeBloggs, janeDoe, bobBtp, charlieBtp];

export const joeBloggsOrganisationMembership = {
  organisationId: rootOrg.id,
  userId: joeBloggs.id,
  role: "officer",
  isOnboarded: true,
};

export const janeDoeOrganisationMembership = {
  organisationId: parentOrg.id,
  userId: janeDoe.id,
  role: "officer",
  isOnboarded: true,
};

export const bobOrganisationMembership = {
  organisationId: btpRootOrg.id,
  userId: bobBtp.id,
  role: "officer",
  isOnboarded: true,
};

export const charlieOrganisationMembership = {
  organisationId: btpRootOrg.id,
  userId: charlieBtp.id,
  role: "officer",
  isOnboarded: true,
};

export const userOrganisationMemberships = [
  joeBloggsOrganisationMembership,
  janeDoeOrganisationMembership,
  bobOrganisationMembership,
  charlieOrganisationMembership,
];
