import { Info, InfoOutlined } from "@mui/icons-material/";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip as MUITooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  TooltipProps as MUITooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { TranslationKey } from "@vision/common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type TooltipProps = {
  fieldName: string;
  title: TranslationKey;
  content: TranslationKey;
};

const IconButtonCircle = ({ onClick }: { onClick: () => void }) => {
  return (
    <InfoOutlined
      data-testid="info-outlined"
      sx={{ color: "white" }}
      onClick={onClick}
    >
      <Info />
    </InfoOutlined>
  );
};

const LightTooltip = styled(({ className, ...props }: MUITooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#49454F",
    boxShadow: theme.shadows[1],
    fontWeight: 400,
    fontSize: 13,
    maxWidth: 200,
  },
}));

export const Tooltip = ({ fieldName, title, content }: TooltipProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const iconWrapperStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "text.primary",
    borderRadius: "50%",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    "&:hover": {
      backgroundColor: "text.secondary",
    },
  };

  return isMobile ? (
    <Box data-testid={`tooltip-${fieldName}-mobile`}>
      <Box sx={iconWrapperStyles}>
        <IconButtonCircle onClick={handleOpen} />
      </Box>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "0.875rem" }}>{t(title)}</DialogTitle>
        <DialogContent sx={{ fontSize: "0.875rem" }}>
          {t(content)}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            data-testid="popup-close-dialog"
            onClick={handleClose}
            color="primary"
          >
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : (
    <LightTooltip
      data-testid={`tooltip-${fieldName}-desktop`}
      title={
        <Box>
          <Box sx={{ fontWeight: "bold", mb: 1 }}>{t(title)}</Box>
          <Box>{t(content)}</Box>
        </Box>
      }
      placement="right-start"
    >
      <Box sx={iconWrapperStyles}>
        <InfoOutlined
          data-testid="info-outlined"
          sx={{ color: "white" }}
          onClick={handleOpen}
        />
      </Box>
    </LightTooltip>
  );
};
