/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/// <reference types="@types/google.maps" />
import { Location, LocationSchema } from "@vision/common";
import { debug as debugFn } from "debug";
import { useCallback, useRef } from "react";

const debug = debugFn("vision-frontend:useGoogleAutocomplete");

import {
  ReactGoogleAutocompleteProps,
  usePlacesWidget,
} from "react-google-autocomplete";

export const useGoogleAutocomplete = ({
  apiKey,
  onPlaceSelected,
}: Required<Pick<ReactGoogleAutocompleteProps, "apiKey">> & {
  onPlaceSelected: (location: Location) => void;
}) => {
  const handlePlaceSelected = useCallback(
    (place: google.maps.places.PlaceResult) => {
      debug("Google API provided", place);

      const latitude = place.geometry?.location?.lat();
      const longitude = place.geometry?.location?.lng();

      // We see instances where the place name is not included in the formatted address.
      // e.g. "Tate Modern"
      // In these cases, we want to include the name in the formatted address.
      // For residential addresses, the name is included in the formatted address.
      const formattedValue =
        place.name && place.formatted_address?.includes(place.name)
          ? place.formatted_address
          : [place.name, place.formatted_address]
              .filter((x) => x !== undefined)
              .join(", ");

      const parsedValue = LocationSchema.safeParse({
        primaryLocationType: "postalAddress",
        userProvided: {
          formattedValue,
        },
        postalAddress: (place.address_components ?? []).map((component) => ({
          shortName: component.short_name,
          longName: component.long_name,
          types: component.types,
        })),
        // Only include geoLocation if we have both latitude and longitude
        ...(latitude !== undefined &&
          longitude !== undefined && {
            geoLocation: {
              latitude,
              longitude,
            },
          }),
      });

      if (parsedValue.success) {
        debug("Parsed location", parsedValue.data);
        onPlaceSelected(parsedValue.data);
      } else {
        debug("Failed to parse location", parsedValue.error);
      }
    },
    [onPlaceSelected],
  );

  // Make sure we always have access to the most recent
  // version of the callback, which will have new bound
  // values courtesy of the hook dependencies.
  const handlePlaceSelectedRef = useRef(handlePlaceSelected);
  handlePlaceSelectedRef.current = handlePlaceSelected;

  const hookResult = usePlacesWidget({
    apiKey,
    // This usePlacesWidget never stores anything but the initial version
    // of this callback. It doesn't put it in any of it's hook dependencies.
    // To work around this, we use a second level of indirection through a
    // ref hook to ensure we always call with the latest bound dependencies.
    onPlaceSelected: (place) => {
      handlePlaceSelectedRef.current(place);
    },
    options: {
      // TIP: If you set this to `fields: ["ALL"]`, you can see the full set of values which could be returned.
      //      This costs money, so don't leave it on in production.
      // fields: ["ALL"],
      fields: [
        "name",
        "type",
        "address_components",
        "formatted_address",
        "geometry",
      ],
      // ASP-1574
      // This types array needs to be empty in order to be able to lookup
      // both residential and establishment (e.g. hospital) addresses.
      // TODO: Write a Component Test which validates it offers us:
      // * "London Bridge" suggests "London Bridge Pier" (a "residential" address)
      // * "Arrowe Park" suggests "Arrowe Park Hospital" (an "establishment" address)
      types: [],
      componentRestrictions: { country: ["uk"] },
    },
  });

  return hookResult;
};
