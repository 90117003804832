import { Box, Tab, Tabs } from "@mui/material";
import { ExtendedVisionUser } from "@vision/common";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrganisationManagement } from "../../components/Admin/Organisation/Organisation.js";
import { createRoute } from "../../routing/createRoute.js";

export type AdminPageProps = {
  user: ExtendedVisionUser;
};

export function AdminPage({ user }: AdminPageProps) {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const handleChange = (_event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  useEffect(() => {
    const isAdminUser = user?.isAdminSession;
    if (!isAdminUser) {
      navigate(createRoute.home());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={tab}
        onChange={handleChange}
      >
        <Tab label="Organisations" value={0} />
      </Tabs>
      <Box>{tab === 0 && <OrganisationManagement />}</Box>
    </Box>
  );
}
