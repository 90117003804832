import {
  CreateIncidentMinimumFields,
  CreateIncidentWorkItemRequest,
  CreateTransferRequestUnbranded,
  EndIncidentOrFinishForm,
  FormSection,
  GetIncidentWorkItemResponse,
  IncidentFormId,
  IncidentWorkItemId,
  IncidentWorkItemIdUnbranded,
  IncidentWorkItemStatus,
  IncidentWorkItemWithContext,
  notificationChannelFns,
  ResolveTransferRequestUnbranded,
  RevokeTransferRequestUnbranded,
  UserId,
} from "@vision/common";
import { AxiosInstance } from "axios";
import { usePusherChannelReload } from "../axios/api.js";

export const config = {
  incidentWorkItem: {
    get: (id: IncidentWorkItemId) => ({
      url: `/api/incident-work-item/${id}`,
      method: "get",
    }),
    getPdf: (id: IncidentWorkItemId) => ({
      url: `/api/incident-work-item/${id}/pdf`,
      method: "get",
    }),
    getMyActiveIncidentWorkItems: () => ({
      url: `/api/incident-work-item`,
      method: "get",
    }),
    create: (
      id: IncidentWorkItemId,
      creatIncidentWorkItemReq: CreateIncidentWorkItemRequest<CreateIncidentMinimumFields>,
    ) => ({
      url: `/api/incident-work-item/${id}`,
      method: "post",
      data: creatIncidentWorkItemReq,
    }),
    share: (id: IncidentWorkItemIdUnbranded, email: string) => ({
      url: `/api/incident-work-item/${id}/share`,
      method: "post",
      data: { email },
    }),
    updateData(
      id: IncidentWorkItemId,
      formId: IncidentFormId,
      section: FormSection,
      data: Record<string, unknown>,
      validateData: boolean,
      status?: IncidentWorkItemStatus,
    ) {
      return {
        url: `/api/incident-work-item/${id}/form/${formId}/section/${section}`,
        method: "post",
        data,
        params: { validateData, status },
      };
    },
    create136Form(id: IncidentWorkItemId) {
      return {
        url: `/api/incident-work-item/${id}/form/136`,
        method: "post",
      };
    },
    createPlaceOfSafetyForm(
      workItemId: IncidentWorkItemId,
      formId: IncidentFormId,
    ) {
      return {
        url: `/api/incident-work-item/${workItemId}/form/place-of-safety/${formId}`,
        method: "post",
      };
    },
    updateActivePlaceOfSafety(
      incidentWorkItemId: IncidentWorkItemId,
      placeOfSafetyFormId: IncidentFormId,
    ) {
      return {
        url: `/api/incident-work-item/${incidentWorkItemId}/active-place-of-safety`,
        method: "post",
        data: { formId: placeOfSafetyFormId },
      };
    },
    updateActiveOfficerRequest(
      incidentWorkItemId: IncidentWorkItemId,
      officerRequestFormId: IncidentFormId | null,
    ) {
      return {
        url: `/api/incident-work-item/${incidentWorkItemId}/active-officer-request`,
        method: "post",
        data: { formId: officerRequestFormId },
      };
    },
    createEndIncidentOrFinishForm(
      workItemId: IncidentWorkItemId,
      formId: IncidentFormId,
      formType: EndIncidentOrFinishForm,
    ) {
      return {
        url: `/api/incident-work-item/${workItemId}/form/end-incident/${formId}`,
        method: "post",
        data: { formType },
      };
    },
    createTransfer(createTransfer: CreateTransferRequestUnbranded) {
      return {
        url: `/api/transfer/create/`,
        method: "post",
        data: createTransfer,
      };
    },
    resolveTransfer(resolveTransfer: ResolveTransferRequestUnbranded) {
      return {
        url: `/api/transfer/resolve`,
        method: "post",
        data: resolveTransfer,
      };
    },
    revokeTransfer(incidentWorkItemId: RevokeTransferRequestUnbranded) {
      return {
        url: `/api/transfer/revoke`,
        method: "post",
        data: incidentWorkItemId,
      };
    },
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    incidentWorkItem: {
      get: (id: IncidentWorkItemId) =>
        apiAxios.request<IncidentWorkItemWithContext | null>(
          config.incidentWorkItem.get(id),
        ),
      getPdf: (id: IncidentWorkItemId) =>
        apiAxios.request<string>(config.incidentWorkItem.getPdf(id)),

      getMyActiveIncidentWorkItems: () =>
        apiAxios.request<GetIncidentWorkItemResponse>(
          config.incidentWorkItem.getMyActiveIncidentWorkItems(),
        ),

      create: (
        id: IncidentWorkItemId,
        creatIncidentWorkItemReq: CreateIncidentWorkItemRequest<CreateIncidentMinimumFields>,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.create(id, creatIncidentWorkItemReq),
        ),

      share: (id: IncidentWorkItemIdUnbranded, email: string) =>
        apiAxios.request(config.incidentWorkItem.share(id, email)),

      updateData: (
        id: IncidentWorkItemId,
        formId: IncidentFormId,
        section: FormSection,
        data: Record<string, unknown>,
        validateData: boolean,
        status?: IncidentWorkItemStatus,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.updateData(
            id,
            formId,
            section,
            data,
            validateData,
            status,
          ),
        ),

      create136Form: (id: IncidentWorkItemId) =>
        apiAxios.request(config.incidentWorkItem.create136Form(id)),

      createPlaceOfSafetyForm: (
        workItemId: IncidentWorkItemId,
        formId: IncidentFormId,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.createPlaceOfSafetyForm(workItemId, formId),
        ),

      updateActivePlaceOfSafety: (
        incidentWorkItemId: IncidentWorkItemId,
        placeOfSafetyFormId: IncidentFormId,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.updateActivePlaceOfSafety(
            incidentWorkItemId,
            placeOfSafetyFormId,
          ),
        ),

      updateActiveOfficerRequest: (
        incidentWorkItemId: IncidentWorkItemId,
        officerRequestFormId: IncidentFormId | null,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.updateActiveOfficerRequest(
            incidentWorkItemId,
            officerRequestFormId,
          ),
        ),

      createEndIncidentOrFinishForm: (
        workItemId: IncidentWorkItemId,
        formId: IncidentFormId,
        formType: EndIncidentOrFinishForm,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.createEndIncidentOrFinishForm(
            workItemId,
            formId,
            formType,
          ),
        ),

      createTransfer(createTransfer: CreateTransferRequestUnbranded) {
        return apiAxios.request(
          config.incidentWorkItem.createTransfer(createTransfer),
        );
      },
      resolveTransfer: (resolveTransfer: ResolveTransferRequestUnbranded) => {
        return apiAxios.request(
          config.incidentWorkItem.resolveTransfer(resolveTransfer),
        );
      },

      revokeTransfer: (incidentWorkItemId: RevokeTransferRequestUnbranded) => {
        return apiAxios.request(
          config.incidentWorkItem.revokeTransfer(incidentWorkItemId),
        );
      },
    },
  };
};

export const apiHooks = () => {
  return {
    incidentWorkItem: {
      get: (id: IncidentWorkItemId) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return usePusherChannelReload<IncidentWorkItemWithContext | undefined>(
          notificationChannelFns.vision.police(id),
          config.incidentWorkItem.get(id),
        );
      },
      getMyActiveIncidentWorkItems: function useGetMyActiveIncidentWorkItem(
        userId: UserId | undefined,
      ) {
        return usePusherChannelReload<GetIncidentWorkItemResponse | undefined>(
          notificationChannelFns.user.notifications(userId ?? ""),
          config.incidentWorkItem.getMyActiveIncidentWorkItems(),
        );
      },
      getPdf: (id: IncidentWorkItemId) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return usePusherChannelReload<string | null>(
          notificationChannelFns.vision.police(id),
          config.incidentWorkItem.getPdf(id),
        );
      },
    },
  };
};
