import { z } from "zod";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  commandUnitSchema,
  dateTimeSchema,
  freeTextSchema,
  LocationSchema,
  OrganisationRoles,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "custodyNumber", // 136-detention-1.0
    type: "textbox",
    ...new FieldBuilder().withNullableSchema(freeTextSchema).build(),
    label: "detentionDetails.custodyNumber",
  })
  .withFieldAlwaysVisible({
    fieldName: "dateTimeOfDetention", // 136-detention-2.0
    type: "date-time",
    timeLabel: "detentionDetails.timeOfDetention",
    dateLabel: "detentionDetails.dateOfDetention",
    ...new FieldBuilder().withSchema(dateTimeSchema).build(),
  })
  .withFieldAlwaysVisible({
    fieldName: "orgResponsibleForDetention", // 136-detention-3.0
    type: "organisation",
    ...new FieldBuilder()
      .withSchema(commandUnitSchema)
      .captureValueLabel()
      .build(),
    filterByRoles: [OrganisationRoles.PoliceCommandUnit],
    label: "detentionDetails.responsibleForDetention",
  })
  .withFieldAlwaysVisible({
    fieldName: "locationDetentionTookPlace", // 136-detention-3.0.1
    type: "location",
    ...new FieldBuilder().withSchema(LocationSchema).build(),
    label: "detentionDetails.locationDetentionTookPlace",
  })
  .withFieldAlwaysVisible({
    fieldName: "locationType", // 136-detention-3.0.1.1
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(
        z.enum([
          "onTrain" as const,
          "concourse" as const,
          "platform" as const,
          "stationToilet" as const,
          "stationForecourt" as const,
          "carPark" as const,
          "onTracksAtStation" as const,
          "onTracksAwayFromStation" as const,
          "lineside" as const,
          "bridge" as const,
          "levelCrossing" as const,
          "disusedRailwayOrStation" as const,
          "tunnel" as const,
          "depot" as const,
          "otherRailwayInfrastructure" as const,
          "offBtpJurisdiction" as const,
          "btpOffice" as const,
          "ticketBarriers" as const,
          "hotelWithinRailwayEnvironment" as const,
          "publicHouse" as const,
          "other" as const,
        ]),
      )
      .captureValueLabel()
      .build(),
    label: "detentionDetails.locationType.title",
    options: [
      {
        value: "onTrain",
        label: "detentionDetails.locationType.onTrain",
      },
      {
        value: "concourse",
        label: "detentionDetails.locationType.concourse",
      },
      {
        value: "platform",
        label: "detentionDetails.locationType.platform",
      },
      {
        value: "stationToilet",
        label: "detentionDetails.locationType.stationToilet",
      },
      {
        value: "stationForecourt",
        label: "detentionDetails.locationType.stationForecourt",
      },
      {
        value: "carPark",
        label: "detentionDetails.locationType.carPark",
      },
      {
        value: "onTracksAtStation",
        label: "detentionDetails.locationType.onTracksAtStation",
      },
      {
        value: "onTracksAwayFromStation",
        label: "detentionDetails.locationType.onTracksAwayFromStation",
      },
      {
        value: "lineside",
        label: "detentionDetails.locationType.lineside",
      },
      {
        value: "bridge",
        label: "detentionDetails.locationType.bridge",
      },
      {
        value: "levelCrossing",
        label: "detentionDetails.locationType.levelCrossing",
      },
      {
        value: "disusedRailwayOrStation",
        label: "detentionDetails.locationType.disusedRailwayOrStation",
      },
      {
        value: "tunnel",
        label: "detentionDetails.locationType.tunnel",
      },
      {
        value: "depot",
        label: "detentionDetails.locationType.depot",
      },
      {
        value: "otherRailwayInfrastructure",
        label: "detentionDetails.locationType.otherRailwayInfrastructure",
      },
      {
        value: "offBtpJurisdiction",
        label: "detentionDetails.locationType.offBtpJurisdiction",
      },
      {
        value: "btpOffice",
        label: "detentionDetails.locationType.btpOffice",
      },
      {
        value: "ticketBarriers",
        label: "detentionDetails.locationType.ticketBarriers",
      },
      {
        value: "hotelWithinRailwayEnvironment",
        label: "detentionDetails.locationType.hotelWithinRailwayEnvironment",
      },
      {
        value: "publicHouse",
        label: "detentionDetails.locationType.publicHouse",
      },
      { value: "other", label: "detentionDetails.locationType.other" },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "locationTypeOther", // 136-detention-3.0.1.1.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.locationType?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withPage([
    "custodyNumber", // 136-detention-1.0
  ])
  .withPage([
    "dateTimeOfDetention", // 136-detention-2.0
  ])
  .withPage([
    "orgResponsibleForDetention", // 136-detention-3.0
  ])
  .withPage([
    "locationDetentionTookPlace", // 136-detention-3.0.1
  ])
  .withPage([
    "locationType", // 136-detention-3.0.1.1
    "locationTypeOther", // 136-detention-3.0.1.1.1.1
  ])
  .build();

export type DetentionDetailsFormData = typeof formSpecification.formType;
