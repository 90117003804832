/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { match, P } from "ts-pattern";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  dateSchema,
  ethnicitySchema,
  forenameSchema,
  freeTextSchema,
  genderSchema,
  LocationSchema,
  selfDeclaredEthnicitySchema,
  surnameSchema,
  yesNoSchema,
  yesOnlySchema,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "personForename", // 136-person-1.0
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(forenameSchema).build(),
    label: "createIncident.personForename",
  })
  .withFieldAlwaysVisible({
    fieldName: "personSurname", // 136-person-1.1
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(surnameSchema).build(),
    label: "createIncident.personSurname",
  })
  .withFieldAlwaysVisible({
    fieldName: "personAddress", // 136-person-2.0
    type: "location",
    ...new FieldBuilder().withUnknowableSchema(LocationSchema).build(),
    label: "personDetails.personAddress",
  })
  .withFieldConditionallyVisible({
    fieldName: "confirmPersonAddress", // 136-person-2.0.1
    type: "checkbox",
    ...new FieldBuilder().withSchema(yesOnlySchema).captureValueLabel().build(),
    label: "personDetails.confirmPersonAddress",
    isVisible: ({ formValues }) =>
      match(formValues.personAddress)
        .with({ value: P._, isUnknown: true }, () => false)
        .with(undefined, { value: P._, isUnknown: false }, () => true)
        .exhaustive(),
  })
  .withFieldAlwaysVisible({
    fieldName: "dateOfBirth", // 136-person-3.0
    type: "date",
    label: "personDetails.dob",
    ...new FieldBuilder().withUnknowableSchema(dateSchema).build(),
  })
  .withFieldConditionallyVisible({
    fieldName: "satisfiedPersonIsOver18", // 136-person-3.0.1
    type: "radio",
    label: "personDetails.satisfiedPersonIsOver18",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
    isVisible: ({ formValues }) =>
      match(formValues.dateOfBirth)
        .with(undefined, { value: P._, isUnknown: false }, () => false)
        .with({ value: P._, isUnknown: true }, () => true)
        .exhaustive(),
  })
  .withFieldAlwaysVisible({
    fieldName: "gender", // 136-person-5.0
    type: "dropdown",
    ...new FieldBuilder().withSchema(genderSchema).captureValueLabel().build(),
    label: "personDetails.gender.title",
    options: [
      {
        label: "personDetails.gender.options.male",
        value: "male",
      },
      {
        label: "personDetails.gender.options.female",
        value: "female",
      },
      {
        label: "personDetails.gender.options.nonBinary",
        value: "nonBinary",
      },
      {
        label: "personDetails.gender.options.preferNotToSay",
        value: "preferNotToSay",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "ethnicAppearance", // 136-person-6.0
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(ethnicitySchema)
      .captureValueLabel()
      .build(),
    label: "personDetails.ethnicity.title",
    options: [
      {
        label: "personDetails.ethnicity.options.IC1",
        value: "IC1",
      },
      {
        label: "personDetails.ethnicity.options.IC3",
        value: "IC3",
      },
      {
        label: "personDetails.ethnicity.options.IC4",
        value: "IC4",
      },
      {
        label: "personDetails.ethnicity.options.IC5",
        value: "IC5",
      },
      {
        label: "personDetails.ethnicity.options.IC6",
        value: "IC6",
      },
      {
        label: "personDetails.ethnicity.options.IC7",
        value: "IC7",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "selfDeclaredEthnicity", // 136-person-6.1.1
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(selfDeclaredEthnicitySchema)
      .captureValueLabel()
      .build(),
    label: "personDetails.selfDeclaredEthnicity.title",
    options: [
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteEnglish",
        value: "whiteEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteIrish",
        value: "whiteIrish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteGypsy",
        value: "whiteGypsy",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteRoma",
        value: "whiteRoma",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteOther",
        value: "whiteOther",
      },
      {
        label:
          "personDetails.selfDeclaredEthnicity.options.mixedBlackCaribbean",
        value: "mixedBlackCaribbean",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedBlackAfrican",
        value: "mixedBlackAfrican",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedAsian",
        value: "mixedAsian",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedOther",
        value: "mixedOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianEnglish",
        value: "asianEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianIndian",
        value: "asianIndian",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianPakistani",
        value: "asianPakistani",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianBangladeshi",
        value: "asianBangladeshi",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianChinese",
        value: "asianChinese",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianOther",
        value: "asianOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackEnglish",
        value: "blackEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackAfrican",
        value: "blackAfrican",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackCaribbean",
        value: "blackCaribbean",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackOther",
        value: "blackOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.arab",
        value: "arab",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.other",
        value: "other",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.preferNotToSay",
        value: "preferNotToSay",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "selfDeclaredEthnicityOther", // 136-person-6.1.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.selfDeclaredEthnicity?.value === "other",
    label: "personDetails.selfDeclaredEthnicityOther",
  })
  .withFieldAlwaysVisible({
    fieldName: "hasRelativeOrFriend", // 136-person-10.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "personDetails.relativeOrFriend",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendForename", // 136-person-10.0.2
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(forenameSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendForename",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendSurname", // 136-person-10.0.3
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(surnameSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendSurname",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendRelationship", // 136-person-10.0.4
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendRelationship",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "unknownRelativeOrFriend", // 136-person-10.0.1
    type: "banner",
    bannerType: "info",
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "no",
    label: "personDetails.unknownRelativeOrFriend",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "displayPersonAddress",
    type: "typography",
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    textFn: ({ formValues, t }) =>
      formValues.personAddress.isUnknown
        ? [t("unknown")]
        : [formValues.personAddress.value?.userProvided.formattedValue].filter(
            (x) => x !== undefined,
          ),
    label: "personDetails.personAddress",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendSameAddress", // 136-person-10.0.5
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendSameAddress",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendAddress", // 136-person-10.0.5.2
    type: "location",
    ...new FieldBuilder().withUnknowableSchema(LocationSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.relativeOrFriendSameAddress?.value === "no",
    label: "personDetails.relativeOrFriendAddress",
  })
  .withReadonlyFieldConditionallyVisible({
    fieldName: "unknownRelativeOrFriendAddress",
    type: "banner",
    bannerType: "info",
    isVisible: ({ formValues }) =>
      match(formValues.relativeOrFriendAddress)
        .with({ value: P._, isUnknown: true }, () => true)
        .with({ value: P._, isUnknown: false }, () => false)
        .with(null, undefined, () => false)
        .exhaustive(),
    label: "personDetails.unknownRelativeOrFriendAddress",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendTelephoneNumber", // 136-person-10.0.6
    type: "textbox",
    ...new FieldBuilder().withUnknowableSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendTelephone",
    inputType: "tel",
  })
  .withFieldConditionallyVisible({
    fieldName: "relativeOrFriendInformed", // 136-person-10.0.7
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    isVisible: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendInformed",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonNotInformed", // 136-person-10.0.7.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.relativeOrFriendInformed?.value === "no",
    label: "personDetails.reasonNotInformed",
  })
  .withPage([
    "personForename", // 136-person-1.0
    "personSurname", // 136-person-1.1
  ])
  .withPage([
    "personAddress", // 136-person-2.0
    "confirmPersonAddress", // 136-person-2.0.1
  ])
  .withPage([
    "dateOfBirth", // 136-person-3.0
    "satisfiedPersonIsOver18", // 136-person-3.0.1
  ])
  .withPage([
    "gender", // 136-person-5.0
  ])
  .withPage([
    "ethnicAppearance", // 136-person-6.0
    "selfDeclaredEthnicity", // 136-person-6.1
    "selfDeclaredEthnicityOther", // 136-person-6.1.1.1
  ])
  .withPage([
    "hasRelativeOrFriend", // 136-person-10.0
    "relativeOrFriendForename", // 136-person-10.0.2
    "relativeOrFriendSurname", // 136-person-10.0.3
    "relativeOrFriendRelationship", // 136-person-10.0.4
    "unknownRelativeOrFriend", // 136-person-10.0.1
  ])
  .withPage([
    "displayPersonAddress",
    "relativeOrFriendSameAddress", // 136-person-10.0.5
    "relativeOrFriendAddress", // 136-person-10.0.5.2
    "unknownRelativeOrFriendAddress",
  ])
  .withPage([
    "relativeOrFriendTelephoneNumber", // 136-person-10.0.6
    "relativeOrFriendInformed", // 136-person-10.0.7
    "reasonNotInformed", // 136-person-10.0.7.1
  ])
  .build();

export type PersonDetailsFormData = typeof formSpecification.formType;
