import { defined } from "@thalamos/common";
import { ok, safeTry } from "neverthrow";
import { match, P } from "ts-pattern";
import {
  FormSection,
  IncidentFormType,
  IncidentWorkItemStatus,
  ParticipantRole,
  ParticipantStatus,
  type CreateIncidentMinimumFields,
  type IncidentFormUnbranded,
  type MinimumFieldsPerSection,
  type PersonDetailsMinimumFields,
} from "../types/index.js";
import type { ActionInput } from "./ActionInput.js";

const inActiveIncidentStatuses = [
  IncidentWorkItemStatus.ended,
  IncidentWorkItemStatus.inactive,
  IncidentWorkItemStatus.unknown,
];

export function incidentIsActive({ incidentWorkItem }: ActionInput): boolean {
  return !inActiveIncidentStatuses.includes(
    incidentWorkItem.incidentWorkItem.status,
  );
}

export function hasTriageForm({ incidentWorkItem }: ActionInput): boolean {
  const triageForm = incidentWorkItem.forms.find(
    (form) => form.formType === IncidentFormType.triage,
  );
  if (!triageForm) return false;

  const triageSection = triageForm.sections[0];
  if (!triageSection.data || Object.keys(triageSection.data).length === 0)
    return false;

  return true;
}

export function hasSection136Form({ incidentWorkItem }: ActionInput): boolean {
  return (
    incidentWorkItem.forms.find(
      (form) => form.formType === IncidentFormType.section136,
    ) !== undefined
  );
}

export function hasPlaceOfSafety({ incidentWorkItem }: ActionInput): boolean {
  return (
    incidentWorkItem.forms.find(
      (form) => form.formType === IncidentFormType.recordPlaceOfSafety,
    ) !== undefined
  );
}

export function userIsIncidentOwner({
  incidentWorkItem,
  user,
}: ActionInput): boolean {
  const ownerParticipant = incidentWorkItem.participants.find((participant) => {
    return participant.role === ParticipantRole.owner;
  });

  return ownerParticipant?.participantId === user.id;
}

export function hasArrivedAtActivePlaceOfSafety({
  incidentWorkItem,
}: ActionInput): boolean {
  const result = safeTry(function* () {
    const activePlaceOfSafetyFormId = yield* defined(
      incidentWorkItem.incidentWorkItem.activePlaceOfSafetyFormId,
    );

    const activePlaceOfSafetyForm = yield* defined(
      incidentWorkItem.forms.find(
        (form) =>
          form.formType === IncidentFormType.recordPlaceOfSafety &&
          form.id === activePlaceOfSafetyFormId,
      ),
    );

    const activePlaceOfSafetyFormSectionArrival = (yield* defined(
      activePlaceOfSafetyForm.sections.find((section) => {
        return section.id === FormSection.recordPlaceOfSafetyArrival;
      })?.data ?? null,
    )) as MinimumFieldsPerSection[FormSection.recordPlaceOfSafetyArrival];

    const arrivalCompleted =
      activePlaceOfSafetyFormSectionArrival.didNotArrive === null;

    return ok(arrivalCompleted);
  });

  return result.unwrapOr(false);
}

function section136SectionComplete(
  section136Form: IncidentFormUnbranded,
  sectionId: string,
): boolean {
  const section = section136Form.sections.find(
    (section) => section.id === sectionId,
  );
  return section ? section.data !== null : false;
}

export function section136FormCompleted(actionInput: ActionInput): boolean {
  if (!hasSection136Form(actionInput)) return false;

  const section136Form = actionInput.incidentWorkItem.forms.find(
    (form) => form.formType === IncidentFormType.section136,
  );

  const sectionIds = [
    FormSection.personDetails,
    FormSection.detentionDetails,
    FormSection.informationForHealthcare,
  ];

  return sectionIds.every((sectionId) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    section136SectionComplete(section136Form!, sectionId),
  );
}

export function incidentHasTransferRequest({
  incidentWorkItem,
}: ActionInput): boolean {
  const pendingTransfer = incidentWorkItem.participants.some((participant) => {
    return participant.status === ParticipantStatus.pendingTransfer;
  });
  return pendingTransfer;
}

export function getPatientName({
  incidentWorkItem,
}: Pick<ActionInput, "incidentWorkItem">): {
  fullName: string;
  forename?: string;
  surname?: string;
} {
  const createIncidentFormSection = (incidentWorkItem.forms
    .find((form) => form.formType === IncidentFormType.createIncident)
    ?.sections.find((section) => section.id === FormSection.createIncidentMain)
    ?.data ?? undefined) as CreateIncidentMinimumFields | undefined;

  const personDetailsFormSection = (incidentWorkItem.forms
    .find((form) => form.formType === IncidentFormType.section136)
    ?.sections.find((section) => section.id === FormSection.personDetails)
    ?.data ?? undefined) as PersonDetailsMinimumFields | undefined;

  const forename = match(
    personDetailsFormSection?.personForename ??
      createIncidentFormSection?.personForename,
  )
    .with({ value: P.string, isUnknown: false }, (forename) => forename.value)
    .otherwise(() => undefined);

  const surname = match(
    personDetailsFormSection?.personSurname ??
      createIncidentFormSection?.personSurname,
  )
    .with({ value: P.string, isUnknown: false }, (surname) => surname.value)
    .otherwise(() => undefined);

  const fullName = match([forename, surname])
    .with([undefined, undefined], ([_forename, _surname]) => "Unknown") // TODO: Can we get this from the translation library? Perhaps inject it into this function?
    .with([P.string, undefined], ([forename, _surname]) => forename)
    .with([undefined, P.string], ([_forename, surname]) => surname)
    .with([P.string, P.string], ([forename, surname]) =>
      [forename, surname].join(" "),
    )
    .exhaustive();

  return { fullName, forename, surname };
}

export function getCommandUnit({
  incidentWorkItem,
}: Pick<ActionInput, "incidentWorkItem">):
  | {
      id: string; // TODO: This should be a branded type
      name: string;
    }
  | undefined {
  const createIncidentFormSection = (incidentWorkItem.forms
    .find((form) => form.formType === IncidentFormType.createIncident)
    ?.sections.find((section) => section.id === FormSection.createIncidentMain)
    ?.data ?? undefined) as CreateIncidentMinimumFields | undefined;

  return createIncidentFormSection
    ? {
        id: createIncidentFormSection.commandUnit.value,
        name: createIncidentFormSection.commandUnit.valueLabel,
      }
    : undefined;
}
