/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export type VisionConfig = {
  backendUrl: string;
  environment: string;
  datadog: {
    applicationId: string;
    clientToken: string;
    disableTracing: boolean;
  };
  version: string;
  pusher: {
    cluster: string;
    clientKey: string;
    channelPrefix?: string;
  };
  googleMaps: {
    apiKey: string;
  };
};

export const config: VisionConfig = {
  environment: import.meta.env.VITE_ENVIRONMENT,
  backendUrl: import.meta.env.VITE_BACKEND_URL,
  version: import.meta.env.VITE_CODE_VERSION,
  datadog: {
    applicationId:
      import.meta.env.VITE_DATADOG_APPLICATION_ID ||
      "078efd4f-d245-4ec5-8a5f-1cb1ae35cb15",
    clientToken:
      import.meta.env.VITE_DATADOG_CLIENT_TOKEN ||
      "pube32cfb18d225feb73d4b9973adf6d838",
    disableTracing: import.meta.env.VITE_DATADOG_DISABLE_TRACING === "true",
  },
  pusher: {
    cluster: import.meta.env.VITE_PUSHER_CLUSTER || "eu",
    clientKey: import.meta.env.VITE_PUSHER_CLIENT_KEY || "f8d11e33083bd40bc95b",
    channelPrefix:
      (import.meta.env.VITE_PUSHER_CHANNEL_PREFIX?.length ?? 0) === 0
        ? undefined
        : import.meta.env.VITE_PUSHER_CHANNEL_PREFIX,
  },
  googleMaps: {
    apiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
  },
} satisfies VisionConfig;
