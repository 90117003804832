import React, { useMemo } from "react";

import {
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { CuratedPlaceOfSafetySchema } from "@thalamos/common";
import {
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  RecordPlaceOfSafetyArrivalMinimumFields,
  RecordPlaceOfSafetyLeaveMinimumFields,
  RecordPlaceOfSafetyMinimumFields,
} from "@vision/common";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiHooks } from "../../api/index.js";
import { Footer } from "../../components/FormBuilder/MultiPageForm/Footer.js";
import { PlacesOfSafetyContext } from "../../components/FormBuilder/PlaceOfSafetyPicker/PlaceOfSafetyContext.js";
import {
  PlacesOfSafety,
  PlacesOfSafetyProps,
} from "../../components/PlacesOfSafety/PlacesOfSafety.js";
import { FormWrapper } from "../../layout/FormWrapper.js";
import { Error as ErrorComponent } from "../../pages/Error/Error.js";
import { createRoute } from "../../routing/createRoute.js";

export type ReviewPlacesOfSafetyPageProps = Record<string, unknown>;

const extractPlacesOfSafety = (
  incidentWorkItem: IncidentWorkItemWithContext | undefined,
): PlacesOfSafetyProps["placesOfSafety"] => {
  if (incidentWorkItem === undefined) {
    return [];
  }

  // Get all of the places of safety forms
  const placesOfSafetyForms: PlacesOfSafetyProps["placesOfSafety"] =
    incidentWorkItem.forms
      .filter((form) => form.formType === "recordPlaceOfSafety")
      .sort(
        // TODO: This needs more thought
        // This is attempting to sort the places of safety into the order that they
        // were visited, but this may not map onto the order that the records were created.
        // We do not currently know the order in which they visited the places of safety IRL,
        // only the order in which the records were created. We may need to prompt for more
        // information from the end user.
        (a, b) => b.createdAt.valueOf() - a.createdAt.valueOf(),
      )
      .map((form) => {
        const mainSection: RecordPlaceOfSafetyMinimumFields | undefined =
          form.sections
            .filter(
              (section) =>
                section.id === "recordPlaceOfSafetyMain" &&
                section.data !== null,
            )
            .map((section) => section.data as RecordPlaceOfSafetyMinimumFields)
            .at(0);

        const arrivalSection:
          | RecordPlaceOfSafetyArrivalMinimumFields
          | undefined = form.sections
          .filter(
            (section) =>
              section.id === "recordPlaceOfSafetyArrival" &&
              section.data !== null,
          )
          .map(
            (section) =>
              section.data as RecordPlaceOfSafetyArrivalMinimumFields,
          )
          .at(0);

        const leaveSection: RecordPlaceOfSafetyLeaveMinimumFields | undefined =
          form.sections
            .filter(
              (section) =>
                section.id === "recordPlaceOfSafetyLeave" &&
                section.data !== null,
            )
            .map(
              (section) =>
                section.data as RecordPlaceOfSafetyLeaveMinimumFields,
            )
            .at(0);

        if (mainSection === undefined) {
          return undefined;
        }

        const state =
          leaveSection !== undefined
            ? "left"
            : arrivalSection?.didNotArrive?.value !== "yes"
              ? "arrived"
              : "created";

        return {
          formId: form.id,
          status: state,
          name: mainSection.placeOfSafety.name,
        };
      })
      .filter(
        (placeOfSafety) => placeOfSafety !== undefined,
      ) as PlacesOfSafetyProps["placesOfSafety"];

  return placesOfSafetyForms;
};

export function ReviewPlacesOfSafetyPage(
  _props: ReviewPlacesOfSafetyPageProps,
) {
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }
  const [
    {
      data: incidentWorkItem,
      loading: isIncidentLoading,
      error: incidentError,
    },
  ] = apiHooks.incidentWorkItem.get(incidentId.data);

  const [
    {
      data: placesOfSafety,
      loading: isPlacesOfSafetyLoading,
      error: placeOfSafetyError,
    },
  ] = apiHooks.placeOfSafety.getAll();

  if (incidentError || placeOfSafetyError) {
    <ErrorComponent />;
  }

  if (isIncidentLoading || isPlacesOfSafetyLoading) {
    return <CircularProgress />;
  }

  const placesList = placesOfSafety?.placesOfSafety ?? [];

  return (
    <PlacesOfSafetyContext.Provider
      value={{
        placesOfSafety: placesList
          .map((placeOfSafety) => {
            const result = CuratedPlaceOfSafetySchema.safeParse(placeOfSafety);
            return result.success ? result.data : undefined;
          })
          .filter((placeOfSafety) => placeOfSafety !== undefined),
      }}
    >
      {incidentWorkItem && <Inner incidentWorkItem={incidentWorkItem} />}
    </PlacesOfSafetyContext.Provider>
  );
}

export type InnerProps = {
  incidentWorkItem: IncidentWorkItemWithContext;
};

export function Inner({ incidentWorkItem }: InnerProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [addAnother, setAddAnother] = React.useState<boolean | null>(null);

  const placesOfSafety = useMemo(
    () => extractPlacesOfSafety(incidentWorkItem),
    [incidentWorkItem],
  );

  return (
    <FormWrapper>
      <Stack gap={2}>
        <PlacesOfSafety placesOfSafety={placesOfSafety} />
        <Divider />
        <FormControl>
          <FormLabel>{t("reviewPlacesOfSafety.didYouVisitMore")}</FormLabel>
          <RadioGroup
            value={addAnother}
            onChange={(event) => {
              setAddAnother(event.target.value === "true");
            }}
          >
            <FormControlLabel
              key={"yes"}
              value={true}
              control={<Radio />}
              label={t("common.yes")}
              data-testid={"radio-option-yes"}
            />
            <FormControlLabel
              key={"no"}
              value={false}
              control={<Radio />}
              label={t("common.no")}
              data-testid={"radio-option-no"}
            />
          </RadioGroup>
        </FormControl>
        <Divider />
        <Footer
          onNext={
            addAnother === null
              ? undefined
              : () => {
                  if (addAnother) {
                    navigate(
                      createRoute.incidentRecordRetrospectivePlaceOfSafetyPage(
                        incidentWorkItem.incidentWorkItem.id,
                      ),
                    );
                  } else {
                    navigate(
                      createRoute.incidentProgressPage(
                        incidentWorkItem.incidentWorkItem.id,
                      ),
                    );
                  }
                }
          }
          onCancel={() => {
            navigate(
              createRoute.incidentProgressPage(
                incidentWorkItem.incidentWorkItem.id,
              ),
            );
          }}
        />
      </Stack>
    </FormWrapper>
  );
}
