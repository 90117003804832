import {
  ExtendedVisionUser,
  SearchUsersResponse,
  UserId,
  UserUpdateRequestUnbranded,
} from "@vision/common";
import { AxiosInstance } from "axios";
import { useApiAxios } from "../axios/api.js";

export const config = {
  user: {
    me: () => ({
      url: `/api/user/me`,
      method: "get",
    }),
    update: (id: UserId, updateUserRequest: UserUpdateRequestUnbranded) => ({
      url: `/api/user/${id}/update`,
      method: "post",
      data: updateUserRequest,
    }),
    search: (query: string, limit: number, offset: number) => ({
      url: `/api/user/search`,
      method: "get",
      params: { query, limit, offset },
    }),
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    user: {
      me: () => apiAxios.request<ExtendedVisionUser>(config.user.me()),
      update: (id: UserId, updateUserRequest: UserUpdateRequestUnbranded) =>
        apiAxios.request(config.user.update(id, updateUserRequest)),
      search: (query: string, limit: number, offset: number) =>
        apiAxios.request<SearchUsersResponse>(
          config.user.search(query, limit, offset),
        ),
    },
  };
};

export const apiHooks = () => {
  return {
    user: {
      me: function useUserMe() {
        return useApiAxios<ExtendedVisionUser>(config.user.me());
      },
    },
  };
};
