import {
  createInitialValues,
  FormSection,
  workflows,
  WorkflowVersions,
} from "@vision/common";
import React, { useMemo } from "react";
import { z } from "zod";
import {
  MultiPageForm,
  MultiPageFormSkeleton,
} from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";
import { Error } from "../../pages/Error/Error.js";

type DetentionDetailsSchema<T extends WorkflowVersions> = z.infer<
  (typeof workflows)[T]["136"]["formDefinition"]["sections"][1]["formSchema"] // TODO pick by id
>;

///

export function DetentionDetailsSkeleton() {
  return <MultiPageFormSkeleton headerText={"detentionDetails.title"} />;
}

///

export type DetentionDetailsFormProps<T extends WorkflowVersions> = {
  onSubmit: (value: DetentionDetailsSchema<T>) => void;
  onCancel: () => void;
  workflowVersion: T;
  initialValues?: DetentionDetailsSchema<T>;
};

///

export function DetentionDetailsForm<T extends WorkflowVersions>(
  props: DetentionDetailsFormProps<T>,
) {
  const workflow = workflows[props.workflowVersion];
  const section = workflow[136].formDefinition.sections.find(
    (section) => section.id === FormSection.detentionDetails,
  );

  const formSchema = section?.formSchema;
  const strictFieldSchemas = section?.strictFieldSchemas;

  const initialValuesOrDefaults = useMemo(() => {
    if (!section || !strictFieldSchemas || !formSchema) {
      return {};
    } else {
      return {
        ...createInitialValues(strictFieldSchemas),
        ...props.initialValues,
      };
    }
  }, [props, strictFieldSchemas, section, formSchema]);

  if (!section || !formSchema || !strictFieldSchemas) {
    return <Error />;
  }

  return (
    <MultiPageForm<typeof formSchema>
      headerText={"detentionDetails.title"}
      schema={formSchema}
      strictFieldSchemas={strictFieldSchemas}
      initialValues={initialValuesOrDefaults}
      pages={workflow[136].formDefinition.sections[1].pages}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    />
  );
}
