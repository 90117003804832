import React from "react";

import {
  createInitialValues,
  OrganisationId,
  workflows,
  WorkflowVersions,
} from "@vision/common";
import { z } from "zod";
import { MultiPageForm } from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";

type WorkflowSchema<T extends WorkflowVersions> = z.infer<
  (typeof workflows)[T]["createIncident"]["formDefinition"]["sections"][0]["formSchema"]
>;

export type CreateIncidentWorkItemFormProps<T extends WorkflowVersions> = {
  onSubmit: (value: WorkflowSchema<T>) => void;
  onCancel: () => void;
  defaultCommandUnitOrganisationId: OrganisationId;
  workflowVersion: T;
};

export function CreateIncidentWorkItemForm<T extends WorkflowVersions>({
  onSubmit,
  onCancel,
  // TODO: Do we need this?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultCommandUnitOrganisationId,
  workflowVersion,
}: CreateIncidentWorkItemFormProps<T>) {
  const workflow = workflows[workflowVersion];
  const schema = workflow.createIncident.formDefinition.sections[0].formSchema;
  const strictFieldSchemas =
    workflow.createIncident.formDefinition.sections[0].strictFieldSchemas;

  return (
    <MultiPageForm<typeof schema>
      headerText={"createIncident.newIncidentForm"}
      schema={schema}
      strictFieldSchemas={strictFieldSchemas}
      initialValues={createInitialValues(strictFieldSchemas)}
      // This form only ever has one section
      pages={workflow.createIncident.formDefinition.sections[0].pages}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}
