import type { Prettify } from "@thalamos/common";
import z, { ZodType } from "zod";

/**
 * A free-text address entered by the user
 */
type FreeTextAddress = {
  formattedValue: string;
};

const FreeTextAddressSchema = z.object({
  formattedValue: z.string().min(1).max(255),
});

/**
 * A postal address designed around the values returned from the Google Maps API.
 * See: https://developers.google.com/maps/documentation/places/web-service/supported_types
 */
type PostalAddress = {
  shortName: string;
  longName: string;
  types: string[];
}[];

const PostalAddressSchema = z.array(
  z.object({
    shortName: z.string().max(255),
    longName: z.string().max(255),
    types: z.array(z.string().max(255)),
  }),
);

/**
 * A geo location designed around the values returned from the Google Maps API.
 */
type GeoLocation = {
  latitude: number;
  longitude: number;
};

const GeoLocationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

/**
 * The different type of locations which can be chosen.
 */
export type LocationTypes = {
  userProvided: FreeTextAddress;
  postalAddress: PostalAddress;
  geoLocation: GeoLocation;
};
export type LocationType = Prettify<keyof LocationTypes>;

export type LocationUnbranded = Prettify<
  | ({
      primaryLocationType: "userProvided";
    } & Required<Pick<LocationTypes, "userProvided">> &
      Partial<Omit<LocationTypes, "userProvided">>)
  | ({
      primaryLocationType: "postalAddress";
    } & Required<Pick<LocationTypes, "userProvided" | "postalAddress">> &
      Partial<Omit<LocationTypes, "userProvided" | "postalAddress">>)
  | ({
      primaryLocationType: "geoLocation";
    } & Required<Pick<LocationTypes, "userProvided" | "geoLocation">> &
      Partial<Omit<LocationTypes, "userProvided" | "geoLocation">>)
>;

export const LocationSchema = z
  .union([
    z.object({
      primaryLocationType: z.literal("userProvided"),
      userProvided: FreeTextAddressSchema,
      postalAddress: PostalAddressSchema.optional(),
      geoLocation: GeoLocationSchema.optional(),
    }),
    z.object({
      primaryLocationType: z.literal("postalAddress"),
      userProvided: FreeTextAddressSchema,
      postalAddress: PostalAddressSchema,
      geoLocation: GeoLocationSchema.optional(),
    }),
    z.object({
      primaryLocationType: z.literal("geoLocation"),
      userProvided: FreeTextAddressSchema,
      postalAddress: PostalAddressSchema.optional(),
      geoLocation: GeoLocationSchema,
    }),
  ])
  .brand(Symbol("Location")) satisfies ZodType<LocationUnbranded>;

export type Location = z.infer<typeof LocationSchema>;
