import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  commandUnitSchema,
  dateTimeSchema,
  freeTextSchema,
  LocationSchema,
  OrganisationRoles,
} from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "custodyNumber", // 136-detention-1.0
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "detentionDetails.custodyNumber",
  })
  .withFieldAlwaysVisible({
    fieldName: "dateTimeOfDetention", // 136-detention-2.0
    type: "date-time",
    timeLabel: "detentionDetails.timeOfDetention",
    dateLabel: "detentionDetails.dateOfDetention",
    ...new FieldBuilder().withSchema(dateTimeSchema).build(),
  })
  .withFieldAlwaysVisible({
    fieldName: "orgResponsibleForDetention", // 136-detention-3.0
    type: "organisation",
    ...new FieldBuilder()
      .withSchema(commandUnitSchema)
      .captureValueLabel()
      .build(),
    filterByRoles: [OrganisationRoles.PoliceCommandUnit],
    label: "detentionDetails.responsibleForDetention",
  })
  .withFieldAlwaysVisible({
    fieldName: "locationDetentionTookPlace", // 136-detention-3.0.1
    type: "location",
    ...new FieldBuilder().withSchema(LocationSchema).build(),
    label: "detentionDetails.locationDetentionTookPlace",
  })
  .withPage([
    "custodyNumber", // 136-detention-1.0
  ])
  .withPage([
    "dateTimeOfDetention", // 136-detention-2.0
  ])
  .withPage([
    "orgResponsibleForDetention", // 136-detention-3.0
  ])
  .withPage([
    "locationDetentionTookPlace", // 136-detention-3.0.1
  ])
  .build();

export type DetentionDetailsFormData = typeof formSpecification.formType;
