import {
  OrganisationConfigurationSchema,
  OrganisationRoles,
  OrganisationSchema,
  WorkflowVersions,
} from "../../types/index.js";

/*
This file is responsible for seeding organisations and their configurations into the database.
It sets up the organisational structure and configurations but does not handle user creation.
To add new users, you must do so through Auth0.
*/

const thalamosOrgId = "a4157eff-87da-4799-831d-755006e3e81d";
const rootOrgId = "b3046fee-87da-4799-831d-755006e3e81d";
const parentOrgId = "cbb8c1d8-ce1b-4e30-995c-00a0304a6160";
const childOrgId = "0378dfeb-c15c-4788-9be3-ac1c781ba118";

const btpRootOrgId = "2a26d5b3-7161-4f9a-9625-2c6edfa49b0a";
const btpRegionalOrgAId = "ae3a9ec2-f3fb-4f03-a5f8-25af02f86760";
const btpRegionalUnitAId = "b3b8c1d8-ce1b-4e30-995c-00a0304a6160";
const btpChildOrgId = "5d50780e-e358-44f1-aac3-a4ce3c9c0f95";
const btpChildOrgId2 = "0623cc7c-aa44-47b7-8b3c-30101b9f507a";

const maxPoliceForceId = "f0e9d8c7-b6a5-4321-0fed-cba987654321";
const maxRegionalUnitId = "cbb8c1d8-ce1b-4e30-995c-44a0304a9494";
const maxCommandUnitId = "cbb8c1d8-ce1b-4e30-995c-55a0304a0505";
const maxGeographicUnitId = "cbb8c1d8-ce1b-4e30-995c-66a0304a6066";

const minPoliceForceId = "a1f2c3d4-56b7-89e0-1234-56789abcdef0";
const minRegionalUnitId = "cbb8c1d8-ce1b-4e30-995c-99a0304a8080";
const minCommandUnitId = "cbb8c1d8-ce1b-4e30-995c-88a0304a7070";
const minGeographicUnitId = "cbb8c1d8-ce1b-4e30-995c-77a0304a6060";

const metRootOrgId = "b1b905ab-74b0-4452-8da1-0f0a5a10e306";

export const thalamosOrg = OrganisationSchema.parse({
  id: thalamosOrgId,
  name: "Thalamos",
  address: "Old Union Street Arches",
  postalCode: "NW1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.Admin,
  status: "active",
});

export const rootOrg = OrganisationSchema.parse({
  id: rootOrgId,
  name: "A Division",
  address: "25 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

export const parentOrg = OrganisationSchema.parse({
  id: parentOrgId,
  name: "B Division",
  address: "26 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: rootOrgId,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

export const btpRootOrg = OrganisationSchema.parse({
  id: btpRootOrgId,
  name: "British Transport Police",
  address: "10 Oxford Street London",
  postalCode: "NW11 9PP",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

export const childOrg = OrganisationSchema.parse({
  id: childOrgId,
  name: "C Division",
  address: "27 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: parentOrg.id,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

export const btpRegionalUnitA = OrganisationSchema.parse({
  id: btpRegionalUnitAId,
  name: "C Division",
  address: "11 Oxford Street London",
  postalCode: "NW11 9PP",
  managingOrganisationId: btpRootOrg.id,
  role: OrganisationRoles.PoliceRegionalUnit,
  status: "active",
});

export const btpChildOrg = OrganisationSchema.parse({
  id: btpRegionalOrgAId,
  name: "C Pennine",
  address: "11 Oxford Street London",
  postalCode: "NW11 9PP",
  managingOrganisationId: btpRegionalUnitA.id,
  role: OrganisationRoles.PoliceCommandUnit,
  status: "active",
});

export const btpGeographicUnitA = OrganisationSchema.parse({
  id: btpChildOrgId,
  name: "PI Liverpool",
  address: "12 Oxford Street Liverpool",
  postalCode: "NW11 9PP",
  managingOrganisationId: btpChildOrg.id,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

export const btpGeographicUnitB = OrganisationSchema.parse({
  id: btpChildOrgId2,
  name: "PM Manchester",
  address: "13 Oxford Street Manchester",
  postalCode: "NW11 9PP",
  managingOrganisationId: btpChildOrg.id,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

const metOrg = OrganisationSchema.parse({
  id: metRootOrgId,
  name: "Metropolitan Police",
  address: "100 Camden Road London",
  postalCode: "TE1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

// Max organisations
export const maxPoliceForce = OrganisationSchema.parse({
  id: maxPoliceForceId,
  name: "Max Police Force",
  address: "25 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

export const maxRegionalUnit = OrganisationSchema.parse({
  id: maxRegionalUnitId,
  name: "Max Regional Unit",
  address: "26 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: maxPoliceForce.id,
  role: OrganisationRoles.PoliceRegionalUnit,
  status: "active",
});

export const maxCommandUnit = OrganisationSchema.parse({
  id: maxCommandUnitId,
  name: "Max Command Unit",
  address: "27 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: maxRegionalUnit.id,
  role: OrganisationRoles.PoliceCommandUnit,
  status: "active",
});

export const maxGeographicUnit = OrganisationSchema.parse({
  id: maxGeographicUnitId,
  name: "Max Geographic Unit",
  address: "28 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: maxCommandUnit.id,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

// Min organisations
export const minPoliceForce = OrganisationSchema.parse({
  id: minPoliceForceId,
  name: "Min Police Force",
  address: "25 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

export const minRegionalUnit = OrganisationSchema.parse({
  id: minRegionalUnitId,
  name: "Min Regional Unit",
  address: "26 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: minPoliceForce.id,
  role: OrganisationRoles.PoliceRegionalUnit,
  status: "active",
});

export const minCommandUnit = OrganisationSchema.parse({
  id: minCommandUnitId,
  name: "Min Command Unit",
  address: "27 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: minRegionalUnit.id,
  role: OrganisationRoles.PoliceCommandUnit,
  status: "active",
});

export const minGeographicUnit = OrganisationSchema.parse({
  id: minGeographicUnitId,
  name: "Min Geographic Unit",
  address: "28 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: minCommandUnit.id,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

// Organisation configurations
const rootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: rootOrgId,
  configuration: {
    language: "en",
    associatedEmailDomains: ["vision.thalamos.co.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

const minOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: minPoliceForceId,
  configuration: {
    language: "en",
    associatedEmailDomains: ["vision.min.thalamos.co.uk"],
    workflowVersion: WorkflowVersions.ThalamosMin01,
  },
});

const maxOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: maxPoliceForceId,
  configuration: {
    language: "en",
    associatedEmailDomains: ["vision.max.thalamos.co.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

const btpRootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: btpRootOrgId,
  configuration: {
    language: "enBtp",
    associatedEmailDomains: ["btp.police.uk"],
    workflowVersion: WorkflowVersions.BTP_1_0,
  },
});

const metRootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: metRootOrgId,
  configuration: {
    language: "enMet",
    associatedEmailDomains: ["met.police.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

const thalamosRootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: thalamosOrgId,
  configuration: {
    language: "en",
    associatedEmailDomains: ["thalamos.co.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

export const organisations = [
  rootOrg,
  parentOrg,
  childOrg,
  thalamosOrg,

  // BTP workflow
  btpRootOrg,
  btpRegionalUnitA,
  btpChildOrg,
  btpGeographicUnitA,
  btpGeographicUnitB,

  // Min workflow
  minPoliceForce,
  minRegionalUnit,
  minCommandUnit,
  minGeographicUnit,

  // Max workflow
  maxPoliceForce,
  maxRegionalUnit,
  maxCommandUnit,
  maxGeographicUnit,

  // Met workflow
  metOrg,
];

export const organisationConfigurations = [
  rootOrgConfiguration,
  btpRootOrgConfiguration,
  metRootOrgConfiguration,
  thalamosRootOrgConfiguration,
  minOrgConfiguration,
  maxOrgConfiguration,
];
