import { Box } from "@mui/material";
import {
  PolicePresenceRequestedBody,
  ThalamosNotification,
} from "@vision/common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PolicePresenceRequestedModal } from "../../../modals/PolicePresenceRequested/PolicePresenceRequested.js";
import ActionBanner, {
  ActionBannerList,
} from "../../ActionBanner/ActionBanner.js";

interface PolicePresenceRequestedNotificationProps {
  notification: ThalamosNotification;
  close: () => void;
}

export const PolicePresenceRequestedNotification = ({
  notification,
  close,
}: PolicePresenceRequestedNotificationProps) => {
  const { t } = useTranslation();

  const [
    isPolicePresenceRequestedModalOpen,
    setIsPolicePresenceRequestedModalOpen,
  ] = useState(true);
  const notificationText = notification.text as PolicePresenceRequestedBody;

  const handleClosePolicePresenceRequestedModal = () => {
    setIsPolicePresenceRequestedModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          padding: ".5rem",
        }}
      >
        <ActionBanner
          title={t("policePresenceNotification.requested")}
          bannerType={ActionBannerList.WARNING}
          onClick={() => {
            setIsPolicePresenceRequestedModalOpen(true);
          }}
        />
      </Box>
      {isPolicePresenceRequestedModalOpen && (
        <PolicePresenceRequestedModal
          open={isPolicePresenceRequestedModalOpen}
          close={handleClosePolicePresenceRequestedModal}
          closeNotification={close}
          posName={notificationText.posName}
          requesterName={notificationText.requesterName}
          reason={notificationText.reason}
          reasonDescription={notificationText.reasonDescription}
          incidentId={notificationText.incidentWorkItemId}
          notificationId={notification.id}
        />
      )}
    </>
  );
};
