import { Box, Stack } from "@mui/material";
import { ExtendedVisionUser } from "@vision/common";
import React from "react";
import { Outlet } from "react-router-dom";
import { NotificationProvider } from "../../Context/NotificationContext.js";
import { UserContextType } from "../../Context/UserContext.js";
import { AppDrawer } from "../AppDrawer/AppDrawer.js";
import { Notifications } from "../Notifications/Notifications.js";

export const MainLayout = ({
  user,
  refetchUser,
}: {
  user: ExtendedVisionUser;
  refetchUser: UserContextType["refetchUser"];
}) => {
  return (
    <Stack>
      <AppDrawer user={user}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <NotificationProvider userId={user.id}>
            <Notifications user={user} refetchUser={refetchUser} />
            <Outlet />
          </NotificationProvider>
        </Box>
      </AppDrawer>
    </Stack>
  );
};
