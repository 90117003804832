import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import { freeTextSchema, yesNoSchema } from "../../types/index.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "incidentDescription", // 136-healthinfo-1.0
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    label: "informationForHealthcare.incidentDescription",
    multiline: true,
    rows: "5",
  })
  .withFieldAlwaysVisible({
    fieldName: "wasHealthcareProvided", // 136-healthinfo-2.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "informationForHealthcare.wasHealthcareProvided",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withReadonlyFieldAlwaysVisible({
    fieldName: "healthcareBanner", // 136-healthinfo-2.0.2
    type: "banner",
    label: "informationForHealthcare.healthcareBanner",
    bannerType: "warning",
  })
  .withFieldConditionallyVisible({
    fieldName: "medicalAttentionProvided", // 136-healthinfo-2.0.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.wasHealthcareProvided?.value === "yes",
    label: "informationForHealthcare.medicalAttentionProvided",
  })
  .withFieldAlwaysVisible({
    fieldName: "hasPersonBeenSearched", // 136-healthinfo-4.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "informationForHealthcare.hasPersonBeenSearched",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldAlwaysVisible({
    fieldName: "hasAdditionalRiskFactors", // 136-healthinfo-7.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "informationForHealthcare.hasAdditionalRiskFactors",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "describeRiskFactorsOther", // 136-healthinfo-7.0.1.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.hasAdditionalRiskFactors?.value === "yes",
    label: "informationForHealthcare.describeRiskFactors.title",
  })
  .withPage([
    "incidentDescription", // 136-healthinfo-1.0
  ])
  .withPage([
    "wasHealthcareProvided", // 136-healthinfo-2.0
    "healthcareBanner", // 136-healthinfo-2.0.2
    "medicalAttentionProvided", // 136-healthinfo-2.0.1
  ])
  .withPage([
    "hasPersonBeenSearched", // 136-healthinfo-4.0
  ])
  .withPage([
    "hasAdditionalRiskFactors", // 136-healthinfo-7.0
    "describeRiskFactorsOther", // 136-healthinfo-7.0.1.1.1
  ])
  .build();

export type InformationForHealthcareFormData =
  typeof formSpecification.formType;
