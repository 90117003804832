import { Done, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { defined, parsed } from "@thalamos/common";
import {
  calculateActions,
  defaultActions,
  ExtendedVisionUser,
  FormSection,
  getPatientName,
  IncidentFormId,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentType,
  IncidentWorkItemActionsObject,
  IncidentWorkItemId,
  IncidentWorkItemStatus,
  IncidentWorkItemWithContext,
  NotificationBodyType,
  PolicePresenceRequestedBody,
  RecordPlaceOfSafetyMinimumFields,
  TranslationKey,
  workflows,
} from "@vision/common";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { ok, safeTry } from "neverthrow";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api, { apiHooks } from "../../api/index.js";
import IncidentBanner from "../../components/IncidentBanner/IncidentBanner.js";
import { TypographyI18N } from "../../components/index.js";
import { useNotifications } from "../../Context/NotificationContext.js";
import { useUser } from "../../Context/UserContext.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { InitiateTransferModal } from "../../modals/InitiateTransfer/InitiateTransfer.js";
import { PolicePresenceRequestedModal } from "../../modals/PolicePresenceRequested/PolicePresenceRequested.js";
import { createRoute } from "../../routing/createRoute.js";
import { Error as ErrorComponent } from "../Error/Error.js";
import { ShareModal } from "./ShareModal.js";

type StepData = {
  label: TranslationKey;
  completed: boolean;
  onClick?: () => void;
};

type FormProgressProps = {
  incidentWorkItemId?: IncidentWorkItemId;
  steps: StepData[];
  activePlaceOfSafetyName: string;
  activePlaceOfSafetyFormId?: IncidentFormId;
  arrivalUrl?: string;
  actions: IncidentWorkItemActionsObject;
  refetchWorkItem: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
};

const FormProgressSteps = ({ steps }: { steps: StepData[] }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mx: { xs: 0, sm: 10 }, pt: 2 }}>
      <Stepper orientation="vertical" nonLinear>
        {steps.map((step, index) => (
          <Step key={index} active={true} completed={step.completed}>
            <StepLabel
              icon={
                step.completed ? (
                  <Done color="success" />
                ) : (
                  <RadioButtonUncheckedOutlined color="action" />
                )
              }
              StepIconProps={{
                sx: {
                  color: step.completed
                    ? "success.main"
                    : theme.palette.common.black,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TypographyI18N
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "0.8125rem", sm: "1rem" },
                    color: theme.palette.common.black,
                  }}
                  translationKey={step.label}
                />
                <TypographyI18N
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: "0.8125rem",
                  }}
                  data-testid={`action-button-${index.toString()}`}
                  onClick={step.onClick}
                  translationKey={
                    step.completed
                      ? "formProgressPage.view"
                      : "formProgressPage.add"
                  }
                />
              </Box>
              <Divider />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export const getIncidentStatus = (
  incidentWorkItem: IncidentWorkItemWithContext,
  t: TFunction,
  actions: IncidentWorkItemActionsObject,
) => {
  if (
    incidentWorkItem.incidentWorkItem.status ===
    IncidentWorkItemStatus.awaitingArrival
  ) {
    return t("formProgressPage.statuses.awaitingArrival");
  }
  if (
    incidentWorkItem.incidentWorkItem.status ===
    IncidentWorkItemStatus.arrivedAtPlaceOfSafety
  ) {
    return t("formProgressPage.statuses.arrivedAtPlaceOfSafety");
  }

  if (
    incidentWorkItem.incidentWorkItem.status ===
    IncidentWorkItemStatus.formShared
  ) {
    return t("formProgressPage.statuses.formShared");
  }

  if (
    incidentWorkItem.incidentWorkItem.status ===
    IncidentWorkItemStatus.nhsFormAccepted
  ) {
    return t("formProgressPage.statuses.formAccessed");
  }

  if (
    incidentWorkItem.incidentWorkItem.status ===
    IncidentWorkItemStatus.policePresenceRequested
  ) {
    return t("formProgressPage.statuses.policePresenceRequested");
  }

  if (
    incidentWorkItem.incidentWorkItem.status ===
    IncidentWorkItemStatus.policePresenceNotRequested
  ) {
    return t("formProgressPage.statuses.policePresenceNotRequested");
  }

  if (actions.section136.canStart)
    return t("formProgressPage.statuses.s136InProgress");

  return incidentWorkItem.incidentWorkItem.status;
};

export const extractIncidentDetails = (
  incidentWorkItem: IncidentWorkItemWithContext | undefined,
  t: TFunction,
  actions: IncidentWorkItemActionsObject,
) => {
  if (incidentWorkItem === undefined) {
    return { fullName: t("unknown"), incidentStatus: t("unknown") };
  }

  const { fullName } = getPatientName({ incidentWorkItem });

  const incidentStatus = getIncidentStatus(incidentWorkItem, t, actions);

  return { fullName, incidentStatus };
};

export function FormProgressInner({
  user,
  incidentWorkItem,
  refetchWorkItem,
}: {
  user: ExtendedVisionUser | undefined;
  incidentWorkItem: IncidentWorkItemWithContext | undefined;
  refetchWorkItem: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isOwner = incidentWorkItem?.participants.some(
    (p) =>
      p.participantId === user?.id &&
      p.role === "owner" &&
      (p.status === "active" || p.status === "requested-transfer"),
  );

  React.useEffect(() => {
    if (incidentWorkItem && user && !isOwner) {
      navigate(
        createRoute.incidentPdfPage(incidentWorkItem.incidentWorkItem.id),
        { replace: true },
      );
    }
  }, [user, incidentWorkItem, isOwner, navigate]);

  if ([user, incidentWorkItem].some((x) => x === undefined)) {
    return <CircularProgress />;
  }

  const actions =
    incidentWorkItem && user
      ? calculateActions({ incidentWorkItem, user, asOfDateTime: dayjs() })
      : defaultActions;

  const { fullName, incidentStatus } = extractIncidentDetails(
    incidentWorkItem,
    t,
    actions,
  );

  const currentWorkFlowVersion =
    incidentWorkItem?.incidentWorkItem.workflowVersion;

  const currentWorkItemForm = (incidentWorkItem?.forms ?? []).find(
    (form) =>
      form.incidentWorkItemId === incidentWorkItem?.incidentWorkItem.id &&
      form.formDefinitionVersion === currentWorkFlowVersion &&
      form.status === "active",
  );

  if (currentWorkItemForm === undefined) {
    return <ErrorComponent />;
  }

  const loadWorkFlow = workflows[currentWorkItemForm.formDefinitionVersion];

  // TODO - we need to dynamically load the current workflow based on what flow we have chosen from the triage form
  const loadCurrentWorkFlow =
    loadWorkFlow[IncidentType.section136].formDefinition ?? {};
  const triageWorkFlow =
    loadWorkFlow[IncidentFormType.triage].formDefinition ?? {};
  const createIncidentWorkFlow =
    loadWorkFlow[IncidentFormType.createIncident].formDefinition ?? {};

  // Merge createIncidentWorkFlow and triageWorkFlow sections with a `+` between their names
  const mergedCreateAndTriageSections = (() => {
    const triageSections = triageWorkFlow.sections ?? [];
    const createSections = createIncidentWorkFlow.sections ?? [];
    const triageComplete = actions.triage.canEdit;

    // Combine sections by index, with a `+` for overlapping sections
    const combinedSections = triageSections.map((section, index) => {
      const createSection = createSections[index];
      // if theres a triage section and create section, combine their names using a translation key
      if (createSection && section) {
        return {
          name: "incidentFormSections.incidentAndTriage" as TranslationKey,
          completed: triageComplete,
        };
      }
      return {
        name: section.name,
        completed: triageComplete,
      };
    });

    return combinedSections;
  })();

  const is136FormSectionComplete = (sectionId: string) => {
    const forms = incidentWorkItem?.forms;
    if (!forms) return false;

    const form = forms.find(
      (form) => form.formType === IncidentFormType.section136,
    );
    if (!form || !form.sections) return false;

    switch (sectionId) {
      case FormSection.personDetails: {
        const section = form.sections.find(
          (section) => section.id === FormSection.personDetails,
        );
        return section ? section.data !== null : false;
      }
      case FormSection.detentionDetails: {
        const section = form.sections.find(
          (section) => section.id === FormSection.detentionDetails,
        );
        return section ? section.data !== null : false;
      }
      case FormSection.informationForHealthcare: {
        const section = form.sections.find(
          (section) => section.id === FormSection.informationForHealthcare,
        );
        return section ? section.data !== null : false;
      }
      default:
        return false;
    }
  };

  const get136FormSectionClickHandler = (sectionId: string) => {
    if (!incidentWorkItem) {
      return null;
    }

    switch (sectionId) {
      case FormSection.personDetails:
        navigate(
          createRoute.incidentPersonDetailsPage(
            incidentWorkItem.incidentWorkItem.id,
          ),
        );
        break;
      case FormSection.detentionDetails:
        navigate(
          createRoute.incidentDetentionDetailsPage(
            incidentWorkItem.incidentWorkItem.id,
          ),
        );
        break;
      case FormSection.informationForHealthcare:
        navigate(
          createRoute.incidentInformationForHealthcarePage(
            incidentWorkItem.incidentWorkItem.id,
          ),
        );
        break;
      default:
        return null;
    }
  };
  // Combine all workflows into steps
  const currentSteps = [
    ...mergedCreateAndTriageSections.map(
      (section) =>
        ({
          label: section.name,
          completed: section.completed,
          onClick: () => {
            navigate(
              incidentWorkItem
                ? createRoute.incidentTriagePage(
                    incidentWorkItem.incidentWorkItem.id,
                  )
                : createRoute.home(),
            );
          },
        }) satisfies StepData,
    ),
    ...(loadCurrentWorkFlow.sections.map(
      (section) =>
        ({
          label: section.name,
          completed: is136FormSectionComplete(section.id),
          onClick: () => {
            return get136FormSectionClickHandler(section.id);
          },
        }) satisfies StepData,
    ) ?? []),
  ];

  // Calculate key details about the active Place of Safety
  const activePlaceOfSafety = (() => {
    const result = safeTry(function* () {
      const posFormId = yield* defined(
        incidentWorkItem?.incidentWorkItem.activePlaceOfSafetyFormId,
      );

      const posForm = yield* defined(
        (incidentWorkItem?.forms ?? [])
          .filter(
            (form) => form.formType === IncidentFormType.recordPlaceOfSafety,
          )
          .find((form) => form.id === posFormId),
      );

      const posFormSectionMain = yield* defined(
        posForm.sections.find(
          (section) => section.id === FormSection.recordPlaceOfSafetyMain,
        ),
      );

      const posFormSectionArrival = yield* defined(
        posForm.sections.find(
          (section) => section.id === FormSection.recordPlaceOfSafetyArrival,
        ),
      );

      const posFormSectionArrivalId = yield* parsed(
        IncidentFormIdSchema,
        posFormSectionArrival.incidentFormId,
      );

      const posName = yield* defined(
        (
          (posFormSectionMain.data ?? undefined) as
            | RecordPlaceOfSafetyMinimumFields
            | undefined
        )?.placeOfSafety.name,
      );

      return ok({
        name: posName,
        arrivalFormId: posFormSectionArrivalId,
      });
    });
    return result.unwrapOr(undefined);
  })();

  return (
    <Stack
      data-testid="form-progress"
      sx={{
        [theme.breakpoints.up("md")]: {
          padding: "5rem",
        },
        [theme.breakpoints.down("md")]: {
          padding: "0.5rem",
        },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Paper
        sx={{
          padding: { xs: 0, sm: 3 },
          mx: "auto",
          boxShadow: { xs: 0, sm: 3 },
          maxWidth: { xs: "100%", sm: "80%" },
          width: "100%",
        }}
      >
        {/* Incident Banner */}
        <IncidentBanner
          name={fullName}
          incidentStatus={incidentStatus}
          shareCode={incidentWorkItem?.incidentWorkItem.shareCode}
          isViewShareFormDisabled={!actions.share.canShareForm}
        />
        {/* Form Progress */}
        <FormProgress
          incidentWorkItemId={incidentWorkItem?.incidentWorkItem.id}
          steps={currentSteps}
          activePlaceOfSafetyName={
            activePlaceOfSafety?.name ??
            t(
              "formProgressPage.activePlaceOfSafety.notKnown" satisfies TranslationKey,
            )
          }
          activePlaceOfSafetyFormId={activePlaceOfSafety?.arrivalFormId}
          arrivalUrl={
            incidentWorkItem?.incidentWorkItem.id !== undefined &&
            activePlaceOfSafety !== undefined &&
            actions.arrival.canMarkArrival
              ? createRoute.incidentRecordPlaceOfSafetyArrivalPage(
                  incidentWorkItem.incidentWorkItem.id,
                  activePlaceOfSafety.arrivalFormId,
                )
              : undefined
          }
          actions={actions}
          refetchWorkItem={refetchWorkItem}
        />
      </Paper>
    </Stack>
  );
}

export function FormProgressWrapper() {
  const { user, loading: isUserLoading, error: userError } = useUser();
  const params = useParams();

  const incidentId = IncidentFormIdSchema.safeParse(params.id);
  if (!incidentId.success) {
    throw new Error("Invalid form ID");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    {
      data: incidentWorkItem,
      loading: incidentWorkItemLoading,
      error: incidentWorkItemError,
    },
    refetchWorkItem,
  ] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    apiHooks.incidentWorkItem.get(incidentId.data);

  if (isUserLoading || incidentWorkItemLoading) {
    return <CircularProgress />;
  }

  if (userError || incidentWorkItemError) {
    return <ErrorComponent />;
  }

  const render = ({
    user,
    incidentWorkItem,
    refetchWorkItem,
  }: {
    user: ExtendedVisionUser | undefined;
    incidentWorkItem: IncidentWorkItemWithContext | undefined;
    refetchWorkItem: ReturnType<typeof apiHooks.incidentWorkItem.get>[1];
  }) => {
    return (
      <FormProgressInner
        user={user}
        incidentWorkItem={incidentWorkItem}
        refetchWorkItem={refetchWorkItem}
      />
    );
  };
  return render({ user, incidentWorkItem, refetchWorkItem });
}

export function FormProgress({
  incidentWorkItemId,
  steps,
  activePlaceOfSafetyName,
  activePlaceOfSafetyFormId,
  arrivalUrl = undefined,
  actions,
  refetchWorkItem,
}: FormProgressProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showTransferModal, setShowTransferModal] = React.useState(false);
  const notifications = useToastNotifications();
  const { t } = useTranslation();
  const [showShareModal, setShowShareModal] = React.useState(false);

  const { policePresenceRequested } = useNotifications();
  const [
    isPolicePresenceRequestedModalOpen,
    setIsPolicePresenceRequestedModalOpen,
  ] = React.useState(false);

  return (
    <>
      {/* Form Progress Steps */}
      <Box sx={{ px: { xs: 1, sm: 2 } }}>
        <FormProgressSteps steps={steps} />
      </Box>

      {isPolicePresenceRequestedModalOpen &&
        policePresenceRequested.data &&
        (() => {
          const notificationText = policePresenceRequested.data
            .text as PolicePresenceRequestedBody;
          return (
            <PolicePresenceRequestedModal
              open={isPolicePresenceRequestedModalOpen}
              close={() => {
                setIsPolicePresenceRequestedModalOpen(false);
              }}
              closeNotification={() => {
                policePresenceRequested.close();
              }}
              posName={notificationText.posName}
              requesterName={notificationText.requesterName}
              reason={notificationText.reason}
              reasonDescription={notificationText.reasonDescription}
              incidentId={notificationText.incidentWorkItemId}
              notificationId={policePresenceRequested.data.id}
            />
          );
        })()}

      {/* Place of Safety Section */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <ShareModal
          isOpen={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
          incidentWorkItemId={incidentWorkItemId ?? ""}
          refetchWorkItem={refetchWorkItem}
        />
        <Grid
          container
          sx={{
            backgroundColor: "common.background",
            maxWidth: { xs: "100%" },
            p: 1.5,
            mx: { xs: 0, sm: 5 },
          }}
        >
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Grid container>
                <Grid item xs={9}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TypographyI18N
                      sx={{ fontSize: "0.75rem" }}
                      color="text.secondary"
                      translationKey="formProgressPage.activePlaceOfSafety.title"
                    />
                    <Typography
                      sx={{
                        marginLeft: "0.25rem",
                        fontWeight: "bold",
                        fontSize: { xs: "0.8125rem", sm: "1rem" },
                      }}
                    >
                      {activePlaceOfSafetyName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TypographyI18N
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: 500,
                      fontSize: "0.8125rem",
                      color: theme.palette.primary.main,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    translationKey={
                      activePlaceOfSafetyFormId === undefined
                        ? "formProgressPage.add"
                        : "formProgressPage.change"
                    }
                    onClick={() => {
                      if (incidentWorkItemId !== undefined) {
                        if (activePlaceOfSafetyFormId !== undefined) {
                          navigate(
                            createRoute.incidentRecordPlaceOfSafetyLeavePage(
                              incidentWorkItemId,
                              activePlaceOfSafetyFormId,
                            ),
                          );
                        } else {
                          navigate(
                            createRoute.incidentRecordPlaceOfSafetyPage(
                              incidentWorkItemId,
                            ),
                          );
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Arrival and Handover Buttons Section */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Grid
          container
          sx={{
            maxWidth: { xs: "100%" },
            mx: { xs: 0, sm: 5 },
          }}
        >
          <Grid container justifyContent="space-around">
            {/* Arrival Section */}
            <Grid item xs={5}>
              <Stack direction="column" spacing={1}>
                <TypographyI18N
                  sx={{
                    mb: 1,
                    fontSize: { xs: "0.8125", sm: "1rem" },
                    fontWeight: 600,
                  }}
                  translationKey="formProgressPage.arrival"
                />
                <TypographyI18N
                  variant="caption"
                  translationKey="formProgressPage.confirmArrival"
                />
                <Button
                  variant="contained"
                  data-testid="arrival-button"
                  disabled={arrivalUrl === undefined}
                  sx={{ mt: 2, maxWidth: 300, width: "100%" }}
                  onClick={() => {
                    if (arrivalUrl !== undefined) {
                      navigate(arrivalUrl);
                    }
                  }}
                >
                  <TypographyI18N
                    sx={{ fontWeight: 500, fontSize: "0.8125rem" }}
                    translationKey="formProgressPage.arrival"
                  />
                </Button>
              </Stack>
            </Grid>

            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  minHeight: { xs: "100%", sm: "300px" },
                  height: "100%",
                  borderColor: "divider",
                }}
              />
            </Grid>

            {/* Handover Section */}
            <Grid item xs={5}>
              <Stack direction="column" spacing={1}>
                <TypographyI18N
                  translationKey="formProgressPage.handoverAndShare"
                  sx={{
                    mb: 1,
                    fontSize: { xs: "0.8125", sm: "1rem" },
                    fontWeight: 600,
                  }}
                />
                <TypographyI18N
                  variant="caption"
                  translationKey="formProgressPage.confirmHandover"
                />
                <Button
                  variant="contained"
                  data-testid="handover-button"
                  disabled={!actions.share.canShareForm}
                  sx={{ mt: 2, maxWidth: 300, width: "100%" }}
                  onClick={() => {
                    setShowShareModal(true);
                  }}
                >
                  <TypographyI18N
                    sx={{ fontWeight: 500, fontSize: "0.8125rem" }}
                    translationKey="formProgressPage.share"
                  />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* End and Transfer Incident Buttons */}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1000,
          backgroundColor: "common.background",
          borderRadius: "9px",
        }}
      >
        <Grid
          container
          sx={{
            mt: 3,
            p: 2,
            flexDirection: "row",
            gap: { xs: 2, sm: 0 },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {actions.policePresence.canFinishForm ? (
              <Button
                variant="outlined"
                data-testid="finish-form-button"
                sx={{
                  width: "100%",
                  fontSize: "0.75rem",
                  backgroundColor: "white",
                  color: theme.palette.common.teal,
                  borderColor: theme.palette.common.teal,
                  "&:hover": {
                    borderColor: theme.palette.common.deepTeal,
                  },
                }}
                disabled={!actions.endIncident.canEnd}
                onClick={() => {
                  if (
                    policePresenceRequested.data &&
                    policePresenceRequested.data.text.type ===
                      NotificationBodyType.policePresenceRequested
                  ) {
                    policePresenceRequested.open(policePresenceRequested.data);
                    setIsPolicePresenceRequestedModalOpen(true);
                    return;
                  }
                  if (incidentWorkItemId !== undefined) {
                    navigate(
                      createRoute.incidentFinishFormPage(incidentWorkItemId),
                    );
                  }
                }}
              >
                <TypographyI18N
                  variant="caption"
                  sx={{ fontWeight: 600, fontSize: "0.75rem" }}
                  translationKey="finishForm.finishFormTitle"
                />
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{
                    width: "100%",
                    fontSize: "0.75rem",
                    backgroundColor: "white",
                  }}
                  disabled={!actions.endIncident.canEnd}
                  onClick={() => {
                    if (incidentWorkItemId !== undefined) {
                      navigate(
                        createRoute.incidentPreEndIncidentPage(
                          incidentWorkItemId,
                        ),
                      );
                    }
                  }}
                >
                  <TypographyI18N
                    variant="caption"
                    sx={{ fontWeight: 600, fontSize: "0.75rem" }}
                    translationKey="formProgressPage.endIncident"
                  />
                </Button>
              </>
            )}
          </Grid>

          <Grid
            item
            xs={4}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: "100%",
                fontSize: "0.75rem",
                backgroundColor: "white",
              }}
              disabled={!actions.transfers.canInitiate}
              onClick={() => {
                setShowTransferModal(true);
              }}
            >
              <TypographyI18N
                variant="caption"
                sx={{ fontWeight: 600, fontSize: "0.75rem" }}
                translationKey="formProgressPage.transfer"
              />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <InitiateTransferModal
        open={showTransferModal}
        onClose={() => {
          setShowTransferModal(false);
        }}
        incidentWorkItemId={incidentWorkItemId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onConfirm={async (email: string | undefined) => {
          if (incidentWorkItemId === undefined || email === undefined) return;

          const createTransfer = {
            receivingOfficerEmail: email,
            incidentWorkItemId,
          };

          await api.incidentWorkItem
            .createTransfer(createTransfer)
            .then((res) => {
              if (res.status === 204) {
                notifications.show(
                  t("transfer.initiateTransfer.confirmToast", { email }),
                  {
                    severity: "success",
                  },
                );
                setShowTransferModal(false);
                navigate(createRoute.home());
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                notifications.show(res.data.reason, {
                  severity: "error",
                });
              }
            });
        }}
      />
    </>
  );
}
