import type { UUID } from "@thalamos/common";
import { notificationChannelFns as thalamosNotificationChannelFns } from "@thalamos/common";
import { type UserId, type UserIdUnbranded } from "./user.js";
import {
  type IncidentWorkItemId,
  type IncidentWorkItemIdUnbranded,
} from "./workItem.js";

export type BrowserNotificationEvent = ResourceReloadEvent;

export type ResourceReloadEvent = {
  type: NotificationEvents.resourceReload;
};

export enum NotificationEvents {
  resourceReload = "resource-reload",
}

export enum NotificationBodyType {
  transferRequested = "transfer-requested",
  transferRejected = "transfer-rejected",
  s136FormAccepted = "s136-form-accepted",
  s136FormRejected = "s136-form-rejected",
  policePresenceRequested = "police-presence",
}

export type TransferRequestedBody = {
  type: NotificationBodyType.transferRequested;
};

export type TransferRejectedBody = {
  type: NotificationBodyType.transferRejected;
};

export type S136FormAcceptedBody = {
  type: NotificationBodyType.s136FormAccepted;
};

export type PolicePresenceRequestedBody = {
  type: NotificationBodyType.policePresenceRequested;
  posName?: string;
  incidentWorkItemId: string;
  requesterName: string;
  reason: string;
  reasonDescription?: string;
};

export type S136FormRejectedBody = {
  type: NotificationBodyType.s136FormRejected;
  posName?: string;
  reason: string;
  reasonDescription?: string;
  incidentWorkItemId: string;
};

export type ThalamosNotification = {
  id: UUID;
  text:
    | TransferRequestedBody
    | TransferRejectedBody
    | S136FormAcceptedBody
    | S136FormRejectedBody
    | ResourceReloadEvent
    | PolicePresenceRequestedBody;
  status: "new" | "read" | "discarded";
  userId: UserId;
  incidentWorkItemId: IncidentWorkItemId;
};

export type ThalamosNotificationUnbranded = {
  id: UUID;
  text:
    | TransferRequestedBody
    | TransferRejectedBody
    | S136FormAcceptedBody
    | S136FormRejectedBody
    | ResourceReloadEvent
    | PolicePresenceRequestedBody;
  status: "new" | "read" | "discarded";
  userId: UserIdUnbranded;
  incidentWorkItemId: IncidentWorkItemIdUnbranded;
};

export const notificationChannelFns = {
  user: {
    notifications: (userId: string) => `user.${userId}.notifications`,
  },
  ...thalamosNotificationChannelFns,
};
