import { z, type ZodTypeAny } from "zod";
import { IncidentType } from "./incidentType.js";
import { OrganisationRoles } from "./organisation.js";

/**
 * Check for a valid date and time
 */
export const dateTimeSchema = z
  .string()
  .datetime("fieldSchema.dateTime.invalid");

/**
 * A date in the format
 */
export const dateSchema = z.string().date();

/**
 * A stringified time
 */
export const timeSchema = z
  .string()
  .regex(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, "fieldSchema.time.invalid");

/**
 * A typical free text field that will fit in a database column
 */
export const freeTextSchema = z.string().min(1).max(255);

/**
 * A person's forename
 */
export const forenameSchema = freeTextSchema;

/**
 * A person's surname
 */
export const surnameSchema = freeTextSchema;

/**
 * An address
 */
export const addressSchema = freeTextSchema;

/**
 * A postal code
 */
export const postalCodeSchema = z.string().min(1).max(8);

/**
 * A dispatch number
 */
export const dispatchNumberSchema = z
  .string()
  .min(4, "fieldSchema.dispatchNumber.tooShort")
  .max(255);

/**
 * A command unit
 */
export const commandUnitSchema = z.string().uuid();

export const nameSchema = z.string().min(1).max(255);

export const orgIdSchema = z.string().uuid();

export const emailSchema = z.string().email();

/**
 * A Vision Organisation
 */
export const availableOrganisationSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  managingOrganisationId: z.string().uuid().nullable(),
  role: z.nativeEnum(OrganisationRoles),
});

export const availableOrganisationsArraySchema =
  availableOrganisationSchema.array();

/**
 * A yes/no field
 */
export const yesNoSchema = z.enum(["yes" as const, "no" as const]);

/**
 * A yes only field
 */
export const yesOnlySchema = z
  .enum(["yes", "no"] as const)
  .refine((val) => val === "yes", {
    message: "Required",
  });

export const clinicalCaseRecordNumberSchema = freeTextSchema;
/**
 * Ways of contacting the NHS
 */
export const nhsContactResults = [
  "controlRoom",
  "triageCarHO",
  "triageCarBtp",
  "healthcarePractitionerAmbulance",
  "ldPractitionersInCustody",
  "custodyHealthcarePractitioner",
  "panLondonMentalHealthAdviceLine",
  "localMentalHealthAdviceLine",
  "localHbpos",
  "localStreetTriageTeam",
  "mentalHealthTeamOther",
  "nhs111",
  "nhsStaffNwr",
  "nhsStaffAE",
  "amph",
  "other",
] as const;

export const nhsContactSchema = z.enum(nhsContactResults);

export const otherActionResults = [
  "arrestCriminalOffence",
  "watchList",
  "referSupportOptions",
  "other",
] as const;

export const otherActionSchema = z.enum(otherActionResults);

export const voluntaryActionResults = [
  "voluntaryAttendanceHospital",
  "divertOtherHealthservice",
  "contactSupportGroup",
  "contactFriendsFamily",
  "takePersonHome",
  "divertCrisisCafe",
  "other",
] as const;

export const voluntaryActionSchema = z.enum(voluntaryActionResults);

export const givenHealthCareProfessionalAdviceResults = [
  IncidentType.section136,
  IncidentType.section56,
  IncidentType.voluntary,
  "other",
] as const;

export const givenHealthCareProfessionalAdviceSchema = z.enum(
  givenHealthCareProfessionalAdviceResults,
);

/**
 * Triage outcome
 * TODO: Add more options: https://thalamos.atlassian.net/wiki/spaces/P2/pages/1950744580/Incident+Triage+Page
 */
export const triageOutcomeSchema = z.enum([
  "136" as const,
  "5-6" as const,
  "voluntary" as const,
  "other" as const,
]);

/**
 * This will be deprecated once the FieldBuilder is in place
 *
 * @param valueSchema
 * @returns
 */
export const valueAndLabelSchemaDeprecated = <T extends ZodTypeAny>(
  valueSchema: T,
) =>
  z.object({
    value: valueSchema,
    valueLabel: z.string(),
  });

export const yesNoUnknownSchema = z.enum(["yes", "no", "unknown"] as const);

export const genderOptions = [
  "male",
  "female",
  "nonBinary",
  "preferNotToSay",
] as const;

export const genderSchema = z.enum(genderOptions);
export type GenderType = z.infer<typeof genderSchema>;

export const ethnicityOptions = [
  "IC1",
  "IC2",
  "IC3",
  "IC4",
  "IC5",
  "IC6",
  "IC7",
] as const;

export const ethnicitySchema = z.enum(ethnicityOptions);
export type EthnicityType = z.infer<typeof ethnicitySchema>;

export const selfDeclaredEthnicityOptions = [
  "whiteEnglish",
  "whiteIrish",
  "whiteGypsy",
  "whiteRoma",
  "whiteOther",
  "mixedBlackCaribbean",
  "mixedBlackAfrican",
  "mixedAsian",
  "mixedOther",
  "asianEnglish",
  "asianIndian",
  "asianPakistani",
  "asianBangladeshi",
  "asianChinese",
  "asianOther",
  "blackEnglish",
  "blackAfrican",
  "blackCaribbean",
  "blackOther",
  "arab",
  "other",
  "preferNotToSay",
] as const;

export const selfDeclaredEthnicitySchema = z.enum(selfDeclaredEthnicityOptions);
export type SelfDeclaredEthnicityType = z.infer<
  typeof selfDeclaredEthnicitySchema
>;

export const pncWarningMarkersOptions = [
  "alleges",
  "ailment",
  "conceals",
  "contagious",
  "drugs",
  "escaper",
  "firearm",
  "f/Impers",
  "m/Impers",
  "impersonates",
  "mentalHealth",
  "selfHarm",
  "suicidal",
  "violent",
  "weapons",
  "explosives",
] as const;

export const pncWarningMarkersSchema = z.enum(pncWarningMarkersOptions);

/**
 * A helper function to allow Formik, which is gradually collecting data
 * a keystroke at a time, to handle empty strings and make them null.
 */
function nullableStringSchema<S extends ZodTypeAny>(strictFieldSchema: S) {
  return strictFieldSchema.or(
    z
      .string()
      .nullable()
      .transform((s) => (s === "" ? null : s))
      .pipe(strictFieldSchema.nullable()),
  );
}

/**
 * A helper function to allow Formik, which is gradually collecting data
 * a keystroke at a time, to handle various "nullish" values like ["",
 * null, undefined], and make them null.
 */
export function nullableSchema<S extends ZodTypeAny>(strictFieldSchema: S) {
  const nullishValues = ["", null, undefined];

  return strictFieldSchema.or(
    z
      .string()
      .optional()
      .nullable()
      .transform((s) => (nullishValues.includes(s) ? null : s))
      .pipe(strictFieldSchema.nullable()),
  );
}

/**
 * Creates a permissive schema from a collection of strict field schemas.
 * It allows all values to be nullable strings, and co-erces empty strings to null.
 *
 * @param strictFieldSchemas A collection of strict field schemas
 * @returns A Zod schema containing all of the field schemas but made permissive.
 */
export const createPermissiveSchema = <T extends Record<string, ZodTypeAny>>(
  strictFieldSchemas: T,
) => {
  const existingEntries = Object.entries(strictFieldSchemas) as {
    [K in keyof T]-?: [K, T[K]];
  }[keyof T][];

  const permissiveEntries = existingEntries.map(([key, value]) => [
    key,
    nullableStringSchema(value),
  ]);

  const newObject = Object.fromEntries(permissiveEntries) as {
    [K in keyof T]-?: ReturnType<typeof nullableStringSchema<T[K]>>;
  };

  return z.object(newObject);
};

/**
 * Creates initial values for Formik based on a permissive schema.
 *
 * @param strictFieldSchemas An object whose members are strict field schemas
 * @returns Initial values for a form that matches the permissive schema as created by createPermissiveSchema.
 */
export const createInitialValues = <T extends Record<string, ZodTypeAny>>(
  strictFieldSchemas: T,
) => {
  const emptyString = "";

  const existingEntries = Object.entries(strictFieldSchemas);

  const permissiveEntries = existingEntries.map(([key, _value]) => [
    key,
    emptyString,
  ]);

  const newObject = Object.fromEntries(permissiveEntries) as {
    [K in keyof T]-?: z.infer<T[K]>;
  };

  return newObject;
};
