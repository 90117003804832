import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { default as api } from "../../api/index.js";

export type PlaceholderProps = { title: string };

export const Placeholder = ({ title }: PlaceholderProps): React.ReactNode => {
  const [greeting, setGreeting] = useState<string | undefined>(undefined);
  useEffect(() => {
    const doEffect = async () => {
      try {
        const response = await api.hello.greeting();
        setGreeting(response.data);
      } catch (_error) {
        setGreeting(undefined);
      }
    };

    void doEffect();
  }, []);

  return greeting === undefined ? (
    <CircularProgress />
  ) : (
    <Stack sx={{ display: "flex", textAlign: "center" }}>
      <Typography variant="h3">This is a placeholder page</Typography>
      <Typography variant="h3">{title}</Typography>
      <Typography variant="h3">{greeting}</Typography>
    </Stack>
  );
};
