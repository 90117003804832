import type { TranslationKey } from "../../@types/react-i18next.js";
import {
  FieldBuilder,
  MultiPageFormBuilder,
} from "../../support/MultiPageFormBuilder/index.js";
import {
  FormSection,
  freeTextSchema,
  givenHealthCareProfessionalAdviceSchema,
  IncidentType,
  nhsContactResults,
  nhsContactSchema,
  triageOutcomeSchema,
  yesNoSchema,
  yesOnlySchema,
  type FormDefinition,
  type FormSectionDefinition,
} from "../../types/index.js";

const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysVisible({
    fieldName: "isHealthcareProfessionalConsulted", // triage-1.0
    type: "radio",
    ...new FieldBuilder().withSchema(yesNoSchema).captureValueLabel().build(),
    label: "triageIncident.isHealthcareProfessionalConsulted",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonNotConsulted", // triage-1.0.1
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(freeTextSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "no",
    label: "triageIncident.reasonNotConsulted.title",
    options: [
      {
        label: "triageIncident.reasonNotConsulted.options.needForCare",
        value: "needForCare",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.immediateControl",
        value: "immediateControl",
      },
      {
        label: "triageIncident.reasonNotConsulted.options.noAnswer",
        value: "noAnswer",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhs", // triage-1.0.3
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(nhsContactSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.howDidYouContactNhs.title",
    options: [
      {
        value: "healthcarePractitionerAmbulance", // triage-1.0.3.1
        label:
          "triageIncident.howDidYouContactNhs.options.healthcarePractitionerAmbulance",
      },
      {
        value: "ldPractitionersInCustody", // triage-1.0.3.2
        label:
          "triageIncident.howDidYouContactNhs.options.ldPractitionersInCustody",
      },
      {
        value: "custodyHealthcarePractitioner", // triage-1.0.3.3
        label:
          "triageIncident.howDidYouContactNhs.options.custodyHealthcarePractitioner",
      },
      {
        value: "panLondonMentalHealthAdviceLine", // triage-1.0.3.4
        label:
          "triageIncident.howDidYouContactNhs.options.panLondonMentalHealthAdviceLine",
      },
      {
        value: "localMentalHealthAdviceLine", // triage-1.0.3.5
        label:
          "triageIncident.howDidYouContactNhs.options.localMentalHealthAdviceLine",
      },
      {
        value: "localHbpos", // triage-1.0.3.6
        label: "triageIncident.howDidYouContactNhs.options.localHbpos",
      },
      {
        value: "localStreetTriageTeam", // triage-1.0.3.7
        label:
          "triageIncident.howDidYouContactNhs.options.localStreetTriageTeam",
      },
      {
        value: "controlRoom", // triage-1.0.3.14
        label: "triageIncident.howDidYouContactNhs.options.controlRoom",
      },
      {
        value: "amph", // triage-1.0.3.15
        label: "triageIncident.howDidYouContactNhs.options.amph",
      },
      {
        value: "other", // triage-1.0.3.16
        label: "common.other",
      },
    ] satisfies Array<{
      value: (typeof nhsContactResults)[number];
      label: TranslationKey;
    }>,
  })
  .withFieldConditionallyVisible({
    fieldName: "howDidYouContactNhsOtherDetails", // triage-1.0.3.16.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.howDidYouContactNhs?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withFieldConditionallyVisible({
    fieldName: "givenHealthCareProfessionalAdvice", // triage-1.0.4
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(givenHealthCareProfessionalAdviceSchema)
      .captureValueLabel()
      .build(),
    isVisible: ({ formValues }) =>
      formValues.isHealthcareProfessionalConsulted?.value === "yes",
    label: "triageIncident.givenHealthCareProfessionalAdvice",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: IncidentType.section136,
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: IncidentType.section56,
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: IncidentType.voluntary,
      },
      {
        label: "common.other",
        value: IncidentType.other,
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "reasonsForDetention", // triage-1.0.4.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section136,
    label: "triageIncident.reasonsForDetention",
  })
  .withFieldConditionallyVisible({
    fieldName: "confirmMentalCapacityLack", // triage-1.0.4.1.2
    type: "checkbox",
    ...new FieldBuilder().withSchema(yesOnlySchema).captureValueLabel().build(),
    isVisible: ({ formValues }) =>
      formValues.givenHealthCareProfessionalAdvice?.value ===
      IncidentType.section56,
    label: "triageIncident.confirmMentalCapacityLack",
  })
  .withFieldAlwaysVisible({
    fieldName: "whatWillYouDo", // triage-1.0.2
    type: "dropdown",
    ...new FieldBuilder()
      .withSchema(triageOutcomeSchema)
      .captureValueLabel()
      .build(),
    label: "triageIncident.whatActionWillYouTake",
    options: [
      {
        label: "triageIncident.whatWillYouDo.136",
        value: "136",
      },
      {
        label: "triageIncident.whatWillYouDo.56",
        value: "5-6",
      },
      {
        label: "triageIncident.whatWillYouDo.voluntary",
        value: "voluntary",
      },
      {
        label: "triageIncident.whatWillYouDo.other",
        value: "other",
      },
    ],
  })
  .withFieldConditionallyVisible({
    fieldName: "whatWillYouDoOther", // triage-1.0.2.1.1
    type: "textbox",
    ...new FieldBuilder().withSchema(freeTextSchema).build(),
    isVisible: ({ formValues }) => formValues.whatWillYouDo?.value === "other",
    label: "triageIncident.provideDetails",
  })
  .withPage([
    "isHealthcareProfessionalConsulted", // triage-1.0
  ])
  .withPage([
    "reasonNotConsulted", // triage-1.0.1
    "howDidYouContactNhs", // triage-1.0.3
    "howDidYouContactNhsOtherDetails", // triage-1.0.3.16.1
  ])
  .withPage([
    "givenHealthCareProfessionalAdvice", // triage-1.0.4
    "reasonsForDetention", // triage-1.0.4.1.1
    "confirmMentalCapacityLack", // triage-1.0.4.1.2
  ])
  .withPage([
    "whatWillYouDo", // triage-1.0.2
    "whatWillYouDoOther", // triage-1.0.2.1.1
  ])
  .build();

export type TriageIncidentThalamosMin01FormData =
  typeof formSpecification.formType;

const triageIncidentThalamosMin01: FormDefinition<
  [
    FormSectionDefinition<
      FormSection.triageMain,
      TriageIncidentThalamosMin01FormData
    >,
  ]
> = {
  sections: [
    {
      id: FormSection.triageMain,
      autosave: true,
      strictFieldSchemas: formSpecification.strictFieldSchemas,
      formSchema: formSpecification.formSchema,
      name: "triageIncident.title",
      pages: formSpecification.pages,
    },
  ],
};

export default triageIncidentThalamosMin01;
