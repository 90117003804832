import type { EMHAParticipantUnbranded } from "@thalamos/common";
import z, { ZodType } from "zod";
import { UserIdSchema, type UserIdUnbranded } from "./user.js";

export type IncidentFormIdUnbranded = string;

export const IncidentFormIdSchema = z.coerce
  .string()
  .uuid()
  .brand(Symbol("IncidentFormId")) satisfies ZodType<IncidentFormIdUnbranded>;

export type IncidentFormId = z.infer<typeof IncidentFormIdSchema>;

/**
 *
 * Incident Work Item
 *
 */

/**
 * An ID for an Incident Work Item
 */
export type IncidentWorkItemIdUnbranded = string;
export const IncidentWorkItemIdSchema = z.coerce
  .string()
  .uuid()
  .brand(
    Symbol("IncidentWorkItemId"),
  ) satisfies ZodType<IncidentWorkItemIdUnbranded>;
export type IncidentWorkItemId = z.infer<typeof IncidentWorkItemIdSchema>;

/**
 * An Incident Work Item
 */
export type IncidentWorkItemUnbranded = {
  id: IncidentWorkItemIdUnbranded;
  status: string;
  activePlaceOfSafetyFormId: IncidentFormIdUnbranded | null;
  activeOfficerRequestFormId: IncidentFormIdUnbranded | null;
  workflowVersion: string;
  shareCode: string;
  pdfUri: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type IncidentWorkItemEnhancedUnbranded = IncidentWorkItemUnbranded;

export enum IncidentWorkItemStatus {
  active = "active",
  inactive = "inactive",
  inProgress = "in-progress",
  ended = "ended",
  awaitingArrival = "awaiting-arrival",
  formShared = "form-shared",
  // This is used when the incident has been shared with the NHS
  nhsFormAccepted = "nhs-form-accepted",
  // This is when police have been requested for support
  policePresenceRequested = "police-presence-requested",
  policePresenceNotRequested = "police-presence-not-requested",

  arrivedAtPlaceOfSafety = "arrived-at-place-of-safety",
  unknown = "unknown",
}

export const IncidentWorkItemBaseSchema = z.object({
  id: IncidentWorkItemIdSchema,
  status: z.nativeEnum(IncidentWorkItemStatus),
  activePlaceOfSafetyFormId: IncidentFormIdSchema.nullable(),
  activeOfficerRequestFormId: IncidentFormIdSchema.nullable(),
  workflowVersion: z.string().max(255),
  shareCode: z.string().regex(/^[A-Z0-9]{8}$/),
  pdfUri: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const IncidentWorkItemSchema = IncidentWorkItemBaseSchema.brand(
  Symbol("IncidentWorkItem"),
);

export type IncidentWorkItem =
  z.infer<typeof IncidentWorkItemSchema> extends IncidentWorkItemUnbranded
    ? z.infer<typeof IncidentWorkItemSchema>
    : never;

// This is required to strip the 'brand' from the outer type whilst preserving on the inner type
export type IncidentWorkItemPartial = Partial<
  z.infer<ReturnType<typeof IncidentWorkItemSchema.unwrap>>
>;

export const IncidentWorkItemEnhancedSchema = IncidentWorkItemBaseSchema.extend(
  {},
).brand(Symbol("IncidentWorkItemEnhanced"));

export type IncidentWorkItemEnhanced =
  z.infer<
    typeof IncidentWorkItemEnhancedSchema
  > extends IncidentWorkItemEnhancedUnbranded
    ? z.infer<typeof IncidentWorkItemEnhancedSchema>
    : never;

/**
 *
 * Participant Work Item
 *
 */

/**
 * An ID for an Incident Work Item Participant
 */
export type IncidentWorkItemParticipantIdUnbranded = string;
export const IncidentWorkItemParticipantIdSchema = z
  .string()
  .uuid()
  .brand(
    Symbol("IncidentWorkItemParticipantId"),
  ) satisfies ZodType<IncidentWorkItemParticipantIdUnbranded>;
export type IncidentWorkItemParticipantId = z.infer<
  typeof IncidentWorkItemParticipantIdSchema
>;

export enum ParticipantRole {
  owner = "owner",
  pendingOwner = "pending-owner",
  participant = "participant",
  supervisor = "supervisor",
}

const ParticipantRoleEnum = z.nativeEnum(ParticipantRole);

export enum ParticipantStatus {
  active = "active",
  pendingTransfer = "pending-transfer",
  requestedTransfer = "requested-transfer",
  rejectedTransfer = "rejected-transfer",
  transferred = "transferred",
  revokedTransfer = "revoked-transfer",
}

const ParticipantStatusEnum = z.nativeEnum(ParticipantStatus);

/**
 * An Incident Work Item Participant
 */
export type IncidentWorkItemParticipantUnbranded = {
  id: IncidentWorkItemParticipantIdUnbranded;
  incidentWorkItemId: IncidentWorkItemParticipantIdUnbranded;
  participantId: UserIdUnbranded;
  status: ParticipantStatus;
  role: ParticipantRole;
  assignedAt: Date;
  unassignedAt?: Date | null;
};

export type IncidentWorkItemParticipantEnhancedUnbranded =
  IncidentWorkItemParticipantUnbranded & {
    name: string;
  };

const IncidentWorkItemParticipantSchemaBase = z.object({
  id: IncidentWorkItemParticipantIdSchema,
  incidentWorkItemId: IncidentWorkItemIdSchema,
  participantId: UserIdSchema,
  status: ParticipantStatusEnum,
  role: ParticipantRoleEnum,
  assignedAt: z.coerce.date(),
  unassignedAt: z.coerce
    .date()
    .transform((value) => (value.valueOf() === 0 ? undefined : value))
    .optional(),
});

export const IncidentWorkItemParticipantSchema =
  IncidentWorkItemParticipantSchemaBase.brand(
    Symbol("IncidentWorkItemParticipant"),
  ) satisfies ZodType<IncidentWorkItemParticipantUnbranded>;

export type IncidentWorkItemParticipant = z.infer<
  typeof IncidentWorkItemParticipantSchema
>;

export type IncidentFormSectionUnbranded = {
  id: string;
  incidentFormId: string;
  data: Record<string, unknown> | null;
  updatedAt: Date;
};

export type IncidentFormUnbranded = {
  id: string;
  incidentWorkItemId: string;
  status: string;
  formType: string;
  formDefinitionVersion: string;
  createdAt: Date;
  updatedAt: Date;
  sections: IncidentFormSectionUnbranded[];
};

export type IncidentWorkItemWithContextUnbranded = {
  incidentWorkItem: IncidentWorkItemEnhancedUnbranded;
  participants: IncidentWorkItemParticipantEnhancedUnbranded[];
  forms: IncidentFormUnbranded[];
};

export const IncidentWorkItemParticipantEnhancedSchema =
  IncidentWorkItemParticipantSchemaBase.extend({
    name: z.string().max(255),
  }).brand(
    Symbol("IncidentWorkItemParticipant"),
  ) satisfies ZodType<IncidentWorkItemParticipantEnhancedUnbranded>;

export type IncidentWorkItemParticipantEnhanced = z.infer<
  typeof IncidentWorkItemParticipantEnhancedSchema
>;

export enum IncidentFormStatus {
  active = "active",
  inactive = "inactive",
}

export const buildIncidentFormSectionSchema = (
  // Allow unknown keys on the 'generic' schema
  dataSchema = z.record(z.unknown()).nullable(),
) =>
  z
    .object({
      id: z.string(),
      incidentFormId: IncidentFormIdSchema,
      data: dataSchema,
      updatedAt: z.coerce.date(),
    })
    .brand(Symbol("IncidentFormSection")) satisfies ZodType<
    IncidentFormUnbranded["sections"][0]
  >;

// Add 5-6 etc later
export enum IncidentFormType {
  createIncident = "createIncident",
  triage = "triageIncident",
  recordPlaceOfSafety = "recordPlaceOfSafety",
  section136 = "136",
  preEndIncident = "preEndIncident",
  requestPolicePresence = "requestPolicePresence",
  finishForm = "finishForm",
}

// A police officer can either pre end the incident before the handover or end the incident after a handover has taken place
// This type is used to determine which form to create
export type EndIncidentOrFinishForm =
  | IncidentFormType.finishForm
  | IncidentFormType.preEndIncident;

export const buildIncidentFormSchema = (
  sectionSchema = z.array(buildIncidentFormSectionSchema()),
) =>
  z
    .object({
      id: IncidentFormIdSchema,
      incidentWorkItemId: IncidentWorkItemIdSchema,
      status: z.nativeEnum(IncidentFormStatus),
      formType: z.nativeEnum(IncidentFormType),
      formDefinitionVersion: z.string(),
      createdAt: z.coerce.date(),
      updatedAt: z.coerce.date(),
      sections: sectionSchema,
    })
    .brand(Symbol("IncidentForm")) satisfies ZodType<IncidentFormUnbranded>;

export const EmhaParticipantSchema = z
  .object({
    userId: z.string(),
    userName: z.string(),

    teamId: z.string(),
    teamName: z.string(),
    organisationId: z.string(),
    organisationName: z.string(),
    placeOfSafety: z.object({
      id: z.string().optional().nullable(),
      eMHACuratedPlaceOfSafetyId: z.string().optional().nullable(),
      name: z.string(),
      address: z.string(),
      postalCode: z.string(),
      odsCode: z.string().optional().nullable(),
    }),
    accessedAt: z.coerce.date(),
  })
  .brand(Symbol("EmhaParticipant")) satisfies ZodType<EMHAParticipantUnbranded>;

export type EmhaParticipant = z.infer<typeof EmhaParticipantSchema>;

export const IncidentWorkItemWithContextSchema = z.object({
  incidentWorkItem: IncidentWorkItemEnhancedSchema,
  participants: z.array(IncidentWorkItemParticipantEnhancedSchema),
  forms: z.array(buildIncidentFormSchema()),
  emhaParticipants: z.array(EmhaParticipantSchema),
});

export type IncidentWorkItemWithContext = z.infer<
  typeof IncidentWorkItemWithContextSchema
>;

export type IncidentForm = IncidentWorkItemWithContext["forms"][0];
export type IncidentFormSection = IncidentForm["sections"][0];
