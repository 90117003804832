import { ExtendedVisionUser, ExtendedVisionUserSchema } from "@vision/common";
import React, { createContext, useContext } from "react";
import { apiHooks } from "../api/index.js";
import { Error as ErrorComponent } from "../pages/Error/Error.js";

export type UserContextType = {
  user: ExtendedVisionUser | undefined;
  loading: boolean;
  error: Error | null;
  refetchUser: () => Promise<unknown>;
};

export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const [{ data, loading, error }, refetchUser] = apiHooks.user.me();

  let user: UserContextType["user"] = undefined;

  try {
    if (data) {
      user = ExtendedVisionUserSchema.parse(data);
    }
  } catch (parseError) {
    console.error("Error parsing user data:", parseError);
    return <ErrorComponent />;
  }

  return (
    <UserContext.Provider
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      value={{ user, loading, error, refetchUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
