import {
  FormSection,
  IncidentFormType,
  WorkflowVersions,
  type Workflow,
} from "../../types/index.js";
import {
  formSpecification as arrivePlaceOfSafetyFormSpecification,
  type ArrivePlaceOfSafetyFormData,
} from "./arrivePlaceOfSafety.js";
import {
  default as createIncidentThalamosMin01,
  type CreateIncidentWorkItemFormData,
} from "./createIncident.js";
import {
  formSpecification as detentionDetailsFormSpecification,
  type DetentionDetailsFormData,
} from "./detentionDetails.js";
import {
  formSpecification as finishFormFormSpecification,
  type FinishFormData,
} from "./finishForm.js";
import {
  formSpecification as informationForHealthcareFormSpecification,
  type InformationForHealthcareFormData,
} from "./informationForHealthcare.js";
import {
  formSpecification as leavePlaceOfSafetyFormSpecification,
  type LeavePlaceOfSafetyFormData,
} from "./leavePlaceOfSafety.js";
import { min01PdfTemplate } from "./pdfTemplate.js";
import {
  formSpecification as personDetailsFormSpecification,
  type PersonDetailsFormData,
} from "./personDetails.js";
import {
  formSpecification as preEndIncidentFormSpecification,
  type PreEndIncidentFormData,
} from "./preEndIncident.js";
import {
  formSpecification as recordPlaceOfSafetyFormSpecification,
  type RecordPlaceOfSafetyFormData,
} from "./recordPlaceOfSafety.js";
import {
  formSpecification as recordPolicePresenceFormSpecification,
  type RecordPolicePresenceFormData,
} from "./recordPolicePresence.js";
import {
  formSpecification as requestPolicePresenceMainFormSpecification,
  type RequestPolicePresenceMainFormData,
} from "./requestPolicePresenceMain.js";
import {
  default as triageIncidentThalamosMin01,
  type TriageIncidentThalamosMin01FormData,
} from "./triageIncident.js";

export const thalamosMin01Workflow: Workflow<
  CreateIncidentWorkItemFormData,
  TriageIncidentThalamosMin01FormData,
  PersonDetailsFormData,
  DetentionDetailsFormData,
  InformationForHealthcareFormData,
  RecordPlaceOfSafetyFormData,
  ArrivePlaceOfSafetyFormData,
  LeavePlaceOfSafetyFormData,
  PreEndIncidentFormData,
  RequestPolicePresenceMainFormData,
  RecordPolicePresenceFormData,
  FinishFormData
> = {
  workflowVersion: WorkflowVersions.ThalamosMin01,

  [IncidentFormType.requestPolicePresence]: {
    formDefinition: {
      sections: [
        {
          id: FormSection.requestPolicePresenceMain,
          strictFieldSchemas: {},
          formSchema: requestPolicePresenceMainFormSpecification.formSchema,
          name: "requestPolicePresence.title",
          autosave: false,
          pages: [],
        },
        {
          id: FormSection.recordPolicePresence,
          strictFieldSchemas:
            recordPolicePresenceFormSpecification.strictFieldSchemas,
          formSchema: recordPolicePresenceFormSpecification.formSchema,
          name: "recordPolicePresence.title",
          autosave: false,
          pages: recordPolicePresenceFormSpecification.pages,
        },
      ],
    },
  },

  [IncidentFormType.createIncident]: {
    formDefinition: createIncidentThalamosMin01,
  },

  [IncidentFormType.triage]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formDefinition: triageIncidentThalamosMin01 as any,
  },

  [IncidentFormType.recordPlaceOfSafety]: {
    formDefinition: {
      sections: [
        {
          id: FormSection.recordPlaceOfSafetyMain,
          strictFieldSchemas:
            recordPlaceOfSafetyFormSpecification.strictFieldSchemas,
          formSchema: recordPlaceOfSafetyFormSpecification.formSchema,
          name: "recordPlaceOfSafety.title",
          autosave: false,
          pages: recordPlaceOfSafetyFormSpecification.pages,
        },
        {
          id: FormSection.recordPlaceOfSafetyArrival,
          strictFieldSchemas:
            arrivePlaceOfSafetyFormSpecification.strictFieldSchemas,
          formSchema: arrivePlaceOfSafetyFormSpecification.formSchema,
          name: "arrivePlaceOfSafety.title",
          autosave: true,
          pages: arrivePlaceOfSafetyFormSpecification.pages,
        },
        {
          id: FormSection.recordPlaceOfSafetyLeave,
          strictFieldSchemas:
            leavePlaceOfSafetyFormSpecification.strictFieldSchemas,
          formSchema: leavePlaceOfSafetyFormSpecification.formSchema,
          name: "leavePlaceOfSafety.title",
          autosave: true,
          pages: leavePlaceOfSafetyFormSpecification.pages,
        },
      ],
    },
  },

  [IncidentFormType.preEndIncident]: {
    formDefinition: {
      sections: [
        {
          id: FormSection.preEndIncident,
          strictFieldSchemas: {},
          formSchema: preEndIncidentFormSpecification.formSchema,
          name: "incidentFormSections.endIncident",
          autosave: false,
          pages: preEndIncidentFormSpecification.pages,
        },
      ],
    },
  },

  [IncidentFormType.finishForm]: {
    formDefinition: {
      sections: [
        {
          id: FormSection.finishForm,
          strictFieldSchemas: {},
          formSchema: finishFormFormSpecification.formSchema,
          name: "finishForm.finishFormTitle",
          autosave: false,
          pages: finishFormFormSpecification.pages,
        },
      ],
    },
  },

  [IncidentFormType.section136]: {
    formDefinition: {
      sections: [
        {
          id: FormSection.personDetails,
          strictFieldSchemas: personDetailsFormSpecification.strictFieldSchemas,
          formSchema: personDetailsFormSpecification.formSchema,
          name: "incidentFormSections.person",
          autosave: false,
          pages: personDetailsFormSpecification.pages,
        },
        {
          id: FormSection.detentionDetails,
          strictFieldSchemas:
            detentionDetailsFormSpecification.strictFieldSchemas,
          formSchema: detentionDetailsFormSpecification.formSchema,
          name: "incidentFormSections.detention",
          autosave: true,
          pages: detentionDetailsFormSpecification.pages,
        },
        {
          id: FormSection.informationForHealthcare,
          strictFieldSchemas:
            informationForHealthcareFormSpecification.strictFieldSchemas,
          formSchema: informationForHealthcareFormSpecification.formSchema,
          name: "incidentFormSections.information",
          autosave: true,
          pages: informationForHealthcareFormSpecification.pages,
        },
      ],
    },
    pdfTemplate: min01PdfTemplate,
  },
};
